export const InputVariableDataTypes = ["text", "number", "boolean"];
export type InputVariableDataType = (typeof InputVariableDataTypes)[number];
export type InputVariable = {
  name: string;
  dataType: InputVariableDataType;
  isNullable: boolean;
  defaultInput: string | null;
};

export type VariableType = {
  id: number;
  dependsOn: number;
  value: string;
  dataSourceType: number;
};
export type TypeCsSourceList = {
  DsId: number;
  variables: VariableType[];
  istested: boolean;
  sourceName: string;
  alias: string;
};
export type ArrSourceType = TypeCsSourceList & {
  isUsed: boolean;
};

export type SourceScreen = "SELECT_SOURCE" | "INPUT";
