import ELK, { ElkExtendedEdge, ElkNode } from "elkjs/lib/elk.bundled.js";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Edge, MarkerType, Node, useReactFlow } from "reactflow";
import { WorkflowRunDetails } from "@screens/outcome-details/types";
import {
  BE_BRANCH_NODE_TYPE,
  BE_DECISION_TABLE_NODE_TYPE,
  BE_END_NODE_TYPE,
  BE_MODEL_NODE_TYPE,
  BE_POLICY_NODE_TYPE,
  BE_RULE_GROUP_NODE_TYPE,
  BE_RULE_SET_NODE_TYPE,
  BE_WORKFLOW_NODE_TYPE,
  BRANCH_NODE_TYPE,
  END_NODE_TYPE,
  MODEL_NODE_TYPE,
  POLICY_NODE_TYPE,
  DECISION_TABLE_NODE_TYPE,
  RULE_GROUP_NODE_TYPE,
  RULE_SET_NODE_TYPE,
  START_NODE_TYPE,
  SWITCH_NODE_TYPE,
  WORKFLOW_NODE_TYPE,
  BE_SOURCE_NODE_TYPE,
  SOURCE_NODE_TYPE,
  BE_MODEL_SET_NODE_TYPE,
  MODEL_SET_NODE_TYPE,
} from "@screens/workflow/config";
import logger from "@utils/Logger";
import { useWorkflowContext } from "../WorkflowContext";
import {
  Databasedswitchstate,
  FinalDecision,
  Injectstate,
  Operationstate,
  Schema,
  Transitiondatacondition,
  Workflow,
} from "../types";
import { WfWarning, validateWorkflow } from "./validations";

const withSWtoReactFlow = <T,>(
  WrappedComponent: (props: T) => ReactElement
) => {
  return () => {
    const { workflow, saveMeta } = useWorkflowContext();
    const [nodes, setNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);
    const [warnings, setWarnings] = useState<WfWarning[]>([]);
    const { fitView, getNode } = useReactFlow();

    useEffect(() => {
      const setupNodes = async () => {
        const { nodes, edges } = getNodesAndEdges(structuredClone(workflow));
        setNodes(nodes);
        setEdges([...edges]);

        setTimeout(() => {
          // the delay is added so that handles are populated before runnning validation
          const warnings = validateWorkflow({
            nodes,
            edges,
            getNode,
          });
          setWarnings(warnings);
        }, 700);
      };
      setupNodes();
      //   eslint-disable-next-line
    }, [workflow]);

    const autoArrange = useCallback(async () => {
      const { nodes: _nodes } = await elkToReactFlowNodes(
        structuredClone(nodes),
        structuredClone(edges)
      );

      setNodes(_nodes);
      setTimeout(() => {
        saveMeta();
        fitView({ duration: 500 });
      }, 100);
    }, [nodes, edges]);

    return (
      // @ts-ignore
      <WrappedComponent
        edges={edges}
        nodes={nodes}
        setEdges={setEdges}
        setNodes={setNodes}
        autoArrange={autoArrange}
        warnings={warnings}
      />
    );
  };
};
export default withSWtoReactFlow;

export const getNodesAndEdges = (
  workflow: Workflow | undefined,
  handleAsNodes: boolean = false
) => {
  let nodes: Node[] = [];
  let edges: Edge[] = [];
  if (!workflow?.schema) {
    return { nodes, edges };
  }
  const startNode = getStartNode(workflow?.schema);
  if (!startNode) {
    return { nodes, edges };
  }
  const startNodePos = getPositionFromMeta(workflow?.metadata, startNode.id);
  if (startNodePos) {
    startNode.position = startNodePos;
  }

  nodes = [startNode];
  edges = getStartEdge(workflow?.schema);
  workflow?.schema?.states.forEach((state) => {
    let newNodes = getNode(state, workflow?.schema?.states, handleAsNodes);
    if (newNodes) {
      newNodes?.forEach((node) => {
        if (node) {
          const nodePosition = getPositionFromMeta(workflow?.metadata, node.id);
          if (nodePosition) {
            node.position = nodePosition;
          }
          node && nodes.push({ ...node });
        }
      });
    }

    const newEdges = handleAsNodes
      ? getEdgesWithoutHandleFromState(workflow?.schema?.states, state)
      : getEdgesFromState(workflow?.schema?.states, state);

    edges = [...edges, ...newEdges];
  });
  return { nodes, edges };
};

export const getNodesAndEdgesWithHidden = (
  workflow: Workflow | undefined,
  policiesRan: string[],
  rulesRan: string[],
  finalDecision: string,
  workflowRun: WorkflowRunDetails[],
  handleAsNodes: boolean = false,
  evaluationOutput?: FinalDecision
) => {
  let { edges, nodes } = getNodesAndEdges(workflow, handleAsNodes);
  nodes = nodes.map((n) => {
    // TODO(rwithik): Add source node
    n.data["isFromFlow"] = true;
    if (n.type === POLICY_NODE_TYPE && !policiesRan.includes(n.data.policyID)) {
      n.data["hidden"] = true;
      n.selectable = false;
    }
    if (
      n.type === WORKFLOW_NODE_TYPE &&
      !Object.keys(evaluationOutput?.workflows ?? {}).includes(n.data.label)
    ) {
      n.data["hidden"] = true;
      n.selectable = false;
    } else if (
      n.type === RULE_GROUP_NODE_TYPE &&
      !rulesRan.includes(n.data.ruleID)
    ) {
      n.data["hidden"] = true;
      n.selectable = false;
    } else if (
      n.type === MODEL_NODE_TYPE &&
      !Object.keys(evaluationOutput?.models ?? {}).includes(n.data.label)
    ) {
      n.data["hidden"] = true;
      n.selectable = false;
    } else if (
      n.type === RULE_SET_NODE_TYPE &&
      !Object.keys(evaluationOutput?.ruleset ?? {}).includes(n.id)
    ) {
      n.data["hidden"] = true;
      n.selectable = false;
    } else if (
      n.type === BRANCH_NODE_TYPE &&
      !Object.keys(evaluationOutput?.branch ?? {}).includes(n.data.label)
    ) {
      n.data["hidden"] = true;
      n.selectable = false;
    } else if (
      n.type === MODEL_SET_NODE_TYPE &&
      !Object.keys(evaluationOutput?.models ?? {}).includes(n.data.label)
    ) {
      n.data["hidden"] = true;
      n.selectable = false;
    } else if (
      n.type === DECISION_TABLE_NODE_TYPE &&
      !Object.keys(evaluationOutput?.models ?? {}).includes(n.data.label)
    ) {
      n.data["hidden"] = true;
      n.selectable = false;
    } else if (n.type === END_NODE_TYPE && finalDecision !== n.data.label) {
      n.data["hidden"] = true;
    }
    return n;
  });

  edges = edges.map((edge) => {
    const isEdgeInFlow = !!workflowRun.find((wr) => {
      if (handleAsNodes) {
        if (
          wr.stateID === edge.source &&
          `${wr.stateID}-switch-${wr.transitionCondition}` === edge.target
        ) {
          return true;
        }
        if (
          `${wr.stateID}-switch-${wr.transitionCondition}` === edge.source &&
          wr.transition === edge.target
        ) {
          return true;
        }
      }

      if (wr.stateID === edge.source && wr.transition === edge.target) {
        if (wr.transitionCondition === "") {
          return true;
        }
        if (wr.transitionCondition === edge.sourceHandle) {
          return true;
        }
      }
      return false;
    });
    if (isEdgeInFlow) {
      edge.style = { stroke: "#4c4c4c", strokeWidth: 2 };
      edge.markerEnd = {
        type: MarkerType.Arrow,
        height: 12,
        width: 12,
        color: "#4c4c4c",
      };
    } else {
      edge.style = { stroke: "#4c4c4c10", strokeWidth: 2 };
      edge.markerEnd = {
        type: MarkerType.Arrow,
        height: 12,
        width: 12,
        color: "#4c4c4c10",
      };
    }
    return edge;
  });
  return { nodes, edges };
};

const getPositionedLayout = async (
  nodes: Node[],
  edges: Edge[],
  direction: string
) => {
  try {
    const elk = new ELK();
    const graph: ElkNode = {
      id: "root",
      layoutOptions: {
        "elk.direction": direction,
        "elk.algorithm": "layered",
        "elk.spacing.nodeNode": "320",
        "elk.layered.spacing.nodeNodeBetweenLayers": "400",
        // "elk.layered.wrapping.strategy": "MULTI_EDGE",
        "elk.edgeRouting": "POLYLINE",
        "elk.layered.mergeEdges": "true",
        // "elk.layered.layering.wideNodesOnMultipleLayers": "ON",
      },
      children: nodes?.map((n) => ({
        ...n,
        height: n.type === SWITCH_NODE_TYPE ? 0 : 40,
        width: 100,
      })), // mapped nodes
      edges: edges as unknown as ElkExtendedEdge[], // Our edges do not need to be transformed as they are in a format that both elk and React Flow can use
    };
    return await elk.layout(graph);
  } catch (error) {
    logger.error(error);
  }
};

export const elkToReactFlowNodes = async (
  nodes: Node[],
  edges: Edge[],
  direction: string = "RIGHT"
) => {
  const layout = await getPositionedLayout(nodes, edges, direction);
  if (!layout || !layout.children) {
    return { nodes: [], edges: [] as unknown as Array<Edge> };
  }

  const _nodes = layout.children.map((positionedNode) => {
    let foundNode = nodes[0];
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === positionedNode.id) {
        foundNode = nodes[i];
        const { x, y } = positionedNode;
        if (x && y) {
          foundNode.position = { x, y };
        }
        return foundNode;
      }
    }
    return foundNode;
  });
  return { nodes: _nodes, edges: layout.edges as unknown as Array<Edge> };
};

const getStateFromName = (
  states: Schema["states"],
  name: string | undefined
) => {
  if (!name) {
    return null;
  }
  let state = null;
  states?.forEach((s) => {
    if (s.name === name) {
      state = s;
    }
  });
  if (state) {
    return state as Schema["states"][number];
  }
  return null;
};

const getPositionFromMeta = (metadata: Record<string, any>, id: string) => {
  if (metadata[id]) {
    return metadata[id];
  }
  return null;
};

const getStartNode = (wf: Schema): Node | null => {
  let startState = getStateFromName(
    wf.states,
    wf.start?.stateName
  ) as Injectstate;
  if (startState) {
    return {
      id: startState.name!,
      type: START_NODE_TYPE,
      position: { x: 0, y: 0 },
      data: { label: startState.name },
      width: 300,
      height: 200,
      deletable: false,
    };
  }
  return null;
};

export const getSwitchNode = (
  states: Array<Schema["states"][number]>,
  stateName?: string
) => {
  if (!stateName) {
    return null;
  }
  for (const s of states) {
    if (s.name === stateName + "-switch") {
      return s;
    }
  }
  return null;
};

const getNodeWithHandles = (
  state: Schema["states"][number],
  states: Array<Schema["states"][number]>
) => {
  switch (state?.metadata?.type) {
    case BE_POLICY_NODE_TYPE:
      const policyState = state as Operationstate;
      return {
        id: policyState.name!,
        type: POLICY_NODE_TYPE,
        position: { x: 0, y: 0 },
        width: 300,
        height: 200,
        data: {
          label: policyState.metadata?.name,
          policyID: policyState.actions?.[0]?.functionRef?.arguments?.policyID,
          desc: policyState.metadata?.description,
        },
      };
    case BE_MODEL_NODE_TYPE:
      const modelState = state as Operationstate;
      return {
        id: modelState.name!,
        type: MODEL_NODE_TYPE,
        position: { x: 0, y: 0 },
        width: 300,
        height: 200,
        data: {
          label: modelState.metadata?.name,
          modelExpressionID:
            modelState.actions?.[0]?.functionRef?.arguments?.modelExpressionID,
          desc: modelState.metadata?.description,
        },
      };
    case BE_MODEL_SET_NODE_TYPE:
      const modelSetState = state as Operationstate;
      return {
        id: modelSetState.name!,
        type: MODEL_SET_NODE_TYPE,
        position: { x: 0, y: 0 },
        width: 300,
        height: 200,
        data: {
          label: modelSetState.metadata?.name,
          modelExpressionID:
            modelSetState.actions?.[0]?.functionRef?.arguments
              ?.modelExpressionID,
          desc: modelSetState.metadata?.description,
        },
      };
    case BE_SOURCE_NODE_TYPE:
      const sourceState = state as Operationstate;

      return {
        id: sourceState.name!,
        type: SOURCE_NODE_TYPE,
        position: { x: 0, y: 0 },
        width: 300,
        height: 200,
        data: {
          label: sourceState.metadata?.name,
          sourceNodeId:
            sourceState.actions?.[0]?.functionRef?.arguments?.modelExpressionID,
          desc: sourceState.metadata?.description,
        },
      };
    case BE_DECISION_TABLE_NODE_TYPE:
      const matrixState = state as Operationstate;
      return {
        id: matrixState.name!,
        type: DECISION_TABLE_NODE_TYPE,
        position: { x: 0, y: 0 },
        width: 300,
        height: 200,
        data: {
          label: matrixState.metadata?.name,
          matrixId:
            matrixState.actions?.[0]?.functionRef?.arguments
              ?.modelDecisionTableID,
        },
      };
    case BE_RULE_SET_NODE_TYPE:
      const rulesetState = state as Operationstate;
      const ruleset = getSwitchNode(
        states,
        rulesetState.name
      ) as Databasedswitchstate;
      const rulesetHandles = ruleset?.dataConditions?.map((c) => c.name) || [];
      return {
        id: rulesetState.name!,
        type: RULE_SET_NODE_TYPE,
        position: { x: 0, y: 0 },
        width: 250,
        height: 200,
        data: {
          label: rulesetState.metadata?.name,
          ruleID: rulesetState.name,
          desc: rulesetState.metadata?.description,
          handles: rulesetHandles,
        },
      };
    case BE_WORKFLOW_NODE_TYPE:
      const workflowState = state as Operationstate;
      const workflowSwitchState = getSwitchNode(
        states,
        workflowState.name
      ) as Databasedswitchstate;
      const workflowHandles = workflowSwitchState?.dataConditions?.map(
        (c) => c.name
      );
      return {
        id: workflowState.name!,
        type: WORKFLOW_NODE_TYPE,
        position: { x: 0, y: 0 },
        width: 300,
        height: 200,
        data: {
          label: workflowState.metadata?.name,
          workflowID:
            workflowState.actions?.[0]?.functionRef?.arguments?.workflowID,
          desc: workflowState.metadata?.description,
          handles: workflowHandles || [],
        },
      };
    case BE_RULE_GROUP_NODE_TYPE:
      const ruleState = state as Operationstate;
      const ruleSwitchState = getSwitchNode(
        states,
        ruleState.name
      ) as Databasedswitchstate;
      const handles = ruleSwitchState?.dataConditions?.map((c) => c.name);

      return {
        id: ruleState.name!,
        type: RULE_GROUP_NODE_TYPE,
        position: { x: 0, y: 0 },
        width: 250,
        height: 200,
        data: {
          label: ruleState.metadata?.name,
          ruleID: ruleState.actions?.[0]?.functionRef?.arguments?.ruleGroupID,
          desc: ruleState.metadata?.description,
          body: ruleState.metadata?.ruleGroupBody,
          handles: handles || [],
        },
      };
    case BE_BRANCH_NODE_TYPE:
      const branchState = state as Operationstate;
      const branchSwitchState = getSwitchNode(
        states,
        branchState.name
      ) as Databasedswitchstate;
      const branchHandles =
        branchSwitchState?.dataConditions?.map((c) => c.name) || [];
      const branchConditions = branchSwitchState?.dataConditions || [];
      return {
        id: branchState.name!,
        type: BRANCH_NODE_TYPE,
        position: { x: 0, y: 0 },
        width: 250,
        height: 200,
        data: {
          label: branchState.metadata?.name,
          ruleID: branchState.name,
          desc: branchState.metadata?.description,
          handles: branchHandles,
          conditions: branchConditions,
        },
      };
    case BE_END_NODE_TYPE:
      const endState = state as Operationstate;
      return {
        id: endState.name!,
        type: END_NODE_TYPE,
        position: { x: 0, y: 0 },
        width: 250,
        height: 200,
        data: { label: endState.actions?.[0].functionRef?.arguments?.decision },
      };
    default:
      return null;
  }
};

const getNodeWithoutHandles = (
  state: Schema["states"][number],
  states: Array<Schema["states"][number]>
) => {
  switch (state?.metadata?.type) {
    case BE_POLICY_NODE_TYPE:
      const policyState = state as Operationstate;
      return [
        {
          id: policyState.name!,
          type: POLICY_NODE_TYPE,
          position: { x: 0, y: 0 },
          width: 300,
          height: 200,
          data: {
            label: policyState.metadata?.name,
            policyID:
              policyState.actions?.[0]?.functionRef?.arguments?.policyID,
            desc: policyState.metadata?.description,
          },
        },
      ];
    case BE_MODEL_NODE_TYPE:
      const modelState = state as Operationstate;
      return [
        {
          id: modelState.name!,
          type: MODEL_NODE_TYPE,
          position: { x: 0, y: 0 },
          width: 300,
          height: 200,
          data: {
            label: modelState.metadata?.name,
            modelExpressionID:
              modelState.actions?.[0]?.functionRef?.arguments
                ?.modelExpressionID,
            desc: modelState.metadata?.description,
          },
        },
      ];
    case BE_MODEL_SET_NODE_TYPE:
      const modelSetState = state as Operationstate;
      return [
        {
          id: modelSetState.name!,
          type: MODEL_SET_NODE_TYPE,
          position: { x: 0, y: 0 },
          width: 300,
          height: 200,
          data: {
            label: modelSetState.metadata?.name,
            modelExpressionID:
              modelSetState.actions?.[0]?.functionRef?.arguments
                ?.modelExpressionID,
            desc: modelSetState.metadata?.description,
          },
        },
      ];
    case BE_SOURCE_NODE_TYPE:
      const sourceState = state as Operationstate;
      return [
        {
          id: sourceState.name!,
          type: SOURCE_NODE_TYPE,
          position: { x: 0, y: 0 },
          width: 300,
          height: 200,
          data: {
            label: sourceState.metadata?.name,
            sourceNodeId:
              sourceState.actions?.[0]?.functionRef?.arguments
                ?.modelExpressionID,
            desc: sourceState.metadata?.description,
          },
        },
      ];
    case BE_DECISION_TABLE_NODE_TYPE:
      const matrixState = state as Operationstate;
      return [
        {
          id: matrixState.name!,
          type: DECISION_TABLE_NODE_TYPE,
          position: { x: 0, y: 0 },
          width: 300,
          height: 200,
          data: {
            label: matrixState.metadata?.name,
            matrixId:
              matrixState.actions?.[0]?.functionRef?.arguments
                ?.modelDecisionTableID,
          },
        },
      ];
    case BE_WORKFLOW_NODE_TYPE:
      const workflowState = state as Operationstate;
      return [
        {
          id: workflowState.name!,
          type: WORKFLOW_NODE_TYPE,
          position: { x: 0, y: 0 },
          width: 300,
          height: 200,
          data: {
            label: workflowState.metadata?.name,
            workflowID:
              workflowState.actions?.[0]?.functionRef?.arguments?.workflowID,
            desc: workflowState.metadata?.description,
          },
        },
      ];
    case BE_RULE_GROUP_NODE_TYPE:
      const ruleState = state as Operationstate;
      const ruleSwitchState = getSwitchNode(
        states,
        ruleState.name
      ) as Databasedswitchstate;
      const handles = ruleSwitchState?.dataConditions?.map((c) => c.name);
      return [
        {
          id: ruleState.name!,
          type: RULE_GROUP_NODE_TYPE,
          position: { x: 0, y: 0 },
          width: 300,
          height: 200,
          data: {
            label: ruleState.metadata?.name,
            ruleID: ruleState.actions?.[0]?.functionRef?.arguments?.ruleGroupID,
            desc: ruleState.metadata?.description,
            body: ruleState.metadata?.ruleGroupBody,
            handles: handles || [],
          },
        },
      ];
    case BE_RULE_SET_NODE_TYPE:
      const ruleSetState = state as Operationstate;
      const ruleSetSwitchState = getSwitchNode(
        states,
        ruleSetState.name
      ) as Databasedswitchstate;
      const ruleSetHandles = ruleSetSwitchState?.dataConditions?.map(
        (c) => c.name
      );

      return [
        {
          id: ruleSetState.name!,
          type: RULE_GROUP_NODE_TYPE,
          position: { x: 0, y: 0 },
          width: 300,
          height: 200,
          data: {
            label: ruleSetState.metadata?.name,
            ruleID:
              ruleSetState.actions?.[0]?.functionRef?.arguments?.ruleGroupID,
            desc: ruleSetState.metadata?.description,
            body: ruleSetState.metadata?.ruleGroupBody,
            handles: ruleSetHandles || [],
          },
        },
      ];
    case BE_END_NODE_TYPE:
      const endState = state as Operationstate;
      return [
        {
          id: endState.name!,
          type: END_NODE_TYPE,
          position: { x: 0, y: 0 },
          width: 300,
          height: 200,
          data: {
            label: endState.actions?.[0].functionRef?.arguments?.decision,
          },
        },
      ];
  }
  if (state?.metadata?.type?.includes("-switch")) {
    const switchState = state as Databasedswitchstate;

    const switchNodes = switchState?.dataConditions?.map((m) => ({
      id: state.name + "-" + m.name,
      type: SWITCH_NODE_TYPE,
      position: { x: 0, y: 0 },
      width: 300,
      height: 200,
      data: {
        label: m.name,
      },
    }));
    return switchNodes || [];
  }
  return [];
};
const getNode = (
  state: Schema["states"][number],
  states: Array<Schema["states"][number]>,
  handleAsNodes: boolean = false
) => {
  const node = getNodeWithHandles(state, states);
  if (node) {
    return [node];
  }
  if (!handleAsNodes) {
    const node = getNodeWithHandles(state, states);
    return node ? [node] : [];
  }
  return getNodeWithoutHandles(state, states);
};

const getStartEdge = (wf: Schema): Edge[] => {
  let startState = getStateFromName(
    wf.states,
    wf.start?.stateName
  ) as Injectstate;
  if (startState.name && startState.transition?.nextState) {
    return [
      {
        id: startState.name + "->" + startState.transition.nextState,
        source: startState.name,
        sourceHandle: "start",
        target: startState.transition.nextState,
        ...edgeCommon,
      },
    ];
  }
  return [];
};

const policyLabelMap: Record<string, string> = {
  pass: "Approved",
  fail: "Rejected",
  cant_decide: "Can't Decide",
};

const edgeCommon: Partial<Edge> = {
  style: { stroke: "#cfcfcf", strokeWidth: 1.5 },
  type: "customedge",
  markerEnd: {
    color: "#cfcfcf",
    strokeWidth: 1.5,
    type: MarkerType.Arrow,
    height: 12,
    width: 12,
  },
};
const getEdgesFromState = (
  states: Schema["states"],
  state: Schema["states"][number]
) => {
  let edges: Edge[] = [];
  switch (state?.metadata?.type) {
    case BE_POLICY_NODE_TYPE:
      const policySwitchState = getStateFromName(
        states,
        state.name + "-switch"
      ) as Databasedswitchstate;
      policySwitchState?.dataConditions?.forEach((d) => {
        const nextState = (d as Transitiondatacondition)?.transition?.nextState;
        if (state.name && d.name && nextState) {
          edges.push({
            id: state.name + d.name + "->" + nextState,
            source: state.name,
            sourceHandle: d.name,
            target: nextState,
            label: policyLabelMap[d.name],
            ...edgeCommon,
          });
        }
      });
      break;
    case BE_MODEL_NODE_TYPE:
      const modelNextState = (state as Operationstate)?.transition?.nextState;
      if (modelNextState && state.name) {
        edges.push({
          id: state.name + "->" + modelNextState,
          source: state.name,
          target: modelNextState,
          ...edgeCommon,
        });
      }
      break;
    case BE_MODEL_SET_NODE_TYPE:
      const modelSetNextState = (state as Operationstate)?.transition
        ?.nextState;
      if (modelSetNextState && state.name) {
        edges.push({
          id: state.name + "->" + modelSetNextState,
          source: state.name,
          target: modelSetNextState,
          ...edgeCommon,
        });
      }
      break;
    case BE_SOURCE_NODE_TYPE:
      const sourceNextState = (state as Operationstate)?.transition?.nextState;
      if (sourceNextState && state.name) {
        edges.push({
          id: state.name + "->" + sourceNextState,
          source: state.name,
          target: sourceNextState,
          ...edgeCommon,
        });
      }
      break;
    case BE_RULE_SET_NODE_TYPE:
      const ruleSetSwitchState = getStateFromName(
        states,
        state.name + "-switch"
      ) as Databasedswitchstate;
      ruleSetSwitchState?.dataConditions?.forEach((d) => {
        const nextState = (d as Transitiondatacondition)?.transition?.nextState;
        if (state.name && d.name && nextState) {
          edges.push({
            id: state.name + d.name + "->" + nextState,
            source: state.name,
            sourceHandle: d.name,
            label: d.name,
            target: nextState,
            ...edgeCommon,
          });
        }
      });
      break;
    case BE_DECISION_TABLE_NODE_TYPE:
      const matrixNextState = (state as Operationstate)?.transition?.nextState;
      if (matrixNextState && state.name) {
        edges.push({
          id: state.name + "->" + matrixNextState,
          source: state.name,
          target: matrixNextState,
          ...edgeCommon,
        });
      }
      break;
    case BE_RULE_GROUP_NODE_TYPE:
      const ruleSwitchState = getStateFromName(
        states,
        state.name + "-switch"
      ) as Databasedswitchstate;
      ruleSwitchState?.dataConditions?.forEach((d) => {
        const nextState = (d as Transitiondatacondition)?.transition?.nextState;
        if (state.name && d.name && nextState) {
          edges.push({
            id: state.name + d.name + "->" + nextState,
            source: state.name,
            sourceHandle: d.name,
            label: d.name,
            target: nextState,
            ...edgeCommon,
          });
        }
      });
      break;
    case BE_BRANCH_NODE_TYPE:
      const branchSwitch = getStateFromName(
        states,
        state.name + "-switch"
      ) as Databasedswitchstate;
      branchSwitch?.dataConditions?.forEach((d) => {
        const nextState = (d as Transitiondatacondition)?.transition?.nextState;
        if (state.name && d.name && nextState) {
          edges.push({
            id: state.name + d.name + "->" + nextState,
            source: state.name,
            sourceHandle: d.name,
            label: d.name,
            target: nextState,
            ...edgeCommon,
          });
        }
      });
      break;
    case BE_WORKFLOW_NODE_TYPE:
      const workflowSwitchState = getStateFromName(
        states,
        state.name + "-switch"
      ) as Databasedswitchstate;
      workflowSwitchState?.dataConditions?.forEach((d) => {
        const nextState = (d as Transitiondatacondition)?.transition?.nextState;
        if (state.name && d.name && nextState) {
          edges.push({
            id: state.name + d.name + "->" + nextState,
            source: state.name,
            sourceHandle: d.name,
            label: d.name,
            target: nextState,
            ...edgeCommon,
          });
        }
      });
      break;
    default:
      break;
  }
  return edges;
};

const getEdgesWithoutHandleFromState = (
  states: Schema["states"],
  state: Schema["states"][number]
) => {
  let edges: Edge[] = [];
  switch (state?.metadata?.type) {
    case BE_POLICY_NODE_TYPE:
      const policySwitchState = getStateFromName(
        states,
        state.name + "-switch"
      ) as Databasedswitchstate;
      policySwitchState?.dataConditions?.forEach((d) => {
        const nextState = (d as Transitiondatacondition)?.transition?.nextState;
        if (state.name && policySwitchState.name && d.name) {
          edges.push({
            id: policySwitchState.name + "->" + d.name,
            source: state.name,
            // label: d.name,
            target: policySwitchState.name + "-" + d.name,
            ...edgeCommon,
          });
        }
        if (policySwitchState.name && d.name && nextState) {
          edges.push({
            id: policySwitchState.name + d.name + "->" + nextState,
            source: policySwitchState.name + "-" + d.name,
            // label: d.name,
            target: nextState,
            ...edgeCommon,
          });
        }
      });
      break;
    case BE_RULE_GROUP_NODE_TYPE:
    case BE_BRANCH_NODE_TYPE:
    case BE_RULE_SET_NODE_TYPE:
      const ruleSwitchState = getStateFromName(
        states,
        state.name + "-switch"
      ) as Databasedswitchstate;
      ruleSwitchState?.dataConditions?.forEach((d) => {
        const nextState = (d as Transitiondatacondition)?.transition?.nextState;
        if (state.name && ruleSwitchState.name && d.name) {
          edges.push({
            id: ruleSwitchState.name + "->" + d.name,
            source: state.name,
            target: ruleSwitchState.name + "-" + d.name,
            ...edgeCommon,
          });
        }
        if (ruleSwitchState.name && d.name && nextState) {
          edges.push({
            id: ruleSwitchState.name + d.name + "->" + nextState,
            source: ruleSwitchState.name + "-" + d.name,
            target: nextState,
            ...edgeCommon,
          });
        }
      });
      break;
    case BE_MODEL_NODE_TYPE:
    case BE_MODEL_SET_NODE_TYPE:
      const modelNextState = (state as Operationstate)?.transition?.nextState;
      if (modelNextState && state.name) {
        edges.push({
          id: state.name + "->" + modelNextState,
          source: state.name,
          target: modelNextState,
          ...edgeCommon,
        });
      }
      break;
    case BE_SOURCE_NODE_TYPE:
      const sourceNextState = (state as Operationstate)?.transition?.nextState;
      if (sourceNextState && state.name) {
        edges.push({
          id: state.name + "->" + sourceNextState,
          source: state.name,
          target: sourceNextState,
          ...edgeCommon,
        });
      }
      break;
    case BE_WORKFLOW_NODE_TYPE:
      const workflowSwitchState = getStateFromName(
        states,
        state.name + "-switch"
      ) as Databasedswitchstate;
      workflowSwitchState?.dataConditions?.forEach((d) => {
        const nextState = (d as Transitiondatacondition)?.transition?.nextState;
        if (state.name && workflowSwitchState.name && d.name) {
          edges.push({
            id: workflowSwitchState.name + "->" + d.name,
            source: state.name,
            // label: d.name,
            target: workflowSwitchState.name + "-" + d.name,
            ...edgeCommon,
          });
        }
        if (workflowSwitchState.name && d.name && nextState) {
          edges.push({
            id: workflowSwitchState.name + d.name + "->" + nextState,
            source: workflowSwitchState.name + "-" + d.name,
            // label: d.name,
            target: nextState,
            ...edgeCommon,
          });
        }
      });
      break;
    default:
      break;
  }
  return edges;
};
