import axios from "@axios";
import { LOCALSTORAGE_KEYS } from "@config";
import { AppDispatchContext } from "@context/AppProvider";
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  FinBoxResponse,
  NotificationList,
  NotificationType,
  PolicyStatus,
  ProgramTemplateWithOutcome,
} from "src/types";
import {
  getNetworkErrorText,
  normalizeQueryKey,
  notify,
} from "src/utils/utils";

export const getProgramsQuery = (id?: string) => {
  return queryOptions({
    queryKey: ["getProgram", id],
    queryFn: async () => {
      return axios.get<
        FinBoxResponse<Omit<ProgramTemplateWithOutcome, "clientId" | "id">>
      >(`/program/${id}`);
    },
    select: (data) => data.data?.data,
    enabled: !!id,
  });
};

export const getOutcomesQuery = () => {
  return queryOptions({
    queryKey: ["allOutcomes"],
    queryFn: async () => axios.get<FinBoxResponse<string[]>>("/outcomes"),
  });
};

export const useUpdatePolicyStatus = () =>
  useMutation({
    mutationFn: ({
      oldStatus,
      newStatus,
      policyVersion,
      note,
      emails,
    }: {
      oldStatus: PolicyStatus;
      newStatus: PolicyStatus;
      policyVersion: string;
      note?: string;
      emails?: string[];
    }) =>
      axios.post<FinBoxResponse<any>>(`${oldStatus}/${newStatus}`, {
        policyVersion,
        note,
        emails,
      }),
  });

export const useUpdatePolicyVersionStatus = () =>
  useMutation({
    mutationFn: ({
      oldStatus,
      newStatus,
      policyId,
      note,
      emails,
    }: {
      oldStatus: PolicyStatus;
      newStatus: PolicyStatus;
      policyId: string;
      note?: string;
      emails?: string[];
    }) =>
      axios.post<FinBoxResponse<any>>(
        `updatepolicystatus/${oldStatus}/${newStatus}`,
        {
          policyId,
          note,
          emails,
        }
      ),
  });

export const useDownloadPolicyVersionReport = () =>
  useMutation({
    mutationFn: ({ policyId }: { policyId: string }) =>
      axios.get<FinBoxResponse<Record<string, string>>>(
        `downloadPolicy?policy_id=${policyId}`
      ),
  });

export const useDownloadWorkflowVersionReport = () =>
  useMutation({
    mutationFn: ({ policyId }: { policyId: string }) =>
      axios.post<FinBoxResponse<string>>(`workflow/download/${policyId}`),
  });

export const useUpdateWorkflowStatus = (workflowID?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      oldStatus,
      newStatus,
      workflowID,
      emails,
      note,
    }: {
      oldStatus: PolicyStatus;
      newStatus: PolicyStatus;
      workflowID: string;
      emails?: string[];
      note?: string;
    }) =>
      axios.post<FinBoxResponse<any>>(`workflow/${oldStatus}/${newStatus}`, {
        workflowID,
        emails,
        note,
      }),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["getWorkflow", workflowID] }),
  });
};

export const useResetPassword = () =>
  useMutation({
    mutationFn: ({ password, token }: { password: string; token: string }) =>
      axios.post<FinBoxResponse<any>>(
        "newUserCreate",
        {
          password,
        },
        {
          headers: {
            token,
          },
        }
      ),
  });

export const useForgotPassword = () =>
  useMutation({
    mutationFn: ({ email }: { email: string }) =>
      axios.post<FinBoxResponse<any>>("forgotPasswordEmail", {
        email,
      }),
  });

export const useForgotPasswordUpdate = () =>
  useMutation({
    mutationFn: ({ password, token }: { password: string; token: string }) =>
      axios.post<FinBoxResponse<any>>(
        "forgotPasswordUpdate",
        {
          password,
        },
        {
          headers: {
            token,
          },
        }
      ),
  });

export const useGetProgram = (id?: string) => useQuery(getProgramsQuery(id));

export const useLogout = (redirect: boolean = true) => {
  const { setAppState } = useContext(AppDispatchContext);
  const navigate = useNavigate();
  const q = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      if (!localStorage.getItem(LOCALSTORAGE_KEYS.TOKEN)) return;
      q.clear();
      return axios.post("logout");
    },
    onSuccess: () => {
      if (!localStorage.getItem(LOCALSTORAGE_KEYS.TOKEN)) return;
      setAppState((prevState) => ({
        ...prevState,
        isAuthenticated: false,
        token: "",
        paletteOpen: false,
        role: "",
      }));
      Object.keys(LOCALSTORAGE_KEYS).forEach((item) => {
        localStorage.removeItem(
          LOCALSTORAGE_KEYS[item as keyof typeof LOCALSTORAGE_KEYS]
        );
      });
      if (redirect) navigate("/login");
    },
  });
};

export function useGetOutcomes() {
  return useQuery(getOutcomesQuery());
}

export const getNotificationsList = (args?: {
  page?: number;
  limit?: number;
  type?: string[];
  showUnread?: boolean;
}) => {
  return queryOptions({
    queryKey: normalizeQueryKey([
      "getNotificationsList",
      args?.type,
      args?.page,
      args?.limit,
      args?.showUnread,
    ]),
    queryFn: async () =>
      axios.get<
        FinBoxResponse<{
          count: number;
          list: NotificationList[];
        }>
      >(`/notifications/all`, {
        params: {
          type: args?.type,
          page: args?.page,
          limit: args?.limit,
          showUnread: args?.showUnread,
        },
      }),
  });
};
export const getNotReadCounts = () => {
  return queryOptions({
    queryKey: ["getNotReadCounts"],
    queryFn: async () =>
      axios.get<FinBoxResponse<Record<NotificationType, number>>>(
        `/notifications/notReadCounts`
      ),
  });
};

export const useMarkAllRead = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: () =>
      axios.post<FinBoxResponse<Record<string, string>>>(
        "/notifications/markAllRead"
      ),
    onSuccess: () => {
      qc.invalidateQueries(getNotificationsList());
      qc.invalidateQueries(getNotReadCounts());
      notify({
        title: "Updated notifications",
        text: "Marked all notifications read",
        type: "success",
      });
    },
    onError: (e) => {
      notify({
        title: "Could not update notifications",
        text: getNetworkErrorText(e),
      });
    },
  });
};

export const useMarkNotificationRead = () => {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: ({ notificationId }: { notificationId: number }) =>
      axios.post<FinBoxResponse<Record<string, string>>>(
        `/notifications/markRead?id=${notificationId}`,
        {
          id: notificationId,
        }
      ),
    onSuccess: () => {
      qc.invalidateQueries(getNotificationsList());
      qc.invalidateQueries(getNotReadCounts());
    },
    onError: (e) => {
      notify({
        title: "Could not update notifications",
        text: getNetworkErrorText(e),
      });
    },
  });
};
