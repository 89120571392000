import { useNavigate } from "react-router-dom";
import Button from "@components/Button";
import ContentWrapper from "@components/ContentWrapper";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <ContentWrapper>
      <div className="min-h-full justify-center px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <div className="gap-2 flex flex-col items-center">
              <h1 className="text-4xl font-medium tracking-tight text-neutral-black">
                Coming soon
              </h1>
              <p className="mt-1 font-b1-medium text-neutral-500">
                Feature is under construction, stay tuned for updates
              </p>
              <Button
                className="grow-0 w-fit mt-4"
                onClick={() => navigate("/")}
              >
                Go back home
              </Button>
            </div>
          </main>
        </div>
      </div>
    </ContentWrapper>
  );
}
