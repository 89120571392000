import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

type BreadcrumbItem = {
  name: string;
  link: string;
};

type BreadcrumbsContextType = {
  breadcrumbs: BreadcrumbItem[];
  setBreadcrumbs: setStateType<BreadcrumbItem[]>;
};

export const BreadcrumbsContext = createContext<
  BreadcrumbsContextType | undefined
>(undefined);

export const BreadcrumbsProvider = (props: PropsWithChildren) => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        setBreadcrumbs,
      }}
    >
      {props.children}
    </BreadcrumbsContext.Provider>
  );
};

export function useBreadcrumbsContext() {
  const context = useContext(BreadcrumbsContext);
  if (!context)
    throw new Error(
      "useBreadcrumbsContext can only be used inside an BreadcrumbsProvider"
    );
  return context;
}

export default function useBreadcrumbs(args: BreadcrumbItem[]) {
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const stringArgs = JSON.stringify(args);
  useEffect(() => {
    setBreadcrumbs(JSON.parse(stringArgs));
  }, [setBreadcrumbs, stringArgs]);
}
