import alertCircle from "@assets/icons/alert-circle.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import QueryWrapper from "@components/QueryWrapper";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { END_NODE_TYPE } from "@screens/workflow/config";
import BottomPanel from "@screens/workflow/studio/components/BottomPanel";
import { ErrorList } from "@screens/workflow/studio/components/ErrorList";
import CustomScript from "@screens/workflow/studio/components/Outcome/CustomScript";
import {
  getOutcomeConfig,
  useUpdateOutcomeNode,
} from "@screens/workflow/studio/components/Outcome/queries";
import useBottomPanelState, {
  CLOSE,
} from "@screens/workflow/studio/hooks/useBottomPanelState";
import useGetIcon from "@screens/workflow/studio/hooks/useGetIcon";
import useIsHiddenAfterTest from "@screens/workflow/studio/hooks/useIsHiddenAfterTest";
import {
  getIcon,
  getUpdateCustomEndNode,
} from "@screens/workflow/studio/utils";
import { EndId } from "@screens/workflow/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { clsx } from "clsx";
import { memo, useState } from "react";
import { useParams } from "react-router-dom";
import { Handle, NodeProps, Position } from "reactflow";
import InputList from "../../components/InputList";
import NodeMenu from "../../components/NodeMenu";
import OutcomeWorkflow from "../../components/Outcome/OutcomeWorkflow";
import useIsExpand, {
  useGetHandleStyles,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
} from "../../hooks/useIsExpand";
import useKeywordsFromWorkflowKeywords from "../../hooks/useKeywordsFromWorkflowKeywords";

function getLabel(label: string) {
  switch (label.toLowerCase()) {
    case "approved":
      return "Approved";
    case "rejected":
      return "Rejected";
    case "cant_decide":
      return "Can't Decide";
    default:
      return label;
  }
}

const EndNode = ({ data, selected, id }: NodeProps) => {
  const {
    updateWorkflow,
    workflow,
    isWorkflowEditable,
    setShowDeleteConfirmation,
    errors,
  } = useWorkflowContext();
  const [isEditing, _setIsEditing] = useState(false);
  const queryClient = useQueryClient();
  const { workflowId } = useParams();

  const isExpanded = useIsExpand();
  const [bottomPanelStatus, setBottomPanelStatus] = useBottomPanelState();

  const fontStyles = useGetNodeFontStyles(12, 18);
  const iconStyles = useGetNodeIconStyles();
  const handleStyles = useGetHandleStyles();

  const updateConfig = useUpdateOutcomeNode();

  const onUpdateCustomEndNode = async (p: string) => {
    const e = getUpdateCustomEndNode(p, id);
    await updateWorkflow(e);
    setIsEditing(false);
  };
  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflow?.id, id);

  const setIsEditing = (value: boolean) => {
    const isEditable =
      data.label !== "approved" &&
      data.label !== "rejected" &&
      data.label !== "cant_decide";

    _setIsEditing(isEditable && isWorkflowEditable && value);
  };

  const hidden = useIsHiddenAfterTest(id, data?.hidden);
  const Icon = useGetIcon(END_NODE_TYPE);

  const nodeErrors =
    errors?.[id as EndId]?.errors?.split(",").filter((s) => s.length) ?? [];

  // const { ref, isInView } = useInView();

  const outcomeConfig = useQuery(
    getOutcomeConfig({
      workflowId: workflow?.id!,
      nodeId: id,
      // enabled: isInView && isExpanded,
    })
  );

  if (!outcomeConfig.data?.data.data.from || !isExpanded)
    return (
      <div
        // ref={ref}
        className={clsx(
          "bg-white py-1.5 pl-2.5 pr-2 border-neutral-100 rounded-full overflow-visible flex gap-2 justify-center font-b2 border items-center",
          selected && "border-primary-200",
          {
            "opacity-25": hidden,
            "shadow-studio-node": !(data?.isFromFlow && !hidden),
            "shadow-lg": data?.isFromFlow && !hidden,
            "!border-error-500": nodeErrors.length > 0,
          }
        )}
        style={fontStyles}
      >
        <Icon label={data.label} style={iconStyles} />
        {isEditing ? (
          <input
            autoFocus
            defaultValue={data.label || "end_node"}
            onBlur={(e) => {
              onUpdateCustomEndNode(e.target.value);
            }}
            className="outline-0 h-6 border-neutral-100 border rounded-md px-1"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onUpdateCustomEndNode((e.target as HTMLInputElement).value);
              }
            }}
          />
        ) : (
          <span onClick={() => setIsEditing(isWorkflowEditable)}>
            {getLabel(data.label) || "custom_outcome"}
          </span>
        )}
        <NodeMenu
          isExport={false}
          items={[
            {
              key: "delete",
              label: (
                <span className="text-error-500 flex gap-2 items-center">
                  <TrashIcon className="[&>path]:stroke-error-500 h-3 w-3 -mt-0.5" />
                  Delete
                </span>
              ),
              onClick: () =>
                setShowDeleteConfirmation({ id: id, label: data.label }),
            },
          ]}
          isFromFlow={data.isFromFlow}
        />
        <Handle
          type="target"
          position={Position.Left}
          className="w-2 h-2 opacity-50 hover:opacity-100 rounded-full bg-white border-neutral-500 duration-150 !-left-4"
          isConnectable={isWorkflowEditable}
          style={handleStyles}
        >
          <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
        </Handle>
      </div>
    );

  const updateTab = (value: "decisionNode" | "workflows") => {
    if (!isWorkflowEditable) return;

    queryClient.setQueryData(
      getOutcomeConfig({
        workflowId: workflow?.id!,
        nodeId: id,
      }).queryKey,
      (prev) => {
        if (!prev) return;

        const newConfig = structuredClone(prev.data.data);
        newConfig.from = value;
        return { ...prev, data: { ...prev.data, data: newConfig } };
      }
    );
  };

  return (
    <>
      <div
        // ref={ref}
        className={clsx(
          "bg-white p-2 w-[360px] relative border-neutral-100 rounded-lg overflow-visible flex flex-col justify-center font-b2 border",
          selected && "border-primary-200",
          {
            "opacity-25": hidden,
            "shadow-studio-node": !(data?.isFromFlow && !hidden),
            "shadow-lg": data?.isFromFlow && !hidden,
            "pb-10": isExpanded,
          }
        )}
        style={fontStyles}
      >
        <div className={clsx("flex items-center", isExpanded ? "mb-2" : "")}>
          <span className="mr-1.5">{getIcon(data.label, iconStyles)}</span>
          {isEditing ? (
            <input
              autoFocus
              defaultValue={data.label || "end_node"}
              onBlur={(e) => onUpdateCustomEndNode(e.target.value)}
              className="outline-0 h-6 border-neutral-100 border rounded-md px-1 mr-auto"
              onKeyDown={(e) => {
                if (e.key === "Enter")
                  onUpdateCustomEndNode((e.target as HTMLInputElement).value);
              }}
            />
          ) : (
            <span
              className="mr-auto"
              onClick={() => setIsEditing(isWorkflowEditable)}
            >
              {getLabel(data.label) || "custom_outcome"}
            </span>
          )}
          <div className="flex items-center gap-4">
            <NodeMenu
              isExport={false}
              items={[
                {
                  key: "delete",
                  label: (
                    <span className="text-error-500 flex gap-2 items-center">
                      <TrashIcon className="[&>path]:stroke-error-500 h-3 w-3 -mt-0.5" />
                      Delete
                    </span>
                  ),
                  onClick: () =>
                    setShowDeleteConfirmation({ id: id, label: data.label }),
                },
              ]}
              isFromFlow={data.isFromFlow}
            />
          </div>
        </div>
        {isExpanded && (
          <QueryWrapper query={outcomeConfig}>
            {(data) => {
              const currentTab = data.data.data.from;

              const changeTab = (newTab: "workflows" | "decisionNode") => {
                const newConfig = structuredClone(data.data.data);
                newConfig.from = newTab;
                updateConfig.mutate({
                  workflowId: workflowId!,
                  nodeId: id,
                  payload: newConfig,
                });
                updateTab(newTab);
              };

              return (
                <>
                  <div
                    className={clsx(
                      "w-full flex items-center mb-2 h-[28px] border divide-x divide-neutral-100 rounded-md overflow-clip border-neutral-100",
                      isWorkflowEditable
                        ? "cursor-pointer"
                        : "cursor-not-allowed"
                    )}
                  >
                    <div
                      className={clsx(
                        "text-center font-b2-medium w-1/2 h-[28px] flex items-center justify-center",
                        currentTab === "decisionNode" && "bg-neutral-50"
                      )}
                      onClick={() => changeTab("decisionNode")}
                    >
                      Custom Script
                    </div>
                    <div
                      className={clsx(
                        "text-center font-b2-medium w-1/2 h-[28px] flex items-center justify-center",
                        currentTab === "workflows" && "bg-neutral-50"
                      )}
                      onClick={() => changeTab("workflows")}
                    >
                      Workflow
                    </div>
                  </div>
                  {
                    {
                      workflows: (
                        <OutcomeWorkflow config={data.data.data} nodeId={id} />
                      ),
                      decisionNode: (
                        <CustomScript config={data.data.data} nodeId={id} />
                      ),
                    }[currentTab]
                  }
                </>
              );
            }}
          </QueryWrapper>
        )}

        <Handle
          type="target"
          position={Position.Left}
          className="w-2 h-2 top-[22px] opacity-50 hover:opacity-100 rounded-full border-2 bg-neutral-0  border-neutral-300 duration-150 !-left-4"
          isConnectable={isWorkflowEditable}
          style={handleStyles}
        >
          <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
        </Handle>
        {isExpanded && (
          <div className="absolute bottom-[0px] left-[0px] w-[358px] h-7 border-t bg-neutral-0 rounded-b-[8px] font-b2-medium border-neutral-100 px-2 py-1.5 flex gap-6 items-center">
            <span
              className="cursor-pointer text-neutral-500 hover:text-neutral-black"
              onClick={() => setBottomPanelStatus("Inputs")}
            >
              Inputs
            </span>
            {nodeErrors.length > 0 && (
              <span
                className="flex items-center ml-auto gap-1.5 cursor-pointer text-error-500 font-b2-medium"
                onClick={() => setBottomPanelStatus("Errors")}
              >
                <img src={alertCircle} alt="!" />
                {nodeErrors.length}{" "}
                {nodeErrors.length === 1 ? " Error" : " Errors"}
              </span>
            )}
          </div>
        )}
      </div>
      <BottomPanel selected={selected} show={bottomPanelStatus === "Inputs"}>
        <InputList
          close={() => setBottomPanelStatus(CLOSE)}
          keywords={keywordsQuery}
          query={""}
        />
      </BottomPanel>
      <BottomPanel selected={selected} show={bottomPanelStatus === "Errors"}>
        <ErrorList
          errorCount={nodeErrors.length}
          close={() => setBottomPanelStatus(CLOSE)}
        >
          {nodeErrors.map((desc, index) => (
            <ErrorList.ItemDescription key={desc + index}>
              {desc}
            </ErrorList.ItemDescription>
          ))}
        </ErrorList>
      </BottomPanel>
    </>
  );
};

export default memo(EndNode);
