import {
  keepPreviousData,
  queryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { normalizeQueryKey } from "@utils/utils";
import axios from "src/axios";
import { FinBoxResponse } from "src/types";
import { getNetworkErrorText, notify } from "src/utils/utils";
import {
  ConfigType,
  CurlType,
  DatasourceDetails,
  LookupFileUpload,
  LookupTableSources,
  SaveConfigType,
  SaveCurlType,
  UpdateDatasourceType,
  ViewVariableType,
} from "./types";
import { AvailableSources } from "../create-policy/types";

export const useGetVar = (text: string) =>
  useQuery({
    queryKey: ["curlVariables", text],
    queryFn: () =>
      axios.post<FinBoxResponse<{ variables: string[] | null }>>(
        "datasource/extractVariables",
        {
          text,
        }
      ),
    enabled: !!text,
    placeholderData: keepPreviousData,
    meta: {
      errorMessage: "Failed to extract variables",
    },
  });

export const useTestDsConnCurl = () =>
  useMutation({
    mutationFn: ({ curl, timeout = 0, retries = 0, variables }: CurlType) => {
      return axios.post<
        FinBoxResponse<{
          responseBody: null | Record<string, any>;
          statusCode: number;
        }>
      >("datasource/testCurl", {
        curl,
        timeout,
        retries,
        variables,
      });
    },
  });

export const useSaveDsConnCurl = () => {
  return useMutation({
    mutationFn: ({
      curl,
      name,
      timeout = 0,
      retries = 0,
      variables,
      output,
      istested,
      alias,
    }: SaveCurlType) => {
      return axios.post<
        FinBoxResponse<{
          id: number;
        }>
      >("datasource/saveCurl", {
        curl,
        timeout,
        retries,
        variables,
        output,
        istested,
        name,
        alias,
      });
    },
  });
};

export const useTestDsConn = () =>
  useMutation({
    mutationFn: ({
      url,
      method,
      headers,
      body,
      timeout = 0,
      retries = 0,
      variables,
    }: ConfigType) => {
      return axios.post<
        FinBoxResponse<{
          responseBody: null | Record<string, any>;
          statusCode: number;
        }>
      >("datasource/test", {
        url,
        method,
        headers,
        body,
        timeout,
        retries,
        variables,
      });
    },
  });

export const useSaveDsConn = () => {
  return useMutation({
    mutationFn: ({
      name,
      url,
      method,
      headers,
      body,
      timeout = 0,
      retries = 0,
      variables,
      output,
      istested,
      alias,
    }: SaveConfigType) => {
      return axios.post<
        FinBoxResponse<{
          id: number;
        }>
      >("datasource/save", {
        name,
        url,
        method,
        headers,
        body,
        timeout,
        retries,
        variables,
        output,
        istested,
        alias,
      });
    },
  });
};

export type TypeVarList = {
  variableID: number;
  variableName: string;
  datasourceID: number;
  type: string;
};

export const useGetDsVarList = () =>
  useMutation({
    mutationFn: ({
      id,
      entityID,
      entityType,
    }: {
      id: number;
      entityID: string;
      entityType: string;
    }) => {
      return axios.get<FinBoxResponse<Record<"variables", TypeVarList[]>>>(
        `datasource/variableList`,
        {
          params: {
            dataSourceID: id,
            entityID: entityID,
            entityType: entityType,
          },
        }
      );
    },
  });

type TypeVariable = {
  id: number;
  dependsOn: number;
  value: string;
};

export const useAddDS = () => {
  return useMutation({
    mutationFn: ({
      entityID,
      dataSourceID,
      variables,
      entityType,
    }: {
      entityID: string;
      dataSourceID: number;
      variables: Array<TypeVariable>;
      entityType: string;
    }) => {
      return axios.post<FinBoxResponse<Record<string, string>>>(
        "datasource/entityMapping/add",
        {
          entityID,
          dataSourceID,
          variables,
          entityType,
        }
      );
    },
  });
};

export const useViewCSMapping = () => {
  return useMutation({
    mutationFn: ({
      dataSourceID,
      entityID,
      entityType,
    }: {
      dataSourceID: number;
      entityID: string;
      entityType: string;
    }) => {
      return axios.post<
        FinBoxResponse<Record<"variables", ViewVariableType[]>>
      >("datasource/entityMapping/view", {
        dataSourceID,
        entityID,
        entityType,
      });
    },
  });
};

export const useUpdateDS = () => {
  return useMutation({
    mutationFn: ({
      entityID,
      dataSourceID,
      variables,
      entityType,
    }: {
      entityID: string;
      dataSourceID: number;
      variables: Array<TypeVariable>;
      entityType: string;
    }) => {
      return axios.post<FinBoxResponse<Record<string, string>>>(
        "datasource/entityMapping/update",
        {
          entityID,
          dataSourceID,
          variables,
          entityType,
        }
      );
    },
  });
};

export const useDeleteDsMapping = () => {
  return useMutation({
    mutationFn: async ({
      entityID,
      dataSourceID,
      entityType,
    }: {
      entityID: string;
      dataSourceID: number;
      entityType: string;
    }) =>
      axios.post<FinBoxResponse<string>>("datasource/entityMapping/delete", {
        entityID,
        dataSourceID,
        entityType,
      }),
  });
};

export const useDeleteDS = () => {
  return useMutation({
    mutationFn: async ({ dataSourceID }: { dataSourceID: number }) =>
      axios.post<FinBoxResponse<string>>("datasource/delete", {
        dataSourceID,
      }),
    onSuccess: () => {
      notify({
        title: "Deleted Successfully",
        text: "Datasource deleted",
        type: "success",
      });
    },
    onError: (err) =>
      notify({
        title: "Couldn't delete datasource",
        text: getNetworkErrorText(err),
      }),
  });
};

export const lookupTableListQuery = (args?: {
  pageNumber?: number;
  name?: string;
  pageSize?: number;
  isRefetch?: boolean;
}) =>
  queryOptions({
    queryKey: normalizeQueryKey(["lookupTableList", args]),
    queryFn: async () => {
      const params = {
        pageNumber: args?.pageNumber,
        name: args?.name,
        pageSize: args?.pageSize,
      };
      return axios.get<FinBoxResponse<Record<string, LookupTableSources>>>(
        `/policybucket/lookups/list`,
        {
          params,
        }
      );
    },
    select: (data) => data.data,
    refetchInterval: (query) =>
      args?.isRefetch &&
      query.state.data?.data.data.lookups?.some(
        (item) => item.status.toLowerCase() === "processing"
      )
        ? 5000
        : false,
  });

export const useLookupDownloadFile = () => {
  return useMutation({
    mutationFn: ({ lookupId }: { lookupId: number }) =>
      axios.get<FinBoxResponse<string>>(`/lookup/version/download/${lookupId}`),
    onError: (e) => {
      notify({
        title: "Could not download lookup file",
        text: getNetworkErrorText(e),
      });
    },
  });
};

export const useLookupDeactivateFile = () => {
  return useMutation({
    mutationFn: ({ lookupId }: { lookupId: number }) =>
      axios.post<FinBoxResponse<string>>(`/lookup/deactivate/${lookupId}`),
    onError: (e) => {
      notify({
        title: "Could not deactivate data",
        text: getNetworkErrorText(e),
      });
    },
  });
};

export const useLookupUpload = () => {
  return useMutation({
    mutationFn: ({ formData }: { formData: FormData }) =>
      axios.post<FinBoxResponse<LookupFileUpload>>(
        "/lookup/fetchsourcefiledetails",
        formData
      ),
    onError: (e) => {
      notify({
        title: "Could not get lookup file data",
        text: getNetworkErrorText(e),
      });
    },
  });
};

export const useCreateLookup = () => {
  return useMutation({
    mutationFn: ({ formData }: { formData: FormData }) =>
      axios.post<FinBoxResponse>("/lookup/create", formData),
    onError: (e) => {
      notify({
        title: "Could not create lookup",
        text: getNetworkErrorText(e),
        type: "error",
      });
    },
  });
};
export const useUpdateLookup = () => {
  return useMutation({
    mutationFn: ({ formData }: { formData: FormData }) =>
      axios.post<FinBoxResponse>("/lookup/version/create", formData),
    onError: (e) => {
      notify({
        title: "Could not update lookup",
        text: getNetworkErrorText(e),
        type: "error",
      });
    },
  });
};

export const useAddLookupPolicySource = () => {
  return useMutation({
    mutationFn: ({
      entityId,
      entityType,
      lookupId,
    }: {
      entityId: string;
      entityType: string;
      lookupId: number;
    }) => {
      return axios.post<FinBoxResponse<Record<string, string>>>(
        "lookup/entityMapping/add",
        {
          entityId,
          entityType,
          lookupId,
        }
      );
    },
  });
};

export const useDeleteLookupSource = () => {
  return useMutation({
    mutationFn: ({
      entityId,
      entityType,
      lookupId,
    }: {
      entityId: string;
      entityType: string;
      lookupId: number;
    }) => {
      return axios.post<FinBoxResponse>("lookup/entityMapping/delete", {
        entityId,
        entityType,
        lookupId,
      });
    },
  });
};

export const getDatasourceDetailsQuery = (datasourceId: number | false) => {
  return queryOptions({
    queryKey: ["datasourceDetails", datasourceId],
    queryFn: async () =>
      axios.get<FinBoxResponse<DatasourceDetails>>(
        `datasource/${datasourceId}/details`
      ),
    enabled: !!datasourceId,
  });
};

export const useUpdateDatasource = () => {
  return useMutation({
    mutationFn: async (data: UpdateDatasourceType) =>
      axios.post("datasource/updateCurl", data),
  });
};

export const datasourceListQuery = (args?: {
  pageNumber?: number;
  sourceName?: string;
  pageSize?: number;
}) =>
  queryOptions({
    queryKey: normalizeQueryKey(["datasourceList", args]),
    queryFn: async () => {
      const params = args;
      return axios.get<FinBoxResponse<AvailableSources>>(`/datasource/list`, {
        params,
      });
    },
    select: (data) => data.data,
    placeholderData: keepPreviousData,
  });

export const useGetAvailableSourceList = (
  args: {
    pageNumber?: number;
    sourceName?: string;
    pageSize?: number;
  } = {}
) => useQuery(datasourceListQuery(args));


export const lookupTableListDsQuery = (args?: {
  pageNumber?: number;
  name?: string;
  pageSize?: number;
  isRefetch?: boolean;
}) =>
  queryOptions({
    queryKey: normalizeQueryKey(["lookupTableList", args]),
    queryFn: async () => {
      const params = {
        pageNumber: args?.pageNumber,
        name: args?.name,
        pageSize: args?.pageSize,
      };
      return axios.get<FinBoxResponse<Record<string, LookupTableSources>>>(
        `/lookup/list`,
        {
          params,
        }
      );
    },
    select: (data) => data.data,
    refetchInterval: (query) =>
      args?.isRefetch &&
      query.state.data?.data.data.lookups?.some(
        (item) => item.status.toLowerCase() === "processing"
      )
        ? 5000
        : false,
  });
