import { useSearchParams } from "react-router-dom";
import LoginWrapper from "../login/components/LoginWrapper";

const ErrorScreen = () => {
  const [searchParams] = useSearchParams();
  let errorText = "Login Failed Please try again later";

  switch (searchParams.get("errorCode")) {
    case "INVALID_CLIENT":
      errorText = "Invalid login details. Invalid client";
      break;
    case "INVALID_AUTH_TYPE":
      errorText = "Login method not supported";
      break;
    case "AUTH_FAILED":
      errorText = "Authentication failed";
      break;
    case "CREATE_USER_FAILED":
      errorText = "Unable to create user";
      break;
  }

  return (
    <LoginWrapper>
      <div className="w-4/5 sm:max-w-[380px]">
        <h1 className="text-xl font-medium tracking-tight text-error-500">
          {errorText}
        </h1>
        <div className="mt-4 font-b1 text-neutral-500">
          Please try after sometime
        </div>
      </div>
    </LoginWrapper>
  );
};

export default ErrorScreen;
