import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ProtectedComponent from "src/components/ProtectedComponent";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import { PermissionTypes } from "src/types";
import Button from "@components/Button";
import Modal from "@components/Dialogue";
import { CHART_TYPE_NAME_MAP } from "../../config";
import { useAddChart, useUpdateChart } from "../../queries";
import { AddChartBody } from "../../types";
import OutcomeRateVsTrafficForm from "./OutcomeRateVsTrafficForm";
import PolicyInsightsForm from "./PolicyInsightsForm";
import ReasonsOfRejectionForm from "./ReasonsOfRejectionForm";
import VariableDistributionForm from "./VariableDistributionForm";
import { ChartType, FormType } from "./types";

function getDefaultChartName(type: ChartType) {
  switch (type) {
    case "metrics":
      return "Decision Metrics";
    case "outcomeVsTraffic":
      return "Outcome Vs Traffic";
    case "policyInsights":
      return "Policy Insights";
    case "reasonsOfRejection":
      return "Top Reasons of Rejection";
  }
}

export default function AddChartModal() {
  const [searchParams] = useSearchParams({
    type: "",
  });

  const type = searchParams.get("type") as ChartType;
  const funnelType = searchParams.get("funnelType") || undefined;
  const funnelValues = JSON.parse(searchParams.get("funnelValues") || "[]") as {
    name: string;
    id: string;
  }[];
  const groupByKey = searchParams.get("groupByKey") || undefined;
  const groupByValues: string[] = JSON.parse(
    searchParams.get("groupByValues") || "[]"
  );
  const output = searchParams.get("output") || undefined;
  const outcome = searchParams.get("outcome") || undefined;
  const operator =
    (searchParams.get("operator") as "AVG" | "COUNT" | "SUM") || undefined;
  const representation =
    (searchParams.get("representation") as "Chart" | "Metric") || undefined;
  const showTraffic: boolean = JSON.parse(
    searchParams.get("showTraffic") ?? "true"
  );
  const name = searchParams.get("name") || undefined;

  const chartId = Number(searchParams.get("id"));

  const isUpdate = !!chartId;

  const form = useForm<FormType>({
    defaultValues: {
      operator,
      type,
      funnelType,
      groupByKey,
      groupByValues,
      output,
      representation,
      config: { showTraffic: showTraffic, outcome },
      funnelValues,
      name: name ?? getDefaultChartName(type),
    },
  });
  const addChartMutation = useAddChart();
  const updateChartMutation = useUpdateChart();

  const templateId = Number(useParams<{ templateId: string }>().templateId);

  const navigate = useNavigate();

  const close = () => navigate(`/analytics/template/${templateId}`);

  if (!type) {
    close();
    return null;
  }

  const handleSubmit = form.handleSubmit((data) => {
    let body: AddChartBody = {};
    body.config = {};
    body.name = data.name;

    switch (data.type) {
      case "outcomeVsTraffic":
        body.chartTypeId = 1;
        body.filters = [
          {
            filterType: "filter_by",
            filterKey: data.funnelType.toLowerCase(),
            filterValues: data.funnelValues.map((o) => o.id),
          },
        ];
        if (data.groupByKey && data.groupByValues) {
          body.filters.push({
            filterType: "group_by",
            filterKey: data.groupByKey,
            filterValues: data.groupByValues,
          });
        }
        body.output = data.config.outcome;
        body.operator = "COUNT";
        body.config = {
          showTraffic: data.config.showTraffic,
        };
        break;
      case "policyInsights":
        body.chartTypeId = 5;
        body.filters = [
          {
            filterType: "filter_by",
            filterKey: data.funnelType.toLowerCase(),
            filterValues: data.funnelValues.map((o) => o.id),
          },
        ];
        if (data.groupByKey && data.groupByValues) {
          body.filters.push({
            filterType: "group_by",
            filterKey: data.groupByKey,
            filterValues: data.groupByValues,
          });
        }
        body.output = data.output;
        body.operator = data.operator;
        body.config = {
          outcome: data.config.outcome,
        };
        break;
      case "reasonsOfRejection":
        body.chartTypeId = 6;
        body.filters = [
          {
            filterType: "filter_by",
            filterKey: data.funnelType.toLowerCase(),
            filterValues: data.funnelValues.map((o) => o.id),
          },
        ];
        if (data.groupByKey && data.groupByValues) {
          body.filters.push({
            filterType: "group_by",
            filterKey: data.groupByKey,
            filterValues: data.groupByValues,
          });
        }
        body.output = "rejected";
        body.operator = "COUNT";
        body.config = {
          outcome: "rejected",
        };
        break;
      case "metrics":
        body.chartTypeId = 4;
        body.filters = [
          {
            filterType: "filter_by",
            filterKey: data.funnelType.toLowerCase(),
            filterValues: data.funnelValues.map((o) => o.id),
          },
        ];
        if (data.groupByKey && data.groupByValues) {
          body.filters.push({
            filterType: "group_by",
            filterKey: data.groupByKey,
            filterValues: data.groupByValues,
          });
        }
        body.output = data.output;
        body.operator = data.operator;
        body.config = {
          showTraffic: false,
        };
    }

    if (isUpdate)
      updateChartMutation.mutate(
        {
          templateId,
          chartId: chartId!,
          body,
        },
        {
          onSuccess: () => {
            close();
            form.reset();
          },
        }
      );
    else
      addChartMutation.mutate(
        {
          templateId,
          body,
        },
        {
          onSuccess: () => {
            close();
            form.reset();
          },
        }
      );
  });

  return (
    <>
      <Modal open={true} onClose={close}>
        <Modal.Panel size="sm">
          <Modal.Header>{CHART_TYPE_NAME_MAP[type]}</Modal.Header>
          <Modal.Body>
            {
              {
                outcomeVsTraffic: <OutcomeRateVsTrafficForm form={form} />,
                policyInsights: <PolicyInsightsForm form={form} />,
                metrics: <VariableDistributionForm form={form} />,
                reasonsOfRejection: <ReasonsOfRejectionForm form={form} />,
                variableDistribution: <></>,
              }[type]
            }
          </Modal.Body>
          <Modal.Footer className="bg-neutral-0 !py-0 !h-11 justify-end items-center border border-neutral-100">
            <div className="flex w-full justify-end gap-2 bg-neutral-0">
              <Button
                variant="outline"
                onClick={() => {
                  form.reset();
                  close();
                }}
              >
                Cancel
              </Button>
              {isUpdate ? (
                <ProtectedComponent
                  type={PERMISSIONS_TYPE.analytics as PermissionTypes}
                  action="edit"
                >
                  <Button
                    disabled={updateChartMutation.isPending}
                    onClick={handleSubmit}
                  >
                    Update Chart
                  </Button>
                </ProtectedComponent>
              ) : (
                <ProtectedComponent
                  type={PERMISSIONS_TYPE.analytics as PermissionTypes}
                  action="create"
                >
                  <Button
                    disabled={addChartMutation.isPending}
                    onClick={handleSubmit}
                  >
                    Add Chart
                  </Button>
                </ProtectedComponent>
              )}
            </div>
          </Modal.Footer>
        </Modal.Panel>
      </Modal>
    </>
  );
}
