import { getErrors, getWorkflowPredictors } from "@screens/workflow/queries";
import axios from "@axios";
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { getNetworkErrorText, notify } from "@utils/utils";
import { RuleSetCondition } from "./types";

export const getRuleSetConditionsQuery = (
  workflowId?: string,
  ruleSetId?: string
) =>
  queryOptions({
    queryKey: ["ruleSetConditions", workflowId, ruleSetId],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<{
          rules: RuleSetCondition[] | null;
          ruleSet: Record<string, any>;
        }>
      >(`workflow/${workflowId}/ruleSet/${ruleSetId}`),
    enabled: Boolean(workflowId && ruleSetId),
  });

export const useAddRuleSetCondition = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      description,
      approve,
      cant_decide,
      workflowId,
      nodeId,
    }: {
      description: string;
      approve: string;
      cant_decide?: string;
      workflowId: string;
      nodeId: string;
    }) =>
      axios.post<FinBoxResponse<any>>(
        `workflow/${workflowId}/ruleSet/${nodeId}`,
        {
          description,
          approve,
          cant_decide,
        }
      ),
    onError(error) {
      notify({
        title: "Failed to add expression",
        text: getNetworkErrorText(error),
      });
    },
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(
        getRuleSetConditionsQuery(vars.workflowId, vars.nodeId)
      );
      queryClient.invalidateQueries(getErrors(vars.workflowId));
    },
  });
};

export const useUpdateRuleSetCondition = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      workflowId,
      nodeId,
      conditionId,
      description,
      approve,
      cant_decide,
      seq,
    }: {
      workflowId: string;
      nodeId: string;
      conditionId: string;
      description: string;
      approve: string;
      cant_decide?: string;
      seq?: number;
    }) => {
      return axios.put<FinBoxResponse<any>>(
        `workflow/${workflowId}/ruleSet/${nodeId}/${conditionId}`,
        {
          description,
          approve,
          cant_decide,
          seq,
        }
      );
    },
    onError(error) {
      notify({ title: "Update Failed", text: getNetworkErrorText(error) });
    },
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(getWorkflowPredictors(vars.workflowId));
      queryClient.invalidateQueries(
        getRuleSetConditionsQuery(vars.workflowId, vars.nodeId)
      );
      queryClient.invalidateQueries(getErrors(vars.workflowId));
    },
  });
};

export const useDeleteRuleSetCondition = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      workflowId,
      nodeId,
      conditionId,
    }: {
      workflowId: string;
      nodeId: string;
      conditionId: string;
    }) =>
      axios.delete<FinBoxResponse<any>>(
        `workflow/${workflowId}/ruleSet/${nodeId}/${conditionId}`
      ),
    onError(error) {
      notify({ title: "Update Failed", text: getNetworkErrorText(error) });
    },
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(getWorkflowPredictors(vars.workflowId));
      queryClient.invalidateQueries(
        getRuleSetConditionsQuery(vars.workflowId, vars.nodeId)
      );
      queryClient.invalidateQueries(getErrors(vars.workflowId));
    },
  });
};

export function useTestRuleSet() {
  return useMutation({
    mutationFn: (body: {
      ruleset: Array<{
        rule_id: string;
        approve: string;
        cant_decide?: string;
      }>;
      variables?: Record<string, any>;
    }) =>
      axios.v2.post<
        FinBoxResponse<{
          decision: string;
          rules: Record<string, "PASS" | "REJECT" | "CANT_DECIDE">;
        }>
      >("eval", body),
  });
}
