import { AxiosResponse } from "axios";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { ReactComponent as PlusIcon } from "@assets/icons/workflow/plus-add-condition.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { getCurrentSourcesQuery } from "@screens/workflow/studio/components/Source/queries";
import { CurrentSourceItem } from "@screens/workflow/studio/components/Source/types";
import QueryWrapper from "@components/QueryWrapper";
import Shimmer from "@components/Shimmer";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import SourceItem from "./SourceItem";

interface SourceListProps {
  nodeId: string;
  selectedSourcesQuery: UseQueryResult<
    AxiosResponse<FinBoxResponse<CurrentSourceItem[]>, any>,
    Error
  >;
}

const SourceList = ({ nodeId, selectedSourcesQuery }: SourceListProps) => {
  const { isWorkflowEditable } = useWorkflowContext();
  const workflowId = useParams().workflowId;

  const queryClient = useQueryClient();

  const onAddNewSource = () => {
    queryClient.setQueryData(
      getCurrentSourcesQuery(workflowId!, nodeId).queryKey,
      (prev) => {
        if (!prev) return;
        if (prev.data.data.findIndex((p) => p.id === -1) !== -1) return prev;
        const newData = structuredClone(prev.data.data);

        newData.push({
          id: -1,
          type: "",
          seq_no: newData.length,
          data_source_id: -1,
          name: "",
        });
        return {
          ...prev,
          data: {
            ...prev.data,
            data: newData,
          },
        };
      }
    );
  };

  return (
    <div className="relative flex flex-col gap-2 pt-2">
      <div className="font-b2-medium">Select Source(s)</div>
      <QueryWrapper
        loader={
          <>
            <Shimmer w="100%" h="32px" />
            <Shimmer w="100%" h="32px" />
            <Shimmer className="w-[120px] h-[1.5em]" />
          </>
        }
        query={selectedSourcesQuery}
      >
        {(data) => {
          const currentSources = data.data.data;
          return (
            <>
              {currentSources.map((item, index) => (
                <SourceItem
                  nodeId={nodeId}
                  key={item.data_source_id}
                  item={item}
                  index={index}
                />
              ))}
              {isWorkflowEditable && (
                <div
                  className={clsx(
                    "font-b2-medium cursor-pointer mt-1 mb-2 text-neutral-black group/add-expr hover:text-primary-900 w-max flex items-center gap-1",
                    data.data.data.findIndex((p) => p.id === -1) !== -1 &&
                      "!cursor-not-allowed opacity-50"
                  )}
                  onClick={onAddNewSource}
                >
                  <PlusIcon className="w-4 h-4 group-hover/add-expr:[&>path]:stroke-primary-900 [&>path]:stroke-neutral-black" />
                  Add Source
                </div>
              )}
            </>
          );
        }}
      </QueryWrapper>
    </div>
  );
};

export default SourceList;
