import { clsx } from "clsx";
import { useState } from "react";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { useGetNodeFontStyles } from "@screens/workflow/studio/hooks/useIsExpand";
import { isValidName } from "@screens/workflow/studio/utils";
import { notify } from "@utils/utils";

export function NodeName({
  defaultName,
  onChange,
  className,
}: {
  defaultName: string;
  onChange: (value: string) => void;
  className?: string;
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [isError, setIsError] = useState(false);
  const { isWorkflowEditable } = useWorkflowContext();
  const fontStyles = useGetNodeFontStyles();

  const submit = (e: string) => {
    onChange(e);
  };

  if (isEdit)
    return (
      <input
        autoFocus
        defaultValue={defaultName}
        onBlur={(e) => {
          const value = (e.target as HTMLInputElement).value;

          const [isValid, error] = isValidName(value);
          if (!isValid) {
            notify({ title: "Invalid Name", text: error });
            setIsError(true);
            setIsEdit(false);
            return;
          }

          setIsError(false);
          submit(value);
          setIsEdit(false);
        }}
        maxLength={30}
        className={clsx(
          "outline-0 h-6 border-neutral-100 border rounded-md px-1 font-b2-medium nodrag",
          isError && "!border-error-500"
        )}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            const value = (e.target as HTMLInputElement).value;

            const [isValid, error] = isValidName(value);
            if (!isValid) return notify({ title: "Invalid Name", text: error });

            submit(value);
            setIsEdit(false);
          }
        }}
      />
    );
  return (
    <span
      onClick={() => setIsEdit(isWorkflowEditable)}
      style={fontStyles}
      className={clsx(
        "max-w-44 w-max truncate cursor-pointer font-medium hover:bg-neutral-25 px-1 py-0.5 rounded-md",
        className
      )}
    >
      {defaultName || "Name"}
    </span>
  );
}
