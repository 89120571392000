import { useRef } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

type Props = {
  data: {
    approved: number;
    rejected: number;
    cantDecide: number;
    totalCount: number;
  };
};

type Label = "Pass" | "Fail" | "Under Review";

const colors: Record<Label, string> = {
  Pass: "#5A3FFF",
  Fail: "#268AFF",
  "Under Review": "#1ED6FF",
};

const textColors: Record<Label, string> = {
  Pass: "#FFFFFF",
  Fail: "#FFFFFF",
  "Under Review": "#5A3FFF",
};

export default function OutputPieChart({ data }: Props) {
  const pieRef = useRef<HTMLDivElement>(null);
  const chartData: Array<{
    name: Label;
    count: number;
  }> = [
    { name: "Pass", count: data.approved },
    { name: "Fail", count: data.rejected },
    { name: "Under Review", count: data.cantDecide },
  ];

  const RADIAN = Math.PI / 180;
  return (
    <ResponsiveContainer width="100%" height="100%" ref={pieRef}>
      <PieChart>
        <Pie
          dataKey="count"
          fill="#0075FF"
          data={chartData}
          isAnimationActive={false}
          innerRadius="60"
          outerRadius="110"
          paddingAngle={0}
          label={({
            cx,
            cy,
            midAngle,
            outerRadius,
            innerRadius,
            percent,
            name,
          }: {
            cx: number;
            cy: number;
            midAngle: number;
            outerRadius: number;
            innerRadius: number;
            percent: number;
            name: Label;
          }) => {
            if (percent <= 0) return null;
            const r = (outerRadius - innerRadius) / 2 + innerRadius;
            const x = cx + r * Math.cos(-midAngle * RADIAN);
            const y = cy + r * Math.sin(-midAngle * RADIAN);
            return (
              <g>
                <text
                  style={{
                    fill: textColors[name],
                  }}
                  x={x}
                  y={y + 13}
                  className="text-xs font-bold -translate-x-[1ch] pointer-events-none rounded-sm"
                >
                  {name}
                </text>
                <text
                  style={{ fill: textColors[name] }}
                  x={x}
                  y={y}
                  className="text-xs font-bold -translate-x-[1ch] pointer-events-none rounded-sm"
                >
                  {`${(percent * 100).toFixed(0)}%`}
                </text>
              </g>
            );
          }}
          labelLine={false}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[entry.name]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
