import clsx from "clsx";
import { useRef, useState } from "react";
import { ReactComponent as PlusIcon } from "@assets/icons/workflow/plus-add-condition.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import {
  getSuggestionList,
  getUniqueItemName,
} from "@screens/workflow/studio/utils";
import Loader from "@components/Loader";
import Shimmer from "@components/Shimmer";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import ModelItem from "./ModelItem";
import {
  useAddModelExpression,
  useUpdateModelExpressionOrder,
} from "./queries";
import { ModelExpression } from "./types";
import { useLookupFunctionsInput } from "src/screens/create-policy/queries";
import useMonacoContext from "../../MonacoContext";

interface ModelExpressionsProps {
  expressions: ModelExpression[];
  workflowId: string;
  nodeId: string;
  isLoading: boolean;
  isFetching: boolean;
  nodeName: string;
}

const ModelExpressions = ({
  expressions,
  workflowId,
  nodeId,
  isLoading,
  isFetching,
  nodeName,
}: ModelExpressionsProps) => {
  const addModelMutation = useAddModelExpression(workflowId, nodeId);
  const [expressionInEdit, _setExpressionInEdit] = useState<string | null>(
    null
  );

  const { autocompleteProvider } = useMonacoContext();

  const itemsRef = useRef<Map<string, HTMLDivElement> | null>(null);
  const sensors = useSensors(useSensor(PointerSensor));

  const updateModelOrderMutation = useUpdateModelExpressionOrder();
  const queryClient = useQueryClient();

  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflowId, nodeId, nodeName);

  const { isWorkflowEditable } = useWorkflowContext();

  const lookupFunctionInputs = useLookupFunctionsInput({
    entityId: workflowId || "",
    entityType: "workflow",
  });

  const setExpressionInEdit = (newExpressionId: string | null) => {
    if (newExpressionId) {
      let modelsForAutoComplete = [];
      if (expressions) {
        for (const exp of expressions) {
          if (exp.id === newExpressionId) break;
          modelsForAutoComplete.push(exp.name);
        }
      }

      const { sourceList, keywords } = getSuggestionList(keywordsQuery);

      autocompleteProvider({
        nodeId,
        sourceList: [
          ...sourceList.filter((source) => source !== nodeName),
          ...modelsForAutoComplete,
        ],
        keywords,
        autoCompleteRecords: {},
        lookupFunctionInputs: lookupFunctionInputs?.data?.data,
      });
    }
    _setExpressionInEdit(newExpressionId);
  };

  const onAddNewNode = () => {
    if (addModelMutation.isPending) return;
    setExpressionInEdit(null);
    const length = expressions.length || 0;
    const modelName = expressions.map((e) => e.name) || [];
    addModelMutation.mutate(
      {
        expression: {
          name: getUniqueItemName(modelName, length, "model_"),
          body: "1",
          seqNo: length,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(getWorkflowKeywordsQuery());
        },
      }
    );
  };

  function getRefMap() {
    if (!itemsRef.current) {
      itemsRef.current = new Map();
    }
    return itemsRef.current;
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    const items = expressions.map((c) => c.id) ?? [];
    if (items.length === 0) return;

    if (over && active.id !== over.id) {
      const oldIndex = items.indexOf(active.id as string);
      const newIndex = items.indexOf(over.id as string);

      const item = expressions[oldIndex];
      updateModelOrderMutation.mutate({
        nodeId,
        expressionId: item.id,
        workflowId: workflowId,
        expression: {
          name: item.name,
          body: item.body,
          seqNo: newIndex,
        },
      });
    }
  };

  return (
    <div
      className="relative flex flex-col gap-2 mt-2 mb-3"
      id="exp-parent"
      tabIndex={0}
      onBlur={(e) => {
        if (!itemsRef.current) {
          return;
        }
        const isInside = Array.from(itemsRef.current.values()).some(
          (el) =>
            el.contains(e.relatedTarget) || e.relatedTarget?.id === "exp-parent"
        );
        if (!isInside) {
          setExpressionInEdit(null);
        }
      }}
    >
      {(isFetching || updateModelOrderMutation.isPending) && (
        <div className="absolute flex items-center z-10 justify-center inset-0 bg-white bg-opacity-50">
          <Loader size="xs" />
        </div>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={expressions.map((m) => m.id) || []}
          strategy={verticalListSortingStrategy}
        >
          {isLoading && (
            <>
              <Shimmer w="100%" h="60px" />
              <Shimmer w="100%" h="60px" />
            </>
          )}
          {expressions.map((exp) => (
            <ModelItem
              showDelete={expressions.length > 1}
              ref={(r) => {
                const map = getRefMap();
                if (r) {
                  map.set(exp.id, r);
                } else {
                  map.delete(exp.id);
                }
              }}
              key={exp.id}
              expression={exp}
              workflowId={workflowId}
              nodeId={nodeId}
              isEdit={exp.id === expressionInEdit}
              setIsEdit={() => setExpressionInEdit(exp.id)}
            />
          ))}
        </SortableContext>
      </DndContext>
      {isWorkflowEditable &&
        (isLoading ? (
          <Shimmer className="w-[120px] h-[1.5em]" />
        ) : (
          <div
            className={clsx(
              "font-b2-medium cursor-pointer mt-1 ml-4 text-neutral-black group/add-expr hover:text-primary-900 w-max flex items-center gap-1 mb-1",
              addModelMutation.isPending && "!cursor-not-allowed opacity-50"
            )}
            onClick={onAddNewNode}
          >
            <PlusIcon className="w-4 h-4 group-hover/add-expr:[&>path]:stroke-primary-900 [&>path]:stroke-neutral-black" />
            Add Expression
          </div>
        ))}
    </div>
  );
};

export default ModelExpressions;
