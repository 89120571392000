import { PropsWithChildren } from "react";
import Modal from ".";
import Button from "../Button";

interface SimpleModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  action: () => Promise<any>;
  confirmationtText: string;
  className?: string;
  isSubmitting?: boolean;
  size?: "sm" | "lg";
}

const SimpleModal = ({
  children,
  onClose,
  isOpen,
  title,
  action,
  confirmationtText,
  className,
  isSubmitting,
  size = "sm",
}: PropsWithChildren<SimpleModalProps>) => {
  return (
    <Modal onClose={onClose} open={isOpen}>
      <Modal.Panel size={size} className={className}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={async () => {
              try {
                await action();
              } catch (error) {}
            }}
            disabled={isSubmitting}
          >
            {confirmationtText}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
};

export default SimpleModal;
