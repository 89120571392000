import { useParams } from "react-router-dom";
import { Node } from "reactflow";
import { ReactComponent as BranchIcon } from "@assets/icons/shuffle-01.svg";
import { useTestBranch } from "@screens/workflow/queries";
import { getBranchConditionsQuery } from "@screens/workflow/studio/components/Branch/queries";
import { useQuery } from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import RightPanel from "./RightPanel";

type BranchNodeProps = {
  data: Node<{
    label: string;
  }>;
};

const BranchNode = ({ data }: BranchNodeProps) => {
  const testMutation = useTestBranch();
  const { workflowId } = useParams();

  const branchExpressionQuery = useQuery(
    getBranchConditionsQuery({
      workflowId: workflowId ?? "",
      branchId: data.id!,
    })
  );
  const expressions = branchExpressionQuery.data?.data.data ?? [];

  const test = (
    variables: Record<string, Record<string, Record<string, string | null>>>
  ) => {
    testMutation.mutate({
      branch:
        expressions
          .filter((item) => item.body)
          .map((item) => ({
            condition: item.body,
            output: item.name,
          })) ?? [],
      variables,
    });
  };

  return (
    <RightPanel
      nodeId={data.id}
      label={data?.data.label}
      expressions={expressions.map((item) => item.body)}
      onTest={test}
      testResult={
        testMutation.isSuccess ? (
          <div className="text-neutral-500 flex flex-col gap-4 font-b2">
            <div className="flex text-neutral-black font-b2-medium">
              <BranchIcon className="w-4 h-4 mr-2" />
              {data?.data.label || "Branch_Node"}
            </div>
            <div className="font-b2-medium flex justify-between">
              Result:
              <span className="font-b2-medium">
                {JSON.stringify(testMutation.data?.data.data)}
              </span>
            </div>
          </div>
        ) : testMutation.isError ? (
          <div className="text-error-500 font-code flex flex-col gap-2 font-b2 py-3">
            <span>
              {(
                testMutation.error as unknown as {
                  response: { data: FinBoxResponse };
                }
              ).response.data.error
                .split("\n")
                .map((row) => (
                  <>
                    {row}
                    <br />
                  </>
                ))}
            </span>
          </div>
        ) : (
          <></>
        )
      }
    />
  );
};
export default BranchNode;
