import chevronDown from "@assets/icons/chevron-down.svg";
import { Accordion } from "@components/Accordion";
import Search from "@components/Search";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import Outcome from "@screens/workflow/components/Outcome";
import WorkflowSettings from "@screens/workflow/components/WorkflowSettings";
import { ADD_BLOCK_CONFIG } from "@screens/workflow/config";
import { BaseTab } from "@screens/workflow/studio/components/Sidebar/BaseTab";
import useGetIcon from "@screens/workflow/studio/hooks/useGetIcon";
import classNames from "clsx";
import { useRef, useState } from "react";
import { Node, useReactFlow } from "reactflow";
import usePermissions from "src/context/PermissionContext";
import Debugger from "src/screens/workflow/components/Debugger";
import WorkflowSimulation from "src/screens/workflow/components/WorkflowSimulation";
import InputParameters from "../InputParameters";

function NodeItem({
  item,
  type,
}: {
  type: (typeof ADD_BLOCK_CONFIG)[number]["name"];
  item: (typeof ADD_BLOCK_CONFIG)[number]["items"][number];
}) {
  const wrapperRef = useRef<HTMLSpanElement>(null);
  const { setNodeRef, attributes, listeners, transform, isDragging } =
    useDraggable({
      id: `${type}:${item.name}`,
      data: {
        type,
        nodeType: item.name,
        item,
        initialPosition: {
          x: wrapperRef.current?.getBoundingClientRect().x || 0,
          y: wrapperRef.current?.getBoundingClientRect().y || 0,
        },
      },
    });

  const Icon = useGetIcon(item.type);

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  return (
    <span
      ref={wrapperRef}
      className={classNames(
        "flex relative flex-col gap-2 items-center cursor-pointer font-b2 text-center"
      )}
      title={item.name}
    >
      <span className="absolute z-0 opacity-80 bg-neutral-0 w-[60px] h-[60px] flex items-center justify-center border border-neutral-100 rounded-md">
        <div className="p-1 rounded-sm" style={{ background: item.bgColor }} />
      </span>
      <span
        id={`${type}:${item.name}`}
        ref={setNodeRef}
        style={style}
        {...listeners}
        {...attributes}
        className={classNames(
          "bg-neutral-0 z-10 w-[60px] h-[60px] flex items-center justify-center border border-neutral-100 rounded-md",
          isDragging && "z-[11] border-primary-500 shadow-lg"
        )}
      >
        <div className="p-1 rounded-sm">
          <Icon label={item.name} />
        </div>
      </span>
      {item.name}
    </span>
  );
}

export function AddBlockTab() {
  const { featureFlags } = usePermissions();

  return (
    <BaseTab title="Add Block">
      <BaseTab.Content>
        <div className="flex flex-col gap-4 stroke-white p-3">
          {ADD_BLOCK_CONFIG.map((item) => {
            return (
              <Accordion
                key={item.name}
                defaultOpen
                content={({ open }) => (
                  <span className="font-b2-medium flex gap-1">
                    <img
                      src={chevronDown}
                      alt=""
                      className={classNames(
                        "duration-100",
                        !open ? "-rotate-90" : "rotate-0"
                      )}
                    />
                    {item.name}
                  </span>
                )}
              >
                <div className="grid grid-cols-3 gap-5">
                  {item.items.map((node) => {
                    if (
                      item.name === "Blocks" &&
                      node.name === "Policy" &&
                      featureFlags?.isPolicyDisabled
                    )
                      return null;
                    return (
                      <NodeItem key={node.name} type={item.name} item={node} />
                    );
                  })}
                </div>
              </Accordion>
            );
          })}
        </div>
      </BaseTab.Content>
    </BaseTab>
  );
}

function NavigatorItem({
  type,
  label,
  handleClick,
}: {
  type?: string;
  label?: string;
  handleClick: () => void;
}) {
  const Icon = useGetIcon(type ?? "");
  return (
    <div
      onClick={handleClick}
      className="text-neutral-black flex gap-2 font-b2-medium py-1.5 mb-1 cursor-pointer hover:bg-neutral-50 px-2 rounded-md"
    >
      <Icon label={label} />
      <span className="truncate">{label || type}</span>
    </div>
  );
}

export function NavigatorTab() {
  const { setCenter, getNodes } = useReactFlow();

  const nodes = getNodes();
  const [searchQuery, setSearchQuery] = useState("");

  const handleClick = (node: Node) => {
    return () => {
      setCenter(
        node.position.x + 235,
        node.position.y + (node.height || 0) / 2,
        {
          duration: 500,
          zoom: 1,
        }
      );
    };
  };
  const onSearchChange = (val: string) => {
    setSearchQuery(val);
  };

  return (
    <BaseTab className="min-w-[255px]" title="Navigator">
      <BaseTab.Content>
        <div className="p-2 overflow-scroll h-[97%]">
          <Search
            className="p-2 mb-2 !mr-0"
            onSearchChange={(e) => onSearchChange(e.target.value)}
          />
          {nodes
            .filter(
              (n) =>
                n.type !== "startNode" &&
                n.data.label.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((node) => {
              return (
                <NavigatorItem
                  key={node.id}
                  handleClick={handleClick(node)}
                  type={node.type}
                  label={node.data.label}
                />
              );
            })}
        </div>
      </BaseTab.Content>
    </BaseTab>
  );
}

export function InputParametersTab() {
  return (
    <BaseTab title="Configure Input Parameters" className="w-[480px]">
      <BaseTab.Content>
        <InputParameters />
      </BaseTab.Content>
    </BaseTab>
  );
}

export function ConfigureOutcomes() {
  const { isWorkflowEditable } = useWorkflowContext();
  return (
    <BaseTab
      title={isWorkflowEditable ? "Configure Outcomes" : "Workflow Outcomes"}
      className="w-[443px]"
    >
      <BaseTab.Content>
        <Outcome />
      </BaseTab.Content>
    </BaseTab>
  );
}

export function TestWorkflowTab() {
  return (
    <BaseTab title="Debugger" className="w-[680px]">
      <BaseTab.Content>
        <Debugger />
      </BaseTab.Content>
    </BaseTab>
  );
}

export function SimulateTab() {
  return (
    <BaseTab title="Simulate" className="w-[680px]">
      <BaseTab.Content>
        <WorkflowSimulation />
      </BaseTab.Content>
    </BaseTab>
  );
}

export function SettingsTab() {
  const { workflow } = useWorkflowContext();
  if (!workflow) return null;

  return (
    <BaseTab title="Settings" className="w-[480px]">
      <BaseTab.Content>
        <WorkflowSettings initialSettings={workflow.settings} />
      </BaseTab.Content>
    </BaseTab>
  );
}
