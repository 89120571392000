import { createContext, PropsWithChildren, useContext } from "react";
import {
  UseFloatingReturn,
  Placement,
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
} from "@floating-ui/react-dom";

const FloatContext = createContext<UseFloatingReturn | undefined>(undefined);

export const FloatProvider = (
  props: PropsWithChildren<{ placement: Placement }>
) => {
  const float = useFloating({
    placement: props.placement,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: (...args) =>
      autoUpdate(...args, { animationFrame: true }),
  });

  return (
    <FloatContext.Provider value={float}>
      {props.children}
    </FloatContext.Provider>
  );
};

export const useFloat = () => {
  const floatState = useContext(FloatContext);
  if (!floatState)
    throw new Error("floatState can only be used inside an FloatProvider");
  return floatState;
};
