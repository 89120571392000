import React, { useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import Autocomplete from "@components/Autocomplete";
import Input from "@components/Input";
import { useGetFunnelItems } from "../../queries";
import GroupBySelect from "./components/GroupBySelect";
import { FormType } from "./types";

export default function ReasonsOfRejectionForm({
  form,
}: {
  form: UseFormReturn<FormType>;
}) {
  const { register, setValue } = form;

  setValue("funnelType", "policy");

  const [funnelItemFilterQuery, setFunnelItemFilterQuery] = useState("");

  const funnelItemsQuery = useGetFunnelItems("policy");
  const funnelItems = funnelItemsQuery.isSuccess
    ? Object.keys(funnelItemsQuery.data.data.data)
        .filter(
          (key) =>
            funnelItemFilterQuery === "" ||
            funnelItemsQuery.data.data.data[key]
              .toLowerCase()
              .includes(funnelItemFilterQuery.toLowerCase())
        )
        .map((key) => ({
          name: funnelItemsQuery.data.data.data[key],
          id: key,
        }))
    : [];

  return (
    <form className="flex gap-4 flex-col">
      <div>
        <span className="font-b2 font-normal">Name</span>
        <Input {...form.register("name")} />
      </div>
      <input
        value="reasonsOfRejection"
        {...register("type")}
        className="hidden"
      />
      <input value="policy" {...register("funnelType")} className="hidden" />
      <div className="flex flex-col gap-2">
        <span className="font-b2 font-medium">Select Policy</span>
        <Controller
          control={form.control}
          name="funnelValues"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <Autocomplete
                name="funnelValues"
                onChange={(e) => {
                  field.onChange([e]);
                  form.resetField("groupByKey", { defaultValue: "" });
                  form.resetField("groupByValues", { defaultValue: [] });
                  form.resetField("output", { defaultValue: "" });
                  form.resetField("config.outcome", { defaultValue: "" });
                }}
                options={funnelItems}
                displayKey="name"
                onSearchChange={(e) => setFunnelItemFilterQuery(e)}
                selected={field.value?.[0]}
                disableShadow
              />
            );
          }}
        />
      </div>
      <GroupBySelect form={form} />
    </form>
  );
}
