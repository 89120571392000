
import {
    assertNever,
  } from "@utils/utils";
  import {
    RunSimAction as Action,
    RunSimState as State,
  } from "../SimulationModal.types";
  
  export const historicalSimulationReducer = (state: Partial<State>, action: Action): Partial<State> => {
    switch (action.type) {
      case "Reset":
        return {
          userCount: 0,
          decision: undefined,
          endDate: null,
          startDate: null,
          policy: "",
          customValue: "reference_id",
          file: null,
        };
      case "SetUserCount":
        return { ...state, userCount: action.value };
      case "SetDecision":
        return { ...state, decision: action.value };
      case "SetStartDate":
        return { ...state, startDate: action.value };
      case "SetEndDate":
        return { ...state, endDate: action.value };
      case "SetDateRange":
        return {
          ...state,
          startDate: action.value.startDate,
          endDate: action.value.endDate,
        };
      case "SetPolicy":
        return { ...state, policy: action.value };
      case "SetCustomValue":
        return { ...state, customValue: action.value };
      case "SetFile":
        return { ...state, file: action.value };
      default:
        assertNever(action);
        return state;
    }
  };
