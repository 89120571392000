import { useRef, useState } from "react";
import { useEdges } from "reactflow";
import { ReactComponent as PlusIcon } from "@assets/icons/workflow/plus-add-condition.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { useAddBranch } from "@screens/workflow/studio/components/Branch/queries";
import NodeDescription from "@screens/workflow/studio/components/NodeDescription";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import {
  getUniqueItemName,
  getUpdateBranchNode,
} from "@screens/workflow/studio/utils";
import BranchItem from "./BranchItem";

type Props = {
  conditions: {
    id: number;
    name: string;
    body: string;
  }[];
  errors: [string, string][];
  handles: string[];
  nodeId: string;
  label: string;
  desc: string;
};

export default function BranchConditions({
  conditions,
  nodeId,
  label,
  desc,
  errors,
}: Props) {
  const [expressionInEdit, setExpressionInEdit] = useState<number>(-1);

  const { isWorkflowEditable, workflow, refetchWf, updateWorkflow } =
    useWorkflowContext();

  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflow?.id, nodeId);
  const itemsRef = useRef<Map<string, HTMLDivElement> | null>(null);

  const edges = useEdges();

  const addBranch = useAddBranch();

  const findUnconnectedHandles = () => {
    const e = edges
      ?.filter((e) => e.source === nodeId)
      .map((e) => e.sourceHandle);
    return (
      conditions
        .filter((item: { name: string }) => !e.includes(item.name))
        .map((i: { name: string }) => i.name) || []
    );
  };
  const unconnectedHandles: Array<string> = findUnconnectedHandles();

  const addNewBranch = async () => {
    addBranch.mutate({
      branchId: nodeId,
      workflowId: workflow?.id!,
      name: getUniqueItemName(
        conditions.map((i) => i.name),
        conditions.length,
        "condition#"
      ),
      body: "true",
    });
  };

  const handleUpdateDesc = async (updatedDesc: string) => {
    const e = getUpdateBranchNode(label, nodeId, updatedDesc);
    await updateWorkflow(e);
    refetchWf();
  };

  function getRefMap() {
    if (!itemsRef.current) {
      itemsRef.current = new Map();
    }
    return itemsRef.current;
  }

  return (
    <div
      className="px-2 pb-3 flex gap-2 flex-col max-h-full overflow-visible"
      id="exp-parent"
      tabIndex={0}
      onBlur={(e) => {
        if (!itemsRef.current) {
          return;
        }
        const isInside = Array.from(itemsRef.current.values()).some(
          (el) =>
            el.contains(e.relatedTarget) || e.relatedTarget?.id === "exp-parent"
        );
        if (!isInside) {
          setExpressionInEdit(-1);
        }
      }}
    >
      <NodeDescription
        defaultDesc={desc}
        placeholder="Add description"
        onChange={(e) => handleUpdateDesc(e)}
      />
      {conditions.map((item, index) => {
        return (
          <BranchItem
            key={item.name}
            ref={(r) => {
              const map = getRefMap();
              if (r) {
                map.set(item.name, r);
              } else {
                map.delete(item.name);
              }
            }}
            error={errors.find((s) => s[0] === item.name)?.[1] || ""}
            isEdit={index === expressionInEdit}
            setIsEdit={() => setExpressionInEdit(index)}
            isLoading={false}
            nodeId={nodeId}
            isSidePanel={false}
            keywordsQuery={keywordsQuery}
            expression={item}
            label={
              index === 0
                ? "If"
                : index === conditions.length - 1
                ? "Else(Default)"
                : "Else If"
            }
            isDefault={index === conditions.length - 1}
            showDelete={conditions.length > 2}
            isHandleConnected={unconnectedHandles?.includes(item.name)}
          />
        );
      })}
      {isWorkflowEditable && (
        <div
          className="font-b2-medium mt-1 cursor-pointer text-neutral-black group/add-expr hover:text-primary-900 w-max flex items-center gap-1"
          onClick={addNewBranch}
        >
          <PlusIcon className="w-4 h-4 group-hover/add-expr:[&>path]:stroke-primary-900 [&>path]:stroke-neutral-black" />
          Add Condition
        </div>
      )}
    </div>
  );
}
