import React from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import Dropdown from "@components/DropDown";
import Input from "@components/Input";
import FunnelValuesSelect from "./components/FunnelValuesSelect";
import GroupBySelect from "./components/GroupBySelect";
import OutcomeSelect from "./components/OutcomeSelect";
import OutputSelect from "./components/OutputSelect";
import { FormType } from "./types";

export default function PolicyInsightsForm({
  form,
}: {
  form: UseFormReturn<FormType>;
}) {
  const { register, setValue } = form;

  setValue("funnelType", "policy");

  const [output] = useWatch({
    control: form.control,
    name: ["output"],
  });

  return (
    <form className="flex gap-4 flex-col">
      <input value="policyInsights" {...register("type")} className="hidden" />
      <div>
        <span className="font-b2 font-normal">Name</span>
        <Input {...form.register("name")} />
      </div>
      <input value="policy" {...register("funnelType")} className="hidden" />
      <div className="flex flex-col gap-2">
        <span className="font-b2">Select Policy</span>
        <FunnelValuesSelect form={form} />
      </div>
      <OutcomeSelect form={form} />
      <OutputSelect form={form} />
      <GroupBySelect form={form} />
      <div className="flex flex-col gap-2">
        <span className="font-b2">Choose Operator</span>
        <Controller
          defaultValue="COUNT"
          control={form.control}
          name="operator"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <Dropdown
                value={field.value}
                onChange={field.onChange}
                disabled={!Boolean(output)}
              >
                <Dropdown.Button
                  className="bg-white w-full"
                  disabled={!Boolean(output)}
                >
                  {{ SUM: "Sum", AVG: "Average", COUNT: "Count" }[
                    field.value
                  ] || "Select"}
                </Dropdown.Button>
                <Dropdown.Options className="h-auto">
                  <Dropdown.Option value="SUM">Sum</Dropdown.Option>
                  <Dropdown.Option value="AVG">Average</Dropdown.Option>
                  <Dropdown.Option value="COUNT">Count</Dropdown.Option>
                </Dropdown.Options>
              </Dropdown>
            );
          }}
        />
      </div>
    </form>
  );
}
