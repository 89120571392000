import classNames from "clsx";
import { useState, MouseEvent } from "react";
import ReactDatePicker from "react-datepicker";
import Button from "../Button";
import { SHORTCUTS } from "./constants";
import { DateRange, ShortcutKeys, ShortcutType } from "./types";

type Props = {
  onChange: (
    d:
      | (DateRange & { type: "date-range" })
      | (ShortcutType & { type: "shortcut" })
  ) => void;
  value?: { startDate: string; endDate: string };
  showShortcuts?: boolean;
  show: boolean;
  close: () => void;
  showAllTimeShortcut?: boolean;
  onClear: () => void;
};

export default function DatePicker({
  onChange,
  close,
  showShortcuts,
  showAllTimeShortcut,
  show,
  onClear,
}: Props) {
  const [startDate, _setStartDate] = useState<Date | null>();
  const [endDate, _setEndDate] = useState<Date | null>();
  const [selectedShortcut, setSelectedShortcut] = useState<ShortcutKeys>();

  const setStartDate = (d: Date | null) => {
    setSelectedShortcut(undefined);
    _setStartDate(d);
  };

  const setEndDate = (d: Date | null) => {
    setSelectedShortcut(undefined);
    _setEndDate(d);
  };

  const onShortcutClick = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target;
    if (target instanceof HTMLSpanElement) {
      const k = target.dataset.periodKey as ShortcutKeys | "all";
      if (k === "all") {
        onClear();
      } else {
        setSelectedShortcut(k);
        const { startDate, endDate } = SHORTCUTS[k].period;
        _setStartDate(startDate);
        _setEndDate(endDate);
      }
    }
  };

  if (!show) return null;

  return (
    <div className="flex flex-col w-full">
      <div className="flex gap-4">
        {showShortcuts && (
          <div
            className="flex w-full flex-col items-start justify-start gap-6 border-r border-neutral-100 p-4 font-b2"
            onClick={onShortcutClick}
          >
            {(Object.keys(SHORTCUTS) as ShortcutKeys[]).map((k) => {
              const ob = SHORTCUTS[k];
              const isSelected = selectedShortcut === k;
              return (
                <span
                  key={k}
                  data-period-key={k}
                  className={classNames(
                    "cursor-pointer hover:text-primary-500 whitespace-pre",
                    isSelected && "font-medium text-primary-700"
                  )}
                >
                  {ob.label}
                </span>
              );
            })}
            {showAllTimeShortcut && (
              <span
                key="all time"
                data-period-key="all"
                className="cursor-pointer hover:text-primary-500 whitespace-pre"
              >
                All Time
              </span>
            )}
          </div>
        )}
        <div
          className="flex w-full items-start pt-0 justify-between"
          // TODO: remove once fix is pushed by react-datepicker
          key={selectedShortcut}
        >
          <ReactDatePicker
            inline
            selectsRange
            selected={startDate}
            onChange={([startDate, endDate]) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            startDate={startDate}
            endDate={endDate}
            maxDate={new Date()}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-end gap-2 border-t bg-neutral-0 border-neutral-100 h-11 px-3 text-neutral-500">
        <div className="gap-2 flex items-center">
          {/*<Input*/}
          {/*  className="[&>span]:h-0 [&>span]:hidden [&>span]:mt-0"*/}
          {/*  value={startDate}*/}
          {/*  onChange={(e) => {*/}
          {/*    setStartDate(*/}
          {/*      e.target.value +*/}
          {/*        (e.target.value.length === 2 || e.target.value.length === "5"*/}
          {/*          ? "/"*/}
          {/*          : "")*/}
          {/*    );*/}
          {/*  }}*/}
          {/*  placeholder="DD/MM/YYYY"*/}
          {/*/>{" "}*/}
          {/*to*/}
          {/*<Input*/}
          {/*  value={endDate}*/}
          {/*  onChange={(e) => {*/}
          {/*    setEndDate(*/}
          {/*      e.target.value +*/}
          {/*        (e.target.value.length === 2 || e.target.value.length === "5"*/}
          {/*          ? "/"*/}
          {/*          : "")*/}
          {/*    );*/}
          {/*  }}*/}
          {/*  className="[&>span]:h-0 [&>span]:hidden [&>span]:mt-0"*/}
          {/*  placeholder="DD/MM/YYYY"*/}
          {/*/>*/}
        </div>

        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            close && close();
          }}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          onClick={() => {
            onChange(
              selectedShortcut
                ? { ...SHORTCUTS[selectedShortcut], type: "shortcut" }
                : {
                    startDate: startDate!,
                    endDate: endDate!,
                    type: "date-range",
                  }
            );
            close && close();
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
}
