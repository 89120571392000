import { useState } from "react";
import SimulationDetails from "@screens/workflow/components/Simulation";
import SimulationForm from "@screens/workflow/components/Simulation/SimulationForm";

export default function WorkflowSimulation() {
  const [showSimulationResult] = useState<boolean>(false);

  return (
    <div className="flex-1 bg-white h-full w-full flex flex-col">
      <div className="bg-red-200 h-full w-full flex">
        <div className="bg-white h-full w-full flex-1">
          <div className="h-full">
            <div className="overflow-auto h-full flex flex-col">
              {!showSimulationResult ? (
                <SimulationForm />
              ) : (
                <SimulationDetails />
              )}
              {/*{!showSimulationResult ? (*/}
              {/*  <div className="fixed w-[33.3%] justify-end flex items-center bottom-0 border-t border-indigo-50 bg-neutral-0 px-4 py-2.5">*/}
              {/*    <Button*/}
              {/*      onClick={() => {*/}
              {/*        setShowSimulationResult(true);*/}
              {/*        // api call here*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      Run Simulation*/}
              {/*    </Button>*/}
              {/*  </div>*/}
              {/*) : (*/}
              {/*  <div className="fixed w-[33.3%] justify-end flex items-center bottom-0 border-t border-indigo-50 px-4 py-2.5 gap-2 bg-neutral-0">*/}
              {/*    <Button*/}
              {/*      variant="outline"*/}
              {/*      onClick={() => {*/}
              {/*        // download url from previous call*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      Generate Report*/}
              {/*    </Button>*/}
              {/*    <Button*/}
              {/*      onClick={() => {*/}
              {/*        setShowSimulationResult(false);*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      Run New Simulation*/}
              {/*    </Button>*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
