import axios from "@axios";
import { DATE_FORMAT } from "@config";
import {
  queryOptions,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { DateRange, FinBoxResponse, ProgramTemplate } from "@types";
import {
  getGQEndTime,
  getGQStartTime,
  getNetworkErrorText,
  notify,
} from "@utils/utils";

export function useCardQueries({
  dateRange,
  program,
}: {
  dateRange: DateRange;
  program: string;
}) {
  return useQueries({
    queries: [
      {
        queryKey: [
          "inReviewPolicyCount",
          program,
          dateRange.startDate,
          dateRange.endDate,
        ],
        queryFn: async () =>
          axios.get<FinBoxResponse<number>>("overview/policyCount", {
            params: {
              program,
              startDate: getGQStartTime(dateRange.startDate, DATE_FORMAT),
              endDate: getGQEndTime(dateRange.endDate, DATE_FORMAT),
              status: "inreview",
            },
          }),
      },
      {
        queryKey: [
          "activeEndpointCount",
          program,
          dateRange.startDate,
          dateRange.endDate,
        ],
        queryFn: async () =>
          axios.get<FinBoxResponse<number>>("overview/activeEndpointCount", {
            params: {
              program,
              startDate: getGQStartTime(dateRange.startDate, DATE_FORMAT),
              endDate: getGQEndTime(dateRange.endDate, DATE_FORMAT),
            },
          }),
      },
    ],
  });
}

export function useUserTrends({
  dateRange,
  programID,
}: {
  dateRange: DateRange;
  programID: string;
}) {
  return useQuery({
    queryKey: ["userTrend", dateRange, programID],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<{
          old: {
            metrics: {
              pass?: number;
              reject?: number;
              approved?: number;
              rejected?: number;
            };
            totalCount: number;
          };
          new: {
            metrics: {
              pass?: number;
              reject?: number;
              approved?: number;
              rejected?: number;
            };
            totalCount: number;
          };
        }>
      >("overview/userTrend", {
        params: {
          startDate: getGQStartTime(dateRange.startDate, DATE_FORMAT),
          endDate: getGQEndTime(dateRange.endDate, DATE_FORMAT),
          ...(programID && {
            entityId: programID,
            entityType: "program",
          }),
          level: "user",
        },
      }),
  });
}

export function useEntityEvalutationStats({
  dateRange,
  programID,
}: {
  dateRange: DateRange;
  programID: string;
}) {
  return useQuery({
    queryKey: ["entityEvalutationStatisticsOverview", dateRange, programID],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<
          Array<{
            timestamp: string;
            metrics: Record<string, number>;
            totalCount: number;
          }>
        >
      >("overview/entityEvaluationStatistics", {
        params: {
          startDate: getGQStartTime(dateRange.startDate, DATE_FORMAT),
          endDate: getGQEndTime(dateRange.endDate, DATE_FORMAT),
          ...(programID && {
            entityId: programID,
            entityType: "program",
          }),
          level: "user",
        },
      }),
  });
}

type ExploreSentinel = {
  stepNumber: string;
};

export const exploreSentinelQuery = () =>
  queryOptions({
    queryKey: ["exploreSentinel"],
    queryFn: async () =>
      axios.get<FinBoxResponse<ExploreSentinel>>("overview/exploreSentinel"),
  });

export const useExploreSentinelNext = () => {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({ stepNumber }: { stepNumber: string }) =>
      axios.post<FinBoxResponse<string>>(
        `overview/exploreSentinel?stepNumber=${stepNumber}`,
        {
          stepNumber,
        }
      ),
    onSuccess: () => {
      qc.invalidateQueries(exploreSentinelQuery());
    },
    onError: (err) =>
      notify({
        title: "Couldn't goto next step",
        text: getNetworkErrorText(err),
      }),
  });
};

export const deploymentHistoryQuery = (
  dateRange: DateRange,
  program?: string
) =>
  queryOptions({
    queryKey: [
      "deploymentHistoryList",
      dateRange.startDate,
      dateRange.endDate,
      program,
    ],
    queryFn: async () => {
      return axios.get<FinBoxResponse<Record<string, string>[]>>(
        "overview/activepolicies",
        {
          params: {
            startDate: getGQStartTime(dateRange.startDate, DATE_FORMAT),
            endDate: getGQEndTime(dateRange.endDate, DATE_FORMAT),
            program,
          },
        }
      );
    },
    select: (data) => data.data.data,
    meta: {
      errorMessage: "Could not get overview history",
    },
  });

export const useGetDeploymentHistory = ({
  dateRange,
  program,
}: {
  dateRange: DateRange;
  program?: string;
}) => useQuery(deploymentHistoryQuery(dateRange, program));

const programTemplatesQuery = () =>
  queryOptions({
    queryKey: ["programTemplate"],
    queryFn: async () => {
      return axios.get<FinBoxResponse<Array<ProgramTemplate>>>(
        "/overview/getProgramTemplates"
      );
    },
    select: (data) => data.data,
  });

export const useProgramOverviewTemplates = () =>
  useQuery(programTemplatesQuery());
