import classNames from "clsx";
import { Badge as OGBadge } from "@finbox-in/finblocks";
import BadgeProps from "@finbox-in/finblocks/dist/lib/components/Badge/types";

const BgClasses: Record<string, string> = {
  error: "bg-error-100",
  warning: "bg-warning-100",
};

export default function Badge({ className, ...props }: BadgeProps) {
  return (
    <OGBadge
      {...props}
      className={classNames(
        "!text-[11px]",
        BgClasses[props.variant || ""],
        className
      )}
    />
  );
}
