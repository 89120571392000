import Button from "@components/Button";
import Modal from "@components/Dialogue";
import Dropdown from "@components/DropDown";
import QueryWrapper from "@components/QueryWrapper";
import Table from "@components/Table";
import { FloatingPortal, useFloating } from "@floating-ui/react";
import { Combobox } from "@headlessui/react";
import {
  WorkflowKeywords,
  getKeywordsQuery,
} from "@screens/create-policy/queries";
import {
  getCurrentSourcesQuery,
  getSourceListQuery,
  getSourceVariableMapping,
  getVariablesQuery,
  useAddSource,
  useUpdateSourceItem,
} from "@screens/workflow/studio/components/Source/queries";
import { SourceItem } from "@screens/workflow/studio/components/Source/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { notify } from "@utils/utils";
import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export function ConfigureModalWhileAdd({ isUpdate }: { isUpdate?: boolean }) {
  const { workflowId, nodeId, datasourceId, oldDatasourceId } = useParams<{
    workflowId: string;
    nodeId: string;
    datasourceId: string;
    oldDatasourceId: string;
  }>();
  const variableList = useQuery(getVariablesQuery(workflowId!, datasourceId!));

  const queryClient = useQueryClient();

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [variables, setVariables] = useState<
    {
      dependsOn: number;
      value: string;
      dataSourceType: number;
      id: number;
    }[]
  >([]);

  const navigate = useNavigate();
  const addSourceMutation = useAddSource();
  const updateSourceMutation = useUpdateSourceItem();

  const close = () => navigate(`/workflow/${workflowId}`);

  const dataSourceDetails = queryClient
    .getQueryData(getSourceListQuery(workflowId!).queryKey)
    ?.data.data.find((i) => i.data_source_id === Number(datasourceId));

  const validate = () => {
    return (
      variableList.data?.data.data.variables.length === variables.length &&
      variables.every((v) => v.value)
    );
  };

  const save = () => {
    if (!validate()) return setShowErrors(true);

    setShowErrors(false);

    if (!isUpdate && !dataSourceDetails)
      return notify({
        title: "Invalid operation",
        text: "Trying to add an invalid datasource",
      });

    if (isUpdate)
      updateSourceMutation.mutate(
        {
          workflowId: workflowId!,
          datasourceId: Number(datasourceId!),
          nodeId: nodeId!,
          variables: [],
          dataSourceType: dataSourceDetails?.type!,
          oldDatasourceId: Number(oldDatasourceId!),
        },
        {
          onSuccess: () => {
            close();
            notify({
              title: "Added",
              text: "Added data source",
              type: "success",
            });
          },
        }
      );
    else
      addSourceMutation.mutate(
        {
          nodeId: nodeId!,
          workflowId: workflowId!,
          dataSourceType: dataSourceDetails?.type!,
          dataSourceId: Number(datasourceId!),
          variables,
        },
        {
          onSuccess: () => {
            close();
            notify({
              title: "Added",
              text: "Added data source",
              type: "success",
            });
          },
        }
      );
  };

  return (
    <Modal className="z-[1000]" open={true} onClose={() => close()}>
      <Modal.Panel size="lg">
        <Modal.Header>Configure Source</Modal.Header>
        <Modal.Body className="pb-4 px-6">
          <h1 className="font-b1 text-neutral-900">
            Map your API Input Variables to Policy Predictors
          </h1>
          <Table
            data={variableList.data?.data.data.variables || []}
            className="sm:overflow-x-visible w-full mt-4"
            emptyText={<p className="font-b2">No variables found</p>}
            isLoading={false}
            headers={["Variable Name", "Predictor"]}
          >
            {(row, defaultRowClassNames, index) => {
              return (
                <tr
                  className={clsx(defaultRowClassNames, "!cursor-default")}
                  key={row.variableID}
                >
                  <td
                    className="w-1/2 truncate py-3 pr-1"
                    style={{ overflow: "hidden" }}
                  >
                    <Table.TdWrapper className="h-[52px]">
                      {row.variableName}
                    </Table.TdWrapper>
                  </td>
                  <td className="w-1/2  min-w-[90px]">
                    <PredictorSelect
                      setValue={(value) => {
                        const prev = structuredClone(variables);
                        prev.splice(index, 1, value);
                        setVariables(prev);
                      }}
                      variableID={
                        variableList.data?.data.data.variables[index]
                          .variableID ?? -1
                      }
                      showError={showErrors}
                    />
                  </td>
                </tr>
              );
            }}
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline" className="mr-3" onClick={() => close()}>
            Cancel
          </Button>
          <Button disabled={addSourceMutation.isPending} onClick={save}>
            Save
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
}

export function ConfigureModalWhileUpdate() {
  const { workflowId, nodeId, datasourceId } = useParams<{
    workflowId: string;
    nodeId: string;
    datasourceId: string;
  }>();
  const variableList = useQuery(getVariablesQuery(workflowId!, datasourceId!));

  const queryClient = useQueryClient();

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [variables, setVariables] = useState<
    {
      dependsOn: number;
      value: string;
      dataSourceType: number;
      id: number;
    }[]
  >([]);

  const navigate = useNavigate();
  const updateSourceMutation = useUpdateSourceItem();

  const mappedVariables = useQuery(
    getSourceVariableMapping({
      workflowId: workflowId!,
      dataSourceId: Number(datasourceId!),
      enabled: true,
    })
  );

  const isFirstRender = useRef(true);

  if (
    isFirstRender.current &&
    !mappedVariables.isPending &&
    mappedVariables.isSuccess
  ) {
    isFirstRender.current = false;

    setVariables(
      mappedVariables.data.data.data.variables.map((item) => {
        return {
          id: item.variableID,
          value: item.value,
          dependsOn: item.dataSourceID,
          dataSourceType: item.dataSourceType,
        };
      })
    );
  }

  const close = () => navigate(`/workflow/${workflowId}`);

  const dataSourceDetails: SourceItem | undefined = queryClient
    .getQueryData(getCurrentSourcesQuery(workflowId!, nodeId!).queryKey)
    ?.data.data.find((i) => i.data_source_id === Number(datasourceId));

  const validate = () => {
    return (
      variableList.data?.data.data.variables.length === variables.length &&
      variables.every((v) => v.value)
    );
  };

  const save = () => {
    if (!validate()) return setShowErrors(true);
    setShowErrors(false);

    if (!dataSourceDetails)
      return notify({
        title: "Invalid operation",
        text: "Trying to edit an invalid datasource",
      });

    updateSourceMutation.mutate(
      {
        nodeId: nodeId!,
        workflowId: workflowId!,
        dataSourceType: dataSourceDetails.type,
        datasourceId: Number(datasourceId!),
        oldDatasourceId: Number(datasourceId!),
        variables,
      },
      {
        onSuccess: () => {
          close();
          queryClient.invalidateQueries(
            getVariablesQuery(workflowId!, datasourceId!)
          );
          queryClient.invalidateQueries(
            getSourceVariableMapping({
              workflowId: workflowId!,
              dataSourceId: Number(datasourceId!),
              enabled: true,
            })
          );
          notify({
            title: "Updated",
            text: "Updated variable mapping",
            type: "success",
          });
        },
      }
    );
  };

  return (
    <Modal className="z-[1000]" open={true} onClose={() => close()}>
      <Modal.Panel size="lg">
        <Modal.Header>Configure Source</Modal.Header>
        <Modal.Body className="pb-4 px-6">
          <h1 className="font-b1 text-neutral-900">
            Map your API Input Variables to Policy Predictors
          </h1>
          <QueryWrapper query={mappedVariables}>
            {(data) => {
              return (
                <Table
                  data={variableList.data?.data.data.variables || []}
                  className="sm:overflow-x-visible w-full mt-4"
                  emptyText={<p className="font-b1">No variables found</p>}
                  isLoading={false}
                  headers={["Variable Name", "Predictor"]}
                >
                  {(row, defaultRowClassNames, index) => {
                    return (
                      <tr
                        className={clsx(
                          defaultRowClassNames,
                          "!cursor-default"
                        )}
                        key={row.variableID}
                      >
                        <td
                          className="w-1/2 truncate py-3 pr-1"
                          style={{ overflow: "hidden" }}
                        >
                          <Table.TdWrapper className="h-[52px]">
                            {row.variableName}
                          </Table.TdWrapper>
                        </td>
                        <td className="w-1/2 min-w-[90px]">
                          <PredictorSelect
                            showError={showErrors}
                            value={data?.data.data.variables[index]?.value}
                            setValue={(value) => {
                              const prev = structuredClone(variables);
                              prev.splice(index, 1, value);
                              setVariables(prev);
                            }}
                            variableID={
                              variableList.data?.data.data.variables[index]
                                .variableID ?? -1
                            }
                          />
                        </td>
                      </tr>
                    );
                  }}
                </Table>
              );
            }}
          </QueryWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline" className="mr-3" onClick={() => close()}>
            Cancel
          </Button>
          <Button disabled={updateSourceMutation.isPending} onClick={save}>
            Save
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
}

const PredictorSelect = ({
  showError,
  value,
  setValue,
  variableID,
}: {
  showError: boolean;
  value?: string;
  setValue: (value: {
    dependsOn: number;
    value: string;
    dataSourceType: number;
    id: number;
  }) => void;
  variableID: number;
}) => {
  const { workflowId, nodeId } = useParams();

  const keywordsQuery = useQuery(
    getKeywordsQuery<"workflow">({
      id: workflowId || "",
      ruleGroupID: nodeId,
      type: "workflow",
    })
  );

  let source = "",
    pred = "";
  if (value) {
    let rest;
    [source, ...rest] = value.split(".");
    if (rest.length === 0) {
      source = "custom";
      pred = value;
    } else pred = rest.join(".");
  }

  const [selectedSource, setSelectedSource] = useState(source);
  const [selectedPred, setSelectedPred] = useState(pred);
  const [query, setQuery] = useState(pred);

  const { x, y, strategy, refs } = useFloating();

  const {
    sourceList,
    modelNames,
    modelsetNames,
    decisionTableNames,
    rulesetNames,
    keywords,
  } = useMemo(() => {
    const keywords =
      keywordsQuery.data?.data ?? ({} as Partial<WorkflowKeywords>);

    const sourceList = Object.keys(
      keywordsQuery.data?.data.predictorsList ?? {}
    );
    let modelNames: string[] = [],
      rulesetNames: string[] = [],
      decisionTableNames: string[] = [],
      modelsetNames: string[] = [];

    if (keywords.modelExprs)
      modelNames = keywords.modelExprs.map((i) => i.name);

    if (keywords.modelSets)
      modelsetNames = keywords.modelSets.map((i) => i.name);

    if (keywords.modelDecisionTable)
      decisionTableNames = keywords.modelDecisionTable.map((i) => i.name);

    if (keywords.rulesets) rulesetNames = keywords.rulesets.map((i) => i.name);

    sourceList.push(
      ...modelNames,
      ...rulesetNames,
      ...decisionTableNames,
      ...modelsetNames
    );
    return {
      sourceList,
      modelNames,
      rulesetNames,
      decisionTableNames,
      modelsetNames,
      keywords,
    };
  }, [keywordsQuery.data]);

  const getSourceDataType = (text: string) => {
    if (text === "custom") {
      return 0;
    } //static
    else if (text === "input") return 2;
    // TODO: Need to check these two on the repsonse from sourceList api.
    else if (Object.keys(keywords?.predictorsList || {}).includes(text))
      return 1;
    else if (Object.keys(keywords?.customDataSources || {}).includes(text))
      return 3; //custom datasource type
    else if (
      modelsetNames.includes(text) ||
      modelNames.includes(text) ||
      rulesetNames.includes(text) ||
      decisionTableNames.includes(text)
    )
      return 4;
    else return -1;
  };

  const handleChange = (
    selectedPred: string,
    selectedSource: string,
    query: string
  ) => {
    let dataSourceType = getSourceDataType(selectedSource);

    if (dataSourceType === 0) {
      setValue({
        value: query,
        dataSourceType,
        dependsOn: -1,
        id: variableID ?? -1,
      });
    } else if (dataSourceType === 1) {
      setValue({
        id: variableID ?? -1,
        value: selectedSource + "." + selectedPred,
        dataSourceType,
        dependsOn: keywords?.predictorsList?.[selectedSource].id || -1,
      });
    } else if (dataSourceType === 2) {
      setValue({
        value: selectedSource + "." + selectedPred,
        dataSourceType,
        dependsOn: -1,
        id: variableID ?? -1,
      });
    } else if (dataSourceType === 3) {
      setValue({
        id: variableID ?? -1,
        value: selectedSource + "." + selectedPred,
        dataSourceType,
        dependsOn: keywords?.customDataSources?.[selectedSource].id || -1,
      });
    } else if (dataSourceType === 4) {
      setValue({
        id: variableID ?? -1,
        value: selectedSource + "." + selectedPred,
        dataSourceType,
        dependsOn: -1,
      });
    }
  };

  // TODO: need better solution for this.
  useEffect(() => {
    if (value) {
      let source = "",
        pred = "";
      if (value) {
        let rest;
        [source, ...rest] = value.split(".");
        pred = rest.join(".");
        if (rest.length === 0) {
          pred = value;
          source = "custom";
        }
      }

      handleChange(pred, source, value);
    }
    //   eslint-disable-next-line
  }, [value]);

  const getOptions = () => {
    if (keywords?.predictorsList?.[selectedSource])
      return keywords?.predictorsList?.[selectedSource].list
        ?.filter((e) => e.toLowerCase().includes(query?.toLowerCase()))
        .slice(0, 100)
        ?.map((p) => (
          <Combobox.Option
            key={p}
            value={p}
            className="px-4 py-2 text-sm text-neutral-500 cursor-pointer hover:bg-neutral-25"
          >
            {p}
          </Combobox.Option>
        ));
    if (keywords?.customDataSources?.[selectedSource])
      return keywords?.customDataSources?.[selectedSource].list
        ?.filter((e) => e.toLowerCase().includes(query?.toLowerCase()))
        ?.map((p) => (
          <Combobox.Option
            key={p}
            value={p}
            className="px-4 py-2 text-sm text-neutral-500 cursor-pointer hover:bg-neutral-25"
          >
            {p}
          </Combobox.Option>
        ));

    if (modelsetNames.includes(selectedSource))
      return keywords?.modelSets
        ?.find((i) => i.name === selectedSource)
        ?.output?.filter((e) => e.toLowerCase().includes(query?.toLowerCase()))
        ?.map((p) => (
          <Combobox.Option
            key={p}
            value={p}
            className="px-4 py-2 text-sm text-neutral-500 cursor-pointer hover:bg-neutral-25"
          >
            {p}
          </Combobox.Option>
        ));

    if (modelNames.includes(selectedSource))
      return keywords?.modelExprs
        ?.find((i) => i.name === selectedSource)
        ?.output?.filter((e) => e.toLowerCase().includes(query?.toLowerCase()))
        ?.map((p) => (
          <Combobox.Option
            key={p}
            value={p}
            className="px-4 py-2 text-sm text-neutral-500 cursor-pointer hover:bg-neutral-25"
          >
            {p}
          </Combobox.Option>
        ));

    if (decisionTableNames.includes(selectedSource))
      return keywords?.modelDecisionTable
        ?.find((i) => i.name === selectedSource)
        ?.output?.filter((e) => e.toLowerCase().includes(query?.toLowerCase()))
        ?.map((p) => (
          <Combobox.Option
            key={p}
            value={p}
            className="px-4 py-2 text-sm text-neutral-500 cursor-pointer hover:bg-neutral-25"
          >
            {p}
          </Combobox.Option>
        ));

    if (rulesetNames.includes(selectedSource))
      return keywords?.rulesets
        ?.find((i) => i.name === selectedSource)
        ?.output?.filter((e) => e.toLowerCase().includes(query?.toLowerCase()))
        ?.map((p) => (
          <Combobox.Option
            key={p}
            value={p}
            className="px-4 py-2 text-sm text-neutral-500 cursor-pointer hover:bg-neutral-25"
          >
            {p}
          </Combobox.Option>
        ));
  };

  return (
    <div className="flex h-full gap-0.5">
      <Dropdown
        defaultValue={source}
        onChange={(e: string) => {
          setSelectedSource(e);
          setSelectedPred("");
          handleChange(selectedPred, selectedSource, query);
        }}
      >
        <Dropdown.Button
          className={clsx(
            "h-[42px] mt-[3px] rounded-r-none min-w-[9rem] text-sm",
            !source && showError && "border !border-error-500"
          )}
        >
          {selectedSource || "Select source"}
        </Dropdown.Button>
        <Dropdown.Options>
          {sourceList.map((s) => (
            <Dropdown.Option value={s} key={s}>
              {s}
            </Dropdown.Option>
          ))}
          <Dropdown.Option value="custom">custom</Dropdown.Option>
        </Dropdown.Options>
      </Dropdown>
      <div>
        <Combobox
          value={selectedSource === "custom" ? query : selectedPred}
          onChange={(e) => {
            setSelectedPred(e);
            handleChange(e, selectedSource, query);
          }}
        >
          <Combobox.Input
            defaultValue={pred}
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(selectedPred, selectedSource, e.target.value);
            }}
            className={clsx(
              "border font-b1-medium border-neutral-100 rounded-r-md -translate-y-[1px] text-neutral-black",
              showError &&
                !(selectedSource === "custom" ? query : selectedPred) &&
                "!border-error-500"
            )}
            ref={refs.setReference}
          />
          <br />
          <FloatingPortal>
            <Combobox.Options
              ref={refs.setFloating}
              style={{
                width: "max-content",
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 9999,
              }}
              className="max-h-44 overflow-scroll rounded-md bg-white shadow ring-1 ring-gray-100"
            >
              {getOptions()}
            </Combobox.Options>
          </FloatingPortal>
        </Combobox>
      </div>
    </div>
  );
};
