import classNames from "clsx";
import React, { ReactNode, useState } from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import ErrorText from "@components/ErrorText";
import Multiselect, {
  multiselectOnChangeHandler,
} from "@components/Multiselect";
import { useGetFunnelItems } from "../../../queries";
import { FormType } from "../types";

export default function FunnelValuesSelect({
  form,
}: {
  form: UseFormReturn<FormType>;
}) {
  const funnelType = useWatch({
    control: form.control,
    name: "funnelType",
  });

  const [funnelItemFilterQuery, setFunnelItemFilterQuery] = useState("");

  const funnelItemsQuery = useGetFunnelItems(funnelType);
  const funnelItems = funnelItemsQuery.isSuccess
    ? Object.keys(funnelItemsQuery.data.data.data)
        .filter(
          (key) =>
            funnelItemFilterQuery === "" ||
            funnelItemsQuery.data.data.data[key]
              .toLowerCase()
              .includes(funnelItemFilterQuery.toLowerCase())
        )
        .map((key) => ({
          name: funnelItemsQuery.data.data.data[key],
          id: key,
        }))
    : [];

  return (
    <>
      <Controller
        control={form.control}
        name="funnelValues"
        rules={{
          required: { value: true, message: "Funnel Value is required" },
          validate: {
            maxLength: (v) => v.length <= 3 || "Max 3 values can be selected",
          },
        }}
        render={({ field }) => {
          return (
            <Multiselect
              disabled={!funnelType}
              options={funnelItems.sort((a) =>
                field.value.map((item) => item.id).includes(a.id) ? -1 : 1
              )}
              values={field.value}
              onSearchChange={setFunnelItemFilterQuery}
              getKey={(e) => e.id}
              onChange={(e) => {
                const newValues = multiselectOnChangeHandler(e, "id");
                field.onChange(newValues);
                if (newValues.length === 0) {
                  form.resetField("groupByKey", { defaultValue: "" });
                  form.resetField("groupByValues", { defaultValue: [] });
                  form.resetField("output", { defaultValue: "" });
                  form.resetField("config.outcome", { defaultValue: "" });
                }
              }}
              getDisplayValue={(e) => {
                if (!e || e.length === 0) return "";
                const names = e.map((e) => e.name.split("/").at(-1) as string);
                if (names.length === 1) return names[0];
                return `${names[0]} +${names.length - 1}`;
              }}
              getName={(e) => {
                const nameSplit = e.name.split("/");
                const output: ReactNode[] = [];

                nameSplit.forEach((item, index) => {
                  output.push(
                    <span
                      data-dropdown-option="yes"
                      className={classNames(
                        index === nameSplit.length - 1
                          ? "text-neutral-black"
                          : "text-neutral-500"
                      )}
                    >
                      {item}
                      {index !== nameSplit.length - 1 && " / "}
                    </span>
                  );
                });
                return <>{output}</>;
              }}
              isSelected={(e) => !!field.value?.find((p) => p.id === e.id)}
            />
          );
        }}
      />
      {form.formState.errors.funnelValues?.message && (
        <ErrorText>{form.formState.errors.funnelValues?.message}</ErrorText>
      )}
    </>
  );
}
