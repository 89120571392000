import RunFlowModelSetNode from "@screens/workflow/studio/node-types/run-flow-node-types/RunFlowModelSetNode";
import ModelSetNode from "@screens/workflow/studio/node-types/studio-node-types/ModelSetNode";
import {
  BRANCH_NODE_TYPE,
  DECISION_TABLE_NODE_TYPE,
  END_NODE_TYPE,
  MODEL_NODE_TYPE,
  MODEL_SET_NODE_TYPE,
  POLICY_NODE_TYPE,
  RULE_SET_NODE_TYPE,
  SOURCE_NODE_TYPE,
  START_NODE_TYPE,
  WORKFLOW_NODE_TYPE,
} from "../../config";
import RunFlowBranchNode from "./run-flow-node-types/RunFlowBranchNode";
import RunFlowDecisionTableNode from "./run-flow-node-types/RunFlowDecisionTableNode";
import RunFlowEndNode from "./run-flow-node-types/RunFlowEndNode";
import RunFlowModelNode from "./run-flow-node-types/RunFlowModelNode";
import RunFlowPolicyNode from "./run-flow-node-types/RunFlowPolicyNode";
import RunFlowRuleSetNode from "./run-flow-node-types/RunFlowRuleSetNode";
import RunFlowSourceNode from "./run-flow-node-types/RunFlowSourceNode";
import RunFlowWorkflowNode from "./run-flow-node-types/RunFlowWorkflowNode";
import BranchNode from "./studio-node-types/BranchNode";
import DecisionTableNode from "./studio-node-types/DecisionTableNode";
import EndNode from "./studio-node-types/EndNode";
import ModelNode from "./studio-node-types/ModelNode";
import NewNode from "./studio-node-types/NewNode";
import PolicyNode from "./studio-node-types/PolicyNode";
import RuleSetNode from "./studio-node-types/RuleSetNode";
import SourceNode from "./studio-node-types/SourceNode";
import StartNode from "./studio-node-types/StartNode";
import WorkflowNode from "./studio-node-types/WorkflowNode";

// Always use memoized nodes
const nodeTypes = {
  [POLICY_NODE_TYPE]: PolicyNode,
  [START_NODE_TYPE]: StartNode,
  [BRANCH_NODE_TYPE]: BranchNode,
  [END_NODE_TYPE]: EndNode,
  [WORKFLOW_NODE_TYPE]: WorkflowNode,
  [MODEL_NODE_TYPE]: ModelNode,
  [RULE_SET_NODE_TYPE]: RuleSetNode,
  [DECISION_TABLE_NODE_TYPE]: DecisionTableNode,
  [SOURCE_NODE_TYPE]: SourceNode,
  [MODEL_SET_NODE_TYPE]: ModelSetNode,
  newNode: NewNode,
};

// Always use memoized nodes
export const runFlowNodeTypes = {
  [POLICY_NODE_TYPE]: RunFlowPolicyNode,
  [START_NODE_TYPE]: StartNode,
  [BRANCH_NODE_TYPE]: RunFlowBranchNode,
  [END_NODE_TYPE]: RunFlowEndNode,
  [WORKFLOW_NODE_TYPE]: RunFlowWorkflowNode,
  [MODEL_NODE_TYPE]: RunFlowModelNode,
  [RULE_SET_NODE_TYPE]: RunFlowRuleSetNode,
  [DECISION_TABLE_NODE_TYPE]: RunFlowDecisionTableNode,
  [SOURCE_NODE_TYPE]: RunFlowSourceNode,
  [MODEL_SET_NODE_TYPE]: RunFlowModelSetNode,
  newNode: NewNode,
};

export default nodeTypes;
