import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import packageJSON from "../../package.json";

export const initSentry = () => {
  Sentry.init({
    dsn: "https://d017868542b944859b8e3eeb612991a9@o82232.ingest.sentry.io/4504610863644672",
    integrations: [new BrowserTracing()],
    environment: import.meta.env.REACT_APP_API_ENV,
    tracesSampleRate: 0,
    release: packageJSON.name + "@" + packageJSON.version,
    ignoreErrors: [
      "'insertBefore' on 'Node'",
      "privateSpecialRepair is not defined",
      "getReadModeConfig",
      "getReadModeExtract",
      "getReadModeRender",
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      "AxiosError",
      "RecorderError", // thrown by smartlook
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /localhost/,
      /smartlook.cloud/,
      /smartlook.com/,
    ],
  });
};
