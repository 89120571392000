import { format, parseISO } from "date-fns";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useEntityEvaluationStatistics } from "@screens/policyDetails/queries";
import Card from "@components/Card";
import ErrorState from "@components/ErrorState";
import Shimmer from "@components/Shimmer";
import { DateRange } from "@types";
import { shortNumber } from "@utils/utils";
import SubHeader from "./SubHeader";

type Props = {
  dateRange: DateRange;
  policyID: string;
};

export default function PolicyTrafficGraph({ dateRange, policyID }: Props) {
  const stats = useEntityEvaluationStatistics({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    entityID: policyID,
    entityType: "policy",
    level: "user",
  });

  const error = stats.error;
  const data = stats.data?.data.data;

  if (stats.isPending)
    return <Shimmer className="grow shrink-0 h-full w-full" />;

  return (
    <Card className="relative min-h-96 shrink-0 w-[calc(100%_-_396px)]">
      <div className="absolute left-0 right-0 top-0 bottom-0 p-6 flex flex-col gap-2 h-full">
        <SubHeader>User traffic</SubHeader>
        {!!error && (
          <ErrorState error={error}>Could not fetch traffic data</ErrorState>
        )}
        {data && (
          <ResponsiveContainer height="90%" width="100%">
            <BarChart
              data={data}
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient
                  id="policyTrafficGradient"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor="#5A3FFF" stopOpacity={1} />
                  <stop offset="2px" stopColor="#5A3FFF" stopOpacity={1} />
                  <stop offset="1%" stopColor="#5A3FFF" stopOpacity={0.6} />
                  <stop offset="100%" stopColor="#5A3FFF" stopOpacity={0.2} />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                stroke="#F3F4F6"
              />
              <XAxis
                dataKey="timestamp"
                tickLine={false}
                tick={({ x, y, payload }) => {
                  return (
                    <g transform={`translate(${x},${y})`}>
                      <text
                        x={10}
                        y={10}
                        dy={4}
                        dx={10}
                        textAnchor="end"
                        fill="#9CA3AF"
                        fontSize="12px"
                      >
                        {format(
                          parseISO(payload.value),
                          String.raw`dd MMM`
                        ).toUpperCase()}
                      </text>
                    </g>
                  );
                }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={({ x, y, payload }) => {
                  return (
                    <g transform={`translate(${x},${y})`}>
                      <text
                        x={-10}
                        y={0}
                        dy={4}
                        textAnchor="end"
                        fill="#9CA3AF"
                        fontSize="12px"
                      >
                        {shortNumber(payload.value)}
                      </text>
                    </g>
                  );
                }}
              />
              <Tooltip
                content={({ active, payload, label }) => {
                  if (!active || !payload?.length) return null;
                  return (
                    <div className="rounded-md bg-gray-600 px-4 py-2 text-xs text-white shadow-md">
                      <div className="text-sm">
                        {payload.map((p) => {
                          if (!p.dataKey) return null;
                          return (
                            <span
                              className="text-neutral-100 font-b2"
                              key={p.dataKey}
                            >
                              <span>User Count: </span>
                              <span className="ml-1 font-b2-medium text-neutral-100">
                                {p.payload.totalCount}
                              </span>
                            </span>
                          );
                        })}
                      </div>
                      <br />
                      <span className="text-neutral-100">
                        {format(parseISO(label), String.raw`dd-MMM ''yy`)}
                      </span>
                    </div>
                  );
                }}
              />
              <Legend
                content={
                  <div className="flex flex-shrink-0 items-center justify-center gap-4">
                    <span
                      className={`flex items-center gap-1 text-xs text-[#5A3FFF]`}
                    >
                      <span className="h-2.5 w-2.5 rounded-sm bg-current" />
                      <span className="text-gray-600">User Count</span>
                    </span>
                  </div>
                }
              />
              <Bar
                fill="url(#policyTrafficGradient)"
                type="monotone"
                style={{ width: "100%" }}
                isAnimationActive={false}
                dataKey="totalCount"
              ></Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </Card>
  );
}
