import clsx from "clsx";
import { toPng } from "html-to-image";
import {
  type FC,
  memo,
  type PropsWithChildren,
  useEffect,
  useState,
} from "react";
import {
  ControlButton,
  getRectOfNodes,
  getTransformForBounds,
} from "reactflow";
import { shallow } from "zustand/shallow";
import { ReactComponent as CameraIcon } from "@assets/icons/camera-01.svg";
import { ReactComponent as AutoArrangeIcon } from "@assets/icons/layout-grid-01.svg";
import { ReactComponent as MaximizeIcon } from "@assets/icons/workflow/maximize.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import useIsExpand from "@screens/workflow/studio/hooks/useIsExpand";
import Dropdown from "@components/DropDown";
import Loader from "@components/Loader";
import { LOCALSTORAGE_KEYS } from "@config";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import {
  Panel,
  type ReactFlowState,
  useReactFlow,
  useStore,
} from "@reactflow/core";
import { useMutation } from "@tanstack/react-query";
import { getNetworkErrorText, notify } from "@utils/utils";
import type { ControlProps } from "./types";

const imageWidth = 4096;
const imageHeight = 2160;

const useGenerateAndDownloadImage = () => {
  const { workflow } = useWorkflowContext();
  const { getNodes } = useReactFlow();

  return useMutation({
    mutationFn: async () => {
      // https://reactflow.dev/examples/misc/download-image
      const nodesBounds = getRectOfNodes(getNodes());
      const transform = getTransformForBounds(
        nodesBounds,
        imageWidth,
        imageHeight,
        0.6,
        1.5
      );

      const rfNode = document.querySelector(
        ".react-flow__viewport"
      ) as HTMLElement;
      if (rfNode)
        return toPng(rfNode, {
          backgroundColor: "#f6f6f6",
          width: imageWidth,
          height: imageHeight,
          style: {
            width: imageWidth.toString(),
            height: imageHeight.toString(),
            transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
          },
        }).then((data) =>
          downloadImage(data, (workflow?.name ?? "workflow") + ".png")
        );
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
  });
};

const selector = (s: ReactFlowState) => ({
  zoom: s.transform[2],
  minZoomReached: s.transform[2] <= s.minZoom,
  maxZoomReached: s.transform[2] >= s.maxZoom,
});

function downloadImage(dataUrl: string, name: string) {
  const a = document.createElement("a");

  a.setAttribute("download", name);
  a.setAttribute("href", dataUrl);
  a.click();
}

const CustomControls: FC<PropsWithChildren<ControlProps>> = ({
  style,
  showZoom = true,
  showFitView = true,
  fitViewOptions,
  onZoomIn,
  autoArrange,
  onZoomOut,
  onFitView,
  className,
  children,
  position = "bottom-left",
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { minZoomReached, maxZoomReached, zoom } = useStore(selector, shallow);
  const { isWorkflowEditable } = useWorkflowContext();
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  const isExpanded = useIsExpand();

  const generateImage = useGenerateAndDownloadImage();

  const [maxZoom, _setMaxZoom] = useState(
    Number(localStorage.getItem(LOCALSTORAGE_KEYS.STUDIO_MAX_ZOOM) ?? "1")
  );
  const setMaxZoom = (value: number) => {
    localStorage.setItem(LOCALSTORAGE_KEYS.STUDIO_MAX_ZOOM, value.toString());
    _setMaxZoom(value);
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  if (!isVisible) {
    return null;
  }

  const onZoomInHandler = () => {
    zoomIn();
    onZoomIn?.();
  };

  const onZoomOutHandler = () => {
    zoomOut();
    onZoomOut?.();
  };

  const onFitViewHandler = () => {
    fitView(fitViewOptions);
    onFitView?.();
  };

  const onDownloadFile = () => {
    if (generateImage.isPending) return;
    generateImage.mutate();
  };

  return (
    <Panel
      className={clsx("flex bg-transparent p-1 gap-2", className)}
      position={position}
      style={style}
      data-testid="rf__controls"
    >
      <div className="flex bg-white gap-1 px-1 items-center justify-between text-neutral-black border rounded-md font-b2-medium border-neutral-100">
        {showZoom && (
          <>
            <ControlButton
              onClick={onZoomOutHandler}
              title="Zoom Out"
              aria-label="zoom out"
              disabled={minZoomReached}
              className="h-max w-max border-b-0 !bg-white group"
            >
              <MinusIcon className="h-5 !w-5 !max-w-max !max-h-max group-hover:text-neutral-black text-neutral-500" />
            </ControlButton>
            <div className="w-8 text-center">{Math.round(zoom * 100)}%</div>
            <ControlButton
              onClick={onZoomInHandler}
              title="Zoom In"
              aria-label="zoom in"
              disabled={maxZoomReached}
              className="h-max w-max border-b-0 !bg-white group"
            >
              <PlusIcon className="h-5 !w-5 !max-w-max !max-h-max group-hover:text-neutral-black text-neutral-500" />
            </ControlButton>
          </>
        )}
        {showFitView && (
          <ControlButton
            onClick={onFitViewHandler}
            title="Fit View"
            aria-label="fit view"
            className="h-max w-max border-b-0 !bg-white group"
          >
            <MaximizeIcon className="h-4 !w-4 !max-w-max !max-h-max group-hover:[&>path]:stroke-neutral-black" />
          </ControlButton>
        )}
      </div>

      <div className="flex bg-white gap-1 px-2 w-36 items-center justify-between text-neutral-black border rounded-md font-b2-medium border-neutral-100">
        {showZoom && (
          <>
            <span className="whitespace-nowrap">Max Zoom:</span>
            <Dropdown value="1" className="h-7" onChange={setMaxZoom}>
              <Dropdown.Button className="focus:ring-0 relative bg-white rounded-md px-0 border-0 text-left font-b2-medium text-neutral-black !h-7">
                {maxZoom * 100}%
              </Dropdown.Button>
              <Dropdown.Options className="absolute z-20 rounded-md bg-white !max-w-[148px] !w-[148px] translate-x-[14px]">
                <Dropdown.Option value={1}>100%</Dropdown.Option>
                <Dropdown.Option value={1.25}>125%</Dropdown.Option>
                <Dropdown.Option value={1.5}>150%</Dropdown.Option>
              </Dropdown.Options>
            </Dropdown>
          </>
        )}
      </div>

      <div className="flex bg-white gap-1 px-1 items-center justify-between text-neutral-black border rounded-md font-b2-medium border-neutral-100">
        <ControlButton
          onClick={onDownloadFile}
          disabled={!isExpanded || generateImage.isPending}
          title="Download Image"
          className="h-max w-max border-b-0 !bg-white cursor-pointer group"
        >
          {generateImage.isPending ? (
            <Loader className="mr-0" type="inline-block" size="xs" />
          ) : (
            <CameraIcon
              className={clsx(
                "h-4 !w-4 !max-w-max !max-h-max group-hover:[&>path]:stroke-netural-black",
                !isExpanded && "[&>path]:stroke-netural-500"
              )}
            />
          )}
        </ControlButton>
      </div>

      {autoArrange && isWorkflowEditable && (
        <div className="flex bg-white gap-1 px-1 items-center justify-between text-neutral-black border rounded-md font-b2-medium border-neutral-100">
          <ControlButton
            disabled={!isWorkflowEditable}
            onClick={() => autoArrange()}
            title="Auto-Arrange"
            className="h-max w-max border-b-0 !bg-white group"
          >
            <AutoArrangeIcon className="w-4 h-4" />
          </ControlButton>
        </div>
      )}

      {children}
    </Panel>
  );
};

CustomControls.displayName = "Controls";

export default memo(CustomControls);
