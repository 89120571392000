import * as BureauPreds from "./bureau.json";

interface BureauDocsProps {
  query: string;
}

const BureauDocs = ({ query }: BureauDocsProps) => {
  return (
    <div className="mx-8 mt-4 border max-h-[75%] h-max overflow-auto">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase">
          <tr className="divide-x">
            <th scope="col" className="px-6 py-3 sticky top-0 bg-gray-100">
              Predictor
            </th>
            <th scope="col" className="px-6 py-3 sticky top-0 bg-gray-100">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          {BureauPreds.preds
            .filter((e) => e.name.toLowerCase().includes(query))
            .map((bucp) => (
              <tr key={bucp.name} className="divide-x bg-white even:bg-gray-50">
                <td className="px-6 py-4 text-gray-700 font-medium">
                  {bucp.name}
                </td>
                <td className="px-6 py-4">{bucp.description}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default BureauDocs;
