import { useState } from "react";
import Button from "src/components/Button";
import Header from "src/components/Header";
import ProtectedComponent from "src/components/ProtectedComponent";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import useBreadcrumbs from "src/context/BreadcrumbsContext";
import { PermissionTypes } from "src/types";
import Searchbar from "@components/Searchbar";
import FilterDropdown from "@components/Searchbar/filters/FilterDropdown";
import Shimmer from "@components/Shimmer";
import { AddUser } from "./AddUser";
import UsersTable from "./UsersTable";
import { useMemberDashboardUsers, useMemberRoleListQuery } from "./queries";

const DEFAULT_ROLE_OPTION = { role: "All Roles" };
export default function Members() {
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const [name, setName] = useState("");
  const [role, setRole] = useState<{ role: string }>();
  const [page, setPage] = useState(1);

  const usersQuery = useMemberDashboardUsers({
    name,
    page,
    role: role?.role || "",
  });

  useBreadcrumbs([
    {
      name: "Administration",
      link: "/settings/members",
    },
    {
      name: "Members",
      link: "/settings/members",
    },
  ]);

  const { data: roleList, isSuccess } = useMemberRoleListQuery();

  return (
    <div>
      <Header className="w-full border-b-0">
        <div>
          <div className="flex justify-between">
            <div className="flex justify-between w-full items-center">
              <div className="my-5 mx-2 mb-3">
                <Header.PageHeader>Administration</Header.PageHeader>
                <Header.Description>
                  Manage your team members access for your organization
                </Header.Description>
              </div>
              <div className="flex gap-2">
                <ProtectedComponent
                  type={
                    PERMISSIONS_TYPE.administrationMembers as PermissionTypes
                  }
                  action="create"
                >
                  <Button
                    className="sm:w-fit"
                    onClick={() => setShowAddUserModal(true)}
                  >
                    <span className="whitespace-nowrap">Invite Members</span>
                  </Button>
                </ProtectedComponent>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <div className="py-4 px-6">
        <Searchbar onSearch={(e) => setName(e)} hint="Search members">
          {isSuccess && (
            <Searchbar.Filters>
              <FilterDropdown
                onClear={() => setRole(undefined)}
                getKey={(v) => v.role}
                displayValue={(item) => item.role}
                options={roleList!}
                onChange={(e) => setRole(e)}
                value={role}
                defaultValue={DEFAULT_ROLE_OPTION}
              />
            </Searchbar.Filters>
          )}
        </Searchbar>
        {usersQuery.isPending ? (
          <Shimmer className="mt-4" w="100%" h="500px" />
        ) : (
          <UsersTable
            data={
              usersQuery.data?.data?.data?.dashboard_users
                ? usersQuery.data.data.data.dashboard_users
                : []
            }
            isFetching={usersQuery.isFetching}
            setPage={setPage}
            page={page}
            totalCount={usersQuery.data?.data?.data?.total_count || 0}
          />
        )}
      </div>
      {showAddUserModal && (
        <AddUser show={showAddUserModal} setShow={setShowAddUserModal} />
      )}
    </div>
  );
}
