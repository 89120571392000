import clsx from "clsx";
import { forwardRef, useRef, useState } from "react";
import Tooltip from "src/components/Tooltip";
import { ReactComponent as DotsGridIcon } from "@assets/icons/workflow/dots-grid.svg";
import { ReactComponent as PlusIcon } from "@assets/icons/workflow/plus-add-condition.svg";
import { ReactComponent as DeleteIcon } from "@assets/icons/workflow/trash-02.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { getWorkflowQuery } from "@screens/workflow/queries";
import { RuleId } from "@screens/workflow/types";
import { FINBOX_SOURCES } from "@config";
import { useSortable } from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import { CSS } from "@utils/CSS";
import { notify } from "@utils/utils";
import CustomEditor from "@components/Editor/CustomEditor";
import {
  useDeleteRuleSetCondition,
  useUpdateRuleSetCondition,
} from "./queries";
import { RuleSetCondition } from "./types";

interface RuleSetItemProps {
  condition: RuleSetCondition;
  workflowId: string;
  nodeId: string;
  isEdit: boolean;
  setIsEdit: () => void;
  clear: () => void;
  showDelete?: boolean;
  index: number;
}

const RuleSetItem = forwardRef<HTMLDivElement, RuleSetItemProps>(
  (
    { condition, workflowId, showDelete, nodeId, isEdit, setIsEdit, index },
    ref
  ) => {
    const [showCantDecide, setShowCantDecide] = useState(
      !!condition.cant_decide
    );
    const nameRef = useRef<HTMLInputElement>(null);
    const queryClient = useQueryClient();

    const { isWorkflowEditable, errors } = useWorkflowContext();

    const approveError =
      errors?.[nodeId as RuleId]?.errors?.[condition.rule_id]?.approve_error;
    const cantDecideError =
      errors?.[nodeId as RuleId]?.errors?.[condition.rule_id]
        ?.cant_decide_error;

    const updateRuleSetCondition = useUpdateRuleSetCondition();
    const deleteRuleSetCondition = useDeleteRuleSetCondition();
    
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: condition.rule_id });

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
    };

    const onNameChange = (name: string) => {
      if (FINBOX_SOURCES.includes(name))
        return notify({
          title: "Invalid Name",
          text: "Cannot use an inbuilt source name",
        });
      if (name.length > 250)
        return notify({
          title: "Invalid Name",
          text: "Max length 150 characters",
        });

      updateRuleSetCondition.mutate({
        workflowId,
        nodeId,
        conditionId: condition.rule_id,
        description: name,
        approve: condition.approve,
        cant_decide: condition.cant_decide,
      });
    };

    const onExpressionChange = (
      expression: string,
      key: "approve" | "cant_decide" = "approve"
    ) => {
      if (!expression)
        return notify({
          title: "Invalid expression",
          text: "Expression is empty",
        });
      switch (key) {
        case "approve":
          updateRuleSetCondition.mutate({
            workflowId,
            nodeId,
            conditionId: condition.rule_id,
            description: nameRef.current?.value || condition.description,
            approve: expression,
            cant_decide: condition.cant_decide,
          });
          break;
        case "cant_decide":
          updateRuleSetCondition.mutate({
            workflowId,
            nodeId,
            conditionId: condition.rule_id,
            description: nameRef.current?.value || condition.description,
            approve: condition.approve,
            cant_decide: expression,
          });
      }
    };

    const toggleCantDecide = () => {
      if (showCantDecide)
        updateRuleSetCondition.mutate(
          {
            workflowId,
            nodeId,
            conditionId: condition.rule_id,
            description: nameRef.current?.value || condition.description,
            approve: condition.approve,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(getWorkflowQuery(workflowId));
            },
          }
        );
      else
        updateRuleSetCondition.mutate(
          {
            workflowId,
            nodeId,
            conditionId: condition.rule_id,
            description: nameRef.current?.value || condition.description,
            approve: condition.approve,
            cant_decide: "false",
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(getWorkflowQuery(workflowId));
            },
          }
        );
      setShowCantDecide((p) => !p);
    };

    return (
      <div
        className="relative flex"
        ref={setNodeRef}
        onClick={() => {
          !isEdit && setIsEdit();
        }}
        style={style}
      >
        <div
          className={clsx(
            "nodrag p-0.5 mt-2 mr-1",
            (isEdit || !isWorkflowEditable) && "hidden"
          )}
          {...attributes}
          {...listeners}
        >
          <DotsGridIcon className="w-3 h-3" />
        </div>
        <div
          className={clsx(
            "border border-neutral-100 rounded-md p-2 bg-neutral-0 font-b2 w-full",
            !isEdit && "cursor-pointer max-w-[320px]",
            !isWorkflowEditable && "max-w-[360px]",
            (approveError || cantDecideError) && "!border-error-500"
          )}
          ref={ref}
        >
          <div className="transition-all ease-in h-auto">
            <div className="flex justify-between mb-2">
              <div className="flex items-center gap-2 overflow-visible">
                <span className="text-neutral-500">{index + 1}.</span>{" "}
                {isEdit ? (
                  <input
                    ref={nameRef}
                    type="text"
                    readOnly={!isWorkflowEditable}
                    className="p-2 font-b2-medium text-neutral-black nodrag !w-[250px] !outline-none border border-neutral-100 h-6 rounded-md !ring-0"
                    defaultValue={condition.description}
                    onBlur={(e) => {
                      onNameChange(e.target.value);
                    }}
                  />
                ) : (
                  <Tooltip
                    contentClassName="font-b2-medium max-w-[250px] truncate"
                    tooltipContent={condition.description}
                    content={
                      <span
                        onClick={() => {
                          !isEdit && setIsEdit();
                        }}
                      >
                        {condition.description}
                      </span>
                    }
                  />
                )}
              </div>
              <div className="flex ml-2">
                {showDelete && (
                  <DeleteIcon
                    className="cursor-pointer [&:hover>path]:stroke-error-500 w-4 h-4 ml-auto"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteRuleSetCondition.mutate(
                        {
                          nodeId,
                          conditionId: condition.rule_id,
                          workflowId,
                        },
                        {
                          onSuccess: () => {
                            if (condition.cant_decide)
                              queryClient.invalidateQueries(
                                getWorkflowQuery(workflowId)
                              );
                          },
                        }
                      );
                    }}
                  />
                )}
              </div>
            </div>
            {isEdit ? (
              <CustomEditor
                setValue={(e) => {
                  onExpressionChange(e);
                }}
                value={condition.approve}
                monacoOptions={{
                  lineNumbers: "off",
                  readOnly: !isWorkflowEditable,
                  glyphMargin: false,
                  fontWeight: "400",
                  folding: false,
                  lineDecorationsWidth: 0,
                  lineNumbersMinChars: 0,
                  showFoldingControls: "never",
                }}
              />
            ) : (
              <div className="text-neutral-500 truncate font-code text-[14px]">
                {condition.approve}
              </div>
            )}
            {showCantDecide && (
              <>
                <div className="mt-2 mb-1 text-neutral-500 flex justify-between">
                  <span>Can't Decide</span>
                  {isEdit && isWorkflowEditable && (
                    <DeleteIcon
                      className="cursor-pointer [&:hover>path]:stroke-error-500 w-4 h-4 ml-3"
                      onClick={() => toggleCantDecide()}
                    />
                  )}
                </div>
                {isEdit ? (
                  <CustomEditor
                    setValue={(e) => {
                      onExpressionChange(e, "cant_decide");
                    }}
                    value={condition.cant_decide ?? ""}
                    monacoOptions={{
                      lineNumbers: "off",
                      glyphMargin: false,
                      fontWeight: "400",
                      folding: false,
                      lineDecorationsWidth: 0,
                      lineNumbersMinChars: 0,
                      showFoldingControls: "never",
                    }}
                  />
                ) : (
                  <div className="text-neutral-500 truncate font-code text-[14px]">
                    {condition.cant_decide}
                  </div>
                )}
              </>
            )}
            {isEdit && isWorkflowEditable && !showCantDecide && (
              <div
                className="font-b2-medium cursor-pointer text-neutral-black group/add-expr hover:text-primary-900 w-max flex items-center gap-1 mt-2"
                onClick={() => toggleCantDecide()}
              >
                <PlusIcon className="w-4 h-4 group-hover/add-expr:[&>path]:stroke-primary-900 [&>path]:stroke-neutral-black" />
                Add Can’t Decide Condition
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default RuleSetItem;
