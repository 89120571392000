import { getKeywordsQuery } from "@screens/create-policy/queries";
import { getErrors, getWorkflowPredictors } from "@screens/workflow/queries";
import axios from "@axios";
import { arrayMove } from "@dnd-kit/sortable";
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { getNetworkErrorText, normalizeQueryKey, notify } from "@utils/utils";
import { ModelExpression } from "./types";

export const getModelExpressionQuery = (
  workflowID: string | undefined,
  nodeId: string | undefined
) =>
  queryOptions({
    queryKey: normalizeQueryKey(["modelExpression", workflowID, nodeId]),
    queryFn: async () => {
      return axios.get<FinBoxResponse<ModelExpression[]>>(
        `/workflow/${workflowID}/modelExpression/${nodeId}`
      );
    },
    select: (data) => data.data?.data ?? [],
    enabled: !!workflowID && !!nodeId,
  });

export const useAddModelExpression = (workflowId: string, nodeId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      expression,
    }: {
      expression: {
        name: string;
        body: string;
        seqNo: number;
      };
    }) =>
      axios.post<FinBoxResponse<any>>(
        `workflow/${workflowId}/modelExpression/${nodeId}`,
        expression
      ),
    onError(error) {
      notify({
        title: "Failed to add expression",
        text: getNetworkErrorText(error),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        getModelExpressionQuery(workflowId, nodeId)
      );
      queryClient.invalidateQueries(getErrors(workflowId));
    },
  });
};

export const useUpdateModelExpression = (
  workflowId: string,
  nodeId: string,
  expressionId: string
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      expression,
    }: {
      expression: {
        name: string;
        body: string;
        seqNo: number;
      };
    }) => {
      return axios.patch<FinBoxResponse<any>>(
        `workflow/${workflowId}/modelExpression/${nodeId}/${expressionId}`,
        expression
      );
    },
    onError(error) {
      notify({ title: "Update Failed", text: getNetworkErrorText(error) });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        getModelExpressionQuery(workflowId, nodeId)
      );
      queryClient.invalidateQueries(getErrors(workflowId));
      queryClient.invalidateQueries(getWorkflowPredictors(workflowId));
      queryClient.invalidateQueries(getKeywordsQuery());
    },
  });
};

export const useUpdateModelExpressionOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      workflowId,
      nodeId,
      expressionId,
      expression,
    }: {
      workflowId: string;
      nodeId: string;
      expressionId: string;
      expression: {
        name: string;
        body: string;
        seqNo: number;
      };
    }) => {
      queryClient.setQueryData(
        getModelExpressionQuery(workflowId, nodeId).queryKey,
        (data) => {
          if (!data) return data;
          const newData = arrayMove(
            data.data.data,
            data.data.data.findIndex((item) => item.id === expressionId),
            expression.seqNo
          );
          return {
            ...data,
            data: {
              ...data.data,
              data: newData,
            },
          };
        }
      );

      return axios.patch<FinBoxResponse<any>>(
        `workflow/${workflowId}/modelExpression/${nodeId}/${expressionId}`,
        expression
      );
    },
    onError(error, vars) {
      queryClient.invalidateQueries(
        getModelExpressionQuery(vars.workflowId, vars.nodeId)
      );
      notify({ title: "Update Failed", text: getNetworkErrorText(error) });
    },
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(
        getModelExpressionQuery(vars.workflowId, vars.nodeId)
      );
      queryClient.invalidateQueries(getErrors(vars.workflowId));
    },
  });
};

export const useDeleteModelExpression = (
  workflowId: string,
  nodeId: string,
  expressionId: string
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () =>
      axios.delete<FinBoxResponse<any>>(
        `workflow/${workflowId}/modelExpression/${nodeId}/${expressionId}`
      ),
    onError(error) {
      notify({ title: "Update Failed", text: getNetworkErrorText(error) });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        getModelExpressionQuery(workflowId, nodeId)
      );
      queryClient.invalidateQueries(getWorkflowPredictors(workflowId));
      queryClient.invalidateQueries(getErrors(workflowId));
    },
  });
};

export const useTestModel = () => {
  return useMutation({
    mutationFn: (body: {
      workflowId: string;
      modelId: string;
      variables?: Record<string, any>;
    }) =>
      axios.v2.post<FinBoxResponse<Record<string, Record<string, string>>>>(
        "eval",
        {
          type: "workflow",
          id: body.workflowId,
          variables: body.variables,
          modelExprId: body.modelId,
        }
      ),
  });
};

export const useTestModelSet = () => {
  return useMutation({
    mutationFn: (body: {
      workflowId: string;
      modelSetId: string;
      variables?: Record<string, any>;
    }) =>
      axios.v2.post<FinBoxResponse<Record<string, Record<string, string>>>>(
        "eval",
        {
          type: "workflow",
          id: body.workflowId,
          variables: body.variables,
          modelSetId: body.modelSetId,
        }
      ),
  });
};

export const useTestDecisionTable = () => {
  return useMutation({
    mutationFn: (body: {
      workflowId: string;
      modelDecisionTableID: string;
      variables?: Record<string, any>;
    }) =>
      axios.v2.post<FinBoxResponse>("eval", {
        type: "workflow",
        id: body.workflowId,
        variables: body.variables,
        modelDecisionTableID: body.modelDecisionTableID,
      }),
  });
};
