import { useState } from "react";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import { PermissionTypes } from "src/types";
import { ReactComponent as DotsIcon } from "@assets/icons/dots-horizontal.svg";
import { ReactComponent as EditIcon } from "@assets/icons/edit-01.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import ConfirmationModal from "@components/ConfirmationModal";
import EmptyState from "@components/EmptyState";
import Menu from "@components/FloatingMenu";
import ProtectedComponent from "@components/ProtectedComponent";
import Table from "@components/Table";
import { DASHBOARD_USERS_LIMIT } from "@config";
import { getInitial, notify } from "@utils/utils";
import EditMembersSettings from "./EditMemberSettings";
import { useDeleteUser } from "./queries";
import { DashboardUsers } from "./types";

function Row({
  row,
  defaultRowClassNames,
  setDeleteUserData,
  onEditClick,
}: {
  row: NonNullable<DashboardUsers["dashboard_users"]>[number];
  defaultRowClassNames: string;
  index: number;
  setDeleteUserData: (data: NonNullable<Props["data"]>[number]) => void;
  onEditClick: (data: NonNullable<Props["data"]>[number]) => void;
}) {
  return (
    <tr
      className={defaultRowClassNames + " overflow-visible"}
      key={row.dashboard_user_id}
    >
      <td>
        <Table.TdWrapper>
          <span className="flex gap-2 items-center min-h-[32px] font-b1">
            <span className="w-6 h-6 rounded-full bg-neutral-800 text-white flex items-center justify-center font-b2-medium">
              {getInitial(row.name)}
            </span>
            {row.name}
          </span>
        </Table.TdWrapper>
      </td>
      <td className="w-[450px]">{row.email}</td>
      <td className="overflow-visible w-[200px] font-b1-medium">{row.role}</td>
      <td className="w-[80px]">
        <div className="w-[12ch] flex gap-1 ml-auto justify-end">
          <ProtectedComponent
            type={PERMISSIONS_TYPE.administrationMembers as PermissionTypes}
            action="edit"
          >
            <EditIcon
              onClick={() => {
                onEditClick(row);
              }}
              className="pointer-events-auto cursor-pointer flex items-center justify-between rounded-md p-1.5 text-sm text-gray-500 shadow-sm ring-1 ring-gray-300 ml-auto w-7 h-7 duration-150 [&>path]:stroke-neutral-500 hover:bg-neutral-50"
            />
          </ProtectedComponent>
          <ProtectedComponent
            type={PERMISSIONS_TYPE.administrationMembers as PermissionTypes}
            action="delete"
          >
            <Menu as="div" className="relative flex">
              <Menu.Button
                onClick={(e: MouseEvent) => e.stopPropagation()}
                className="ml-2 w-7 h-7 duration-150 p-1.5 hover:bg-neutral-50 pointer-events-auto"
              >
                <DotsIcon className="w-4 h-4 stroke-neutral-800 [&>path]:stroke-neutral-800" />
              </Menu.Button>

              <Menu.Items
                onClick={(e: MouseEvent) => e.stopPropagation()}
                className="cursor-pointer left-2 top-8 rounded-md absolute z-10 flex w-[156px] flex-col bg-white text-neutral-black focus:outline-none border border-neutral-100 shadow-1"
              >
                <Menu.Item
                  onClick={(e: MouseEvent) => {
                    e.stopPropagation();
                    setDeleteUserData(row);
                  }}
                  className="p-2 flex gap-2 items-center font-b2-medium border-t rounded-b-md bg-white hover:bg-neutral-25 !text-error-600"
                >
                  <TrashIcon className="z-10 h-4 w-4 cursor-pointer [&>path]:stroke-error-600" />
                  Archive
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </ProtectedComponent>
        </div>
      </td>
    </tr>
  );
}

type Props = {
  data: DashboardUsers["dashboard_users"];
  isFetching: boolean;
  setPage: setStateType<number>;
  page: number;
  totalCount: number;
};

export default function UsersTable({
  data,
  isFetching,
  setPage,
  page,
  totalCount,
}: Props) {
  const [deleteUserData, setDeleteUserData] = useState<
    false | NonNullable<Props["data"]>[number]
  >(false);
  const [editUserData, setEditUserData] = useState<
    false | NonNullable<Props["data"]>[number]
  >(false);

  const deleteUserMutation = useDeleteUser();

  if (!data || data.length === 0)
    return <EmptyState>No users found</EmptyState>;

  return (
    <>
      <div className="max-w-screen relative z-0 bg-white rounded-lg">
        <Table
          data={data || []}
          emptyText={<EmptyState>No users found</EmptyState>}
          headers={["Name", "Email", "Role", ""]}
          isLoading={isFetching}
          className="sm:overflow-x-visible mt-4"
          page={page}
          setPage={setPage}
          pageLimit={DASHBOARD_USERS_LIMIT}
          totalCount={totalCount}
        >
          {(row, defaultRowClassNames, index) => {
            return (
              <Row
                setDeleteUserData={setDeleteUserData}
                key={row.dashboard_user_id}
                row={row}
                defaultRowClassNames={defaultRowClassNames}
                index={index}
                onEditClick={setEditUserData}
              />
            );
          }}
        </Table>
      </div>
      <ConfirmationModal
        isOpen={!!deleteUserData}
        onClose={() => setDeleteUserData(false)}
        title="Confirm deletion!"
        action={() => {
          if (deleteUserData)
            deleteUserMutation.mutate(deleteUserData.dashboard_user_id, {
              onSuccess: () =>
                notify({
                  title: "Deleted",
                  text: `${deleteUserData.name} has been deleted.`,
                  type: "success",
                }),
            });
        }}
      >
        {!!deleteUserData &&
          `Are you sure you want to delete ${deleteUserData.name}(${deleteUserData.email})?`}
      </ConfirmationModal>
      {!!editUserData && (
        <>
          <EditMembersSettings
            isEditing={!!editUserData}
            advancedSettings={!!editUserData}
            setAdvancedSettings={setEditUserData}
            filters={editUserData.filters}
            userRole={editUserData?.role}
            userData={editUserData}
            setData={(data) => {
              if (editUserData) {
                setEditUserData({
                  ...editUserData,
                  filters: data.filters,
                });
              }
            }}
          />
        </>
      )}
    </>
  );
}
