import axios from "@axios";
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { getErrors } from "src/screens/workflow/queries";
import { getNetworkErrorText, notify } from "src/utils/utils";
import { PolicyMappingCreate, PolicyMappingList } from "./types";

export function getInputPolicyMappingQuery(
  workflowId: string,
  wfNodeId: string,
  childWfId: string
) {
  return queryOptions({
    queryKey: ["getInputPolicyMappingQuery", workflowId, childWfId],
    queryFn: async () =>
      axios.get<FinBoxResponse<PolicyMappingList[]>>(
        `workflow/${workflowId}/input/${wfNodeId}?childWorkflowID=${childWfId}`
      ),
    enabled: !!workflowId && !!childWfId && !!wfNodeId,
    select: (data) => data.data.data,
  });
}
export function useCreateInputPolicyMapping() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({
      workflowId,
      wfNodeId,
      childWfId,
      mapping,
    }: {
      workflowId: string;
      wfNodeId: string;
      childWfId: string;
      mapping: PolicyMappingCreate[];
    }) => {
      return axios.post(
        `workflow/${workflowId}/input/${wfNodeId}?childWorkflowID=${childWfId}`,
        {
          childWorkflowId: childWfId,
          mapping,
        }
      );
    },
    onSuccess: (_, { workflowId, wfNodeId, childWfId }) => {
      qc.invalidateQueries(
        getInputPolicyMappingQuery(workflowId, wfNodeId, childWfId)
      );
      qc.invalidateQueries(getErrors(workflowId));
      notify({
        title: "Input Mapping",
        text: "Created Input Workflow Mapping for the Workflow",
        type: "success",
      });
    },
    onError: (e) => {
      notify({
        title: "Could not create workflow mapping",
        text: getNetworkErrorText(e),
      });
    },
  });
}
export function useUpdateInputPolicyMapping() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({
      workflowId,
      wfNodeId,
      childWfId,
      mapping,
    }: {
      workflowId: string;
      wfNodeId: string;
      childWfId: string;
      mapping: PolicyMappingCreate[];
    }) => {
      return axios.post(
        `workflow/${workflowId}/input/${wfNodeId}?childWorkflowID=${childWfId}`,
        {
          childWorkflowId: childWfId,
          mapping,
        }
      );
    },
    onSuccess: (_, { workflowId, wfNodeId, childWfId }) => {
      qc.invalidateQueries(
        getInputPolicyMappingQuery(workflowId, wfNodeId, childWfId)
      );
      qc.invalidateQueries(getErrors(workflowId));
      notify({
        title: "Input Mapping",
        text: "Updated Input Workflow Mapping for the Workflow",
        type: "success",
      });
    },
    onError: (e) => {
      notify({
        title: "Could not update workflow mapping",
        text: getNetworkErrorText(e),
      });
    },
  });
}
export function useDeleteInputPolicyMapping() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({
      workflowId,
      wfNodeId,
      policyInputId,
    }: {
      workflowId: string;
      wfNodeId: string;
      policyInputId: string[];
    }) => {
      return axios.delete(`workflow/${workflowId}/input/${wfNodeId}`, {
        params: { policyInputId: policyInputId },
      });
    },
    onSuccess: (_, { workflowId }) => {
      notify({
        title: "Input Mapping",
        text: "Deleted Input Workflow Mapping for the Workflow",
        type: "success",
      });
      qc.invalidateQueries({ queryKey: ["getInputPolicyMappingQuery"] });
      qc.invalidateQueries(getErrors(workflowId));
    },
    onError: (e) => {
      notify({
        title: "Could not delete workflow mapping",
        text: getNetworkErrorText(e),
      });
    },
  });
}
