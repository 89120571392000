import axios from "@axios";
import { arrayMove } from "@dnd-kit/sortable";
import {
  getErrors,
  getWorkflowKeywordsQuery,
  getWorkflowPredictors,
} from "@screens/workflow/queries";
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { getNetworkErrorText, normalizeQueryKey, notify } from "@utils/utils";
import { DecisionTableRules, ModelSetItem } from "./types";

export const getModelSetItemsQuery = (
  workflowID: string | undefined,
  nodeId: string | undefined
) =>
  queryOptions({
    queryKey: normalizeQueryKey(["modelSetItems", workflowID, nodeId]),
    queryFn: async () => {
      return axios.get<FinBoxResponse<ModelSetItem[]>>(
        `/workflow/${workflowID}/modelSet/${nodeId}`
      );
    },
    select: (data) => data.data?.data ?? [],
    enabled: !!workflowID && !!nodeId,
  });

export const useAddModelSetItem = (workflowId: string, nodeId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      expression,
    }: {
      expression: {
        name: string;
        body: string;
        seqNo: number;
        decisionTableRules: DecisionTableRules | {};
        type: "expression" | "decisionTable";
      };
    }) =>
      axios.post<FinBoxResponse<any>>(
        `workflow/${workflowId}/modelSet/${nodeId}`,
        expression
      ),
    onError(error) {
      notify({
        title: "Failed to add model",
        text: getNetworkErrorText(error),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getModelSetItemsQuery(workflowId, nodeId));
      queryClient.invalidateQueries(getErrors(workflowId));
    },
  });
};

export const useUpdateModelSetItem = (
  workflowId: string,
  nodeId: string,
  expressionId: string
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      expression,
    }: {
      expression: {
        name?: string;
        body?: string;
        seqNo?: number;
        decisionTableRules?: DecisionTableRules;
      };
    }) => {
      return axios.patch<FinBoxResponse<any>>(
        `workflow/${workflowId}/modelSet/${nodeId}/${expressionId}`,
        expression
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getModelSetItemsQuery(workflowId, nodeId));
      queryClient.invalidateQueries(getErrors(workflowId));
      queryClient.invalidateQueries(getWorkflowPredictors(workflowId));
      queryClient.invalidateQueries(getWorkflowKeywordsQuery());
    },
    onError: (error) => {
      notify({ title: "Update failed", text: getNetworkErrorText(error) });
    },
  });
};

export const useUpdateModelSetItemOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      workflowId,
      nodeId,
      expressionId,
      expression,
    }: {
      workflowId: string;
      nodeId: string;
      expressionId: string;
      expression: {
        name: string;
        body: string;
        seqNo: number;
        decisionTableRules: DecisionTableRules;
      };
    }) => {
      queryClient.setQueryData(
        getModelSetItemsQuery(workflowId, nodeId).queryKey,
        (data) => {
          if (!data) return data;
          const newData = arrayMove(
            data.data.data,
            data.data.data.findIndex((item) => item.id === expressionId),
            expression.seqNo
          );
          return {
            ...data,
            data: {
              ...data.data,
              data: newData,
            },
          };
        }
      );

      return axios.patch<FinBoxResponse<any>>(
        `workflow/${workflowId}/modelSet/${nodeId}/${expressionId}`,
        expression
      );
    },
    onError(error, vars) {
      queryClient.invalidateQueries(
        getModelSetItemsQuery(vars.workflowId, vars.nodeId)
      );
      notify({ title: "Update Failed", text: getNetworkErrorText(error) });
    },
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(
        getModelSetItemsQuery(vars.workflowId, vars.nodeId)
      );
      queryClient.invalidateQueries(getErrors(vars.workflowId));
    },
  });
};

export const useDeleteModelSetItem = (
  workflowId: string,
  nodeId: string,
  expressionId: string
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () =>
      axios.delete<FinBoxResponse<any>>(
        `workflow/${workflowId}/modelSet/${nodeId}/${expressionId}`
      ),
    onError(error) {
      notify({ title: "Update Failed", text: getNetworkErrorText(error) });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getModelSetItemsQuery(workflowId, nodeId));
      queryClient.invalidateQueries(getWorkflowPredictors(workflowId));
      queryClient.invalidateQueries(getErrors(workflowId));
      queryClient.invalidateQueries(getWorkflowKeywordsQuery());
    },
  });
};

// export const useTestModelSet = () => {
//   return useMutation({
//     mutationFn: (body: {
//       workflowId: string;
//       modelId: string;
//       variables?: Record<string, any>;
//     }) =>
//       axios.v2.post<FinBoxResponse<Record<string, Record<string, string>>>>(
//         "eval",
//         {
//           type: "workflow",
//           id: body.workflowId,
//           variables: body.variables,
//           modelExprId: body.modelId,
//         }
//       ),
//   });
// };
//
// export const useTestDecisionTable = () => {
//   return useMutation({
//     mutationFn: (body: {
//       workflowId: string;
//       modelDecisionTableID: string;
//       variables?: Record<string, any>;
//     }) =>
//       axios.v2.post<FinBoxResponse>("eval", {
//         type: "workflow",
//         id: body.workflowId,
//         variables: body.variables,
//         modelDecisionTableID: body.modelDecisionTableID,
//       }),
//   });
// };
