import classNames from "clsx";
import React, { useState } from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import Dropdown from "@components/DropDown";
import ErrorText from "@components/ErrorText";
import Loader from "@components/Loader";
import Multiselect, {
  multiselectOnChangeHandler,
} from "@components/Multiselect";
import { useGetGroupByOptions, useGetGroupByValues } from "../../../queries";
import { FormType } from "../types";

export default function GroupBySelect({
  form,
}: {
  form: UseFormReturn<FormType>;
}) {
  const [groupbyValueFilterQuery, setGroupbyValueFilterQuery] = useState("");

  const [funnelType, funnelValues, groupByKey] = useWatch({
    control: form.control,
    name: ["funnelType", "funnelValues", "groupByKey"],
  });

  const groupByOptionsQuery = useGetGroupByOptions({
    funnelType,
    funnelValues: funnelValues?.map((p) => p.id) ?? [],
  });
  const groupByValuesQuery = useGetGroupByValues({
    funnelType,
    funnelValues: funnelValues?.map((p) => p.id) ?? [],
    groupByKey,
  });

  const keyDisabled = !Boolean(funnelValues && funnelValues.length);

  return (
    <div
      className={classNames("flex flex-col gap-2", keyDisabled && "opacity-50")}
    >
      <span className="font-b2">Group By</span>
      <div className="flex w-full">
        <Controller
          name="groupByKey"
          control={form.control}
          render={({ field }) => (
            <Dropdown
              className={classNames(
                "shrink-0 basis-1/3",
                keyDisabled && "cursor-not-allowed"
              )}
              disabled={keyDisabled}
              value={field.value}
              onChange={(e) => {
                form.resetField("groupByValues", { defaultValue: [] });
                field.onChange(e);
              }}
            >
              <Dropdown.Button
                className={classNames(
                  "bg-neutral-0 rounded-r-none w-full",
                  keyDisabled && "pointer-events-none",
                  !!field.value && "font-b2-medium text-neutral-black"
                )}
              >
                <span className="max-w-[120px] truncate">
                  {field.value || "Select"}
                </span>
              </Dropdown.Button>
              <Dropdown.Options className="h-auto max-h-40 !w-fit ">
                {groupByOptionsQuery.isLoading && <Loader size="xs" />}
                {groupByOptionsQuery.isSuccess &&
                groupByOptionsQuery.data.data.data.length > 0 ? (
                  <>
                    <Dropdown.Option key="select" value="">
                      <span className="text-neutral-500">Select</span>
                    </Dropdown.Option>
                    {groupByOptionsQuery.data.data.data.map((r) => (
                      <Dropdown.Option key={r} value={r}>
                        {r}
                      </Dropdown.Option>
                    ))}
                  </>
                ) : (
                  <Dropdown.Option key="empty" disabled value="empty">
                    No options
                  </Dropdown.Option>
                )}
                {groupByOptionsQuery.isError && (
                  <Dropdown.Option key="error" disabled value="error">
                    An error occured
                  </Dropdown.Option>
                )}
              </Dropdown.Options>
            </Dropdown>
          )}
        />
        <Controller
          name="groupByValues"
          control={form.control}
          rules={{
            validate: {
              maxLength: (v) =>
                (v && v.length <= 3) || "Max 3 values can be selected",
              grouping: (v) => {
                return (
                  !!form.getValues("groupByKey") !== (!v || v.length === 0) ||
                  "Group by values are required when grouping"
                );
              },
            },
          }}
          render={({ field }) => (
            <Multiselect
              className="rounded-l-none border-l-0 h-7 basis-2/3"
              disabled={!groupByKey}
              options={
                groupByValuesQuery.data?.data.data
                  .sort((a) => (field.value?.includes(a) ? -1 : 1))
                  .filter(
                    (p) =>
                      groupbyValueFilterQuery === "" ||
                      p
                        .toLowerCase()
                        .includes(groupbyValueFilterQuery.toLowerCase())
                  )
                  .map((n) => ({
                    name: n,
                  })) ?? []
              }
              values={field.value?.map((p) => ({ name: p })) || []}
              onSearchChange={setGroupbyValueFilterQuery}
              getKey={(e) => e.name}
              onChange={(e) => {
                field.onChange(
                  multiselectOnChangeHandler(e, "name").map((p) => p.name)
                );
              }}
              getDisplayValue={(e) => {
                if (!e || e.length === 0) return "";
                if (e.length === 1) return e[0].name;
                return `${e[0].name} +${e.length - 1}`;
              }}
              getName={(e) => e.name}
              isSelected={(e) => !!field.value?.find((p) => p === e.name)}
            />
          )}
        />
      </div>
      {(form.formState.errors.groupByKey?.message ||
        form.formState.errors.groupByValues?.message) && (
        <ErrorText>
          {form.formState.errors.groupByKey?.message ??
            form.formState.errors.groupByValues?.message}
        </ErrorText>
      )}
    </div>
  );
}
