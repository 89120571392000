import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import Input from "@components/Input";
import DataLevelSelect from "./components/DataLevelSelect";
import FunnelValuesSelect from "./components/FunnelValuesSelect";
import GroupBySelect from "./components/GroupBySelect";
import OutcomeSelect from "./components/OutcomeSelect";
import { FormType } from "./types";

export default function OutcomeRateVsTrafficForm({
  form,
}: {
  form: UseFormReturn<FormType>;
}) {
  return (
    <form className="flex gap-4 flex-col">
      <div className="flex flex-col gap-2">
        <span className="font-b2 font-normal">Name</span>
        <Input {...form.register("name")} />
      </div>
      <input
        value="outcomeVsTraffic"
        {...form.register("type")}
        className="hidden"
      />

      <div className="flex flex-col gap-2 font-b1-medium">
        <DataLevelSelect form={form} showPolicyBucket={true} />
        <FunnelValuesSelect form={form} />
      </div>
      <OutcomeSelect form={form} />
      {(form.getValues("funnelType")?.toLowerCase() === "policy" ||
        form.getValues("funnelType")?.toLowerCase() === "policybucket") && (
        <GroupBySelect form={form} />
      )}
      <div className="flex flex-col gap-2 font-b2">
        <span className="font-b2">Traffic Visibility</span>
        <Controller
          control={form.control}
          render={({ field }) => (
            <>
              <label
                className="flex gap-2 items-center font-b2-medium"
                htmlFor="trafficVisibility-show"
              >
                <input
                  type="radio"
                  checked={field.value}
                  id="trafficVisibility-show"
                  onChange={(event) => field.onChange(event.target.checked)}
                />
                Show
              </label>
              <label
                className="mt-1 flex gap-2 items-center font-b2-medium"
                htmlFor="trafficVisibility-hide"
              >
                <input
                  type="radio"
                  checked={!field.value}
                  value="false"
                  id="trafficVisibility-hide"
                  onChange={(event) => field.onChange(!event.target.checked)}
                />
                Hide
              </label>
            </>
          )}
          name="config.showTraffic"
        />
      </div>
    </form>
  );
}
