import classNames from "clsx";
import { ReactNode } from "react";
import Card from "@components/Card";

export type Props = {
  content: ReactNode;
  title: ReactNode;
  className?: string;
};

export default function OverviewHeaderCard({
  content,
  title,
  className,
}: Props) {
  return (
    <Card
      className={classNames("!p-0 relative bg-white shadow-card", className)}
    >
      <div className="rounded-t-md border-b sticky top-0 z-10 text-neutral-black w-full border-b-neutral-100 px-4 flex h-10 gap-2 items-center bg-neutral-0 font-b1-medium">
        {title}
      </div>
      {content}
    </Card>
  );
}
