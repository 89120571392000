import classNames from "clsx";
import clsx from "clsx";
import { memo } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import rulSetIcon from "@assets/icons/rule-set.svg";
import { ReactComponent as ApprovedIcon } from "@assets/icons/workflow/check-circle.svg";
import { ReactComponent as CantIcon } from "@assets/icons/workflow/help-circle.svg";
import { ReactComponent as RejectIcon } from "@assets/icons/workflow/x-circle.svg";
import useWfId from "@screens/outcome-details/eval-components/WfIdContext";
import HandleWithPlus from "@screens/workflow/studio/components/HandleWithPlus";
import { getRuleSetConditionsQuery } from "@screens/workflow/studio/components/RuleSet/queries";
import useIsHiddenAfterTest from "@screens/workflow/studio/hooks/useIsHiddenAfterTest";
import QueryWrapper from "@components/QueryWrapper";
import Shimmer from "@components/Shimmer";
import Tooltip from "@components/Tooltip";
import { useQuery } from "@tanstack/react-query";
import useIsExpand, {
  useGetHandleStyles,
  useGetHandleStylesForInputDot,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "../../hooks/useIsExpand";

const handleToIcon = (str: string) => {
  switch (str) {
    case "pass":
      return (
        <ApprovedIcon
          className="stroke-neutral-400 fill-neutral-50 group-hover:stroke-success-500 group-hover:fi
ll-success-200 group-hover:[&>path]:stroke-success-100 group-hover:fill-success-100 w-4 h-4 duration-150"
        />
      );
    case "reject":
      return (
        <RejectIcon
          className="stroke-neutral-400 fill-neutral-50 group-hover:stroke-error-500 group-hover:fill-e
rror-200 group-hover:[&>path]:stroke-error-500 group-hover:fill-error-200 w-4 h-4 duration-150"
        />
      );
    case "cant_decide":
      return (
        <CantIcon className="stroke-neutral-400 fill-neutral-5 group-hover:stroke-warning-500 group-hover:fill-warning-100 group-hover:[&>path]:fill-warning-100 w-4 h-4" />
      );
  }
  return "label";
};

const RuleSetNode = ({
  id,
  selected,
  data,
}: NodeProps<{
  handles: string[];
  hidden?: boolean;
  isFromFlow?: boolean;
  label: string;
  desc: string;
}>) => {
  const wfId = useWfId();

  const fontStyles = useGetNodeFontStyles();
  const iconStyles = useGetNodeIconStyles();
  const nodeStyles = useGetNodeStyles();
  const handleStyles = useGetHandleStyles();
  const isExpanded = useIsExpand();

  const ruleSetConditionsQuery = useQuery(
    getRuleSetConditionsQuery(wfId || "", id)
  );

  const hidden = useIsHiddenAfterTest(id, data?.hidden);

  const handleStylesInputDot = useGetHandleStylesForInputDot();

  return (
    <>
      <div className="flex relative cursor-grab active:cursor-grabbing group items-center">
        <div
          style={nodeStyles}
          className={classNames(
            "overflow-visible w-[360px] h-max bg-white rounded-[10px] hover:border-primary-200 border p-[7px] pb-0",
            {
              "opacity-25": hidden,
              "border-primary-200": selected && !hidden,
              "border-neutral-100": !selected,
              "shadow-lg": data?.isFromFlow && !hidden,
              "shadow-studio-node": !(data?.isFromFlow && !hidden),
            }
          )}
        >
          <Handle
            type="target"
            position={Position.Left}
            className="w-2 h-2 top-0 mt-[20px] opacity-50 hover:opacity-100 border-2 rounded-full bg-neutral-0 border-neutral-300 !-left-4 duration-150 overflow-visible"
            isConnectable={false}
            style={handleStylesInputDot}
          >
            <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
          </Handle>

          {!isExpanded && (
            <Handle
              type="source"
              position={Position.Right}
              className="!-right-3 top-1/2"
              isConnectable={false}
              style={handleStyles}
            />
          )}
          <div className="w-full text-neutral-black font-medium flex gap-1.5 items-center">
            <img style={iconStyles} alt="policy" src={rulSetIcon} />
            <span
              style={fontStyles}
              className="max-w-44 w-max truncate cursor-pointer font-medium hover:bg-neutral-25 px-1 py-0.5 rounded-md"
            >
              {data.label || "Ruleset_Name"}
            </span>
          </div>
          {isExpanded && (
            <div className="pt-[1px]">
              {data.desc ? (
                <div className="mt-1 p-1.5 truncate text-neutral-500 font-b2 items-center rounded-md bg-neutral-0 border border-neutral-100 h-7 w-[100%]">
                  {data.desc}
                </div>
              ) : null}
              <div className="flex flex-col gap-2 mt-2 mb-2">
                <QueryWrapper
                  loader={
                    <>
                      <Shimmer w="100%" h="60px" />
                      <Shimmer w="100%" h="60px" />
                    </>
                  }
                  query={ruleSetConditionsQuery}
                >
                  {(data) => (
                    <>
                      {data.data.data.rules?.map((condition, index) => (
                        <div key={condition.rule_id} className="relative">
                          <div
                            className={clsx(
                              "border border-neutral-100 rounded-md p-2 bg-neutral-0 font-b2 nodrag"
                            )}
                          >
                            <div className="transition-all ease-in h-auto nodrag">
                              <div className="flex justify-between mb-2">
                                <div className="flex items-center gap-2 overflow-visible">
                                  <span className="text-neutral-500">
                                    {index + 1}.
                                  </span>{" "}
                                  <Tooltip
                                    contentClassName="font-b2-medium max-w-[250px] truncate"
                                    tooltipContent={condition.description}
                                    content={
                                      <span>{condition.description}</span>
                                    }
                                  />
                                </div>
                              </div>

                              <div className="text-neutral-500 truncate font-code text-[14px]">
                                {condition.approve}
                              </div>
                              {!!condition.cant_decide && (
                                <>
                                  <div className="mt-2 mb-1 text-neutral-500 flex justify-between">
                                    <span>Can't Decide</span>
                                  </div>

                                  <div className="text-neutral-500 truncate font-code text-[14px]">
                                    {condition.cant_decide}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </QueryWrapper>
              </div>
            </div>
          )}
        </div>
        {isExpanded && (
          <div className="flex flex-col gap-2 absolute top-0 -right-[24px]">
            {data.handles?.map((item) => (
              <HandleWithPlus
                key={item}
                nodeId={id}
                label={handleToIcon(item)}
                showAdd={false}
                position={Position.Right}
                id={item}
                type="source"
                isConnectable={false}
                noIcon={true}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(RuleSetNode);
