import classNames from "clsx";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useReactFlow } from "reactflow";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { SIDEBAR_CONFIG } from "@screens/workflow/config";
import { Tooltip } from "@finbox-in/finblocks";
import { Transition } from "@headlessui/react";
import { WfWarning } from "../../validations";
import SidebarContext from "./SidebarContext";
import {
  AddBlockTab,
  ConfigureOutcomes,
  InputParametersTab,
  NavigatorTab,
  SettingsTab,
  SimulateTab,
  TestWorkflowTab,
} from "./Tabs";
import Warnings from "./Warnings";

export default function Sidebar({
  warnings,
  isSidebarOpen,
  setIsSidebarOpen,
}: {
  warnings: WfWarning[];
  isSidebarOpen: boolean;
  setIsSidebarOpen: setStateType<boolean>;
}) {
  const [currentTab, _setCurrentTab] = useState<
    (typeof SIDEBAR_CONFIG)[number]["name"] | null
  >(null);

  const { setIsShowingTestResult, debuggerNodeId, setDebuggerNodeId } =
    useWorkflowContext();

  const { getNode, setCenter } = useReactFlow();

  const setCurrentTab = useCallback(
    (s: typeof currentTab) => {
      setIsShowingTestResult(false);
      if (s) {
        _setCurrentTab(s);
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
        setDebuggerNodeId(null);
        setTimeout(() => {
          _setCurrentTab(s);
        }, 500);
      }
    },
    [setDebuggerNodeId, setIsShowingTestResult]
  );

  useEffect(() => {
    if (debuggerNodeId) {
      setIsSidebarOpen(true);
      setCurrentTab("Test Workflow");
      const node = getNode(debuggerNodeId);
      if (node)
        setTimeout(
          () =>
            setCenter(
              node.position.x + 180,
              node.position.y + 90 + (node.height || 0) / 2,
              {
                duration: 500,
                zoom: 1,
              }
            ),
          500
        );
    }
  }, [debuggerNodeId, getNode, setCenter, setCurrentTab]);

  return (
    <SidebarContext.Provider
      value={{
        setCurrentTab,
      }}
    >
      <div className="w-10 flex-grow-0 basis-10 flex-1 border-r border-neutral-100 bg-white stroke-neutral-500 flex flex-col gap-5 items-center h-[calc(100vh-2.5rem)] pt-2.5 pb-2">
        {SIDEBAR_CONFIG.map((item) => {
          const isTopDivider = item.name === "Configure Input Parameters";
          const isBottomDivider = item.name === "Configure Outcomes";
          return (
            <Fragment key={item.name}>
              {isTopDivider ? (
                <div className="border-t w-4 border-neutral-100 rounded-none" />
              ) : (
                ""
              )}
              <Tooltip placement="right" toolTipContent={item.name}>
                <div
                  key={item.name}
                  onClick={() => {
                    if (currentTab === item.name) {
                      setIsSidebarOpen(false);
                      setTimeout(() => _setCurrentTab(null), 500);
                    } else setCurrentTab(item.name);
                  }}
                  className={classNames(
                    "p-1.5 rounded-md cursor-pointer",
                    item.name === currentTab
                      ? "stroke-primary-900 [&>path]:stroke-primary-900 bg-primary-50"
                      : "hover:bg-primary-50 hover:stroke-neutral-600"
                  )}
                >
                  {item.icon}
                </div>
              </Tooltip>

              {isBottomDivider ? (
                <div className="border-b w-4 border-neutral-100 rounded-none" />
              ) : (
                ""
              )}
            </Fragment>
          );
        })}
        <div className="mt-auto">
          <Warnings warnings={warnings} />
        </div>
      </div>
      <Transition
        enter="hide-scrollbar transform transition-all ease-in-out duration-300 sm:duration-500"
        enterFrom="hide-scrollbar max-w-0"
        enterTo="hide-scrollbar max-w-2xl"
        leave="hide-scrollbar transform transition-all ease-in-out duration-200 sm:duration-500"
        leaveFrom="hide-scrollbar max-w-2xl"
        leaveTo="hide-scrollbar max-w-0"
        show={isSidebarOpen}
        appear
        as="div"
      >
        {currentTab &&
          {
            "Add Block": <AddBlockTab />,
            Navigator: <NavigatorTab />,
            "Configure Input Parameters": <InputParametersTab />,
            "Configure Outcomes": <ConfigureOutcomes />,
            "Test Workflow": <TestWorkflowTab />,
            Simulate: <SimulateTab />,
            Settings: <SettingsTab />,
          }[currentTab]}
      </Transition>
    </SidebarContext.Provider>
  );
}
