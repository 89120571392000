import { useWorkflowContext } from "@screens/workflow/WorkflowContext";

export default function useIsHiddenAfterTest(
  id: string,
  isDataHidden: boolean | undefined
) {
  const { isShowingTestResult, runWorkflowMutation } = useWorkflowContext();

  return (
    isDataHidden ??
    (isShowingTestResult &&
      !runWorkflowMutation.isPending &&
      !runWorkflowMutation.data?.data.data.testEval.data
        .map((i) => i.stateID)
        .includes(id))
  );
}
