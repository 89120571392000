import classNames from "clsx";
import { ReactNode } from "react";
import dotsIcon from "@assets/icons/dots-horizontal.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import useIsExpand from "@screens/workflow/studio/hooks/useIsExpand";
import Menu from "@components/Menu";
import { FloatProvider, useFloat } from "@context/FloatContext";
import { FloatingPortal } from "@floating-ui/react";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";

type Props = {
  isFromFlow: boolean;
  items: Array<{ key: string; label: ReactNode; onClick: () => void }>;
  isExport: boolean;
  handleExportNode?: () => void;
};

function NodeMenu({ isFromFlow, items, isExport, handleExportNode }: Props) {
  const { isWorkflowEditable } = useWorkflowContext();
  const { x, y, strategy, refs } = useFloat();
  const isExpanded = useIsExpand();

  if (!isFromFlow && (isWorkflowEditable || isExport))
    return (
      <Menu className="nodrag">
        <Menu.Button
          ref={refs.setReference}
          className="!shadow-none !ring-0 !py-0 !px-0 mr-1 h-full hover:bg-neutral-25 rounded-md cursor-pointer"
        >
          <img
            alt="menu"
            src={dotsIcon}
            className={classNames("p-1", isExpanded ? "w-6 h-6" : "w-6 h-6")}
          />
        </Menu.Button>
        <FloatingPortal>
          <Menu.Items
            style={{
              position: strategy,
              top: y ?? 100,
              left: x ?? 100,
            }}
            ref={refs.setFloating}
            className="box-border rounded-md w-40 overflow-hidden translate-x-[70px] bg-white shadow-lg ring-1 ring-neutral-100 z-10"
          >
            {isExport && (
              <Menu.Item
                key={"export"}
                onClick={handleExportNode}
                className="px-2 flex h-7 items-center gap-2 font-b2-medium hover:bg-neutral-25"
              >
                <span className="text-neutral-black flex gap-2 items-center">
                  <ArrowDownTrayIcon className="text-neutral-black h-3 w-3 -mt-0.5" />
                  Export
                </span>
              </Menu.Item>
            )}
            {isWorkflowEditable &&
              items.map((item) => {
                return (
                  <Menu.Item
                    key={item.key}
                    onClick={item.onClick}
                    className="px-2 flex h-7 items-center gap-2 font-b2-medium hover:bg-neutral-25"
                  >
                    {item.label}
                  </Menu.Item>
                );
              })}
          </Menu.Items>
        </FloatingPortal>
      </Menu>
    );
  return null;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ ...props }: Props) {
  return (
    <FloatProvider placement="bottom">
      <NodeMenu {...props} />
    </FloatProvider>
  );
}
