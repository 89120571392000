import { forwardRef, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import CustomEditor from "@components/Editor/CustomEditor";
import { useUpdateOutcomeNode } from "./queries";
import { OutcomeNodeConfig } from "./types";
import useNodeSuggestionsProvider from "../../hooks/useNodeSuggestionsProvider";
import useMonacoContext from "../../MonacoContext";
import { getSuggestionList } from "../../utils";

type Props = {
  config: OutcomeNodeConfig;
  nodeId: string;
};

export default function CustomScript({ config, nodeId }: Props) {
  const updateConfig = useUpdateOutcomeNode();
  const { autocompleteProvider } = useMonacoContext();

  const [currentlyEditingKey, setCurrentlyEditingKey] = useState("");

  const { workflowId } = useParams();
  const itemsRef = useRef<Map<string, HTMLDivElement> | null>(null);

  function getRefMap() {
    if (!itemsRef.current) {
      itemsRef.current = new Map();
    }
    return itemsRef.current;
  }

  const form = useForm<Record<string, string>>({
    values: config.decisionNode,
    defaultValues: config.decisionNode,
  });

  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflowId, nodeId);
  const { keywords, sourceList } = getSuggestionList(keywordsQuery);

  const submit = form.handleSubmit((data) => {
    updateConfig.mutate({
      nodeId,
      workflowId: workflowId!,
      payload: {
        from: "decisionNode",
        decisionNode: data,
        workflowState: config.workflowState,
      },
    });
  });

  return (
    <div
      onFocus={() => {
        autocompleteProvider({
          nodeId,
          sourceList,
          keywords,
          autoCompleteRecords: {},
        });
      }}
      className="gap-2 flex flex-col"
      id="exp-parent"
      tabIndex={0}
      onBlur={(e) => {
        if (!itemsRef.current) {
          return;
        }
        const isInside = Array.from(itemsRef.current.values()).some(
          (el) =>
            el.contains(e.relatedTarget) || e.relatedTarget?.id === "exp-parent"
        );
        if (!isInside) {
          setCurrentlyEditingKey("");
          submit();
        }
      }}
    >
      {Object.entries(config.decisionNode).map(([key]) => {
        return (
          <Controller
            key={key}
            name={key}
            control={form.control}
            render={({ field }) => (
              <CustomScriptItem
                ref={(r) => {
                  const map = getRefMap();
                  if (r) {
                    map.set(key, r);
                  } else {
                    map.delete(key);
                  }
                }}
                key={key}
                onChange={(e) => {
                  field.onChange(e);
                }}
                name={key}
                expression={field.value}
                isEditing={key === currentlyEditingKey}
                setIsEditing={() => setCurrentlyEditingKey(key)}
              />
            )}
          />
        );
      })}
    </div>
  );
}

const CustomScriptItem = forwardRef<
  HTMLDivElement,
  {
    name: string;
    expression: string;
    onChange: (e: string) => void;
    isEditing: boolean;
    setIsEditing: () => void;
  }
>(({ name, expression, onChange, isEditing, setIsEditing }, ref) => {
  const { isWorkflowEditable } = useWorkflowContext();
  return (
    <div
      ref={ref}
      id="branch-item-header"
      onClick={setIsEditing}
      className="flex gap-2 flex-col bg-neutral-0 border border-neutral-100 p-2 rounded-md"
    >
      <span className="font-b2-medium">{name}</span>
      {isEditing ? (
        <CustomEditor
          monacoOptions={{
            readOnly: !isWorkflowEditable,
            lineNumbers: "off",
            glyphMargin: false,
            fontWeight: "400",
            folding: false,
            lineDecorationsWidth: 0,
            lineNumbersMinChars: 0,
            showFoldingControls: "never",
          }}
          value={expression}
          setValue={(e) => {
            onChange(e);
          }}
        />
      ) : (
        <div className="text-neutral-500 font-b1 font-code truncate min-h-[1ch]">
          {expression || "-"}
        </div>
      )}
    </div>
  );
});
