import { Fragment, ReactElement, ReactNode } from "react";
import { ReactComponent as CloseIcon } from "@assets/icons/x-close.svg";
import { Transition } from "@headlessui/react";

function SlideFullModal({
  isOpen,
  children,
  close,
  title,
  classes,
}: {
  isOpen: boolean;
  children: ReactElement;
  close: () => void;
  title: ReactNode;
  classes?: string;
}) {
  return (
    <div>
      <Transition show={isOpen} appear>
        <Transition.Child
          enter="transform transition ease-in-out duration-300 sm:duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transform transition ease-in-out duration-200 sm:duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as={Fragment}
        >
          <div className="transition-opacity h-screen w-screen backdrop-brightness-100 bg-[#eaeaea] bg-opacity-80 fixed left-0 bottom-0 right-0 top-0 box-border rounded-md shadow-lg ring-1 ring-gray-100 z-[103]"></div>
        </Transition.Child>
        <Transition.Child
          enter="transform transition ease-in-out duration-300 sm:duration-500"
          enterFrom="translate-x-full opacity-90"
          enterTo="translate-x-0 opacity-100"
          leave="transform transition ease-in-out duration-200 sm:duration-500"
          leaveFrom="translate-x-0 "
          leaveTo="translate-x-full "
          as={Fragment}
        >
          <div className="h-full w-[640px] absolute right-0 top-0 z-[104] bg-white border-l border-indigo-50">
            <div className="h-full overflow-auto">
              <div className=" absolute w-full flex bg-white z-10 items-center justify-between h-12 px-6 border-b border-neutral-100 ">
                <div className="font-b1-medium text-neutral-black">{title}</div>
                <CloseIcon
                  className="text-neutral-500 hover:text-neutral-black cursor-pointer"
                  onClick={() => close()}
                />
              </div>
              <div className={`pt-12 ${classes}`}>{children}</div>
            </div>
          </div>
        </Transition.Child>
      </Transition>
    </div>
  );
}

export default SlideFullModal;
