import { PolicyType } from "@screens/endpoints/types";
import { useQuery } from "@tanstack/react-query";
import axios from "src/axios";
import { DATE_FORMAT } from "src/config";
import { FinBoxResponse } from "src/types";
import { getGQEndTime, getGQStartTime } from "src/utils/utils";

type EntityList = {
  id: string;
  type: PolicyType;
  version: string;
  simulationCount: number;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  status: "active" | "draft" | "archive" | "inreview";
};

export const usePolicyWorkflowList = ({
  program,
  entity = ["compound", "normal", "workflow"],
  policy,
  enabled = true,
  status = ["active", "draft", "archive", "inreview"],
  createdBy,
  startDate,
  endDate,
}: {
  entity?: string[];
  policy?: string;
  program?: string;
  enabled?: boolean;
  status?: string[];
  createdBy?: string;
  startDate?: string;
  endDate?: string;
}) => {
  return useQuery({
    queryKey: [
      "policyWorkflowCompoundlist",
      program,
      entity,
      status,
      policy,
      createdBy,
      startDate,
      endDate,
    ],
    queryFn: async () =>
      axios.get<FinBoxResponse<EntityList[]>>("simulation/policyWorkflow", {
        params: {
          program: program,
          type: entity,
          name: policy,
          status: status,
        },
      }),
    select: (data) => data.data.data,
    enabled,
  });
};

export const usePolicyWorkflowOutcomesList = ({
  program,
  entity = ["compound", "normal", "workflow"],
  policy,
  enabled = true,
  status = ["active", "draft", "archive", "inreview"],
}: {
  entity?: string[];
  policy?: string;
  program?: string;
  enabled?: boolean;
  status?: string[];
}) => {
  return useQuery({
    queryKey: ["policyWorkflowCompoundlist", program, entity, status, policy],
    queryFn: async () =>
      axios.get<FinBoxResponse<EntityList[]>>("outcomes/policyWorkflow", {
        params: {
          program: program,
          type: entity,
          name: policy,
          status: status,
        },
      }),
    select: (data) => data.data.data,
    enabled,
  });
};

export const useEndpointPolicyWorkflowList = ({
  program,
  entity = ["compound", "normal", "workflow"],
  policy,
  enabled = true,
  status = ["active", "draft", "archive", "inreview"],
  createdBy,
  startDate,
  endDate,
}: {
  entity?: string[];
  policy?: string;
  program?: string;
  enabled?: boolean;
  status?: string[];
  createdBy?: string;
  startDate?: string;
  endDate?: string;
}) => {
  return useQuery({
    queryKey: [
      "policyWorkflowCompoundlist",
      program,
      entity,
      status,
      policy,
      createdBy,
      startDate,
      endDate,
    ],
    queryFn: async () =>
      axios.get<FinBoxResponse<EntityList[]>>("endpoint/policyWorkflow", {
        params: {
          program: program,
          type: entity,
          name: policy,
          status: status,
          createdBy: createdBy,
          startDate: getGQStartTime(startDate!, DATE_FORMAT),
          endDate: getGQEndTime(endDate!, DATE_FORMAT),
        },
      }),
    select: (data) => data.data.data,
    enabled,
  });
};

export const useAnalyticsPolicyWorkflowList = ({
  program,
  entity = ["compound", "normal", "workflow"],
  policy,
  enabled = true,
  status = ["active", "draft", "archive", "inreview"],
  createdBy,
  startDate,
  endDate,
}: {
  entity?: string[];
  policy?: string;
  program?: string;
  enabled?: boolean;
  status?: string[];
  createdBy?: string;
  startDate?: string;
  endDate?: string;
}) => {
  return useQuery({
    queryKey: [
      "policyWorkflowCompoundlist",
      program,
      entity,
      status,
      policy,
      createdBy,
      startDate,
      endDate,
    ],
    queryFn: async () =>
      axios.get<FinBoxResponse<EntityList[]>>(
        "analytics/workflow/policyWorkflow",
        {
          params: {
            program: program,
            type: entity,
            name: policy,
            status: status,
          },
        }
      ),
    select: (data) => data.data.data,
    enabled,
  });
};
