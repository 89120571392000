import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import LoginImg from "@assets/login.gif";
import finboxDarkLogo from "@assets/new_finbox_logo.svg";
import ContentWrapper from "@components/ContentWrapper";

export default function LoginWrapper({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  return (
    <ContentWrapper>
      <div className="flex min-h-screen w-screen justify-center">
        <div className="flex justify-center items-center relative w-1/2 bg-white">
          {children}
          <span className="absolute bottom-8 left:6 sm:left-12 font-b1 text-neutral-500">
            © {new Date().getFullYear()} Moshpit Technologies, Inc. All rights
            reserved.
          </span>
          <div
            className="flex items-center gap-2 h-auto absolute top-6 left-8 cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img className="h-8" src={finboxDarkLogo} alt="Finbox - Sentinel" />
          </div>
        </div>
        <div className="bg-[#EEF2FB] w-1/2">
          <div className="w-full h-full flex flex-col items-center justify-center">
            <div className="w-[520px] h-auto -mt-12">
              <img className="w-full" src={LoginImg} alt="Login img" />
            </div>
            <p className="text-neutral-900 text-center">
              Power{" "}
              <span className="font-semibold">smart credit decisions</span> with
              our no-code{" "}
              <span className="font-semibold">
                business rule <br /> engine
              </span>{" "}
              and <span className="font-semibold">fraud detection</span>
            </p>
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
}
