import classNames from "clsx";
import React from "react";

type Props = { children: React.ReactNode; className?: string };

export default function EmptyState({ children, className }: Props) {
  return (
    <div className="flex w-full h-full flex-col items-center justify-center gap-2 text-center">
      <div className={classNames("text-center", className)}>
        <p className="font-b1 text-neutral-500"> {children} </p>
      </div>
    </div>
  );
}
