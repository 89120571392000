import { memo } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  useReactFlow,
} from "reactflow";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import {
  DECISION_TABLE_NODE_TYPE,
  MODEL_NODE_TYPE,
  MODEL_SET_NODE_TYPE,
  SOURCE_NODE_TYPE,
} from "@screens/workflow/config";
import { getErrors, getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import { useGetNodeIconStyles } from "@screens/workflow/studio/hooks/useIsExpand";
import { getRemoveConnection } from "@screens/workflow/studio/utils";
import { useQueryClient } from "@tanstack/react-query";
import "./edge-styles.css";

const CustomEdge = ({
  sourceX,
  sourceY,
  id,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  selected,
  ...props
}: EdgeProps) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const { getEdge, getNode } = useReactFlow();
  const { updateWorkflow, workflow, isShowingTestResult, runWorkflowMutation } =
    useWorkflowContext();
  const iconStyles = useGetNodeIconStyles();

  const queryClient = useQueryClient();

  let edgeStyle = {
    ...props.style,
    stroke: "#CFCFCF",
  };

  const testPathNodeIds = runWorkflowMutation.data?.data.data.testEval.data.map(
    (i) => i.stateID
  );

  const isInTestPath = (
    runWorkflowMutation.data?.data.data.testEval.data ?? []
  ).some((item) => {
    if (item.transitionCondition)
      return (
        (item.stateID === props.source &&
          item.transitionCondition === props.sourceHandleId &&
          item.transition === props.target) ||
        props.source === "Start"
      );

    const node = getNode(props.source);
    return (
      node?.type &&
      testPathNodeIds?.includes(props.source) &&
      [
        MODEL_NODE_TYPE,
        DECISION_TABLE_NODE_TYPE,
        SOURCE_NODE_TYPE,
        MODEL_SET_NODE_TYPE,
      ].includes(node.type)
    );
  });

  if (isShowingTestResult && !runWorkflowMutation.isPending && isInTestPath) {
    edgeStyle = {
      ...edgeStyle,
      stroke: "#000",
      strokeWidth: 2,
    };
  }
  if (selected) {
    edgeStyle = {
      ...edgeStyle,
      stroke: "#AED2FC",
      strokeWidth: 2,
    };
  }

  return (
    <>
      <BaseEdge
        path={edgePath}
        {...props}
        interactionWidth={30}
        style={edgeStyle}
      />
      <EdgeLabelRenderer>
        {selected && (
          <button
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
              pointerEvents: "all",
            }}
            className="edge-delete-button nodrag nopan p-1 bg-error-500 rounded-full flex items-center justify-center"
            onClick={() => {
              const edge = getEdge(id);
              if (!edge) return;
              const e = getRemoveConnection([edge]);
              if (e) {
                updateWorkflow(e).then(() => {
                  queryClient.invalidateQueries(getWorkflowKeywordsQuery());
                  queryClient.invalidateQueries(getErrors(workflow?.id));
                });
              }
            }}
          >
            <TrashIcon style={iconStyles} className="[&>path]:stroke-white" />
          </button>
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export const CustomRunFlowEdge = memo(
  ({
    sourceX,
    sourceY,
    id,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    selected,
    ...props
  }: EdgeProps) => {
    const [edgePath] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });

    return <BaseEdge path={edgePath} {...props} />;
  }
);

export default memo(CustomEdge);
