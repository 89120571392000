import clsx from "clsx";
import { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import closeIcon from "@assets/icons/chevron-selector-vertical-close.svg";
import openIcon from "@assets/icons/chevron-selector-vertical-open.svg";
import ruleSetIcon from "@assets/icons/rule-set.svg";
import branchIcon from "@assets/icons/shuffle-01.svg";
import { ReactComponent as TableIcon } from "@assets/icons/table.svg";
import policyIcon from "@assets/icons/wf-policy-icon.svg";
import { ReactComponent as ExpressionIcon } from "@assets/icons/workflow/calculator.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import {
  denormaliseInputName,
  getShouldDenormalise,
  normaliseInputName,
} from "@screens/workflow/components/Debugger/utils";
import { PREDICTOR_COLOR } from "@screens/workflow/config";
import { getWorkflowPredictors } from "@screens/workflow/queries";
import { getIcon } from "@screens/workflow/studio/utils";
import Button from "@components/Button";
import Dropdown from "@components/DropDown";
import Input from "@components/Input";
import QueryWrapper from "@components/QueryWrapper";
import { Tooltip } from "@finbox-in/finblocks";
import { useQuery } from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { getNetworkErrorText, notify, titleCase } from "@utils/utils";

type Props =
  | {
      name: string;
      output: string;
      type: "policy" | "workflow" | "branch" | "rulegroup" | "decisionTable";
      singleRow: true;
    }
  | {
      name: string;
      output: string;
      type: "model" | "ruleset";
      entries: [string, string][];
      singleRow: false;
    };

function OutputItem({ name, output, type, singleRow, ...props }: Props) {
  if (singleRow) {
    return (
      <div className="py-1.5 border-b border-neutral-25 font-b2-medium px-3 flex justify-between items-center">
        {
          {
            policy: (
              <img alt="policy" src={policyIcon} className="w-3.5 h-3.5 mr-2" />
            ),
            workflow: (
              <img alt="policy" src={policyIcon} className="w-3.5 h-3.5 mr-2" />
            ),
            branch: (
              <img alt="policy" src={branchIcon} className="w-3.5 h-3.5 mr-2" />
            ),
            rulegroup: (
              <img alt="policy" src={branchIcon} className="w-3.5 h-3.5 mr-2" />
            ),
            decisionTable: <TableIcon className="w-3.5 h-3.5 mr-2" />,
          }[type]
        }
        {name || "Name"}
        <span className="ml-auto flex gap-1 items-center">
          {type !== "branch" && type !== "decisionTable" && getIcon(output)}
          {titleCase(output)}
        </span>
      </div>
    );
  }

  if (type === "model" && "entries" in props)
    return (
      <div key={name}>
        <div className="py-2 border-b border-neutral-25 px-3 font-b2-medium flex justify-between">
          <div className="flex">
            <ExpressionIcon className="w-3.5 h-3.5 mr-2" />
            {name || "Name"}
          </div>
        </div>

        {props.entries.map(([key, value]) => (
          <div
            key={key}
            className="pr-3 pl-8 py-1.5 font-b2 flex gap-2 border-b border-neutral-25 justify-between"
          >
            {key}:{" "}
            <span className="font-b2-medium">{JSON.stringify(value)}</span>
          </div>
        ))}
      </div>
    );

  if (type === "ruleset" && "entries" in props)
    return (
      <div>
        <div className="py-2 px-3 font-b2-medium flex justify-between border-b border-neutral-25">
          <div className="flex">
            <img alt="policy" src={ruleSetIcon} className="mr-2 w-3.5 h-3.5" />
            {name || "Name"}
          </div>
          <span className="flex gap-1">
            {getIcon(output)}
            {titleCase(output)}
          </span>
        </div>
        {props.entries.map(([name, output]) => {
          return (
            <div
              key={name}
              className="pl-8 pr-3 py-2 font-b2 flex gap-2 border-b border-neutral-25 justify-between"
            >
              <Tooltip
                toolTipContent={<div className="text-wrap">{name}</div>}
                placement="top"
              >
                <div className="truncate max-w-[340px]">{name}</div>
              </Tooltip>
              <span className="flex gap-1 items-center font-b2-medium">
                {getIcon(output)}
                {titleCase(output)}
              </span>
            </div>
          );
        })}
      </div>
    );
  return null;
}

const GlobalPolicy = () => {
  const [isOutputExpand, setIsOutputExpand] = useState(false);
  const { workflow, runWorkflowMutation } = useWorkflowContext();

  const form = useForm<
    {
      predictors: Record<string, Record<string, string | null>> & {
        workflows: Record<string, Record<string, string | null>>;
        policies: Record<string, Record<string, string | null>>;
      };
    } & {
      [key: string]: { decision: string };
    }
  >({
    shouldUnregister: true,
  });

  useFormPersist(`workflow:${workflow?.id}:debug`, {
    watch: form.watch,
    setValue: form.setValue,
    storage: window.localStorage,
  });

  const predictors = useQuery(getWorkflowPredictors(workflow?.id));

  const finalDecision = runWorkflowMutation.data?.data.data;

  const onAction = form.handleSubmit((data) => {
    if (!workflow) {
      return;
    }

    if (data.predictors?.policies)
      data.predictors.policies = Object.entries(
        data.predictors.policies
      ).reduce((prev, [policyIdName, value]) => {
        const shouldReplace = getShouldDenormalise(policyIdName);

        const nomalizedPolicyIdName = denormaliseInputName(policyIdName);
        const name = nomalizedPolicyIdName;
        const id = nomalizedPolicyIdName;

        if (shouldReplace) {
          prev[nomalizedPolicyIdName] = prev[policyIdName];
          delete prev[policyIdName];
        }

        if (name) prev[name] = value;
        if (id) prev[id] = value;
        return prev;
      }, data.predictors.policies);

    if (data.predictors?.workflows)
      data.predictors.workflows = Object.entries(
        data.predictors.workflows
      ).reduce((prev, [workflowName]) => {
        const shouldReplace = getShouldDenormalise(workflowName);

        const nomalizedWfName = denormaliseInputName(workflowName);

        if (shouldReplace) {
          prev[nomalizedWfName] = prev[workflowName];
          delete prev[workflowName];
        }

        return prev;
      }, data.predictors.workflows);

    for (let source in data.predictors) {
      if (source === "policies" || source === "workflows") continue;

      for (let predName in data.predictors[source]) {
        const shouldReplace = getShouldDenormalise(predName);

        const normalizedPredName = denormaliseInputName(predName);

        if (shouldReplace) {
          data.predictors[source][normalizedPredName] =
            data.predictors[source][predName];
          delete data.predictors[source][predName];
        }
      }
    }

    Object.keys(data.predictors).forEach((key) => {
      if (key === "policies" || key === "workflows") {
        Object.keys(data.predictors[key]).forEach((wfName) => {
          Object.keys(data.predictors[key][wfName]).forEach((predName) => {
            if (data.predictors[key][wfName][predName] === "")
              data.predictors[key][wfName][predName] = null;
          });
        });
      } else {
        Object.keys(data.predictors[key]).forEach((predName) => {
          if (data.predictors[key][predName] === "")
            data.predictors[key][predName] = null;
        });
      }
    });

    runWorkflowMutation.mutate(
      {
        workflowID: workflow.id,
        testData: data,
      },
      {
        onError: (error) =>
          notify({
            text: getNetworkErrorText(error),
            title: "Error",
          }),
      }
    );
  });

  return (
    <>
      <div
        className={clsx("overflow-scroll", isOutputExpand ? "h-1/5" : "h-3/5")}
      >
        <div className="font-b1-medium pb-4">Parameter Values</div>
        <QueryWrapper query={predictors}>
          {(data) => {
            const policies = data.data.data.policy ?? [];
            const workflows = data.data.data.workflow ?? [];

            return (
              <>
                {policies.length > 0 && (
                  <div className="pr-4 mb-4 flex flex-col">
                    <span className="font-b1-medium text-neutral-black">
                      Policies
                    </span>
                    <div className="grid gap-4 grid-cols-2">
                      {policies.map((policy) => (
                        <div key={policy.id}>
                          <div className="my-2 font-b2-medium w-[170px] truncate">
                            {policy.name || "Policy"}
                          </div>
                          <Controller
                            control={form.control}
                            name={`${policy.id}.decision`}
                            defaultValue="pass"
                            render={({ field }) => {
                              return (
                                <Dropdown
                                  value={policy.name}
                                  onChange={(s) => {
                                    form.clearErrors(`${policy.id}.decision`);
                                    field.onChange(s);
                                  }}
                                >
                                  <Dropdown.Button
                                    className={clsx(
                                      "w-full !h-7",
                                      field.value && "text-neutral-black"
                                    )}
                                  >
                                    {field.value || "Select"}
                                  </Dropdown.Button>
                                  <Dropdown.Options className="!w-[172px]">
                                    <Dropdown.Option value="pass">
                                      Pass
                                    </Dropdown.Option>
                                    <Dropdown.Option value="reject">
                                      Reject
                                    </Dropdown.Option>
                                    <Dropdown.Option value="cant_decide">
                                      Can't Decide
                                    </Dropdown.Option>
                                  </Dropdown.Options>
                                </Dropdown>
                              );
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {workflows.length > 0 && (
                  <div className="mb-4 flex flex-col pr-4">
                    <span className="font-b1-medium text-neutral-black">
                      Workflows
                    </span>
                    <div className="grid gap-4 grid-cols-2">
                      {workflows.map((item) => (
                        <div className="w-full" key={item.id}>
                          <span className="mt-2 mb-0 inline-block font-b2-medium w-full truncate">
                            {item.name || "Workflow"}
                          </span>
                          <Controller
                            control={form.control}
                            name={`${item.id}.decision`}
                            rules={{
                              required: {
                                value: true,
                                message: "required",
                              },
                            }}
                            render={({ field, fieldState }) => {
                              return (
                                <Dropdown
                                  value={item.name}
                                  onChange={(s: string) => {
                                    field.onChange(s);
                                  }}
                                >
                                  <Dropdown.Button
                                    error={
                                      fieldState.error?.message && "Required"
                                    }
                                    className={clsx(
                                      "w-full !h-7 mb-3",
                                      field.value && "text-neutral-black"
                                    )}
                                  >
                                    {field.value || "Select"}
                                  </Dropdown.Button>
                                  <Dropdown.Options className="!w-[172px]">
                                    {item.outcomes?.map((o) => (
                                      <Dropdown.Option key={o} value={o}>
                                        {o}
                                      </Dropdown.Option>
                                    ))}
                                  </Dropdown.Options>
                                </Dropdown>
                              );
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {data.data.data.variables &&
                  Object.keys(data.data.data.variables).length > 0 && (
                    <>
                      <div className="font-b1-medium text-neutral-black w-full">
                        Variables
                      </div>
                      <div className="grid grid-cols-2 gap-y-3 mt-2">
                        {Object.entries(data.data.data.variables).map(
                          ([source, preds]) => {
                            if (Array.isArray(preds))
                              return (
                                <Fragment key={source}>
                                  {preds.map((p) => (
                                    <div
                                      key={source + p}
                                      className="pr-4 font-b2"
                                    >
                                      <Input
                                        label={
                                          <div className="w-[210px] truncate">
                                            <span
                                              className={
                                                PREDICTOR_COLOR[source]
                                              }
                                            >
                                              {source}:{" "}
                                            </span>
                                            <Tooltip
                                              className="w-[90%] truncate"
                                              toolTipContent={p}
                                            >
                                              <div>{p}</div>
                                            </Tooltip>
                                          </div>
                                        }
                                        {...form.register(
                                          `predictors.${source}.${normaliseInputName(
                                            `${p}`
                                          )}`
                                        )}
                                      />
                                    </div>
                                  ))}
                                </Fragment>
                              );
                            else return null;
                          }
                        )}
                      </div>
                    </>
                  )}
                {data.data.data.variables.policies &&
                  Object.values(data.data.data.variables.policies).some(
                    (s) => s.length > 0
                  ) && (
                    <>
                      <div className="font-b1-medium text-neutral-black mt-4 w-full">
                        Policy Outputs
                      </div>
                      <div className="grid grid-cols-2 mt-2">
                        {Object.entries(data.data.data.variables.policies).map(
                          ([policyId, preds]) => {
                            return (
                              <Fragment key={policyId}>
                                {preds.map((p) => (
                                  <div key={policyId + p} className="mr-4">
                                    <Input
                                      label={
                                        <div className="w-[170px] truncate">
                                          <span
                                            className={
                                              PREDICTOR_COLOR[policyId]
                                            }
                                          >
                                            {policyId}:{" "}
                                          </span>
                                          <Tooltip
                                            className="w-[90%] truncate"
                                            toolTipContent={p}
                                          >
                                            <div>{p}</div>
                                          </Tooltip>
                                        </div>
                                      }
                                      {...form.register(
                                        `predictors.policies.${normaliseInputName(
                                          policyId
                                        )}.${p}`
                                      )}
                                    />
                                  </div>
                                ))}
                              </Fragment>
                            );
                          }
                        )}
                      </div>
                    </>
                  )}

                {data.data.data.variables.workflows &&
                  Object.keys(data.data.data.variables.workflows).length >
                    0 && (
                    <>
                      <div className="font-b1-medium text-neutral-black mt-4 w-full">
                        Workflow Outputs
                      </div>
                      <div className="grid grid-cols-2 mt-2">
                        {Object.entries(data.data.data.variables.workflows).map(
                          ([wfName, preds]) => {
                            return (
                              <Fragment key={wfName}>
                                {preds.map((p) => (
                                  <div key={wfName + p}>
                                    <Input
                                      {...form.register(
                                        `predictors.workflows.${normaliseInputName(
                                          wfName
                                        )}.${p}`
                                      )}
                                      label={
                                        <div className="w-[170px] truncate">
                                          <span
                                            className={PREDICTOR_COLOR[wfName]}
                                          >
                                            {wfName}:{" "}
                                          </span>{" "}
                                          <Tooltip
                                            className="w-[90%] truncate"
                                            toolTipContent={p}
                                          >
                                            <div>{p}</div>
                                          </Tooltip>
                                        </div>
                                      }
                                    />
                                  </div>
                                ))}
                              </Fragment>
                            );
                          }
                        )}
                      </div>
                    </>
                  )}
              </>
            );
          }}
        </QueryWrapper>
      </div>
      <div
        className={clsx(
          "absolute bottom-0 w-full left-0 bg-white duration-300",
          isOutputExpand ? "h-4/5" : "h-2/5"
        )}
      >
        <div className="flex flex-col">
          <div className="flex items-center justify-between right-0 border-t bg-neutral-0 border-b py-[3px] border-neutral-100 pl-4 pr-1">
            <span className="font-b2-medium text-neutral-black">Output</span>
            <div className="flex items-center">
              <img
                src={isOutputExpand ? closeIcon : openIcon}
                alt=""
                className="hover:bg-neutral-25 cursor-pointer rounded-md p-1 w-6 h-6 "
                onClick={() => {
                  setIsOutputExpand(!isOutputExpand);
                }}
              />
              <Button
                variant="link"
                onClick={onAction}
                className="text-primary-900 hover:enabled:bg-neutral-25 h-6 mr-2"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.33691 3.32642C3.33691 2.67898 3.33691 2.35526 3.47191 2.17681C3.58951 2.02135 3.76926 1.92515 3.96385 1.91353C4.1872 1.9002 4.45656 2.07977 4.99526 2.4389L12.0056 7.11248C12.4508 7.40923 12.6733 7.55761 12.7509 7.74462C12.8187 7.90813 12.8187 8.09188 12.7509 8.25538C12.6733 8.4424 12.4508 8.59077 12.0056 8.88752L4.99526 13.5611C4.45656 13.9202 4.1872 14.0998 3.96385 14.0865C3.76926 14.0749 3.58951 13.9787 3.47191 13.8232C3.33691 13.6447 3.33691 13.321 3.33691 12.6736V3.32642Z"
                    fill="#194CFF"
                    fillOpacity="0.25"
                    stroke="#194CFF"
                    strokeWidth="1.33"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Run Test
              </Button>
            </div>
          </div>
          {runWorkflowMutation.isSuccess && finalDecision?.final && (
            <div className="flex items-center gap-2 px-3 py-1.5 font-b2-medium border-b justify-between">
              <span>Final Decision: </span>
              <span className=" flex gap-1">
                {getIcon(finalDecision.final.decision, {
                  display: "inline-flex",
                })}{" "}
                {titleCase(finalDecision.final.decision)}
              </span>
            </div>
          )}
        </div>
        {!runWorkflowMutation.isError && (
          <div className="overflow-y-auto h-[calc(100%-70px)]">
            {runWorkflowMutation.isSuccess && finalDecision && (
              <>
                {Object.keys(finalDecision.policies).length > 0 &&
                  Object.entries(finalDecision.policies).map(
                    ([policyId, policyOutput]) => (
                      <OutputItem
                        singleRow={true}
                        key={policyId}
                        name={policyOutput.policyName}
                        output={policyOutput.output.decision}
                        type="policy"
                      />
                    )
                  )}
                {Object.keys(finalDecision.workflows).length > 0 &&
                  Object.entries(finalDecision.workflows).map(
                    ([workflowName, workflowOutput]) => (
                      <OutputItem
                        singleRow={true}
                        key={workflowName}
                        name={workflowOutput.name}
                        output={workflowOutput.output.final.decision}
                        type="workflow"
                      />
                    )
                  )}
                {Object.keys(finalDecision.rules).length > 0 &&
                  Object.entries(finalDecision.rules).map(
                    ([branchId, branchOutput]) => (
                      <OutputItem
                        singleRow={true}
                        key={branchId}
                        name={branchOutput.name}
                        output={branchOutput.output}
                        type="rulegroup"
                      />
                    )
                  )}
                {Object.keys(finalDecision.branch).length > 0 &&
                  Object.entries(finalDecision.branch).map(
                    ([branchName, output]) => (
                      <OutputItem
                        singleRow={true}
                        key={branchName}
                        name={branchName}
                        output={output.output}
                        type="branch"
                      />
                    )
                  )}
                {Object.keys(finalDecision.ruleset).length > 0 &&
                  Object.entries(finalDecision.ruleset).map(
                    ([branchName, output]) => {
                      return (
                        <OutputItem
                          key={branchName}
                          name={branchName}
                          output={output.decision}
                          type="ruleset"
                          entries={output.rules.map((item) => [
                            item.rule,
                            item.decision,
                          ])}
                          singleRow={false}
                        />
                      );
                    }
                  )}
                {Object.keys(finalDecision.models).length > 0 &&
                  Object.keys(finalDecision.models).map((modelName) => {
                    const modelData = finalDecision.models[modelName];
                    const isTable = ["string", "boolean", "number"].includes(
                      typeof modelData.output
                    );

                    if (isTable)
                      return (
                        <OutputItem
                          singleRow={true}
                          key={modelName}
                          name={modelName}
                          output={JSON.stringify(modelData.output)}
                          type="decisionTable"
                        />
                      );
                    return (
                      <OutputItem
                        singleRow={false}
                        key={modelName}
                        name={modelName}
                        output=""
                        entries={Object.entries(modelData.output)}
                        type="model"
                      />
                    );
                  })}
              </>
            )}
          </div>
        )}
        {runWorkflowMutation.isError && (
          <div className="px-4 py-3 overflow-scroll">
            <div className="font-b2 text-error-500">
              {(
                runWorkflowMutation.error as unknown as {
                  response: { data: FinBoxResponse };
                }
              ).response.data.error
                .split("\n")
                .map((row) => (
                  <Fragment key={row}>
                    {row}
                    <br />
                  </Fragment>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GlobalPolicy;
