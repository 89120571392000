const DOT_REPLACE_CHAR = "@";
const SQ_BRACKET_OPEN_REPLACE_CHAR = "(";
const SQ_BRACKET_CLOSE_REPLACE_CHAR = ")";
const QUOTE_REPLACE_CHAR = "#";

export const getShouldDenormalise = (str: string) => {
  return (
    str.includes(DOT_REPLACE_CHAR) ||
    str.includes(SQ_BRACKET_OPEN_REPLACE_CHAR) ||
    str.includes(SQ_BRACKET_CLOSE_REPLACE_CHAR) ||
    str.includes(QUOTE_REPLACE_CHAR)
  );
};

export const normaliseInputName = (str: string) =>
  str
    .replace(/\./g, DOT_REPLACE_CHAR)
    .replace(/\[/g, SQ_BRACKET_OPEN_REPLACE_CHAR)
    .replace(/]/g, SQ_BRACKET_CLOSE_REPLACE_CHAR)
    .replace(/"/g, QUOTE_REPLACE_CHAR);

export const denormaliseInputName = (str: string) =>
  str
    .replace(new RegExp(DOT_REPLACE_CHAR, "g"), ".")
    .replace(new RegExp(`\\${SQ_BRACKET_OPEN_REPLACE_CHAR}`, "g"), "[")
    .replace(new RegExp(`\\${SQ_BRACKET_CLOSE_REPLACE_CHAR}`, "g"), "]")
    .replace(new RegExp(`\\${QUOTE_REPLACE_CHAR}`, "g"), '"');
