import clsx from "clsx";
import { forwardRef, useRef } from "react";
import Tooltip from "src/components/Tooltip";
import { ReactComponent as DotsGridIcon } from "@assets/icons/workflow/dots-grid.svg";
import { ReactComponent as DeleteIcon } from "@assets/icons/workflow/trash-02.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { isValidName } from "@screens/workflow/studio/utils";
import { ModelId } from "@screens/workflow/types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@utils/CSS";
import { notify } from "@utils/utils";
import CustomEditor from "@components/Editor/CustomEditor";
import { useDeleteModelExpression, useUpdateModelExpression } from "./queries";
import { ModelExpression } from "./types";

interface ModelItemProps {
  expression: ModelExpression;
  workflowId: string;
  nodeId: string;
  isEdit: boolean;
  setIsEdit: () => void;
  showDelete: boolean;
}

const ModelItem = forwardRef<HTMLDivElement, ModelItemProps>(
  ({ expression, workflowId, nodeId, isEdit, setIsEdit, showDelete }, ref) => {
    const deleteModelMutation = useDeleteModelExpression(
      workflowId,
      nodeId,
      expression.id
    );
    const updateModelMutation = useUpdateModelExpression(
      workflowId,
      nodeId,
      expression.id
    );

    const { isWorkflowEditable, errors } = useWorkflowContext();

    const nameRef = useRef<HTMLInputElement>(null);

    const error = errors?.[nodeId as ModelId]?.errors?.[expression.id];

    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: expression.id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    const onNameChange = (name: string) => {
      const [isValid, error] = isValidName(name);
      if (!isValid) return notify({ title: "Invalid Name", text: error });
      updateModelMutation.mutate({
        expression: {
          name: name,
          body: expression.body,
          seqNo: expression.seqNo,
        },
      });
    };

    const onExpressionChange = (body: string) => {
      updateModelMutation.mutate({
        expression: {
          name: nameRef.current?.value || expression.name,
          body: body,
          seqNo: expression.seqNo,
        },
      });
    };

    return (
      <div
        className="relative flex"
        onClick={() => {
          !isEdit && setIsEdit();
        }}
        ref={setNodeRef}
        style={style}
      >
        <div
          className={clsx(
            "nodrag p-0.5 mt-2 mr-1",
            (isEdit || !isWorkflowEditable) && "hidden"
          )}
          {...attributes}
          {...listeners}
        >
          <DotsGridIcon className="w-3 h-3" />
        </div>
        <div
          className={clsx(
            "border border-neutral-100 rounded-md p-2 bg-neutral-0 font-b2 w-full",
            !isEdit && "cursor-pointer max-w-[320px]",
            !isWorkflowEditable && "max-w-[360px]",
            error && "!border-error-500"
          )}
          ref={ref}
        >
          <div
            className={clsx(
              "transition-all ease-in",
              isEdit ? "max-h" : "max-h-14"
            )}
          >
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center gap-2 overflow-visible">
                {isEdit ? (
                  <input
                    maxLength={50}
                    ref={nameRef}
                    readOnly={!isWorkflowEditable}
                    type="text"
                    className="p-2 font-b2-medium text-neutral-black nodrag !w-[250px] !outline-none border border-neutral-100 h-6 rounded-md !ring-0"
                    defaultValue={expression.name}
                    onBlur={(e) => {
                      const [isValid, error] = isValidName(e.target.value);
                      if (isValid) onNameChange(e.target.value);
                      else notify({ title: "Invalid name", text: error });
                    }}
                  />
                ) : (
                  <Tooltip
                    contentClassName="font-b2-medium max-w-[250px] truncate"
                    tooltipContent={expression.name}
                    content={
                      <span
                        onClick={() => {
                          !isEdit && setIsEdit();
                        }}
                      >
                        {expression.name}
                      </span>
                    }
                  />
                )}
                <div className="text-neutral-black font-b2-medium">=</div>
              </div>
              {showDelete && isWorkflowEditable && (
                <DeleteIcon
                  className="cursor-pointer [&:hover>path]:stroke-error-500 w-4 h-4"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (showDelete) deleteModelMutation.mutate();
                  }}
                />
              )}
            </div>
            {isEdit ? (
              <CustomEditor
                setValue={(e) => {
                  onExpressionChange(e);
                }}
                value={expression.body}
                monacoOptions={{
                  lineNumbers: "off",
                  readOnly: !isWorkflowEditable,
                  glyphMargin: false,
                  fontWeight: "400",
                  folding: false,
                  lineDecorationsWidth: 0,
                  lineNumbersMinChars: 0,
                  showFoldingControls: "never",
                }}
              />
            ) : (
              <div className="text-neutral-500 text-[14px] font-code truncate">
                {expression.body}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default ModelItem;
