import { ReactElement } from "react";
import ErrorState from "@components/ErrorState";
import Loader from "@components/Loader";
import { UseQueryResult } from "@tanstack/react-query";
import { getNetworkErrorText } from "@utils/utils";

export default function QueryWrapper<TData = unknown, TError = unknown>({
  query,
  children,
  loader,
}: {
  query: UseQueryResult<TData, TError>;
  children: (data: TData, options: { isFetching: boolean }) => JSX.Element;
  loader?: ReactElement;
}) {
  if (query.isPending)
    return loader ? (
      loader
    ) : (
      <div className="w-full h-full flex justify-center items-center">
        <Loader />
      </div>
    );
  if (query.isError)
    return (
      <ErrorState error={query.error}>
        {getNetworkErrorText(query.error)}
      </ErrorState>
    );
  return children(query.data, { isFetching: query.isFetching });
}
