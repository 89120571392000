import classNames from "clsx";
import {
  ComponentProps,
  createContext,
  Fragment,
  PropsWithChildren,
  useContext,
} from "react";
import closeIcon from "@assets/icons/x-close.svg";
import { Dialog, Transition } from "@headlessui/react";

const sizeClasses = {
  sm: "w-96",
  lg: "w-[640px]",
};

const CloseContext = createContext<((value: boolean) => void) | undefined>(
  undefined
);

const CloseProvider = ({
  onClose,
  children,
}: PropsWithChildren<Parameters<typeof Dialog>[0]>) => {
  return (
    <CloseContext.Provider value={onClose}>{children}</CloseContext.Provider>
  );
};

const useCloseContext = () => {
  const context = useContext(CloseContext);
  if (!context)
    throw new Error("`useCloseContext` can be used only in `CloseProvider`");
  return context;
};

const _Dialog = ({ className, ...props }: Parameters<typeof Dialog>[0]) => {
  return (
    <CloseProvider onClose={props.onClose}>
      <Transition show={props.open} unmount appear>
        <Transition.Child
          as={Fragment}
          enter="transition duration-300 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-100 ease-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog
            className={classNames(
              "fixed left-0 top-0 z-[999] flex h-full w-full items-center justify-center backdrop-brightness-100 bg-[#eaeaea] bg-opacity-80",
              className
            )}
            {...props}
          />
        </Transition.Child>
      </Transition>
    </CloseProvider>
  );
};

const Panel = ({
  className,
  size,
  ...props
}: Parameters<typeof Dialog.Panel>[0] & {
  onClose?: () => void;
  size: "sm" | "lg";
}) => {
  return (
    <Transition.Child
      as={Fragment}
      enter="transition duration-300 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog.Panel
        className={classNames(
          "relative box-border rounded-md bg-white shadow-lg border border-neutral-100",
          sizeClasses[size],
          className
        )}
        {...props}
      >
        {props.children}
      </Dialog.Panel>
    </Transition.Child>
  );
};

const Title = ({ className, ...props }: Parameters<typeof Dialog.Title>[0]) => {
  return (
    <Dialog.Title
      className={classNames("text-lg font-semibold", className)}
      {...props}
    />
  );
};

const Header = ({
  children,
  closeButtonTestId,
  className,
  ...props
}: ComponentProps<"div"> & {
  closeButtonTestId?: string;
}) => {
  const onClose = useCloseContext();
  return (
    <div
      className={classNames(
        "px-4 py-3 bg-neutral-0 h-10 overflow-hidden w-full flex font-b1-medium border-b absolute top-0 left-0 rounded-t-md",
        className
      )}
      {...props}
    >
      {children}
      <img
        data-test-id={closeButtonTestId ?? "modalCloseButton"}
        alt="x"
        src={closeIcon}
        onClick={() => onClose(true)}
        className="h-4 w-4 p-0 ml-auto cursor-pointer z-10 text-neutral-500"
      />
    </div>
  );
};
const Body = ({ children, className, ...props }: ComponentProps<"div">) => {
  return (
    <div
      className={classNames(
        "p-4 pt-3 w-full overflow-auto mt-10 mb-12",
        className
      )}
      {...props}
      style={{
        height: "calc(100% - 6.5rem) ",
        maxHeight: "80vh",
      }}
    >
      {children}
    </div>
  );
};

const Footer = ({ children, className, ...props }: ComponentProps<"div">) => {
  return (
    <div
      className={classNames(
        "px-4 pb-4 h-12 overflow-hidden w-full flex items-center [&>button]:w-full [&>button]:h-8 [&>button]:justify-center [&>button]:font-semibold absolute bottom-0 left-0 shadow-md rounded-b-md",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

let Modal = Object.assign(_Dialog, {
  ..._Dialog,
  Panel,
  Title,
  Header,
  Body,
  Footer,
});

export default Modal;
