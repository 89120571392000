/* eslint-disable no-console */
import { reportToSentry } from "@utils/utils";

class Logger {
  private prodCheck() {
    return import.meta.env.REACT_APP_API_ENV === "PROD";
  }

  log(...args: any[]) {
    if (this.prodCheck()) return;
    console.log(...args);
  }

  info(...args: any[]) {
    if (this.prodCheck()) return;
    console.log(...args);
  }

  warn(...args: any[]) {
    if (this.prodCheck()) return;
    console.warn(...args);
  }

  trace(...args: any[]) {
    if (this.prodCheck()) return;
    console.trace(...args);
  }

  error(err: unknown) {
    if (this.prodCheck()) {
      reportToSentry(err);
      return;
    }
    if (err instanceof Error) console.error(err.stack);
    else console.error(err);
  }
}

const logger = new Logger();

export default logger;
