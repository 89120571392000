import axios from "@axios";
import { DASHBOARD_USERS_LIMIT } from "@config";
import {
  keepPreviousData,
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { getNetworkErrorText, normalizeQueryKey, notify } from "@utils/utils";
import {
  DashboardUsers,
  Filters,
  RBACRoleList,
  Resources,
  RoleData,
} from "./types";

export const dashboardUserListQuery = (args?: {
  page?: number;
  name: string;
  role: string;
}) =>
  queryOptions({
    queryKey: normalizeQueryKey(["dashboardUsers", args]),
    queryFn: async () => {
      return axios.get<FinBoxResponse<DashboardUsers>>(
        "member/dashboardUsers",
        {
          params: {
            page_size: DASHBOARD_USERS_LIMIT,
            page_number: args?.page ?? 1,
            name: args?.name,
            role: args?.role,
          },
        }
      );
    },
    staleTime: Infinity,
    placeholderData: keepPreviousData,
  });

export function useMemberDashboardUsers({
  page,
  name,
  role,
}: {
  page?: number;
  name: string;
  role: string;
}) {
  return useQuery(dashboardUserListQuery({ page, name, role }));
}

export function useAddUserWithLink() {
  return useMutation({
    mutationFn: async ({
      email,
      name,
      role,
      filters,
    }: {
      email: string;
      name: string;
      role: string;
      filters: Filters;
    }) =>
      axios.post("member/dashboardUser", {
        email,
        name,
        role,
        filters,
      }),
    onError: (err) =>
      notify({
        title: "Could not add user",
        text: getNetworkErrorText(err),
      }),
  });
}

export function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      data: Partial<{
        dashboard_user_id: string;
        email: string;
        client_id: string;
        name: string;
        role: string;
        filters: Filters;
      }>
    ) => {
      return axios.put("member/dashboardUser", data);
    },
    onError: (err) =>
      notify({
        title: "Could not update user",
        text: getNetworkErrorText(err),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(dashboardUserListQuery());
    },
  });
}

export function useDeleteUser() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (dashboardUserID: string) =>
      axios.delete("member/dashboardUser", {
        params: {
          dashboardUserID,
        },
      }),
    onSuccess: () => qc.invalidateQueries(dashboardUserListQuery()),
    onError: (err) =>
      notify({ title: "Failed to delete", text: getNetworkErrorText(err) }),
  });
}

export const memberRoleListQuery = () =>
  queryOptions({
    queryKey: ["memberRoleListQuery"],
    queryFn: async () => {
      return axios.get<FinBoxResponse<RBACRoleList>>("member/role");
    },
    staleTime: Infinity,
    select: (data) => data.data.data,
  });

export function useMemberRoleListQuery() {
  return useQuery(memberRoleListQuery());
}

export const allRoleListQuery = () =>
  queryOptions({
    queryKey: ["allRoleListQuery"],
    queryFn: async () => {
      return axios.get<FinBoxResponse<RBACRoleList>>("rbac/role");
    },
    staleTime: Infinity,
    select: (data) => data.data.data,
  });

export function useAllRoleListQuery() {
  return useQuery(allRoleListQuery());
}

export function useCreateNewRole() {
  return useMutation({
    mutationFn: async ({ roleName }: { roleName: string }) =>
      axios.post("rbac/role", {
        name: roleName,
      }),
    onError: (err) =>
      notify({ title: "Failed to create", text: getNetworkErrorText(err) }),
  });
}

export function useDeleteNewRole() {
  return useMutation({
    mutationFn: async ({ roleName }: { roleName: string }) =>
      axios.delete(`rbac/role/${roleName}`),
    onError: (err) =>
      notify({ title: "Failed to delete", text: getNetworkErrorText(err) }),
  });
}

export const getPermissionQuery = (newRole: string) =>
  queryOptions({
    queryKey: ["getPermissionQuery"],
    queryFn: async () => {
      return axios.get<FinBoxResponse<RoleData>>(
        `rbac/role/${newRole}/permissions`
      );
    },
    select: (data) => data.data.data,
  });

export function useGetPermissionsQuery(newRole: string) {
  return useQuery(getPermissionQuery(newRole));
}

export function useUpdatePermissions() {
  return useMutation({
    mutationFn: async ({
      name,
      resources,
      filters,
    }: {
      name: string;
      resources: Resources;
      filters: Filters;
    }) =>
      axios.put(`rbac/role/${name}/permissions`, {
        name,
        resources,
        filters,
      }),
    onError: (err) =>
      notify({ title: "Failed to update", text: getNetworkErrorText(err) }),
  });
}

export function useDuplicateRole() {
  return useMutation({
    mutationFn: async ({
      oldRoleName,
      roleName,
    }: {
      oldRoleName: string;
      roleName: string;
    }) =>
      axios.post(`rbac/role/${oldRoleName}`, {
        role: roleName,
      }),
    onError: (err) =>
      notify({
        title: "Could not duplicate role",
        text: getNetworkErrorText(err),
      }),
  });
}

export function useChangeRoleForDelete() {
  return useMutation({
    mutationFn: async ({
      updatedUserList,
    }: {
      updatedUserList: { id: string; role: string }[];
    }) => axios.post("rbac/role/assign", updatedUserList),
    onError: (err) =>
      notify({
        title: "Failed to change role",
        text: getNetworkErrorText(err),
      }),
  });
}

export function useGetRoleFunnelItems(funnelType?: string) {
  return useQuery({
    queryKey: ["funnelList", funnelType],
    queryFn: async () =>
      axios.get<FinBoxResponse<Record<Uuid, string>>>("rbac/funnelList", {
        params: { funnelType: funnelType?.toLowerCase() },
      }),
    enabled: !!funnelType,
  });
}
export function useGetMemberFunnelItems(funnelType?: string) {
  return useQuery({
    queryKey: ["funnelList", funnelType],
    queryFn: async () =>
      axios.get<FinBoxResponse<Record<Uuid, string>>>("member/funnelList", {
        params: { funnelType: funnelType?.toLowerCase() },
      }),
    enabled: !!funnelType,
  });
}
