import { clsx } from "clsx";
import { memo } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { getIcon } from "@screens/workflow/studio/utils";
import useIsExpand, {
  useGetHandleStyles,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
} from "../../hooks/useIsExpand";

function getLabel(label: string) {
  switch (label.toLowerCase()) {
    case "approved":
      return "Approved";
    case "rejected":
      return "Rejected";
    case "cant_decide":
      return "Can't Decide";
    default:
      return label;
  }
}

const EndNode = ({ data, selected, id }: NodeProps) => {
  const { isWorkflowEditable } = useWorkflowContext();

  const isExpanded = useIsExpand();

  const fontStyles = useGetNodeFontStyles(12, 18);
  const iconStyles = useGetNodeIconStyles();
  const handleStyles = useGetHandleStyles();

  return (
    <div
      className={clsx(
        "bg-white border-neutral-100 rounded-full overflow-visible flex gap-1 justify-center font-b2 border items-center",
        selected && "border-primary-200",
        {
          "opacity-25": data?.hidden,
          "shadow-studio-node": !(data?.isFromFlow && !data?.hidden),
          "shadow-lg": data?.isFromFlow && !data?.hidden,
          "py-1 pl-2.5 pr-2": isExpanded,
          "py-1.5 pl-2.5 pr-2": !isExpanded,
        }
      )}
      style={fontStyles}
    >
      {getIcon(data.label, iconStyles)}
      <span>{getLabel(data.label) || "custom_outcome"}</span>
      <Handle
        type="target"
        position={Position.Left}
        className="w-2 h-2 opacity-50 hover:opacity-100 rounded-full bg-white border-neutral-500 duration-150 !-left-4"
        isConnectable={isWorkflowEditable}
        style={handleStyles}
      >
        <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
      </Handle>
    </div>
  );
};

export default memo(EndNode);
