import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Modal from "src/components/Dialogue";
import Dropdown from "src/components/DropDown";
import { getInitial, titleCase } from "src/utils/utils";
import {
  allRoleListQuery,
  useChangeRoleForDelete,
  useDeleteNewRole,
} from "./queries";
import { RBACMembers, RBACRoleList } from "./types";

export const ChangeAndDelete = ({
  show,
  setShow,
  roleName,
  roleList,
  userList,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  roleName: string;
  roleList: RBACRoleList;
  userList: RBACMembers[];
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [updatedUserList, setUpdatedUserList] = useState<
    { id: string; role: string }[]
  >([]);
  const changeRole = useChangeRoleForDelete();
  const deleteRole = useDeleteNewRole();
  const qc = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (userList) {
      setUpdatedUserList(
        userList.map((user) => ({
          id: user.id,
          role: roleName,
        }))
      );
    }
  }, [userList, roleName]);

  const onDropDownChange = (id: string, newRole: string) => {
    setUpdatedUserList((prevRoleList) =>
      prevRoleList.map((role) =>
        role.id === id ? { ...role, role: newRole } : role
      )
    );
  };

  const handleConfirmDelete = () => {
    if (updatedUserList.length > 0) {
      changeRole.mutate(
        { updatedUserList },
        {
          onSuccess: () => {
            setConfirmDelete(false);
            deleteRole.mutate(
              { roleName },
              {
                onSuccess: () => {
                  qc.invalidateQueries(allRoleListQuery());
                  setShow(false);
                  navigate("/settings/roles");
                },
              }
            );
          },
        }
      );
    } else {
      deleteRole.mutate(
        { roleName },
        {
          onSuccess: () => {
            qc.invalidateQueries(allRoleListQuery());
            setConfirmDelete(false);
            setShow(false);
            navigate("/settings/roles");
          },
        }
      );
    }
  };

  return (
    <>
      <Modal open={show} onClose={() => setShow(false)}>
        <Modal.Panel size="sm" onClose={() => setShow(false)}>
          <Modal.Header>Delete role {roleName}</Modal.Header>
          <Modal.Body>
            <p className="font-b2 text-neutral-black">
              {userList && userList.length > 0
                ? " In order to delete this role, you need to assign a different role to the existing members of this role."
                : "Are you sure you want to delete this role?"}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setConfirmDelete(true)}>
              {userList && userList.length > 0 ? "Change Role" : "Delete Role"}
            </Button>
          </Modal.Footer>
        </Modal.Panel>
      </Modal>

      <Modal open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <Modal.Panel size="sm" onClose={() => setConfirmDelete(false)}>
          <Modal.Header>
            {userList && userList.length > 0 ? "Change Role" : "Delete"}
          </Modal.Header>
          <Modal.Body className="!py-0 !mb-16">
            {userList && userList.length > 0 ? (
              userList.map((item) => {
                const selectedRole =
                  updatedUserList.find((role) => role.id === item.id)?.role ||
                  roleName;
                return (
                  <div
                    className="flex justify-between border-b py-3"
                    key={item.id}
                  >
                    <div className="flex justify-between gap-2">
                      <span className="w-6 h-6 flex justify-center items-center rounded-full bg-neutral-900 text-neutral-0 font-medium text-[11px]">
                        {getInitial(item.name)}
                      </span>
                      <div className="flex flex-col gap-2">
                        <p className="font-b1-medium">{item.name}</p>
                        <p className="font-b2">{item.email}</p>
                      </div>
                    </div>
                    <Dropdown
                      className="mr-5"
                      value={selectedRole}
                      onChange={(e) => onDropDownChange(item.id, e as string)}
                    >
                      <Dropdown.Button className="w-[126%]">
                        {selectedRole || "Select Roles"}
                      </Dropdown.Button>
                      <Dropdown.Options className="h-fit">
                        {roleList ? (
                          roleList.map((r) => (
                            <Dropdown.Option key={r.role} value={r.role}>
                              {titleCase(r.role)}
                            </Dropdown.Option>
                          ))
                        ) : (
                          <Dropdown.Option
                            className="!text-neutral-500 cursor-not-allowed"
                            value="empty"
                            key="empty"
                          >
                            No options
                          </Dropdown.Option>
                        )}
                      </Dropdown.Options>
                    </Dropdown>
                  </div>
                );
              })
            ) : (
              <p className="font-b2 text-neutral-black mt-3">
                By confirming, you will delete this role permanently.
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleConfirmDelete}
              disabled={changeRole.isPending || deleteRole.isPending}
            >
              {userList && userList.length > 0
                ? "Change & Delete"
                : "Confirm Deletion"}
            </Button>
          </Modal.Footer>
        </Modal.Panel>
      </Modal>
    </>
  );
};
