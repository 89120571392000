import classNames from "clsx";
import { PropsWithChildren } from "react";

interface Props {
  className?: string;
  onClick?: () => void;
}

const Card = ({ className, children, onClick }: PropsWithChildren<Props>) => {
  return (
    <div
      onClick={() => onClick?.()}
      className={classNames(
        "rounded-md bg-white px-4 py-6 border border-neutral-100",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Card;
