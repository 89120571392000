import { ReactFlowProvider } from "reactflow";
import WorkflowProvider from "./WorkflowContext";
import WorkflowStudio from "./studio";

const WorkFlow = () => {
  return (
    <ReactFlowProvider>
      <WorkflowProvider>
        <WorkflowStudio />
      </WorkflowProvider>
    </ReactFlowProvider>
  );
};

export default WorkFlow;
