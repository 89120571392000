import Badge from "@components/Badge";

const statusMap = {
  archive: "Archive",
  draft: "Draft",
  active: "Active",
  inreview: "In Review",
  inactive: "Inactive",
  readytodeploy: "Ready to Deploy",
};

const classNameMap = {
  draft: "bg-neutral-50 text-neutral-700 ",
  archive: "bg-error-50 text-error-600 ",
  inactive: "bg-neutral-50 text-neutral-600 ",
  active: "bg-success-100 text-success-700 ",
  inreview: "bg-warning-100 text-warning-700 ",
  readytodeploy: "bg-primary-100 text-primary-900",
};

export default function StatusChip({
  status,
}: {
  status:
    | "archive"
    | "draft"
    | "active"
    | "inreview"
    | "inactive"
    | "readytodeploy";
}) {
  return <Badge className={classNameMap[status]}>{statusMap[status]}</Badge>;
}
