import classNames from "clsx";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Shimmer from "src/components/Shimmer";
import { ReactComponent as CheckIcon } from "@assets/icons/check.svg";
import { ReactComponent as CloseIcon } from "@assets/icons/x-close.svg";
import { useQuery } from "@tanstack/react-query";
import { exploreSentinelQuery, useExploreSentinelNext } from "../queries";
import OverviewHeaderCard from "./OverviewHeaderCard";

type ExploreSentinel = {
  text: string;
  videoLink: string;
  infoText: string;
  ctaText: string;
  goTo: string;
};
interface StepsRowProps {
  el: ExploreSentinel;
  id: number;
  curStep: number;
}

interface StepsCardProps {
  currentStepId: number;
}

type ExploreOverviewCardProps = {
  setModelScreen: (arg: boolean) => void;
};

const ExploreOverviewCard = ({ setModelScreen }: ExploreOverviewCardProps) => {
  const { data: exploreSentinel, isFetching } = useQuery(
    exploreSentinelQuery()
  );
  const nextStep = useExploreSentinelNext();
  const curStep = Number(exploreSentinel?.data.data.stepNumber);
  if (curStep === 0 || curStep > 6) {
    return <></>;
  }
  if (!curStep) {
    return <></>;
  }
  const handleOnClose = () => {
    setModelScreen(false);
    nextStep.mutate({
      stepNumber: "0",
    });
  };
  return (
    <OverviewHeaderCard
      className="mt-8"
      title={
        <div className="flex items-center justify-between w-full">
          Explore Sentinel
          <div className="flex gap-4">
            <CloseIcon
              className="text-neutral-500 hover:text-neutral-black cursor-pointer w-4 h-4"
              onClick={() => handleOnClose()}
            />
          </div>
        </div>
      }
      content={
        isFetching ? (
          <div className="p-4 flex">
            <Shimmer w="100%" h="156px" />
          </div>
        ) : (
          <div className="p-4 flex flex-row">
            <div className="flex flex-col">
              <>
                {ExploreSentinelData.map((el: ExploreSentinel, id: number) => {
                  return (
                    <StepsRow key={id} id={id + 1} el={el} curStep={curStep} />
                  );
                })}
              </>
            </div>
            <StepsCard currentStepId={curStep - 1} />
          </div>
        )
      }
    />
  );
};

const StepsRow = ({ el, id, curStep }: StepsRowProps) => {
  const isComplete = id < curStep;
  const isSelected = curStep === id;
  return (
    <div
      className={classNames(
        "flex border h-9 w-80 rounded-md px-4 py-2.5 items-center mb-2",
        isComplete
          ? "bg-neutral-25 cursor-auto"
          : isSelected
          ? "bg-primary-50 border-primary-50"
          : "hover:bg-primary-50"
      )}
    >
      <div
        className={classNames(
          "border rounded-full w-5 h-5 justify-center items-center self-center flex mr-2",
          isComplete
            ? "border-neutral-200"
            : isSelected
            ? "border-primary-300"
            : ""
        )}
      >
        <span
          className={classNames(
            "font-b2-medium",
            isComplete
              ? "text-neutral-400"
              : isSelected
              ? "text-primary-900"
              : ""
          )}
        >
          {id}
        </span>
      </div>
      <p
        className={classNames(
          "font-b2-medium",
          isComplete
            ? "text-neutral-400   "
            : isSelected
            ? "text-primary-900"
            : ""
        )}
      >
        {el.text}
      </p>
      {isComplete ? (
        <CheckIcon className="ml-2 w-4 h-4 [&>path]:stroke-neutral-400" />
      ) : (
        ""
      )}
    </div>
  );
};

const StepsCard = ({ currentStepId }: StepsCardProps) => {
  const navigate = useNavigate();
  const nextStep = useExploreSentinelNext();
  const handleGoTo = () => {
    navigate(ExploreSentinelData[currentStepId].goTo);
    nextStep.mutate({ stepNumber: (currentStepId + 2).toString() });
  };
  const handleNextStep = () => {
    nextStep.mutate({ stepNumber: (currentStepId + 2).toString() });
  };

  return (
    <div className="flex items-center w-full h-[256px] ml-5 p-4 border border-neutral-50 bg-neutral-0 rounded-md">
      <div className="flex items-center w-[394px] justify-center border-2 rounded-md border-neutral-50">
        <video src={ExploreSentinelData[currentStepId]?.videoLink} controls>
          <source
            src={ExploreSentinelData[currentStepId]?.videoLink}
            type="video/mp4"
          />
        </video>
      </div>
      <div className="ml-8 w-[45%]">
        <p className="font-b2 text-neutral-black mb-4">
          {ExploreSentinelData[currentStepId]?.infoText}
        </p>
        <div className="flex flex-row">
          <Button className="mr-3" onClick={handleGoTo}>
            {ExploreSentinelData[currentStepId]?.ctaText}
          </Button>
          <Button
            disabled={currentStepId === 5}
            variant="outline"
            onClick={handleNextStep}
          >
            Skip
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExploreOverviewCard;

const ExploreSentinelData: ExploreSentinel[] = [
  {
    text: "Invite Members",
    videoLink:
      "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/onboarding-vids/FinBox+Sentinel+-+Invite+New+members.mp4",
    infoText:
      "Invite members easily by entering their details in the 'Invite Members' section and get started with seamless collaboration and growth",
    ctaText: "Invite member",
    goTo: "/settings/administration/members",
  },
  {
    text: "Create your first policy",
    videoLink:
      "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/onboarding-vids/FinBox+Sentinel+-+Policy+creation.mp4",
    infoText:
      "Create your first policy to configure, add different data sources, add your rules and compute your offer parameters",
    ctaText: "Create Policy",
    goTo: "/policies",
  },
  {
    text: "Publish a Policy",
    videoLink:
      "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/onboarding-vids/FinBox+Sentinel+-+Publish+a+policy.mp4",
    infoText: "Take your policy live and see how it performs in real life",
    ctaText: "Publish Policy",
    goTo: "/endpoints",
  },
  {
    text: "View Outcomes &  Outcome Details",
    videoLink:
      "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/onboarding-vids/FinBox+Sentinel+-+View+outcomes+and+outcome+details.mp4",
    infoText:
      " Published your policy already? Great!! Now lets see how the incoming applications are performing on the policy Outcomes and outcome details page",
    ctaText: "View Outcomes",
    goTo: "/reports",
  },
  {
    text: "Create Analytics Template",
    videoLink:
      "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/onboarding-vids/FinBox+Sentinel+-+create+analytics+dashboard.mp4",
    infoText:
      "Now that users are getting evaluated on Sentinel, lets create a dashboard to analyse the performance of a policy",
    ctaText: "Create Template",
    goTo: "/analytics",
  },
  {
    text: "Create a Monitor",
    videoLink:
      "https://finbox-cdn.s3.ap-south-1.amazonaws.com/sentinel/onboarding-vids/FinBox+Sentinel+-+Create+monitors.mp4",
    infoText:
      "Lastly, add alerts on a policy to monitor the functioning of the policy and get alerts on a real-time basis to track, mitigate and deploy any anomalies",
    ctaText: "Create Monitor",
    goTo: "/monitors",
  },
];
