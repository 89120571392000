import { PROGRAMS } from "@config";

export type DateRange = {
  startDate: string | null;
  endDate: string | null;
};

export type Stastistic = {
  time: string;
  amount_disbursed: number;
  user_count: number;
  evaluation_cant_count: number;
  evaluation_pass_count: number;
  evaluation_reject_count: number;
  evaluation_total_count: number;
};

export type Roles =
  | "view"
  | "run_simulation"
  | "create_policy"
  | "review_policy"
  | "activate_policy"
  | "all";

export enum PolicyStatus {
  ARCHIVE = "archive",
  DRAFT = "draft",
  ACTIVE = "active",
  INREVIEW = "inreview",
  READYTODEPLOY = "readytodeploy",
}

export type PermissionTypes =
  | "Data Sources - API Store"
  | "Monitors - Alert Logs"
  | "Analytics Dashboards"
  | "Data Sources - Custom API Sources"
  | "Monitors - Custom API Monitors"
  | "Endpoints"
  | "Monitors - Endpoint Monitors"
  | "Data Sources - Lookup Table"
  | "Administration - Members"
  | "Reports - Evaluation Details"
  | "Reports - Outcomes Overview"
  | "Home"
  | "Policies - List View"
  | "Settings - Programs"
  | "Reports - All Reports"
  | "Administration - Roles"
  | "Policy - Simulations"
  | "default";

export type FinBoxResponse<T = string> = {
  data: T;
  error: string;
  status: boolean;
};

export type Program = (typeof PROGRAMS)[0];

export type ProgramTemplate = {
  id: string;
  program: string;
  label: string;
  description: string;
  outcome: {
    title: string;
    identifier: string;
    description: string;
  }[];
  createdBy: string;
  createdAt: string;
  journeys: string[];
};

export type ProgramTemplateWithOutcome = {
  id: string;
  program: string;
  description: string;
  clientId: string;
  outcome: Array<{
    description: string;
    identifier: string;
    title: string;
  }>;
  label: string;
  journeyCount: number;
};

declare const __brand: unique symbol;
export type Branded<T, K> = T & { [__brand]: K };

export type NotificationType =
  | "wf_status"
  | "report"
  | "simulation"
  | "monitor";

type CommonNotificationProps = {
  id: number;
  type: NotificationType;
  icon: string;
  isRead: boolean;
  dashboardUserName: string;
  createdAt: string;
  updatedAt: string;
};

export type PolicyNotification = CommonNotificationProps & {
  type: "wf_status";
  metadata: {
    id: string;
    name: string;
    type: "workflow" | "policy";
    newStatus: string;
    oldStatus: string;
    updatedBy: string;
  };
};

export type ReportNotification = CommonNotificationProps & {
  type: "report";
  metadata: {
    id: string;
    type: string;
    status: "delivered" | "failed";
    email: string;
    updatedBy: string;
  };
};

export type SimulationNotification = CommonNotificationProps & {
  type: "simulation";
  metadata: {
    id: string;
    status: "done" | "failed";
    updatedBy: string;
    name: string;
    type: string;
  };
};

export type MonitorNotification = CommonNotificationProps & {
  type: "monitor";
  metadata: {
    id: string;
    monitorName: string;
    sourceName: string;
    status: "warning" | "error";
    description: string;
  };
};

export type NotificationList =
  | PolicyNotification
  | ReportNotification
  | SimulationNotification
  | MonitorNotification;
