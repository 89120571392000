import { Fragment, useState } from "react";
import Button from "src/components/Button";
import NewHeader from "src/components/NewHeader";
import ProtectedComponent from "src/components/ProtectedComponent";
import Searchbar from "src/components/Searchbar";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import { useSearchParamState } from "src/hooks/useSearchParamState";
import { PermissionTypes } from "src/types";
import { DATA_SOURCE_PAGE_LIMIT } from "@config";
import useBreadcrumbs from "@context/BreadcrumbsContext";
import { Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import LookupModel from "../DataSourceModel/LookupModel";
import LookupTable from "../LookupTable";
import { lookupTableListDsQuery } from "../queries";
import { ModelScreen } from "../types";

export default function MasterLookupTable() {
  const [modelScreen, setModelScreen] = useState<ModelScreen>("");
  const { setSearchParams, getSearchParam } = useSearchParamState({
    page: "1",
    searchQuery: "",
  });
  const searchQuery = getSearchParam("searchQuery");
  const page = getSearchParam("page");
  useBreadcrumbs([
    {
      name: "Lookup Data Sources",
      link: "#",
    },
  ]);
  const { data, isPending } = useQuery(
    lookupTableListDsQuery({
      pageNumber: parseInt(page),
      pageSize: DATA_SOURCE_PAGE_LIMIT,
      name: searchQuery,
      isRefetch: true,
    })
  );

  const [modelData, setModelData] = useState(undefined);
  const [openFromModel, setOpenFromModel] = useState(false);

  const handleOpenModel = (row: any) => {
    setOpenFromModel(true);
    setModelData(row);
    setModelScreen("lookup");
  };
  const handleAddSource = () => {
    setOpenFromModel(false);
    setModelScreen("lookup");
    setModelData(undefined);
  };
  return (
    <>
      <NewHeader
        buttons={[
          <ProtectedComponent
            type={PERMISSIONS_TYPE.dataSourcesLookup as PermissionTypes}
            action="create"
          >
            <Button key="add" size="sm" type="button" onClick={handleAddSource}>
              Add Lookup
            </Button>
          </ProtectedComponent>,
        ]}
        filters={
          <Searchbar
            onSearch={(e) => {
              setSearchParams("page", "1");
              setSearchParams("searchQuery", e);
            }}
            hint="Search Lookup"
            defaultValue={searchQuery || ""}
          />
        }
      >
        <NewHeader.Title>Lookup Table</NewHeader.Title>
      </NewHeader>
      <Transition show={modelScreen !== ""}>
        <Transition.Child
          enter="transform transition ease-in-out duration-300 sm:duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transform transition ease-in-out duration-200 sm:duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as={Fragment}
        >
          <div className="transition-opacity h-screen w-screen backdrop-brightness-100 bg-[#eaeaea] bg-opacity-80 fixed left-0 bottom-0 right-0 top-0 box-border rounded-md shadow-lg ring-1 ring-gray-100 z-[103]"></div>
        </Transition.Child>
        <Transition.Child
          enter="transform transition ease-in-out duration-300 sm:duration-500"
          enterFrom="translate-x-full opacity-90"
          enterTo="translate-x-0 opacity-100"
          leave="transform transition ease-in-out duration-200 sm:duration-500"
          leaveFrom="translate-x-0 "
          leaveTo="translate-x-full "
          as={Fragment}
        >
          <div className="h-full overflow-auto w-[640px] absolute right-0 top-0 z-[104]">
            <LookupModel
              modelData={modelData}
              close={() => {
                setModelScreen("");
              }}
              openFromModel={openFromModel}
            />
          </div>
        </Transition.Child>
      </Transition>
      <LookupTable
        customTableData={data?.data.lookups || []}
        isLoading={isPending}
        onPageChange={(page) => {
          setSearchParams("page", page.toString());
        }}
        page={parseInt(page)}
        totalCount={data?.data.lookups.length || 0}
        handleOpenModel={handleOpenModel}
      />
    </>
  );
}
