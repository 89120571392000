
import axios from "@axios";
import { useQuery } from "@tanstack/react-query";
import { FinBoxResponse } from "@types";

export function useGetTemplate({ policy_id }: { policy_id: string }, enabled = true) {
    return useQuery({
      queryKey: ["sampleCSV", policy_id],
      queryFn: async () => {
        return axios.post<FinBoxResponse<{ url: string }>>("simulation/simulationDump", {
          policy_id,
          sample_csv: true,
        });
      },
      select: (data) => data.data.data.url,
      enabled,
    });
  }
  
  export function useGetWorkflowTemplate(workflowId: string, enabled: boolean = true) {
    return useQuery({
      queryKey: ["sampleWorkflowCSV", workflowId],
      queryFn: async () => {
        return axios.v2.get<FinBoxResponse<string>>(
          `simulationWorkflowCustomTemplate/${workflowId}`
        );
      },
      select: (data) => data.data.data,
      enabled,
    });
  }