import clsx from "clsx";
import { ReactNode, useState } from "react";
import { useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import { useParams } from "react-router-dom";
import closeIcon from "@assets/icons/chevron-selector-vertical-close.svg";
import openIcon from "@assets/icons/chevron-selector-vertical-open.svg";
import {
  denormaliseInputName,
  getShouldDenormalise,
  normaliseInputName,
} from "@screens/workflow/components/Debugger/utils";
import { useGetPredictors } from "@screens/workflow/queries";
import Button from "@components/Button";
import Input from "@components/Input";
import Loader from "@components/Loader";
import QueryWrapper from "@components/QueryWrapper";
import { Tooltip } from "@finbox-in/finblocks";

type RightPanelProps = {
  label: string;
  expressions: string[];
  onTest: (
    variables: Record<string, Record<string, Record<string, string | null>>>
  ) => void;
  testResult: ReactNode;
  ignoreNamesList?: string[];
  finalDecisionResult?: ReactNode;
  nodeId: string;
  includeSameNodeModels?: boolean;
};

const RightPanel = ({
  expressions,
  ignoreNamesList,
  onTest,
  testResult,
  finalDecisionResult,
  nodeId,
  includeSameNodeModels = false,
}: RightPanelProps) => {
  const form = useForm<
    Record<string, Record<string, Record<string, string | null>>>
  >({
    shouldUnregister: true,
  });
  const predictorsQuery = useGetPredictors(expressions, includeSameNodeModels);
  const [isOutputExpand, setIsOutputExpand] = useState(false);

  const { workflowId } = useParams();

  useFormPersist(`workflow:${workflowId}:${nodeId}`, {
    watch: form.watch,
    setValue: form.setValue,
    storage: window.localStorage, // default window.sessionStorage
  });

  const _onTest = form.handleSubmit((data) => {
    if (data.policies) {
      Object.keys(data.policies).forEach((key) => {
        const shouldReplace = getShouldDenormalise(key);
        if (shouldReplace) {
          const newKey = denormaliseInputName(key);
          data.policies[newKey] = data.policies[key];
          delete data.policies[key];
        }
      });
    }
    if (data.workflows) {
      Object.keys(data.workflows).forEach((key) => {
        const shouldReplace = getShouldDenormalise(key);
        if (shouldReplace) {
          const newKey = denormaliseInputName(key);
          data.workflows[newKey] = data.workflows[key];
          delete data.workflows[key];
        }
      });
    }

    for (let source in data) {
      if (source === "policies" || source === "workflows") continue;
      for (let predName in data[source]) {
        const shouldReplace = getShouldDenormalise(predName);

        const normalisedName = denormaliseInputName(predName);
        if (shouldReplace) {
          data[source][normalisedName] = data[source][predName];
          delete data[source][predName];
        }
      }
    }

    Object.keys(data).forEach((key) => {
      if (key === "workflows" || key === "policies") {
        Object.keys(data[key]).forEach((wfName) => {
          Object.keys(data[key][wfName]).forEach((predName) => {
            if (data[key][wfName][predName] === "")
              data[key][wfName][predName] = null;
          });
        });
      }
    });

    onTest(data);
  });

  return (
    <>
      <div
        className={clsx("overflow-scroll", isOutputExpand ? "h-1/5" : "h-3/5")}
      >
        <div className="font-b1-medium mb-4">Parameter Values</div>
        <div className="mb-6 flex flex-col">
          <QueryWrapper
            query={predictorsQuery}
            loader={
              <div className="mx-auto pb-4">
                <Loader size="xs" />
              </div>
            }
          >
            {(data) => {
              const predictors = Object.keys(data).reduce((prev, key) => {
                if (["policies", "workflows"].includes(key)) return prev;
                const uniqPreds = Array.from(new Set(data[key]));
                prev.push(
                  ...uniqPreds
                    .filter((p) => p.length)
                    .map((p) => `${key}.${normaliseInputName(p)}`)
                );
                if (data[key].length === 0 && !ignoreNamesList?.includes(key))
                  prev.push(key);
                return prev;
              }, [] as string[]);

              if (data.policies) {
                predictors.push(
                  ...Object.keys(data.policies)
                    .map((key) =>
                      data.policies![key].map(
                        (p) =>
                          `policies.${key.replace(
                            /\./g,
                            "@"
                          )}.${normaliseInputName(p)}`
                      )
                    )
                    .flat()
                );
              }

              if (data.workflows) {
                predictors.push(
                  ...Object.keys(data.workflows)
                    .map((key) =>
                      data.workflows![key].map(
                        (p) =>
                          `workflows.${key.replace(
                            /\./g,
                            "@"
                          )}.${normaliseInputName(p)}`
                      )
                    )
                    .flat()
                );
              }

              predictors.sort((a, b) => a.localeCompare(b));

              return (
                <>
                  {predictors.length === 0 ? (
                    <div className="relative py-2 flex flex-col gap-2 max-h-60 text-neutral-500 font-b2">
                      You don't have any predictors, but you can still test the
                      node.
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 gap-2">
                      {predictors.map((item) => {
                        let name = denormaliseInputName(item);
                        if (item.startsWith("policies."))
                          name = name.substring(9);
                        if (item.startsWith("workflows."))
                          name = name.substring(10);

                        return (
                          <div key={name}>
                            <Input
                              label={
                                <div className="w-[210px] truncate">
                                  <Tooltip
                                    className="w-[90%] truncate"
                                    toolTipContent={name}
                                  >
                                    <div>{name}</div>
                                  </Tooltip>
                                </div>
                              }
                              autoComplete="off"
                              placeholder="Enter value"
                              className="w-[203px]"
                              {...form.register(item)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              );
            }}
          </QueryWrapper>
        </div>
      </div>

      <div
        className={clsx(
          "absolute !bottom-0 w-full left-0 bg-white duration-300",
          isOutputExpand ? "h-4/5" : "h-2/5"
        )}
      >
        <div>
          <div className="flex items-center justify-between right-0 border-t bg-neutral-0 border-b py-0.5 border-neutral-100 pl-4 pr-1">
            <span className="font-b2-medium text-neutral-black">Output</span>
            <div className="flex items-center">
              <img
                src={isOutputExpand ? closeIcon : openIcon}
                alt=""
                className="hover:bg-neutral-25 cursor-pointer rounded-md p-1 w-6 h-6 "
                onClick={() => {
                  setIsOutputExpand(!isOutputExpand);
                }}
              />
              <Button
                variant="link"
                onClick={() => _onTest()}
                className="text-primary-900 hover:enabled:bg-neutral-25 h-6 mr-2"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.33691 3.32642C3.33691 2.67898 3.33691 2.35526 3.47191 2.17681C3.58951 2.02135 3.76926 1.92515 3.96385 1.91353C4.1872 1.9002 4.45656 2.07977 4.99526 2.4389L12.0056 7.11248C12.4508 7.40923 12.6733 7.55761 12.7509 7.74462C12.8187 7.90813 12.8187 8.09188 12.7509 8.25538C12.6733 8.4424 12.4508 8.59077 12.0056 8.88752L4.99526 13.5611C4.45656 13.9202 4.1872 14.0998 3.96385 14.0865C3.76926 14.0749 3.58951 13.9787 3.47191 13.8232C3.33691 13.6447 3.33691 13.321 3.33691 12.6736V3.32642Z"
                    fill="#194CFF"
                    fillOpacity="0.25"
                    stroke="#194CFF"
                    strokeWidth="1.33"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Run Test
              </Button>
            </div>
          </div>
          {finalDecisionResult}
        </div>
        <div className="px-4 py-4 overflow-scroll max-h-[calc(100%-100px)]">
          {testResult}
        </div>
      </div>
    </>
  );
};
export default RightPanel;
