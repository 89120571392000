import checkSign from "@assets/icons/thinTick.svg";
import { Combobox } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import classNames from "clsx";
import { useState } from "react";
import { debounce } from "src/utils/utils";
import { useDashboardUsers } from "../queries";

export type UserType = {
  dashboard_user_id: string;
  email: string;
  client_id: string;
  name: string;
  role: string;
}[];

function NotifySelectCombobox({
  selectedUser,
  setSelectedUser,
}: {
  selectedUser?: UserType;
  setSelectedUser: setStateType<UserType | undefined>;
}) {
  const [searchedName, setSearchedName] = useState("");
  const dashboardUsers = useDashboardUsers({ name: searchedName, role: "" });

  return (
    <Combobox
      as="div"
      className="h-7 w-full"
      value={selectedUser}
      onChange={(e) => setSelectedUser(e)}
      multiple
    >
      <div className="relative mt-1">
        <Combobox.Button className="flex items-center rounded-r-md focus:outline-none w-full">
          <Combobox.Input
            placeholder="Search"
            className="w-full rounded-md border border-gray-300 bg-white h-8 pl-3 pr-8 focus:border-primary-700 focus:outline-none focus:ring-1 focus:ring-primary-700 font-b1"
            onChange={debounce((e) => {
              setSearchedName(e.target.value);
            })}
          />
          <ChevronUpDownIcon
            className="absolute right-3 h-5 w-5 text-neutral-500"
            aria-hidden="true"
          />
        </Combobox.Button>
        <Combobox.Options className="absolute z-[21] mt-1 max-h-[180px] w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {(dashboardUsers.data?.data?.data?.dashboard_users || []).map(
            (op) => (
              <Combobox.Option
                key={op.email}
                value={op}
                className={({ active, selected }) =>
                  classNames(
                    "relative cursor-pointer select-none py-2 pl-4 pr-8",
                    selected
                      ? "bg-neutral-0"
                      : active
                      ? "bg-neutral-0"
                      : "bg-white"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      <span className="font-b1-medium text-neutral-900 mr-2">
                        {op.name}
                      </span>
                      <span className="font-b1 text-neutral-500">
                        {op.email}
                      </span>
                    </div>
                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-0 left-auto flex items-center pl-1.5 pr-2",
                          active ? "text-white" : "text-primary-700"
                        )}
                      >
                        <img
                          src={checkSign}
                          alt="check sign"
                          className="h-5 w-5"
                        />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            )
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

export default NotifySelectCombobox;
