import classNames from "clsx";
import { format, parseISO } from "date-fns";
import { Fragment, useState } from "react";
import {
  Bar,
  BarChart,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useGetOutcomes } from "src/apis";
import {
  DEFAULT_COLOR,
  DEFAULT_TRAFFIC_COLOR,
} from "src/screens/analytics/templates/config";
import chevronDown from "@assets/icons/chevron-down.svg";
import OverviewHeaderCard from "@screens/overview/components/OverviewHeaderCard";
import { useEntityEvalutationStats } from "@screens/overview/queries";
import EmptyState from "@components/EmptyState";
import Loader from "@components/Loader";
import { Popover, Transition } from "@headlessui/react";
import { DateRange } from "@types";
import { shortNumber, titleCase } from "@utils/utils";

const calcOutcomePercentage = (
  item: {
    timestamp: string;
    metrics: Record<string, number>;
    totalCount: number | null;
  },
  outcome: string
) => {
  if (!item.totalCount || item.totalCount === 0) return 0;
  let nr: number;
  if (["pass", "approved"].includes(outcome))
    nr = (item.metrics.pass ?? 0) + (item.metrics.approved ?? 0);
  else if (["reject", "rejected"].includes(outcome))
    nr = (item.metrics.reject ?? 0) + (item.metrics.rejected ?? 0);
  else nr = item.metrics[outcome] ?? 0;

  return ((nr * 100) / item.totalCount).toFixed(2);
};

interface OverviewChartProps {
  isGroupedByHour: boolean;
  dateRange: DateRange;
  programID: string;
}

const OverviewChart = ({
  dateRange,
  programID,
  isGroupedByHour,
}: OverviewChartProps) => {
  const outcomesListQuery = useGetOutcomes();
  const [outcome, setOutcome] = useState("approved");

  const statistics = useEntityEvalutationStats({
    dateRange,
    programID,
  });

  let outcomesList = outcomesListQuery.data?.data.data || [];
  if (outcomesList.includes("pass") && outcomesList.includes("approved"))
    outcomesList = outcomesList.filter((p) => p !== "pass");
  if (outcomesList.includes("reject") && outcomesList.includes("rejected"))
    outcomesList = outcomesList.filter((p) => p !== "reject");

  if (statistics.isPending)
    return (
      <div className="relative flex h-full w-full items-center justify-center bg-gray-200 bg-opacity-30">
        <Loader size="small" type="block" />
      </div>
    );

  const dataMax = statistics.data?.data.data.reduce(
    (p, c) => Math.max(p, c.totalCount),
    0
  );

  const chartWidth =
    Math.max(Math.min(1128, window.innerWidth * 0.9), 1000) - 24;

  return (
    <OverviewHeaderCard
      className="min-h-[325px] max-h-[325px] h-[325px] border-neutral-100 border"
      title={
        <div className="flex items-center justify-between w-full">
          Traffic & Approval
          <div className="flex gap-4">
            <span className="flex items-center gap-1 text-xs text-indigo-400">
              <span className="rounded-sm h-3 w-3 bg-neutral-300" />
              Traffic
            </span>
            <Popover className="relative">
              {({ open }) => {
                return (
                  <>
                    <Popover.Button
                      className={classNames(
                        "focus:outline-0 focus:ring-0 text-neutral-black rounded-md transition duration-150 px-2.5 py-1 inline-flex items-center h-7 gap-2 font-b2-medium border",
                        outcome
                          ? "border-neutral-100 bg-white"
                          : " bg-white border-neutral-100 hover:bg-neutral-0 focus:bg-neutral-0"
                      )}
                    >
                      <span className="w-2.5 h-2.5 rounded-sm bg-primary-800" />
                      <span>{titleCase(outcome || "approved")}</span>
                      <img
                        className={open ? "rotate-180" : ""}
                        alt=""
                        src={chevronDown}
                      />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute flex-col bg-white rounded-md border border-neutral-100 font-b2 left-0 top-full mt-4 flex w-screen !-translate-y-2.5 max-w-min z-[200] shadow-1">
                        {({ close }) => {
                          return (
                            <>
                              {outcomesList.map((item) => {
                                return (
                                  <span
                                    onClick={() => {
                                      setOutcome(item);
                                      close();
                                    }}
                                    key={item}
                                    className="flex items-center gap-2 p-2 first:rounded-t-md cursor-pointer last:rounded-b-md hover:bg-neutral-25"
                                  >
                                    <input
                                      checked={outcome === item}
                                      type="radio"
                                      className="w-3 h-3 border-neutral-100"
                                    />
                                    {titleCase(item)}
                                  </span>
                                );
                              })}
                            </>
                          );
                        }}
                      </Popover.Panel>
                    </Transition>
                  </>
                );
              }}
            </Popover>
          </div>
        </div>
      }
      content={
        <div className="relative flex w-full items-center justify-center min-h-[300px] h-[300px]">
          {statistics.isSuccess && (
            <div className="relative w-full flex flex-col">
              <LineChart
                className={"mx-auto"}
                width={chartWidth}
                height={180}
                data={statistics.data.data.data || []}
                margin={{
                  left: -40,
                  right: 60,
                  top: 5,
                  bottom: -25,
                }}
              >
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  allowDataOverflow
                  domain={[0, 100]}
                  tick={false}
                  type="number"
                  yAxisId="1"
                />
                <ReferenceLine
                  y={25}
                  yAxisId="1"
                  stroke="#ECECED"
                  strokeDasharray="4 4"
                />
                <ReferenceLine
                  y={50}
                  yAxisId="1"
                  stroke="#ECECED"
                  strokeDasharray="4 4"
                />
                <ReferenceLine
                  y={75}
                  yAxisId="1"
                  stroke="#ECECED"
                  strokeDasharray="4 4"
                />
                <ReferenceLine
                  yAxisId="1"
                  y={100}
                  stroke="#ECECED"
                  strokeDasharray="4 4"
                  label={{
                    position: "insideRight",
                    value: calcOutcomePercentage(
                      statistics.data.data.data.at(-1)!,
                      outcome
                    )
                      .toString()
                      .match(/^9\d/)
                      ? ""
                      : "100%",
                    fontSize: 12,
                    className: "translate-x-[35px]",
                  }}
                />
                {statistics.data.data.data?.at(-1) && (
                  <ReferenceLine
                    yAxisId="1"
                    y={calcOutcomePercentage(
                      statistics.data.data.data.at(-1)!,
                      outcome
                    )}
                    stroke="#F0F0F1"
                    strokeDasharray="0 4"
                    label={{
                      position: "insideRight",
                      value:
                        calcOutcomePercentage(
                          statistics.data.data.data.at(-1)!,
                          outcome
                        ) + "%",
                      fontSize: 12,
                      className: "translate-x-[50px] fill-primary-900",
                      fontWeight: 500,
                    }}
                  />
                )}
                <Tooltip
                  cursor={{
                    stroke: "#E5E7EB",
                    strokeWidth: 1,
                    strokeDasharray: "3 3",
                  }}
                  content={(props) => {
                    if (!props.label || !props.payload || !props.active) return;

                    return (
                      <div className="flex flex-col items-start justify-start gap-1 rounded-sm bg-white px-4 py-2 font-b2 !text-neutral-black shadow !font-normal">
                        <span>
                          {format(
                            parseISO(props.label),
                            isGroupedByHour
                              ? String.raw`dd-MMM ''yy hh:mm a`
                              : String.raw`dd-MMM ''yy`
                          )}
                        </span>
                        <span
                          className="flex gap-1 items-center"
                          key={props.payload[0].payload.totalCount}
                        >
                          <span className="inline-block w-2.5 h-2.5 rounded-sm bg-neutral-300" />
                          <span className="text-neutral-500">Traffic</span>
                          {props.payload[0].payload.totalCount ?? 0}
                        </span>
                        <span className="flex gap-1 items-center">
                          <span className="inline-block w-2.5 h-2.5 rounded-sm bg-primary-900" />
                          <span className="mr-1 text-neutral-500">
                            {titleCase(outcome || "approved")}
                          </span>
                          {props.payload[0].value ?? 0}%
                        </span>
                      </div>
                    );
                  }}
                />
                <XAxis
                  dataKey="timestamp"
                  interval="preserveStartEnd"
                  tickSize={0}
                  tickCount={0}
                  tick={false}
                  stroke="#E3E4E5"
                  strokeWidth="1"
                  tickLine={false}
                />
                <Line
                  yAxisId="1"
                  stroke={DEFAULT_COLOR}
                  type="monotone"
                  dataKey={(item: {
                    timestamp: string;
                    metrics: Record<string, number>;
                    totalCount: number;
                  }) => {
                    return calcOutcomePercentage(item, outcome);
                  }}
                  dot={false}
                  fill="none"
                  strokeWidth="1.5"
                />
              </LineChart>
              <BarChart
                data={statistics.data.data.data || []}
                width={chartWidth}
                height={60}
                margin={{
                  left: -30,
                  right: -10,
                  bottom: -5,
                }}
              >
                <Bar
                  barSize={5}
                  dataKey="totalCount"
                  fill={DEFAULT_TRAFFIC_COLOR}
                />
                <XAxis
                  dataKey="timestamp"
                  interval="preserveStartEnd"
                  tickLine={false}
                  ticks={[
                    statistics.data.data.data[0].timestamp,
                    statistics.data.data.data.at(-1)?.timestamp!,
                  ]}
                  axisLine={{ stroke: "#b8b9ba", strokeWidth: 1 }}
                  tick={({ x, y, payload, index }) => (
                    <g transform={`translate(${x},${y})`}>
                      <text
                        x={10}
                        y={10}
                        dy={4}
                        dx={index === 0 ? -70 : 40}
                        textAnchor={index === 0 ? "start" : "end"}
                        fill="#8D8D8E"
                        fontSize="12px"
                      >
                        {format(
                          parseISO(payload.value),
                          isGroupedByHour ? "dd-MMM h a" : "dd-MMM"
                        )}
                      </text>
                    </g>
                  )}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  orientation="right"
                  tick={({ x, y, payload }) => (
                    <g transform={`translate(${x},${y})`}>
                      <text
                        x={5}
                        y={0}
                        dy={4}
                        textAnchor="end"
                        className="fill-gray-500"
                        fontSize="12px"
                      >
                        {shortNumber(payload.value)}
                      </text>
                    </g>
                  )}
                  ticks={[dataMax || 0]}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  allowDataOverflow
                  tick={({ x, y, payload }) => (
                    <g transform={`translate(${x},${y})`}>
                      <text
                        x={5}
                        y={0}
                        dy={4}
                        textAnchor="end"
                        className="fill-gray-500"
                        fontSize="12px"
                      >
                        {payload.value}%
                      </text>
                    </g>
                  )}
                  domain={[0, 100]}
                  ticks={[100]}
                  type="number"
                  yAxisId="1"
                />
                {statistics.data.data.data?.at(-1) && (
                  <ReferenceLine
                    y={statistics.data.data.data.at(-1)!.totalCount}
                    stroke="#F0F0F1"
                    strokeDasharray="0 4"
                    label={{
                      position: "insideRight",
                      value: statistics.data.data.data.at(-1)!.totalCount,
                      fontSize: 12,
                      className: "translate-x-[36px] fill-neutral-500",
                      fontWeight: 500,
                    }}
                  />
                )}
                <Tooltip
                  cursor={false}
                  content={(props) => {
                    if (!props.label || !props.active || !props.payload) return;
                    return (
                      <div className="flex flex-col items-start justify-start gap-1 rounded-sm bg-white px-4 py-2 text-xs text-finbox-dark shadow">
                        <span>
                          {format(
                            parseISO(props.label),
                            isGroupedByHour
                              ? String.raw`dd-MMM ''yy hh:mm a`
                              : String.raw`dd-MMM ''yy`
                          )}
                        </span>
                        <span
                          className="flex gap-1 items-center"
                          key={props.payload[0].dataKey}
                        >
                          {" "}
                          <span className="inline-block w-2.5 h-2.5 rounded-sm bg-neutral-300" />
                          <span className="text-neutral-500">Traffic</span>
                          {props.payload[0].payload.totalCount ?? 0}
                        </span>
                      </div>
                    );
                  }}
                />
              </BarChart>
            </div>
          )}
          {!statistics && (
            <EmptyState>
              No data was found for the applied filters. Try removing some
              filters.
            </EmptyState>
          )}
        </div>
      }
    />
  );
};

export default OverviewChart;
