import plusIcon from "@assets/icons/workflow/plus-add-condition.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import closeIcon from "@assets/icons/x-close.svg";
import Button from "@components/Button";
import Label from "@components/Label";
import QueryWrapper from "@components/QueryWrapper";
import Shimmer from "@components/Shimmer";
import useOnClickOutside from "@hooks/useOnClickOutside";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import { NodeName } from "@screens/workflow/studio/components/NodeName";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { getUpdateDecisionTableNode } from "@screens/workflow/studio/utils";
import { DecisionTableId } from "@screens/workflow/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import logger from "@utils/Logger";
import { generateAndDownloadFile, notify } from "@utils/utils";
import clsx from "clsx";
import { parse } from "csv-parse/browser/esm/sync";
import {
  ChangeEvent,
  Fragment,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";
import CustomEditor from "@components/Editor/CustomEditor";
import { MatrixItem } from "./TableCell";
import MatrixHeader from "./TableHeader";
import { getMatrixQuery, useUpdateMatrix } from "./queries";
import { FormType } from "./types";
import {
  transformColumnArrayToRequest,
  transformResponseToColumnArray,
} from "./utils";

type Props = {
  nodeId?: string;
  isSideBar?: boolean;
};

export function DecisionTableEditor(props?: Props) {
  const { workflowId, matrixId = props?.nodeId } = useParams<{
    workflowId: string;
    matrixId: string;
  }>();
  const [currentlyEditing, setCurrentlyEditing] = useState<
    [number, number] | null
  >(null);

  const ref = useRef<HTMLDivElement>(null);

  const { isWorkflowEditable, errors } = useWorkflowContext();
  const { getNode } = useReactFlow();

  const matrixName = getNode(matrixId!)?.data?.label ?? "";

  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflowId, matrixId);

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => document.body.classList.remove("overflow-hidden");
  }, []);

  useOnClickOutside(ref, () => setCurrentlyEditing(null));

  const matrixData = useQuery(getMatrixQuery(workflowId!, matrixId!));
  const { control, setValue, formState, handleSubmit } = useForm<FormType>({
    values: matrixData.isSuccess
      ? transformResponseToColumnArray(matrixData.data.data.data)
      : undefined,
    defaultValues: matrixData.isSuccess
      ? transformResponseToColumnArray(matrixData.data.data.data)
      : undefined,
    shouldUnregister: true,
  });

  const rowsFieldArray = useFieldArray({
    control: control,
    name: "rows",
  });

  const colsFieldArray = useFieldArray({
    control: control,
    name: "headers",
  });

  const { updateWorkflow } = useWorkflowContext();

  function checkIsEditing(row: number, col: number) {
    if (!currentlyEditing) return false;
    return row === currentlyEditing[0] && col === currentlyEditing[1];
  }

  const nodeErrors: [string, string][] =
    errors[matrixId as DecisionTableId]?.errors
      ?.split(",")
      .map((i) => ["", i]) ?? [];

  const navigate = useNavigate();
  const updateMutation = useUpdateMatrix();

  const queryClient = useQueryClient();

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const listener = (e: globalThis.KeyboardEvent) => {
      if (e.key !== "Tab") return;
      if (!currentlyEditing) return setCurrentlyEditing([0, 0]);

      const [row, col] = currentlyEditing;
      const headerCount = matrixData.data?.data.data.headers.length ?? 0;

      if (row === -1) {
        if (col === headerCount - 1) return setCurrentlyEditing([0, 0]);
      }

      if (col === headerCount) {
        return setCurrentlyEditing([row + 1, 0]);
      }

      setCurrentlyEditing([row, col + 1]);
    };
    document.addEventListener("keypress", listener);
    return () => document.removeEventListener("keypress", listener);
  }, [currentlyEditing, matrixData.data?.data.data.headers.length]);

  const importTable = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        const result = reader.result as string;

        let rows: string[][] = [];
        try {
          rows = parse(result);
        } catch (err) {
          notify({ title: "Failed to import", text: "Invalid CSV file" });
          logger.error(err);
          return;
        }

        if (rows.length === 0) return;

        const headers = rows.shift()!;

        if (headers.pop()!.toLowerCase() !== "output") {
          notify({ title: "Invalid file", text: "output header is missing" });
          return;
        }
        // Note: adding type any because not using this component for now will handle it id require
        const body: any= [];
        for (let row of rows) {
          const items = row;
          const output = items.pop()!;

          const item = {
            column: items.reduce((prev, curr, index) => {
              prev[headers[index]] = curr;
              return prev;
            }, {} as Record<string, string>),
            output,
          };
          body.push(item);
        }

        queryClient.setQueryData(
          getMatrixQuery(workflowId!, matrixId!).queryKey,
          (prev) => {
            if (!prev) return;

            return {
              ...prev,
              data: {
                ...prev.data,
                data: {
                  ...prev.data.data,
                  rows: body,
                  headers,
                },
              },
            };
          }
        );

        setValue(
          "headers",
          headers.map((name) => ({ name }))
        );
        setValue(
          "rows",
          transformResponseToColumnArray({ default: "", headers, rows: body })
            .rows
        );

        if (fileInputRef.current) fileInputRef.current.value = "";
      },
      false
    );
    reader.readAsText(file);
  };

  const exportTable = () => {
    if (!matrixData.data) return;

    let fileContents = "";

    fileContents += `${matrixData.data.data.data.headers.join(",")},output
`;

    for (let row of matrixData.data.data.data.rows) {
      fileContents += `${matrixData.data.data.data.headers
        .map((header) => row.column[header])
        .join(",")},${row.output}
`;
    }
    generateAndDownloadFile(fileContents, `${matrixName}.csv`);
  };

  const keyPressHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!currentlyEditing || !matrixData.isSuccess) return;
    const [row, col] = currentlyEditing;
    const key = e.key;
    const headerCount = matrixData.data.data.data.headers.length;

    // const selectionStart = (e.target as HTMLInputElement).selectionStart;
    // const selectionEnd = (e.target as HTMLInputElement).selectionEnd;
    // const isStart = selectionStart === 0 && selectionEnd === selectionStart;
    // const isEnd =
    //   selectionStart === (e.target as HTMLInputElement).value.length &&
    //   selectionEnd === selectionStart;

    switch (key) {
      case "ArrowUp":
        // Output header is not editable. Handle that special case
        if (row === 0 && col === headerCount) break;

        setCurrentlyEditing([Math.max(-1, row - 1), col]);
        break;
      case "ArrowDown":
        setCurrentlyEditing([
          Math.min(matrixData.data.data.data.rows.length - 1, row + 1),
          col,
        ]);
        break;
      // case "Tab":
      //   e.preventDefault();
      // case "ArrowRight":
      //   if (!isEnd) break;
      // Output header is not editable. Handle that special case
      // if (row === -1 && col === headerCount - 1) break;
      // setCurrentlyEditing([row, Math.min(col + 1, headerCount)]);
      // break;
      // case "ArrowLeft":
      //   if (!isStart) break;
      //   setCurrentlyEditing([row, Math.max(col - 1, 0)]);
      //   break;
    }
  };

  const updateMatrix = handleSubmit((data) => {
    updateMutation.mutate({
      workflowId: workflowId!,
      matrixId: matrixId!,
      data: transformColumnArrayToRequest(data),
    });
  });

  const addRow = () => {
    rowsFieldArray.append({
      column: new Array(colsFieldArray.fields.length).fill(""),
      output: "",
    });
  };

  const addColumn = () => {
    colsFieldArray.append({ name: "" });
  };

  const getDeleteColumn = (index: number) => {
    return () => {
      colsFieldArray.remove(index);
    };
  };

  const getDeleteRow = (index: number) => {
    return () => {
      rowsFieldArray.remove(index);
    };
  };

  const updateNodeName = async (name: string) => {
    const e = getUpdateDecisionTableNode(name, matrixId!, "");
    await updateWorkflow(e).then(() =>
      queryClient.invalidateQueries(getWorkflowKeywordsQuery())
    );
  };

  return (
    <div
      className={clsx(
        !props?.isSideBar
          ? "fixed inset-0 bg-white px-5 py-3 z-10"
          : "fixed inset-0 left-[712px] border-l border-neutral-100 top-10 bg-white px-5 py-3 z-10"
      )}
    >
      <div className="font-p3-medium flex justify-between items-center">
        <NodeName
          onChange={(name) => updateNodeName(name)}
          defaultName={matrixName || "Decision_Table"}
        />
        {!props?.isSideBar && (
          <img
            alt="x"
            src={closeIcon}
            onClick={() =>
              navigate(`/workflow/${workflowId}`, { replace: true })
            }
            className="h-4 w-4 p-0 ml-auto cursor-pointer z-10 text-neutral-500"
          />
        )}
      </div>

      <QueryWrapper
        query={matrixData}
        loader={<Shimmer className="w-full h-[100px] mt-3" />}
      >
        {() => {
          return (
            <>
              <div className="flex flex-col gap-4 mt-3">
                <div className="flex justify-between">
                  <div className="w-[248px]">
                    <Label>Default Value</Label>
                    <Controller
                      control={control}
                      name="default"
                      defaultValue={matrixData.data?.data.data.default}
                      rules={{
                        required: {
                          value: true,
                          message: "Default value is required",
                        },
                      }}
                      render={({ field }) => {
                        return (
                          <CustomEditor
                            value={field.value}
                            setValue={field.onChange}
                            monacoOptions={{
                              lineNumbers: "off",
                              glyphMargin: false,
                              fontWeight: "400",
                              folding: false,
                              lineDecorationsWidth: 0,
                              lineNumbersMinChars: 0,
                              showFoldingControls: "never",
                            }}
                          />
                        );
                      }}
                    />
                    <div className="text-error-500 font-b2 mt-1">
                      {formState.errors.default?.message}
                    </div>
                  </div>
                  {isWorkflowEditable && (
                    <>
                      <Button
                        onClick={exportTable}
                        className="ml-auto mr-2"
                        variant="outline"
                      >
                        Export
                      </Button>
                      <input
                        accept=".csv"
                        onChange={importTable}
                        ref={fileInputRef}
                        className="hidden"
                        type="file"
                      />
                      <Button
                        onClick={() => fileInputRef.current?.click()}
                        className="mr-2"
                        variant="outline"
                      >
                        Import
                      </Button>
                      <Button
                        onClick={addColumn}
                        className="mr-2"
                        variant="outline"
                      >
                        <img src={plusIcon} alt="+" />
                        Add Column
                      </Button>
                      <Button onClick={() => updateMatrix()}>Save</Button>
                    </>
                  )}
                </div>
                <span className="text-error-500 font-b2">
                  {nodeErrors.slice(0, 5).map((i) => {
                    return (
                      <Fragment key={i[1] + i[0]}>
                        {i[1]}
                        <br />
                      </Fragment>
                    );
                  })}
                  {nodeErrors.length > 5 && (
                    <Fragment>
                      +{nodeErrors.length - 5} more errors
                      <br />
                    </Fragment>
                  )}
                </span>
                <div
                  ref={ref}
                  className="border-t overflow-auto border-l font-b1 border-neutral-100 rounded-md grid divide-neutral-100 min-h-[1ch] max-h-[calc(100vh-250px)]"
                  style={{
                    gridTemplateColumns: `75px repeat(${
                      colsFieldArray.fields.length + 1
                    }, minmax(250px, 1fr))`,
                  }}
                >
                  <div className="bg-neutral-0 sticky z-10 top-0 overflow-visible w-full font-medium first:rounded-tl-md py-1 px-4 border-r border-b border-neutral-100 h-10 flex items-center">
                    Sl. No.
                  </div>
                  {colsFieldArray.fields.map(({ name, id }, index) => {
                    return (
                      <div key={id} className="sticky z-10 top-0">
                        {colsFieldArray.fields.length > 1 && (
                          <TrashIcon
                            onClick={getDeleteColumn(index)}
                            className="[&:hover>path]:stroke-error-500 cursor-pointer absolute right-2 top-1/2 -translate-y-1/2"
                          />
                        )}
                        <MatrixHeader
                          path={`headers.${index}.name`}
                          tableName={matrixName}
                          keywords={keywordsQuery}
                          data={name}
                          control={control}
                          setIsEditing={() => setCurrentlyEditing([-1, index])}
                          isEditing={checkIsEditing(-1, index)}
                          headersList={[]}
                        />
                      </div>
                    );
                  })}
                  <div className="sticky top-0 z-10 py-1 pl-4 pr-1 border-r border-neutral-100 font-medium border-b bg-neutral-0 rounded-tr-md flex items-center">
                    Output
                  </div>
                  {rowsFieldArray.fields.map(
                    ({ column, output, id }, rowIndex) => {
                      return (
                        <Fragment key={id}>
                          <div
                            tabIndex={0}
                            className="first:rounded-tl-md py-1 pr-1 pl-4 font-normal border-r border-b border-neutral-100 h-10 flex items-center w-full"
                          >
                            {rowIndex + 1}
                          </div>
                          {colsFieldArray.fields.map(({ id }, colIndex) => {
                            return (
                              <MatrixItem
                                key={id}
                                setIsEditing={() =>
                                  setCurrentlyEditing([rowIndex, colIndex])
                                }
                                isEditing={checkIsEditing(rowIndex, colIndex)}
                                control={control}
                                data={column[colIndex]}
                                path={`rows.${rowIndex}.column.${colIndex}`}
                                keyPressHandler={keyPressHandler}
                              />
                            );
                          })}
                          <div className="relative">
                            {rowsFieldArray.fields.length > 1 && (
                              <TrashIcon
                                onClick={getDeleteRow(rowIndex)}
                                className="[&:hover>path]:stroke-error-500 cursor-pointer absolute right-2 top-1/2 -translate-y-1/2"
                              />
                            )}
                            <MatrixItem
                              key="output"
                              control={control}
                              data={output}
                              path={`rows.${rowIndex}.output`}
                              setIsEditing={() =>
                                setCurrentlyEditing([
                                  rowIndex,
                                  colsFieldArray.fields.length,
                                ])
                              }
                              rules={{
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              }}
                              isEditing={checkIsEditing(
                                rowIndex,
                                colsFieldArray.fields.length
                              )}
                              keyPressHandler={keyPressHandler}
                            />
                          </div>
                        </Fragment>
                      );
                    }
                  )}
                </div>
              </div>
              <Button
                onClick={addRow}
                className={clsx(
                  "self-start mt-3",
                  colsFieldArray.fields.length === 0 && "hidden"
                )}
                variant="outline"
              >
                <img src={plusIcon} alt="+" />
                Add Row
              </Button>
            </>
          );
        }}
      </QueryWrapper>
    </div>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: Props) {
  const { matrixId = props?.nodeId } = useParams<{
    workflowId: string;
    matrixId: string;
  }>();

  // key is required here because of test
  return <DecisionTableEditor key={matrixId} {...props} />;
}
