import { useReducer } from "react";

export const CLOSE = Symbol("Close");
type Close = typeof CLOSE;
type State = "Inputs" | "Errors" | "";
type Action = "Inputs" | "Errors" | Close;

const reducer = (state: State, action: Action) => {
  return {
    Inputs: {
      Inputs: "",
      Errors: "Errors",
      [CLOSE]: "",
    },
    Errors: {
      Errors: "",
      Inputs: "Inputs",
      [CLOSE]: "",
    },
    "": {
      Errors: "Errors",
      Inputs: "Inputs",
      [CLOSE]: "",
    },
  }[state][action] as State;
};

export default function useBottomPanelState() {
  return useReducer(reducer, "");
}
