import { default as classNames } from "clsx";
import {
  format,
  formatDistanceToNow,
  parseISO,
  startOfDay,
  subDays,
} from "date-fns";
import React, { Fragment, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getNotReadCounts,
  getNotificationsList,
  useMarkAllRead,
  useMarkNotificationRead,
} from "src/apis";
import Label from "src/components/Label";
import Popover from "src/components/Popover";
import QueryWrapper from "src/components/QueryWrapper";
import Shimmer from "src/components/Shimmer";
import StatusChip from "src/components/StatusChip";
import Toggle from "src/components/Toggle";
import Tooltip from "src/components/Tooltip";
import { useDownloadReport } from "src/screens/reports/pages/generated-reports/queries";
import { useDownloadSimulationReport } from "src/screens/reports/pages/simulations/queries";
import {
  MonitorNotification,
  NotificationList,
  PolicyNotification,
  PolicyStatus,
  ReportNotification,
  SimulationNotification,
} from "src/types";
import { getInitial } from "src/utils/utils";
import { ReactComponent as PolicyIcon } from "@assets/icons/dataflow-01.svg";
import { ReactComponent as DownloadIcon } from "@assets/icons/download-cloud-01.svg";
import { ReactComponent as AlertCircle } from "@assets/notificationIcons/alert-circle.svg";
import { ReactComponent as AlertSquareIcon } from "@assets/notificationIcons/alert-square.svg";
import { ReactComponent as AlertTriangle } from "@assets/notificationIcons/alert-triangle.svg";
import bellIcon from "@assets/notificationIcons/bell-01.svg";
import { ReactComponent as CheckIcon } from "@assets/notificationIcons/check-circle-broken.svg";
import { ReactComponent as ListIcon } from "@assets/notificationIcons/list.svg";
import { ReactComponent as ReportsIcon } from "@assets/notificationIcons/reports.svg";
import TabRoot, { tabOptions } from "@components/CustomTabs";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";

type CtaMapperProps = {
  [key: string]: {
    text: string;
    icon: JSX.Element;
  };
};

const CTA_MAPPER: CtaMapperProps = {
  wf_status: {
    text: "View",
    icon: <ArrowUpRightIcon className="ml-1 stroke-primary-900 w-3 h-3" />,
  },
  report: {
    text: "Download",
    icon: <DownloadIcon className="ml-1 w-4 h-4 [&>path]:stroke-primary-900" />,
  },
  simulation: {
    text: "Download",
    icon: <DownloadIcon className="ml-1 w-4 h-4 [&>path]:stroke-primary-900" />,
  },
  monitor: {
    text: "View",
    icon: <ArrowUpRightIcon className="ml-1 stroke-primary-900 w-3 h-3" />,
  },
};

const TABS = tabOptions(["All", "Policy", "Reports", "Alerts"] as const);
const TABS_MAPPER = {
  all: {
    status: ["wf_status", "report", "monitor", "simulation"],
  },
  policy: {
    status: ["wf_status"],
  },
  reports: {
    status: ["report", "simulation"],
  },
  alerts: {
    status: ["monitor"],
  },
};

const TAB_ICONS = {
  all: ListIcon,
  policy: PolicyIcon,
  reports: ReportsIcon,
  alerts: AlertSquareIcon,
};

const TIME_RANGE = {
  today: "Today",
  yesterday: "Yesterday",
  last7days: "Last 7 days",
  last30days: "Last 30 days",
};

const NotificationCentre = () => {
  const [unread, setUnread] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState("all");

  const notificationsList = useQuery(
    getNotificationsList({
      type: TABS_MAPPER[notificationStatus as keyof typeof TABS_MAPPER].status,
      page: 0,
      limit: 10,
      showUnread: unread,
    })
  );
  const { data: counts } = useQuery(getNotReadCounts());
  const notReadCounts = counts?.data.data!;

  const markAllRead = useMarkAllRead();
  const handleMarkAllRead = () => {
    markAllRead.mutate();
  };

  const sumValues = (key: string) => {
    if (!notReadCounts) return 0;
    switch (key) {
      case "alerts":
        return notReadCounts["monitor"] || 0;
      case "reports":
        return (
          (notReadCounts["report"] || 0) + (notReadCounts["simulation"] || 0)
        );
      case "all":
        return (
          Object.values(notReadCounts).reduce((acc, curr) => acc + curr, 0) || 0
        );
      case "policy":
        return notReadCounts["wf_status"] || 0;
      default:
        return 0;
    }
  };

  const classifyNotifications = (notifications: NotificationList[]) => {
    const today = startOfDay(new Date());
    const yesterday = startOfDay(subDays(today, 1));
    const last7days = startOfDay(subDays(today, 7));
    const last30days = startOfDay(subDays(today, 30));

    const classifiedNotifications = {
      today: [] as NotificationList[],
      yesterday: [] as NotificationList[],
      last7days: [] as NotificationList[],
      last30days: [] as NotificationList[],
    };

    for (const notification of notifications) {
      const updatedAt = startOfDay(new Date(notification.updatedAt));
      if (updatedAt >= today) {
        classifiedNotifications.today.push(notification);
      } else if (updatedAt >= yesterday) {
        classifiedNotifications.yesterday.push(notification);
      } else if (updatedAt >= last7days) {
        classifiedNotifications.last7days.push(notification);
      } else if (updatedAt >= last30days) {
        classifiedNotifications.last30days.push(notification);
      } else {
        break;
      }
    }

    return classifiedNotifications;
  };

  return (
    <Popover className="relative" placement="bottom-end">
      {({ open }) => (
        <>
          <Popover.Button>
            <div
              className={classNames(
                "border border-neutral-200 rounded-md p-1.5 hover:bg-neutral-50 mr-4",
                open && "bg-neutral-50"
              )}
            >
              <img alt="bell" src={bellIcon} className="w-4 h-4" />
              <span
                className={classNames(
                  `absolute rounded-full w-3 h-3 top-[4px] text-white text-[8px] ${
                    sumValues("all") > 0 ? "bg-red-500" : "bg-none"
                  }`
                )}
              >
                {sumValues("all") > 0 && sumValues("all")}
              </span>
            </div>
          </Popover.Button>
          <Popover.Panel className="-translate-x-3 text-xs -mt-1 !w-[460px] max-h-[70%] overflow-scroll shadow-1">
            <div className="text-neutral-600 flex flex-col cursor-pointer ">
              <div className="flex z-40 sticky top-0 items-center justify-between px-4 h-10 border-b bg-neutral-0 cursor-default">
                <p className="text-neutral-black font-b1-medium">
                  Notifications
                </p>
                <div className="text-neutral-black font-b2 flex items-center">
                  <span className="mr-2">Only show unread</span>
                  <Toggle
                    disable={notificationsList.isPending}
                    onChange={() => {
                      setUnread(!unread);
                    }}
                    value={unread}
                  />
                  <div
                    className="w-6 h-6 ml-4 flex justify-center items-center rounded-md hover:bg-neutral-25 cursor-pointer"
                    onClick={handleMarkAllRead}
                  >
                    <Tooltip
                      bottom
                      left
                      tooltipClassName="-right-[18px]"
                      content={
                        <CheckIcon
                          className="w-4 h-4"
                          onClick={handleMarkAllRead}
                        />
                      }
                      tooltipContent={
                        <p className="font-b2 text-neutral-black">
                          Mark all as read
                        </p>
                      }
                    />
                  </div>
                </div>
              </div>
              <TabRoot defaultTab={TABS[0]}>
                <div className="flex gap-2 px-2 bg-neutral-0 border-b border-neutral-100 z-10 sticky top-[40px]">
                  {TABS.map((tab) => (
                    <TabRoot.Trigger tabName={tab} key={tab}>
                      {({ onClick, isSelected }) => {
                        const handleClick = () => {
                          setNotificationStatus(tab.toLowerCase());
                          onClick();
                        };
                        return (
                          <div
                            className={classNames(
                              "flex font-b1-medium gap-2 px-2 h-9 py-3 mt-[1px] items-center cursor-pointer text-neutral-600 border-b-[3px] border-transparent group",
                              isSelected ? " !border-primary-900" : ""
                            )}
                            onClick={handleClick}
                          >
                            <div
                              className={classNames(
                                isSelected
                                  ? "stroke-primary-900 [&>g&>path]:stroke-primary-900 [&>path]:stroke-primary-900 fill-primary-900"
                                  : "stroke-neutral-500 [&>path]:stroke-neutral-500 group-hover:stroke-primary-900 group-hover:[&>path]:stroke-primary-900"
                              )}
                            >
                              {React.createElement(
                                TAB_ICONS[
                                  tab.toLowerCase() as keyof typeof TAB_ICONS
                                ],
                                {
                                  className: classNames(
                                    "w-4 h-4",
                                    isSelected
                                      ? "stroke-primary-900 [&>g&>path]:stroke-primary-900 [&>path]:stroke-primary-900 "
                                      : "stroke-neutral-500 [&>path]:stroke-neutral-600 group-hover:stroke-primary-900 group-hover:[&>path]:stroke-primary-900"
                                  ),
                                }
                              )}
                            </div>
                            <span
                              className={classNames(
                                isSelected
                                  ? "text-primary-900 !border-primary-900"
                                  : "group-hover:text-primary-900"
                              )}
                            >
                              {tab}
                            </span>
                            {sumValues(tab.toLowerCase()) > 0 ? (
                              <span
                                className={classNames(
                                  "rounded-md items-center justify-center flex w-6 h-6 text-xs font-semibold",
                                  isSelected
                                    ? "bg-primary-50  text-primary-600"
                                    : "bg-neutral-25  text-neutral-600 group-hover:text-primary-900 group-hover:bg-primary-50"
                                )}
                              >
                                {sumValues(tab.toLowerCase())}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      }}
                    </TabRoot.Trigger>
                  ))}
                </div>
                <TabRoot.Panel tabName={TABS[0]}>
                  <QueryWrapper
                    query={notificationsList}
                    loader={<QueryLoader />}
                  >
                    {(data) => {
                      if (
                        data.data.data.list &&
                        data.data.data.list.length > 0
                      ) {
                        return (
                          <>
                            {Object.entries(
                              classifyNotifications(data?.data.data.list)
                            ).map(([timeRange, notifications]) => (
                              <Fragment
                                key={timeRange as keyof typeof TIME_RANGE}
                              >
                                {notifications.length > 0 && (
                                  <Label className="px-4 py-2 sticky top-[78px] z-40 bg-white">
                                    {TIME_RANGE[
                                      timeRange as keyof typeof TIME_RANGE
                                    ].toUpperCase()}
                                  </Label>
                                )}
                                {notifications.map((item) => (
                                  <Fragment key={item.id}>
                                    {item.type === "wf_status" && (
                                      <PolicyCard item={item} />
                                    )}
                                    {item.type === "report" && (
                                      <ReportsCard item={item} />
                                    )}
                                    {item.type === "simulation" && (
                                      <SimulationCard item={item} />
                                    )}
                                    {item.type === "monitor" && (
                                      <AlertsCard item={item} />
                                    )}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ))}
                            {data.data.data.list.length > 7 && (
                              <NotificationFooter />
                            )}
                          </>
                        );
                      } else return <NoNotification />;
                    }}
                  </QueryWrapper>
                </TabRoot.Panel>
                <TabRoot.Panel tabName={TABS[1]}>
                  <QueryWrapper
                    query={notificationsList}
                    loader={<QueryLoader />}
                  >
                    {(data) => {
                      if (data.data.data.list && data.data.data.list.length > 0)
                        return (
                          <>
                            {Object.entries(
                              classifyNotifications(data?.data.data.list)
                            ).map(([timeRange, notifications]) => (
                              <Fragment
                                key={timeRange as keyof typeof TIME_RANGE}
                              >
                                {notifications.length > 0 && (
                                  <Label className="px-4 py-2 sticky top-[78px] z-40 bg-white">
                                    {TIME_RANGE[
                                      timeRange as keyof typeof TIME_RANGE
                                    ].toUpperCase()}
                                  </Label>
                                )}
                                {notifications.map((item) => (
                                  <Fragment key={item.id}>
                                    {item.type === "wf_status" && (
                                      <PolicyCard item={item} />
                                    )}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ))}
                            {data.data.data.list.length > 7 && (
                              <NotificationFooter />
                            )}
                          </>
                        );
                      else return <NoNotification />;
                    }}
                  </QueryWrapper>
                </TabRoot.Panel>
                <TabRoot.Panel tabName={TABS[2]}>
                  <QueryWrapper
                    query={notificationsList}
                    loader={<QueryLoader />}
                  >
                    {(data) => {
                      if (data.data.data.list && data.data.data.list.length > 0)
                        return (
                          <>
                            {Object.entries(
                              classifyNotifications(data?.data.data.list)
                            ).map(([timeRange, notifications]) => (
                              <Fragment
                                key={timeRange as keyof typeof TIME_RANGE}
                              >
                                {notifications.length > 0 && (
                                  <Label className="px-4 py-2 sticky top-[78px] z-40 bg-white">
                                    {TIME_RANGE[
                                      timeRange as keyof typeof TIME_RANGE
                                    ].toUpperCase()}
                                  </Label>
                                )}
                                {notifications.map((item) => (
                                  <Fragment key={item.id}>
                                    {item.type === "report" && (
                                      <ReportsCard item={item} />
                                    )}
                                    {item.type === "simulation" && (
                                      <SimulationCard item={item} />
                                    )}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ))}
                            {data.data.data.list.length > 7 && (
                              <NotificationFooter />
                            )}
                          </>
                        );
                      else return <NoNotification />;
                    }}
                  </QueryWrapper>
                </TabRoot.Panel>
                <TabRoot.Panel tabName={TABS[3]}>
                  <QueryWrapper
                    query={notificationsList}
                    loader={<QueryLoader />}
                  >
                    {(data) => {
                      if (data.data.data.list && data.data.data.list.length > 0)
                        return (
                          <>
                            {Object.entries(
                              classifyNotifications(data?.data.data.list)
                            ).map(([timeRange, notifications]) => (
                              <Fragment
                                key={timeRange as keyof typeof TIME_RANGE}
                              >
                                {notifications.length > 0 && (
                                  <Label className="px-4 py-2 sticky top-[78px] z-40 bg-white">
                                    {TIME_RANGE[
                                      timeRange as keyof typeof TIME_RANGE
                                    ].toUpperCase()}
                                  </Label>
                                )}
                                {notifications.map((item) => (
                                  <Fragment key={item.id}>
                                    {item.type === "monitor" && (
                                      <AlertsCard item={item} />
                                    )}
                                  </Fragment>
                                ))}
                              </Fragment>
                            ))}
                            {data.data.data.list.length > 7 && (
                              <NotificationFooter />
                            )}
                          </>
                        );
                      else return <NoNotification />;
                    }}
                  </QueryWrapper>
                </TabRoot.Panel>
              </TabRoot>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default NotificationCentre;

const QueryLoader = () => {
  return (
    <div className="flex flex-col m-auto p-8 gap-2">
      <Shimmer w="400px" h="20px" />
      <Shimmer w="400px" h="20px" />
      <Shimmer w="400px" h="20px" />
    </div>
  );
};

const NotificationFooter = () => {
  return (
    <p className="m-auto font-b2-medium text-neutral-500 py-2">
      Older notifications beyond 30 days won't be visible
    </p>
  );
};

const NoNotification = () => {
  return (
    <div className="m-auto py-12">
      <p className="font-b2-medium text-neutral-black">No notifications</p>
    </div>
  );
};

const NotificationBaseCard = ({
  children,
  isRead,
}: {
  children: ReactElement;
  isRead: boolean;
}) => {
  return (
    <div className="group relative flex items-start p-4 hover:bg-neutral-0 border-b">
      <div
        className={classNames(
          "rounded w-2 h-2  mr-1 mt-2.5",
          !isRead && "bg-red-500"
        )}
      ></div>
      {children}
    </div>
  );
};

const PolicyCard = ({ item }: { item: PolicyNotification }) => {
  const navigate = useNavigate();
  const markNotificationRead = useMarkNotificationRead();

  const handleCtaAction = () => {
    if (item.metadata?.type === "policy") {
      navigate(`/create-policy/${item.metadata.id}`);
      markNotificationRead.mutate({ notificationId: item.id });
    } else {
      navigate(`/workflow/${item.metadata.id}`);
      markNotificationRead.mutate({ notificationId: item.id });
    }
  };

  return (
    <>
      <NotificationBaseCard isRead={item.isRead}>
        <>
          <div className="bg-neutral-25 p-1.5 rounded-full w-7 h-7 items-center justify-center">
            <PolicyIcon className="[&>path]:stroke-neutral-600 w-4 h-4" />
          </div>{" "}
          <div className="flex flex-col ml-4 w-[90%]">
            <p className="mb-3 font-b1 text-neutral-600">
              <span className="text-neutral-black font-b1-medium">
                {item.metadata?.updatedBy}
              </span>{" "}
              updated the status of{" "}
              <span className="text-neutral-black font-b1-medium">
                {item.metadata?.name}
              </span>
            </p>
            <div className="flex gap-1 mb-2 items-center">
              <StatusChip status={item.metadata?.oldStatus as PolicyStatus} />
              <ArrowRightIcon className="stroke-neutral-600 w-4 h-4" />
              <StatusChip status={item.metadata?.newStatus as PolicyStatus} />
            </div>
            <div className="flex justify-between">
              <p className="font-[12px] text-neutral-600">
                {formatDistanceToNow(parseISO(item.updatedAt))} ago
              </p>
              <button
                className="group-hover:visible invisible flex text-primary-900 font-b2-medium items-center"
                onClick={() => handleCtaAction()}
              >
                {CTA_MAPPER[item.type]?.text}
                {CTA_MAPPER[item.type]?.icon}
              </button>
            </div>
          </div>
        </>
      </NotificationBaseCard>
    </>
  );
};

const ReportsCard = ({ item }: { item: ReportNotification }) => {
  const downloadReport = useDownloadReport();
  const markNotificationRead = useMarkNotificationRead();
  const navigate = useNavigate();

  const handleCtaAction = () => {
    downloadReport.mutate(item.metadata.id);
    markNotificationRead.mutate({ notificationId: item.id });
  };
  const handleRetryAction = () => {
    navigate("/reports/outcomes");
    markNotificationRead.mutate({ notificationId: item.id });
  };
  return (
    <>
      <NotificationBaseCard isRead={item.isRead}>
        <>
          <div className="w-7 h-7 flex justify-center items-center rounded-full bg-neutral-900 text-neutral-0 font-medium text-[11px]">
            {getInitial(item.metadata.updatedBy)}
          </div>
          <div className="flex flex-col ml-4 w-[90%]">
            <p className="mb-3 font-b1 text-neutral-600">
              <span className="text-neutral-black font-b1-medium">
                {item.metadata?.type} Report
              </span>{" "}
              {item.metadata.status.toLowerCase() === "delivered" ? (
                <>
                  is shared to{" "}
                  <span className="text-neutral-black font-b1-medium">
                    {item.metadata?.email}
                  </span>
                </>
              ) : (
                <>has failed</>
              )}
            </p>
            <div className="flex justify-between">
              <p className="font-[12px] text-neutral-600">
                {formatDistanceToNow(parseISO(item.updatedAt))} ago
              </p>
              {item.metadata.status.toLowerCase() === "delivered" ? (
                <button
                  className="group-hover:visible invisible flex text-primary-900 font-b2-medium items-center"
                  onClick={() => handleCtaAction()}
                >
                  {CTA_MAPPER[item.type]?.text}
                  {CTA_MAPPER[item.type]?.icon}
                </button>
              ) : (
                <button
                  className="group-hover:visible invisible flex text-primary-900 font-b2-medium items-center"
                  onClick={() => handleRetryAction()}
                >
                  View
                  <ArrowUpRightIcon className="ml-1 stroke-primary-900 w-3 h-3" />{" "}
                </button>
              )}
            </div>
          </div>
        </>
      </NotificationBaseCard>
    </>
  );
};

const SimulationCard = ({ item }: { item: SimulationNotification }) => {
  const downloadSimulation = useDownloadSimulationReport();
  const markNotificationRead = useMarkNotificationRead();
  const navigate = useNavigate();

  const handleCtaAction = () => {
    downloadSimulation.mutate(item.metadata.id!);
    markNotificationRead.mutate({ notificationId: item.id });
  };
  const handleRetryAction = () => {
    navigate("/reports/simulations");
    markNotificationRead.mutate({ notificationId: item.id });
  };
  return (
    <>
      <NotificationBaseCard isRead={item.isRead}>
        <>
          <div className="w-7 h-7 flex justify-center items-center rounded-full bg-neutral-900 text-neutral-0 font-medium text-[11px]">
            {getInitial(item?.dashboardUserName)}
          </div>
          <div className="flex flex-col ml-4 w-[90%]">
            <p className="mb-3 font-b1 text-neutral-600">
              <span className="text-neutral-black font-b1-medium">
                Simulation
              </span>{" "}
              {item.metadata.status.toLowerCase() === "done" ? (
                <>
                  is triggered on{" "}
                  <span className="text-neutral-black font-b1-medium">
                    {item.metadata?.name}
                  </span>
                </>
              ) : (
                <>
                  has failed for{" "}
                  <span className="text-neutral-black font-b1-medium">
                    {item.metadata?.name}
                  </span>
                </>
              )}
            </p>
            <div className="flex justify-between">
              <p className="text-[12px] text-neutral-600">
                {formatDistanceToNow(parseISO(item.updatedAt))} ago
              </p>
              {item.metadata.status.toLowerCase() === "done" ? (
                <button
                  className="group-hover:visible invisible flex text-primary-900 font-b2-medium items-center"
                  onClick={() => handleCtaAction()}
                >
                  {CTA_MAPPER[item.type]?.text}
                  {CTA_MAPPER[item.type]?.icon}
                </button>
              ) : (
                <button
                  className="group-hover:visible invisible flex text-primary-900 font-b2-medium items-center"
                  onClick={() => handleRetryAction()}
                >
                  View
                  <ArrowUpRightIcon className="ml-1 stroke-primary-900 w-3 h-3" />{" "}
                </button>
              )}
            </div>
          </div>
        </>
      </NotificationBaseCard>
    </>
  );
};

const AlertsCard = ({ item }: { item: MonitorNotification }) => {
  const navigate = useNavigate();
  const markNotificationRead = useMarkNotificationRead();

  const handleCtaAction = () => {
    if (item.metadata?.sourceName === "ENDPOINT_MONITOR") {
      navigate("/monitors/endpoint");
    } else {
      navigate("/monitors/custom");
    }
    markNotificationRead.mutate({ notificationId: item.id });
  };
  const d = new Date(item.updatedAt);
  return (
    <>
      <NotificationBaseCard isRead={item.isRead}>
        <>
          <div
            className={classNames(
              "w-7 h-7 flex justify-center items-center rounded-full",
              item.metadata.status === "error" ? "bg-error-50" : "bg-warning-50"
            )}
          >
            {item.metadata.status === "error" ? (
              <AlertCircle className="w-4 h-4" />
            ) : (
              <AlertTriangle className="w-4 h-4" />
            )}
          </div>
          <div className="flex flex-col ml-4 w-[90%] gap-3">
            <p className="text-neutral-black font-b1-medium">
              Triggered {item.metadata?.status} on "{item.metadata?.monitorName}
              "
            </p>
            <div>
              <span className="rounded-full px-2 py-1 font-b2-medium text-neutral-700 bg-neutral-100 mr-2">
                {item.metadata?.sourceName === "ENDPOINT_MONITOR"
                  ? "Endpoint"
                  : "Custom API"}
              </span>
              <span className="font-b1 text-neutral-600">
                {format(d, "dd MMM, yyyy - h:mm aaaaa'm")}
              </span>
            </div>
            <div className="font-b2 text-neutral-600">
              {item.metadata?.description}
            </div>
            <div className="flex justify-between">
              <p className="font-[12px] text-neutral-600">
                {formatDistanceToNow(parseISO(item.updatedAt))} ago
              </p>
              <button
                className="group-hover:visible invisible flex text-primary-900 font-b2-medium items-center"
                onClick={() => handleCtaAction()}
              >
                {CTA_MAPPER[item.type]?.text}
                {CTA_MAPPER[item.type]?.icon}
              </button>
            </div>
          </div>
        </>
      </NotificationBaseCard>
    </>
  );
};
