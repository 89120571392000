import React, { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProtectedComponent from "src/components/ProtectedComponent";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import { PermissionTypes } from "src/types";
import InnerSidebar, { InnerSidebarItem } from "@components/InnerSidebar";

const SIDEBAR_ITEMS = [
  {
    title: "Platform Reports",
    items: [
      {
        title: "Outcomes",
        href: "/reports/outcomes",
        permissionsTag: PERMISSIONS_TYPE.reportsOutcome,
      },
      {
        title: "Simulations",
        href: "/reports/simulations",
        permissionsTag: PERMISSIONS_TYPE.simulations,
      },
    ],
  },
  {
    title: "Generated Reports",
    items: [
      {
        title: "All Reports",
        href: "/reports/generated",
        permissionsTag: PERMISSIONS_TYPE.reportsAll,
      },
    ],
  },
];

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <InnerSidebar
      title="Reports"
      content={
        <div className="px-6 py-4">
          {SIDEBAR_ITEMS.map((section) => (
            <Fragment key={section.title}>
              <span className="font-b2-medium text-neutral-500 inline-block mb-1">
                {section.title}
              </span>
              {section.items.map((item) => (
                <ProtectedComponent
                  type={item.permissionsTag as PermissionTypes}
                  action="view"
                >
                  <InnerSidebarItem
                    key={item.href}
                    selected={location.pathname === item.href}
                    onClick={() => navigate(item.href)}
                    content={item.title}
                  />
                </ProtectedComponent>
              ))}
            </Fragment>
          ))}
        </div>
      }
    />
  );
}
