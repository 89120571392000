import classNames from "clsx";
import { format, parseISO } from "date-fns";
import { ReactComponent as UserIcon } from "@assets/icons/UserIcon.svg";
import {
  useEvaluationMetrics,
  usePolicyDetails,
} from "@screens/policyDetails/queries";
import Card from "@components/Card";
import Shimmer from "@components/Shimmer";
import { CheckIcon } from "@heroicons/react/20/solid";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { DateRange } from "@types";
import { getPercentage, shortNumber } from "@utils/utils";

type Props = {
  policyID: string;
  dateRange: DateRange;
};

export default function AnalyticsCards({ policyID, dateRange }: Props) {
  const userMetrics = useEvaluationMetrics({
    entityID: policyID,
    startDate: dateRange.startDate || "",
    endDate: dateRange.endDate || "",
    entityType: "policy",
    level: "user",
  });
  const decisionMetrics = useEvaluationMetrics({
    entityID: policyID,
    startDate: dateRange.startDate || "",
    endDate: dateRange.endDate || "",
    entityType: "policy",
    level: "reference",
  });

  const policyDetailQuery = usePolicyDetails({
    policyID,
  });

  return (
    <div className="grid gap-3 min-h-[100px] w-full grid-cols-4">
      {policyDetailQuery.isPending ? (
        <Shimmer h="100px" w="100%" />
      ) : (
        <Card className="pb-1">
          <div className="flex gap-5 items-start px-2">
            <span className="rounded-full bg-purple-50 mt-2 w-10 h-10 flex justify-center items-center">
              <UserIcon className="w-5 text-purple-600" />
            </span>
            <div>
              <span className="flex flex-col items-start gap-2">
                <span
                  className="font-medium text-sm text-gray-500 leading-none"
                >
                  Approved by:
                </span>
                <span
                  className="text-lg font-semibold text-gray-900 leading-none"
                >
                  {policyDetailQuery?.data?.result.approvedBy || "-"}
                </span>
              </span>
              <span
                className="italic text-gray-400 text-xs mt-0.5 leading-none"
              >
                Live on:{" "}
                {policyDetailQuery?.data?.result.approvedAt
                  ? format(
                      parseISO(policyDetailQuery?.data?.result.approvedAt),
                      "do LLLL, yyyy"
                    )
                  : "-"}
              </span>
            </div>
          </div>
        </Card>
      )}
      {userMetrics.isPending || userMetrics.status !== "success" ? (
        <Shimmer h="100px" w="100%" />
      ) : (
        <Card>
          <div className="flex gap-5">
            <span className="rounded-full bg-green-50 mt-2 w-10 h-10 flex justify-center items-center">
              <CheckIcon className="w-5 text-green-600" />
            </span>
            <div className="flex flex-col gap-0">
              <span className="font-medium text-sm text-gray-500">
                Total users evaluated
              </span>
              <span className="text-2xl leading-8 font-semibold text-gray-900">
                {userMetrics.data.data.data.totalCount}
              </span>
            </div>
          </div>
        </Card>
      )}
      {userMetrics.isPending || userMetrics.status !== "success" ? (
        <Shimmer h="100px" w="100%" />
      ) : (
        <Card>
          <div className="flex gap-5">
            <span className="relative rounded-full bg-blue-gray-50 mt-2 w-10 h-10 flex justify-center items-center text-xs font-semibold text-blue-gray-700 border-blue-gray-200 border">
              {getPercentage(
                userMetrics.data.data.data.passCount,
                userMetrics.data.data.data.totalCount,
                0
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={classNames(
                  "absolute top-0 left-0 w-10 h-10 rounded-full translate-y-[-1px] translate-x-[-1px]",
                  userMetrics.data.data.data.passCount === 0 && "hidden"
                )}
              >
                <circle
                  style={{
                    transformOrigin: "50% 50%",
                    transform: "rotate(-90deg)",
                  }}
                  cx="20"
                  cy="20"
                  r="20"
                  fill="none"
                  strokeWidth={4}
                  strokeLinecap="round"
                  stroke="#717BBC"
                  strokeDasharray={`${
                    (2 *
                      Math.PI *
                      20 *
                      (userMetrics.data.data.data.passCount || 0)) /
                    (userMetrics.data.data.data.totalCount || 1)
                  } 120`}
                />
              </svg>
            </span>
            <div className="flex flex-col gap-0">
              <span className="font-medium text-sm text-gray-500">
                Approval rate
              </span>
              <span className="font-semibold text-gray-900">
                <span className="text-2xl">
                  {shortNumber(userMetrics.data.data.data.passCount || 0)}
                </span>{" "}
                <span className="text-base">
                  / {shortNumber(userMetrics.data.data.data.totalCount)} users
                </span>
              </span>
            </div>
          </div>
        </Card>
      )}
      {decisionMetrics.isPending || decisionMetrics.status !== "success" ? (
        <Shimmer h="100px" w="100%" />
      ) : (
        <Card>
          <div className="flex gap-5">
            <span className="rounded-full bg-blue-50 mt-2 w-10 h-10 flex justify-center items-center">
              <BanknotesIcon className="w-5 text-blue-600" />
            </span>
            <div className="flex flex-col gap-0">
              <span className="font-medium text-sm text-gray-500">
                Total Evaluations
              </span>
              <span className="text-2xl leading-8 font-semibold text-gray-900">
                {shortNumber(decisionMetrics.data?.data.data.totalCount || 0)}
              </span>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
}
