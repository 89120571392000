import { createContext } from "react";
import { SIDEBAR_CONFIG } from "@screens/workflow/config";

const SidebarContext = createContext<{
  setCurrentTab: (
    s: Exclude<(typeof SIDEBAR_CONFIG)[number]["name"], "Test Workflow"> | null
  ) => void;
}>({ setCurrentTab: () => null });

export default SidebarContext;
