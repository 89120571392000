import clsx from "clsx";
import React, { memo } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import sourceIcon from "@assets/icons/workflow/database-01.svg";
import useWfId from "@screens/outcome-details/eval-components/WfIdContext";
import { getCurrentSourcesQuery } from "@screens/workflow/studio/components/Source/queries";
import useIsHiddenAfterTest from "@screens/workflow/studio/hooks/useIsHiddenAfterTest";
import QueryWrapper from "@components/QueryWrapper";
import Shimmer from "@components/Shimmer";
import { useQuery } from "@tanstack/react-query";
import useIsExpand, {
  useGetHandleStyles,
  useGetHandleStylesForInputDot,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "../../hooks/useIsExpand";

const SourceNode = ({
  id,
  selected,
  data,
}: NodeProps<{
  label: string;
  isFromFlow?: boolean;
  desc: string;
  hidden?: boolean;
}>) => {
  const wfId = useWfId();
  const selectedSourcesQuery = useQuery(getCurrentSourcesQuery(wfId!, id));

  const fontStyles = useGetNodeFontStyles();
  const iconStyles = useGetNodeIconStyles();
  const handleStyles = useGetHandleStyles();
  const isExpanded = useIsExpand();
  const nodeStyles = useGetNodeStyles();

  const handleStylesInputDot = useGetHandleStylesForInputDot();

  const hidden = useIsHiddenAfterTest(id, data?.hidden);

  return (
    <>
      <div className="flex relative cursor-grab active:cursor-grabbing group">
        <div
          style={nodeStyles}
          className={clsx(
            "overflow-visible w-[360px] bg-white rounded-[10px] hover:border-primary-200 border p-[7px]",
            {
              "opacity-25": hidden,
              "border-primary-200": selected && !hidden,
              "border-neutral-100": !selected,
              "shadow-lg": data?.isFromFlow && !hidden,
              "shadow-studio-node": !(data?.isFromFlow && !hidden),
            }
          )}
        >
          <Handle
            type="target"
            position={Position.Left}
            className="w-2 h-2 top-0 mt-[20px] opacity-50 hover:opacity-100 border-2 rounded-full bg-neutral-0  border-neutral-300 !-left-4 duration-150 overflow-visible"
            isConnectable={false}
            style={handleStylesInputDot}
          >
            <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
          </Handle>
          {!isExpanded && (
            <Handle
              type="source"
              position={Position.Right}
              className="!-right-3 top-1/2"
              isConnectable={false}
              style={handleStyles}
            />
          )}
          <div className="w-full text-neutral-black font-medium flex gap-1.5 items-center">
            <img style={iconStyles} alt="policy" src={sourceIcon} />
            <span
              style={fontStyles}
              className="max-w-44 w-max truncate cursor-pointer font-medium hover:bg-neutral-25 px-1 py-0.5 rounded-md"
            >
              {data.label || "Source_Name"}
            </span>
          </div>
          {isExpanded && (
            <div className="relative flex flex-col gap-2 pt-2">
              <div className="font-b2-medium">Select Source(s)</div>
              <QueryWrapper
                loader={
                  <>
                    <Shimmer w="100%" h="32px" />
                    <Shimmer w="100%" h="32px" />
                    <Shimmer className="w-[120px] h-[1.5em]" />
                  </>
                }
                query={selectedSourcesQuery}
              >
                {(data) => {
                  const currentSources = data.data.data;
                  return (
                    <>
                      {currentSources.map((item) => (
                        <div
                          key={item.data_source_id}
                          className="relative flex items-center gap-2 border border-neutral-100 font-b2 rounded-md px-2 py-1"
                        >
                          {item?.name}
                        </div>
                      ))}
                    </>
                  );
                }}
              </QueryWrapper>
            </div>
          )}
        </div>
        {isExpanded && (
          <Handle
            position={Position.Right}
            id="source"
            type="source"
            className="!absolute -top-[4px] -right-3"
            isConnectable={false}
          />
        )}
      </div>
    </>
  );
};

export default memo(SourceNode);
