import { RefObject, useEffect } from "react";

export function useOnClickOutside<T extends HTMLElement>(
  ref: RefObject<T>,
  handler: (e: MouseEvent | TouchEvent) => void,
  customIgnoreFn?: (e: MouseEvent | TouchEvent) => boolean,
  mouseEvent: "mousedown" | "click" = "mousedown"
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        !ref.current ||
        ref.current.contains(event.target as Node) ||
        customIgnoreFn?.(event)
      )
        return;
      handler(event);
    };
    document.addEventListener(mouseEvent, listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener(mouseEvent, listener);
      document.removeEventListener("touchstart", listener);
    };
    // eslint-disable-next-line
  }, [ref, handler, mouseEvent]);
}

export default useOnClickOutside;
