import { AxiosError } from "axios";
import { LOCALSTORAGE_KEYS } from "@config";
import { QueryCache, QueryClient } from "@tanstack/react-query";
import { notify } from "@utils/utils";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (_, query) => {
      if (query.meta?.errorMessage) {
        notify({
          title: "Network Error",
          text: query.meta.errorMessage as string,
        });
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: (count, error) => {
        if (
          error instanceof AxiosError &&
          error?.response?.status === 401 &&
          !window.location.href.includes("/login")
        ) {
          for (const key in Object.keys(LOCALSTORAGE_KEYS)) {
            localStorage.removeItem(key);
          }
          window.location.href =
            "/login?session_timed_out=1&redirectTo=" +
            encodeURI(`${window.location.pathname}${window.location.search}`);
          return false;
        }

        if (error instanceof AxiosError && error?.response?.status === 403)
          return false;

        return count < 3;
      },
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
    mutations: {
      retry: false,
    },
  },
});

export default queryClient;
