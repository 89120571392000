import classNames from "clsx";
import { useState } from "react";
import { useRuleEvaluationMetrics } from "@screens/policyDetails/queries";
import Card from "@components/Card";
import EmptyState from "@components/EmptyState";
import ErrorState from "@components/ErrorState";
import Shimmer from "@components/Shimmer";
import { DateRange } from "@types";
import { COLOR_CLASS_NAMES } from ".";
import SubHeader from "./SubHeader";

function RuleRow({
  pass,
  fail,
  cantDecide,
  rule,
}: {
  pass: number;
  fail: number;
  cantDecide: number;
  rule: string;
}) {
  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const total = pass + fail + cantDecide;
  let passPerc = (pass * 100) / total;
  let failPerc = (fail * 100) / total;
  let cantPerc = (cantDecide * 100) / total;
  if (total === 0) [passPerc, failPerc, cantPerc] = [0, 0, 0];

  return (
    <div className="text-sm flex gap-5">
      <span className="flex items-center gap-2 rounded-sm basis-[70%] shrink-0 bg-gray-100 text-gray-500 before:h-2 before:w-2 before:bg-purple-400 before:rounded-full my-auto px-4 py-1.5">
        {rule}
      </span>
      <div
        onMouseMove={(e) => {
          setTooltipPosition({
            x: e.clientX,
            y: e.clientY,
          });
        }}
        onMouseLeave={() => {
          setTooltipPosition(null);
        }}
        className="z-0 relative basis-[30%] border-l border-gray-400 flex flex-col gap-0 w-full py-4 hover:bg-blue-gray-50"
      >
        <div className="absolute top-0 bottom-0 left-0 right-0 -z-[1] flex justify-between">
          {[0, 20, 40, 60, 80, 100].map((k) => (
            <span key={k} className="h-full w-0.5 border-l border-dashed " />
          ))}
        </div>
        <span
          className={classNames(
            "h-2 pointer-events-none",
            COLOR_CLASS_NAMES.pass.bg
          )}
          style={{
            width: passPerc ? passPerc + "%" : "1px",
          }}
        />
        <span
          className={classNames(
            "h-2 pointer-events-none",
            COLOR_CLASS_NAMES.fail.bg
          )}
          style={{
            width: failPerc ? failPerc + "%" : "1px",
          }}
        />
        <span
          className={classNames(
            "h-2 pointer-events-none",
            COLOR_CLASS_NAMES.cant.bg
          )}
          style={{
            width: cantPerc ? cantPerc + "%" : "1px",
          }}
        />
      </div>
      {tooltipPosition && (
        <div
          className="z-10 text-sm pointer-events-none fixed bg-[#4B4F52] rounded flex flex-col gap-2 p-2.5 duration-[5]"
          style={{ top: tooltipPosition.y, left: tooltipPosition.x }}
        >
          <span className="flex gap-2 items-center">
            <span
              className={classNames(
                "h-2.5 w-2.5 rounded-sm",
                COLOR_CLASS_NAMES.pass.bg
              )}
            />
            <span className="text-gray-400">Pass</span>
            <span className="ml-auto text-white">
              {pass} ({Math.round(passPerc)}%)
            </span>
          </span>
          <span className="flex gap-2 items-center">
            <span
              className={classNames(
                "h-2.5 w-2.5 rounded-sm",
                COLOR_CLASS_NAMES.fail.bg
              )}
            />
            <span className="text-gray-400">Fail</span>
            <span className="ml-auto text-white">
              {fail} ({Math.round(failPerc)}%)
            </span>
          </span>
          <span className="flex gap-2 items-center">
            <span
              className={classNames(
                "h-2.5 w-2.5 rounded-sm",
                COLOR_CLASS_NAMES.cant.bg
              )}
            />
            <span className="text-gray-400">Can't Decide</span>
            <span className="ml-auto text-white">
              {cantDecide} ({Math.round(cantPerc)}%)
            </span>
          </span>
        </div>
      )}
    </div>
  );
}

export default function RuleMetricsTable({
  dateRange,
  policyID,
}: {
  dateRange: DateRange;
  policyID: string;
}) {
  const metricsQuery = useRuleEvaluationMetrics({
    policyID,
    endDate: dateRange.endDate,
    startDate: dateRange.startDate,
  });

  return (
    <Card className="w-full min-h-[400px] overflow-hidden mb-6">
      <div className="flex h-full flex-col">
        <div className="px-4 border-b border-b-gray-200 pb-4">
          <SubHeader>Rule level metrics</SubHeader>
        </div>
        <div className="flex h-full flex-col p-5 pb-2 pr-[75px]">
          {metricsQuery.isError && (
            <ErrorState error={metricsQuery.error}>
              Rule metrics could not be fetched
            </ErrorState>
          )}
          {metricsQuery.isPending ? (
            <Shimmer h="300px" w="100%"></Shimmer>
          ) : (metricsQuery.data?.data.data?.length || 0) === 0 ? (
            <div className="h-full flex items-center justify-center">
              <EmptyState>No rules were found</EmptyState>
            </div>
          ) : (
            metricsQuery.data?.data.data?.map((rule) => {
              return (
                <RuleRow
                  key={rule.ruleId}
                  pass={rule.evaluationMetrics.passCount ?? 0}
                  cantDecide={rule.evaluationMetrics.cantDecideCount ?? 0}
                  fail={rule.evaluationMetrics.rejectCount ?? 0}
                  rule={rule.description}
                />
              );
            })
          )}
        </div>
        {!metricsQuery.isPending &&
          (metricsQuery.data?.data.data?.length || 0) > 0 && (
            <>
              <div className="flex ml-auto h-fit justify-between text-gray-500 text-sm w-[30%] translate-x-[-32.5px]">
                {[0, 20, 40, 60, 80, 100].map((s) => (
                  <span style={{ transform: `translateX(-${s / 2}%)` }}>
                    {s}%
                  </span>
                ))}
              </div>
              <div className="flex w-1/2 ml-auto h-8 justify-end gap-10 mr-[5%] text-gray-600 font-b2">
                <span
                  className={classNames(
                    "flex items-center w-fit gap-2 before:w-2.5 before:h-2.5 before:rounded-sm ",
                    COLOR_CLASS_NAMES.pass.beforeBg
                  )}
                >
                  Approved
                </span>
                <span
                  className={classNames(
                    "flex items-center w-fit gap-2 before:w-2.5 before:h-2.5 before:rounded-sm",
                    COLOR_CLASS_NAMES.fail.beforeBg
                  )}
                >
                  Rejected
                </span>
                <span
                  className={classNames(
                    "flex items-center w-fit gap-2 before:w-2.5 before:h-2.5 before:rounded-sm",
                    COLOR_CLASS_NAMES.cant.beforeBg
                  )}
                >
                  Can't Decide
                </span>
              </div>
            </>
          )}
      </div>
    </Card>
  );
}
