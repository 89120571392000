import { useRef } from "react";
import { ReactComponent as DownloadIcon } from "@assets/icons/downloadIcon.svg";
import { ReactComponent as UploadIcon } from "@assets/icons/upload-cloud-01.svg";
import { TrashIcon } from "@heroicons/react/24/outline";

export function FileUpload({
  file,
  handleFileChange,
  openFromModel,
  isFileConfidential,
  handleFileDownload,
}: {
  file: File | undefined;
  handleFileChange: (file: File | undefined) => void;
  openFromModel: boolean;
  isFileConfidential: boolean | undefined;
  handleFileDownload: () => void;
}) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  return (
    <div className="h-[120px] group bg-white cursor-pointer first-line:relative flex items-center justify-center gap-2 border-dashed rounded-md border p-5 text-xs text-neutral-500 my-4">
      {openFromModel && !isFileConfidential && !file ? (
        <div className="flex items-center justify-between font-b2 w-[68%]">
          Click here to upload a new file or
          <div className="flex z-[999]">
            <span
              onClick={(e) => {
                e.stopPropagation();
                handleFileDownload();
              }}
              className="underline text-primary-900 mr-2"
            >
              download the current file
            </span>
            <DownloadIcon
              className="z-10 h-4 w-4 cursor-pointer [&>path]:stroke-neutral mr-4"
              onClick={(e) => {
                e.stopPropagation();
                handleFileDownload();
              }}
            />
          </div>
        </div>
      ) : (
        openFromModel &&
        isFileConfidential &&
        !file && (
          <div className="flex items-center justify-between gap-1  w-[40%]">
            Click here to upload a new file
          </div>
        )
      )}
      {file ? (
        <div className="flex items-center justify-between gap-1  w-[70%]">
          {file.name}
          <div className="flex">
            <TrashIcon
              className="z-10 h-4 w-4 cursor-pointer text-red-600"
              onClick={() => {
                handleFileChange(undefined);
                if (fileInputRef.current) fileInputRef.current.value = "";
              }}
            />
          </div>
        </div>
      ) : !openFromModel ? (
        <div className="flex justify-between w-[100%]">
          <div className="flex">
            <span className="stroke-neutral-500 mr-2">
              <UploadIcon className="w-4 h-4" />
            </span>
            <span className="flex items-center gap-2 font-b2 ">
              Upload CSV File(Max file size 5MB)
            </span>
          </div>
          <div className="text-primary-900 font-b2-medium border-b border-primary-900 ">
            Upload
          </div>
        </div>
      ) : (
        <></>
      )}
      <input
        ref={fileInputRef}
        onChange={(e) => handleFileChange(e.target?.files?.[0])}
        type="file"
        className="absolute h-[120px] w-[100%] opacity-0 cursor-pointer"
        accept=".csv"
      />
    </div>
  );
}
