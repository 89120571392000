import { Fragment } from "react";
import { useParams } from "react-router-dom";
import RightPanel from "@screens/workflow/components/Debugger/subComponents/RightPanel";
import axios from "@axios";
import { useMutation } from "@tanstack/react-query";
import { FinBoxResponse } from "@types";

function useTestModelSetTable() {
  return useMutation({
    mutationFn: async (body: {
      workflowId?: string;
      variables?: Record<
        string,
        string | Record<string, Record<string, string | null>>
      >;
      modelSetId?: string;
      tableId?: string;
    }) => {
      return axios.v2.post<
        FinBoxResponse<Record<string, Record<string, string>>>
      >("eval", {
        type: "workflow",
        id: body.workflowId,
        variables: body.variables,
        modelSetId: body.modelSetId,
        modelSetTableId: body.tableId,
      });
    },
  });
}

type Props = { expressions: string[] };

export default function ModelSetTableTest({ expressions }: Props) {
  const { nodeId, itemId, workflowId } = useParams();
  const testMutation = useTestModelSetTable();

  const test = (
    variables: Record<
      string,
      string | Record<string, Record<string, string | null>>
    >
  ) => {
    testMutation.mutate({
      variables,
      modelSetId: nodeId,
      tableId: itemId,
      workflowId: workflowId,
    });
  };

  return (
    <RightPanel
      nodeId={nodeId!}
      label={""}
      expressions={expressions}
      onTest={test}
      includeSameNodeModels
      testResult={
        testMutation.isSuccess ? (
          <div className="px-0 text-neutral-500 flex flex-col gap-4 font-b2">
            <div className="font-b2-medium flex justify-between">
              Result:{" "}
              <span className="font-b2-medium">
                {JSON.stringify(testMutation.data?.data.data)}
              </span>
            </div>
          </div>
        ) : testMutation.isError ? (
          <div className="text-error-500 font-code flex flex-col gap-2 font-b2 py-3">
            <span>
              {(
                testMutation.error as unknown as {
                  response: { data: FinBoxResponse };
                }
              ).response.data.error
                .split("\n")
                .map((row) => (
                  <Fragment key={row}>
                    {row}
                    <br />
                  </Fragment>
                ))}
            </span>
          </div>
        ) : (
          <></>
        )
      }
    />
  );
}
