import { CSSProperties } from "react";
import { ReactFlowState, useReactFlow, useStore } from "reactflow";

const zoomSelector = (s: ReactFlowState) => s.transform[2] >= 0.84;

const useIsExpand = () => {
  return useStore(zoomSelector);
};

export function useGetNodeFontStyles(
  minSize: number = 13,
  maxSize: number = 20
) {
  const { getZoom } = useReactFlow();
  if (useIsExpand())
    return {
      transitionDuration: "50ms",
      fontSize: "13px",
      fontWeight: "500",
    };
  return {
    transitionDuration: "50ms",
    fontSize: `${minSize + (1 - getZoom()) * 2 * (maxSize - minSize)}px`,
    fontWeight: "500",
  };
}

export function useGetNodeIconStyles(): CSSProperties {
  const { getZoom } = useReactFlow();
  if (useIsExpand())
    return {
      transitionDuration: "50ms",
      height: "16px",
      width: "16px",
    };
  return {
    transitionDuration: "50ms",
    height: `${16 + (1 - getZoom()) * 18}px`,
    width: `${16 + (1 - getZoom()) * 18}px`,
  };
}

export function useGetHandleStyles() {
  if (useIsExpand()) return {};
  return {
    height: 0,
    opacity: 0,
    width: 0,
  };
}

export function useGetHandleStylesForInputDot() {
  const { getZoom } = useReactFlow();
  if (useIsExpand()) return {};
  return {
    height: `${11 + (1 - getZoom()) * 1.5}px`,
    width: `${11 + (1 - getZoom()) * 1.5}px`,
    transitionDuration: "50ms",
  };
}

export function useGetNodeStyles() {
  if (useIsExpand()) return {};
  return {
    borderRadius: "8px",
  };
}

export default useIsExpand;
