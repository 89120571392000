import classNames from "clsx";
import { useReactFlow } from "reactflow";
import { Popover } from "@headlessui/react";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { WfWarning } from "../../validations";

interface WarningsProps {
  warnings: WfWarning[];
}

const Warnings = ({ warnings }: WarningsProps) => {
  const { setCenter, getNode } = useReactFlow();
  return (
    <Popover className="relative">
      <Popover.Button className="h-5 outline-none ring-0">
        <ExclamationCircleIcon
          className={classNames(
            "w-5 h-5 mb-1",
            warnings.length > 0 ? "text-error-500" : "text-white"
          )}
        />
      </Popover.Button>

      {warnings.length > 0 && (
        <Popover.Panel className="min-w-[300px] absolute z-10 left-10 bottom-[0.7rem] bg-white shadow-sm w-max h-max rounded-md overflow-hidden border border-neutral-100">
          <div className="font-b2-medium w-full px-4 py-2 flex items-center justify-between bg-neutral-0 border-b border-neutral-100">
            Errors
            <Popover.Button>
              <XMarkIcon className="h-4 w-4" />
            </Popover.Button>
          </div>
          <ol className="py-1 font-b2 !list-decimal pr-4 pl-7">
            {warnings?.map((w) => (
              <li
                onClick={() => {
                  const node = getNode(w.node);
                  if (!node) return;
                  setCenter(
                    node.position.x + 235,
                    node.position.y + (node.height || 0) / 2,
                    {
                      duration: 500,
                      zoom: 1,
                    }
                  );
                }}
                className="py-1.5 cursor-pointer"
                key={w.message}
              >
                {w.message}
              </li>
            ))}
          </ol>
        </Popover.Panel>
      )}
    </Popover>
  );
};

export default Warnings;
