import { ReactComponent as PlayIcon } from "@assets/icons/play.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";

export default function TestButton({ nodeId }: { nodeId: string }) {
  const { setDebuggerNodeId } = useWorkflowContext();

  return (
    <button
      className="p-1 hover:bg-neutral-25 rounded-md"
      onClick={() => {
        setDebuggerNodeId(nodeId);
      }}
    >
      <PlayIcon className="w-4 h-4" />
    </button>
  );
}
