import { subDays } from "date-fns";

export const SHORTCUTS = {
  today: {
    label: "Today",
    period: {
      startDate: new Date(),
      endDate: new Date(),
    },
  },
  yesterday: {
    label: "Yesterday",
    period: {
      startDate: subDays(new Date(), 1),
      endDate: subDays(new Date(), 1),
    },
  },
  last7Days: {
    label: "Last 7 days",
    period: {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
    },
  },
  last30days: {
    label: "Last 30 days",
    period: {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
    },
  },
  last90days: {
    label: "Last 90 days",
    period: {
      startDate: subDays(new Date(), 90),
      endDate: new Date(),
    },
  },
};
