import { getNetworkErrorText, notify } from "src/utils/utils";
import { getErrors } from "@screens/workflow/queries";
import axios from "@axios";
import {
  QueryClient,
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";

function invalidateAfterBranchUpdate(
  qc: QueryClient,
  workflowId: string,
  branchId: string
) {
  qc.invalidateQueries(getBranchConditionsQuery({ workflowId, branchId }));
  qc.invalidateQueries(getErrors(workflowId));
}

export function getBranchConditionsQuery({
  workflowId,
  branchId,
}: {
  workflowId: string;
  branchId: string;
}) {
  return queryOptions({
    queryKey: ["branchConditions", workflowId, branchId],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<
          Array<{
            id: number;
            name: string;
            body: string;
            seqNo: number;
            tag?: string;
          }>
        >
      >(`workflow/${workflowId}/branchNode/${branchId}`),
  });
}

export function useAddBranch() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({
      workflowId,
      branchId,
      name,
      body,
    }: {
      workflowId: string;
      branchId: string;
      body: string;
      name: string;
    }) =>
      axios.post(`workflow/${workflowId}/branchNode/${branchId}`, {
        name,
        body,
      }),
    onSuccess: (_, variables) => {
      invalidateAfterBranchUpdate(qc, variables.workflowId, variables.branchId);
    },
  });
}

export function useUpdateBranch() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({
      workflowId,
      branchId,
      conditionId,
      name,
      body,
    }: {
      workflowId: string;
      branchId: string;
      conditionId: number;
      body: string;
      name: string;
    }) =>
      axios.put(
        `workflow/${workflowId}/branchNode/${branchId}/${conditionId}`,
        {
          name,
          body,
        }
      ),
    onSuccess: (_, variables) => {
      invalidateAfterBranchUpdate(qc, variables.workflowId, variables.branchId);
    },
    onError(error) {
      notify({ title: "Update Failed", text: getNetworkErrorText(error) });
    },
  });
}

export function useDeleteBranch() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({
      workflowId,
      branchId,
      conditionId,
    }: {
      workflowId: string;
      branchId: string;
      conditionId: number;
    }) =>
      axios.delete(
        `workflow/${workflowId}/branchNode/${branchId}/${conditionId}`
      ),
    onSuccess: (_, variables) => {
      invalidateAfterBranchUpdate(qc, variables.workflowId, variables.branchId);
    },
  });
}
