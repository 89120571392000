import { ReactComponent as ShareIcon } from "@assets/icons/share-04.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import Autocomplete from "@components/Autocomplete";
import Button from "@components/Button";
import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useGetWorkflowList } from "@screens/workflow-list/queries";
import { WORKFLOW_NODE_TYPE } from "@screens/workflow/config";
import {
  getErrors,
  getWorkflowKeywordsQuery,
  getWorkflowPredictors,
} from "@screens/workflow/queries";
import InputList from "@screens/workflow/studio/components/InputList";
import NodeMenu from "@screens/workflow/studio/components/NodeMenu";
import useBottomPanelState, {
  CLOSE,
} from "@screens/workflow/studio/hooks/useBottomPanelState";
import useGetIcon from "@screens/workflow/studio/hooks/useGetIcon";
import useIsHiddenAfterTest from "@screens/workflow/studio/hooks/useIsHiddenAfterTest";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { useQueryClient } from "@tanstack/react-query";
import { default as classNames } from "clsx";
import { memo, useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import { WorkflowId } from "src/screens/workflow/types";
import { useWorkflowContext } from "../../../WorkflowContext";
import BottomPanel from "../../components/BottomPanel";
import { ErrorList } from "../../components/ErrorList";
import HandleWithPlus from "../../components/HandleWithPlus";
import StateConnector from "../../components/StateConnectors";
import ConfigureWorkflowModal from "../../components/Workflow/ConfigureWorkflowModal";
import useIsExpand, {
  useGetHandleStyles,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "../../hooks/useIsExpand";
import { getUpdateWorkflowNode } from "../../utils";

const WorkflowNode = ({
  data,
  id,
  selected,
}: NodeProps<{
  hidden?: boolean;
  isFromFlow?: boolean;
  label: string;
  workflowID: string;
  handles: string[];
}>) => {
  const {
    updateWorkflow,
    isWorkflowEditable,
    setShowDeleteConfirmation,
    workflow,
    errors,
  } = useWorkflowContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [bottomPanelStatus, setBottomPanelStatus] = useBottomPanelState();

  const isExpanded = useIsExpand();

  const fontStyles = useGetNodeFontStyles();
  const iconStyles = useGetNodeIconStyles();
  const handleStyles = useGetHandleStyles();
  const nodeStyles = useGetNodeStyles();

  const queryClient = useQueryClient();

  const Icon = useGetIcon(WORKFLOW_NODE_TYPE);

  const onUpdatePolicy = async (p: string) => {
    const e = getUpdateWorkflowNode(p, id);
    return await updateWorkflow(e).then(() => {
      queryClient.invalidateQueries(getWorkflowKeywordsQuery());
      queryClient.invalidateQueries(getWorkflowPredictors(workflow?.id));
      queryClient.invalidateQueries(getErrors(workflow?.id));
    });
  };

  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflow?.id, id);
  const hidden = useIsHiddenAfterTest(id, data?.hidden);
  const workflowList = useGetWorkflowList({
    activeTab: ["active", "readytodeploy", "inactive"],
    page: 1,
    searchQuery,
    enabled: true,
    startDate: null,
    endDate: null,
  });

  const nodeErrors =
    errors?.[id as WorkflowId]?.errors?.split(",").filter((s) => s.length) ??
    [];
  const [configureModal, setConfigureModal] = useState(false);

  return (
    <>
      <div className="flex relative group cursor-grab active:cursor-grabbing">
        <div
          style={nodeStyles}
          className={classNames(
            "overflow-visible w-[360px] h-max bg-white border hover:border-primary-200 rounded-[10px] pt-[7px]",
            isExpanded ? "" : "pb-[7px]",
            {
              "opacity-25": hidden,
              "border-primary-200": selected && !hidden,
              "border-neutral-100": !selected,
              "shadow-lg": data?.isFromFlow && !hidden,
              "shadow-studio-node": !(data?.isFromFlow && !hidden),
            }
          )}
        >
          <Handle
            type="target"
            position={Position.Left}
            className="w-2 h-2 top-0 mt-[18px] opacity-50 hover:opacity-100 border-2 rounded-full bg-neutral-0  border-neutral-300 !-left-4 duration-150 overflow-visible"
            isConnectable={isWorkflowEditable}
            style={handleStyles}
          >
            <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
          </Handle>
          {!isExpanded && (
            <Handle
              type="source"
              position={Position.Right}
              className="w-2 h-2 opacity-50 hover:opacity-100 bg-white hover:bg-primary-50 group-hover:bg-primary-50 border-neutral-500 hover:h-3 hover:w-3 group-hover:h-3 group-hover:w-3 hover:border-primary-300 group-hover:border-primary-300 duration-150 rounded-full !-right-3 top-1/2"
              isConnectable={false}
              style={handleStyles}
            />
          )}

          <div className="w-full text-neutral-black font-medium flex items-center justify-between px-2">
            <div className="flex gap-2 items-center">
              <Icon style={iconStyles} />
              <span className="w-56 truncate" style={fontStyles}>
                {data.label || "Workflow_Name"}
              </span>
            </div>
            <NodeMenu
              isExport={false}
              items={[
                {
                  key: "delete",
                  label: (
                    <span className="text-error-500 flex gap-2 items-center">
                      <TrashIcon className="[&>path]:stroke-error-500 h-3 w-3 -mt-0.5" />
                      Delete
                    </span>
                  ),
                  onClick: () =>
                    setShowDeleteConfirmation({ id: id, label: data.label }),
                },
              ]}
              isFromFlow={!!data.isFromFlow}
            />
          </div>
          {isExpanded && (
            <div className="relative px-2">
              <span className="font-b2 text-neutral-black mb-1">
                <span>Select Workflow</span>
                {data.workflowID && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(`/workflow/${data.workflowID}`, "_blank");
                    }}
                    variant="outline"
                    className="font-b2 !font-medium h-6 w-6 !p-0 group absolute z-10 bottom-1 right-8 justify-center"
                  >
                    <ShareIcon className="[&>path]:stroke-neutral-500 group-hover:[&>path]:stroke-neutral-500 w-3 h-3" />
                  </Button>
                )}
              </span>
              {isWorkflowEditable ? (
                <Autocomplete
                  onChange={(e) => onUpdatePolicy(e.id)}
                  selected={{ name: data.label, id: data.workflowID }}
                  displayKey="name"
                  onSearchChange={setSearchQuery}
                  disableShadow
                  className="nodrag"
                >
                  {(workflowList.data?.workflows ?? []).map((workflow) => (
                    <Combobox.Option
                      key={workflow.id}
                      value={workflow}
                      className={({ active }) =>
                        classNames(
                          "relative cursor-pointer select-none py-2 px-3 text-neutral-black",
                          active ? "bg-neutral-50" : ""
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <span
                            className={classNames(
                              "block truncate",
                              selected && "font-semibold"
                            )}
                          >
                            <span className="text-neutral-500 uppercase mr-1.5">
                              {workflow.program}
                            </span>{" "}
                            {workflow.name}
                          </span>
                          {selected && (
                            <span
                              className={classNames(
                                "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                active && "bg-neutral-25"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Autocomplete>
              ) : (
                <span className="font-b1-medium flex w-[100%] break-all p-2 rounded-md mt-2 border border-neutral-100">
                  {data.label}
                </span>
              )}
            </div>
          )}
          {isExpanded && (
            <>
              {data.workflowID && (
                <button
                  className="right-16 h-6 mx-2 mt-2 text-xs text-neutral-black px-2 border border-neutral-100 rounded-md"
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfigureModal(true);
                  }}
                >
                  Configure
                </button>
              )}
              {configureModal && (
                <ConfigureWorkflowModal
                  parentWfId={workflow?.id!}
                  childWfId={data.workflowID}
                  childwfName={data.label}
                  wfNodeId={id}
                  setConfigureModal={setConfigureModal}
                  isWorkflowEditable={isWorkflowEditable}
                />
              )}
            </>
          )}
          {isExpanded && (
            <div className="border-t mt-3 bg-neutral-0 rounded-b-[10px] font-b2-medium border-neutral-100 px-2 py-1.5 flex gap-6 items-center">
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setBottomPanelStatus("Inputs");
                }}
                className="cursor-pointer text-neutral-500 hover:text-neutral-black"
              >
                Inputs
              </span>
            </div>
          )}
        </div>
        {data?.workflowID && isExpanded && (
          <div
            className={classNames(
              "flex items-center ml-1 -top-[26px] relative",
              hidden && "opacity-25"
            )}
          >
            <StateConnector length={data?.handles?.length} />
            <div className="flex justify-between gap-3 h-max flex-col">
              {data?.handles?.map((h: string) => (
                <HandleWithPlus
                  nodeId={id}
                  key={h}
                  label={h}
                  showAdd={false}
                  position={Position.Right}
                  id={h}
                  type="source"
                  isConnectable={isWorkflowEditable}
                  handleStyles={handleStyles}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {isExpanded && (
        <>
          <BottomPanel
            selected={selected}
            show={bottomPanelStatus === "Inputs"}
          >
            <InputList
              close={() => setBottomPanelStatus(CLOSE)}
              keywords={keywordsQuery}
              query={""}
            />
          </BottomPanel>
          <BottomPanel
            selected={selected}
            show={bottomPanelStatus === "Errors"}
          >
            <ErrorList
              errorCount={nodeErrors.length}
              close={() => setBottomPanelStatus(CLOSE)}
            >
              {nodeErrors.map((desc, index) => (
                <ErrorList.ItemDescription key={desc + index}>
                  {desc}
                </ErrorList.ItemDescription>
              ))}
            </ErrorList>
          </BottomPanel>
        </>
      )}
    </>
  );
};

export default memo(WorkflowNode);
