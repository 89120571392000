import classNames from "clsx";

type Props = {
  text: string;
  className: string | Record<string, boolean>;
};

const Chip = ({ text, className }: Props) => {
  return (
    <span
      className={classNames(
        "inline-flex content-center items-center gap-2 rounded-full px-3 py-1 text-xs font-medium",
        className
      )}
    >
      {text}
    </span>
  );
};

export default Chip;
