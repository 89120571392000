import axios from "src/axios";
import { FinBoxResponse } from "src/types";
import { fetchSimulationsQuery } from "@screens/reports/pages/simulations/queries";
import { TabNames } from "src/components/RunSimulationsModal/SimulationModal.types";
import { useAppState } from "@context/AppProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getNetworkErrorText, notify, toSentenceCase } from "@utils/utils";

export default function useSimulation(onSuccess: (data: any) => void) {
  const { appState } = useAppState();
  return useMutation({
    mutationFn: async ({
      formData,
      type,
    }: {
      formData: FormData;
      type: TabNames;
    }) => {
      const response = await fetch(
        import.meta.env.REACT_APP_API_ENDPOINT +
          `/sentinel/dashboard/${
            type === "Custom" ? "simulation" : "simulationUnifiedData"
          }`,
        {
          method: "POST",
          body: formData,
          headers: { token: appState.token },
        }
      );
      const json = await response.json();
      if (response.ok) return json;
      else throw json;
    },
    retry: false,
    onError: (e: unknown) => {
      notify({
        title: "Could not run simulation",
        text: toSentenceCase((e as { error: string }).error),
      });
    },
    onSuccess,
  });
}

export function useSimulationV2() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({ formData }: { formData: FormData }) =>
      axios.v2.postForm<FinBoxResponse<any>>("simulation", formData),
    retry: false,
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: [
          fetchSimulationsQuery({
            endDate: null,
            startDate: null,
            page: 1,
          }).queryKey[0],
        ],
      });
    },
    onError: (e) => {
      notify({
        title: "Could not run simulation",
        text: getNetworkErrorText(e),
      });
    },
  });
}

export function useUserTypeSimulation(onSuccess: (data:any) => void) {
  const { appState } = useAppState();
  return useMutation({
    mutationFn: async ({
      policyID
    }: {
      policyID: string;
    }) => {
      const response = await fetch(
        import.meta.env.REACT_APP_API_ENDPOINT +
          `/sentinel/dashboard/policybucket/outcomeList?policyId=${policyID}`,
        {
          method: "GET",
          headers: { token: appState.token },
        }
      );
      const json = await response.json();
      if (response.ok) return json;
      else throw json;
    },
    retry: false,
    onError: (e: unknown) => {
      notify({
        title: "Could not run simulation",
        text: toSentenceCase((e as { error: string }).error),
      });
    },
    onSuccess,
  });
}