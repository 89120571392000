import { ReactComponent as DeleteIcon } from "@assets/icons/workflow/trash-02.svg";
import Button from "@components/Button";
import Shimmer from "@components/Shimmer";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import {
  getCurrentSourcesQuery,
  getSourceListQuery,
  useAddSource,
  useDeleteSource,
  useUpdateSourceItem,
} from "@screens/workflow/studio/components/Source/queries";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getNetworkErrorText, notify } from "@utils/utils";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "src/components/DropDown";
import { CurrentSourceItem, SourceItem as SourceItemType } from "./types";

interface SourceItemProps {
  item: CurrentSourceItem;
  index: number;
  nodeId: string;
}

const SourceItem = ({ item, nodeId }: SourceItemProps) => {
  const { isWorkflowEditable } = useWorkflowContext();
  const workflowId = useParams().workflowId;

  const deleteSourceMutation = useDeleteSource();
  const addSourceMutation = useAddSource();
  const updateSourceMutation = useUpdateSourceItem();
  const queryClient = useQueryClient();

  const sourceListQuery = useQuery(getSourceListQuery(workflowId!));
  const navigate = useNavigate();

  if (sourceListQuery.isPending) return <Shimmer className="w-full h-6" />;

  const options =
    sourceListQuery.data?.data.data.reduce((prev, current) => {
      if (current.type in prev) {
        prev[current.type].push(current);
      } else {
        prev[current.type] = [current];
      }
      return prev;
    }, {} as Record<string, SourceItemType[]>) ?? {};

  const openConfigure = (id: number, action: "add" | "edit") => {
    navigate(`/workflow/${workflowId}/${nodeId}/datasource/${action}/${id}`);
  };

  return (
    <div className="relative flex items-center gap-2">
      {isWorkflowEditable && item.type === "customSource" && (
        <Button
          className="absolute z-10 right-16 h-6"
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            openConfigure(item.data_source_id, "edit");
          }}
        >
          Configure
        </Button>
      )}
      {/* <ProtectedComponent
        type={PERMISSIONS_TYPE.policy as PermissionTypes}
        action="view datasource list"
      > */}
      <Dropdown
        className="w-full"
        value={item}
        disabled={!isWorkflowEditable}
        onChange={(e: typeof item) => {
          if (e.type === "customSource") {
            if (item.data_source_id === -1)
              openConfigure(e.data_source_id, "add");
            else
              navigate(
                `/workflow/${workflowId}/${nodeId}/datasource/update/${item.data_source_id}/${e.data_source_id}`
              );
          } else {
            if (item.data_source_id === -1)
              addSourceMutation.mutate({
                workflowId: workflowId!,
                nodeId,
                dataSourceId: e.data_source_id,
                dataSourceType: e.type,
                variables: [],
              });
            else
              updateSourceMutation.mutate({
                workflowId: workflowId!,
                datasourceId: e.data_source_id,
                nodeId,
                variables: [],
                dataSourceType: e.type,
                oldDatasourceId: item.data_source_id,
              });
          }
        }}
      >
        <Dropdown.Button
          className="w-full h-7 text-neutral-black"
          disabled={!isWorkflowEditable}
        >
          {item?.name || "Select a source"}
        </Dropdown.Button>
        <Dropdown.Options className="h-auto">
          {Object.keys(options).map((sourceType) => {
            return (
              <Fragment key={sourceType}>
                <Dropdown.Option
                  value={undefined}
                  className="hover:bg-white"
                  disabled
                  key={sourceType}
                >
                  {
                    {
                      finboxSource: "FinBox Sources",
                      customSource: "Custom Sources",
                      lookup: "Lookups",
                    }[sourceType]
                  }
                </Dropdown.Option>
                {options[sourceType].map((option) => {
                  return (
                    <Dropdown.Option key={option.name} value={option}>
                      {option.name}
                    </Dropdown.Option>
                  );
                })}
              </Fragment>
            );
          })}
        </Dropdown.Options>
      </Dropdown>
      {/* </ProtectedComponent> */}
      {isWorkflowEditable && (
        <DeleteIcon
          onClick={() => {
            if (item.data_source_id === -1) {
              return queryClient.invalidateQueries(
                getCurrentSourcesQuery(workflowId!, nodeId)
              );
            }
            deleteSourceMutation.mutate(
              {
                workflowId: workflowId!,
                dataSourceId: item.data_source_id,
                dataSourceType: item.type,
                nodeId,
              },
              {
                onSuccess: () =>
                  queryClient.invalidateQueries(
                    getCurrentSourcesQuery(workflowId!, nodeId)
                  ),
                onError: (err) =>
                  notify({ title: "Failed", text: getNetworkErrorText(err) }),
              }
            );
          }}
          className="[&:hover>path]:stroke-error-500"
        />
      )}
    </div>
  );
};

export default SourceItem;
