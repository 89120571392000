import classNames from "clsx";

interface LabelProps extends React.ComponentProps<"label"> {}

const Label = ({ className, ...props }: LabelProps) => {
  return (
    <label
      className={classNames(
        "text-neutral-500 font-b2-medium inline-block",
        className
      )}
      {...props}
    >
      {props.children}
    </label>
  );
};

export default Label;
