import alertCircle from "@assets/icons/alert-circle.svg";
import { TrashIcon } from "@heroicons/react/24/outline";
import { SOURCE_NODE_TYPE } from "@screens/workflow/config";
import { getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import BottomPanel from "@screens/workflow/studio/components/BottomPanel";
import ErrorList from "@screens/workflow/studio/components/ErrorList";
import SourceList from "@screens/workflow/studio/components/Source/SourceList";
import { getCurrentSourcesQuery } from "@screens/workflow/studio/components/Source/queries";
import useBottomPanelState, {
  CLOSE,
} from "@screens/workflow/studio/hooks/useBottomPanelState";
import useGetIcon from "@screens/workflow/studio/hooks/useGetIcon";
import useIsHiddenAfterTest from "@screens/workflow/studio/hooks/useIsHiddenAfterTest";
import { ModelId } from "@screens/workflow/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { Handle, NodeProps, Position } from "reactflow";
import { useWorkflowContext } from "../../../WorkflowContext";
import HandleWithPlus from "../../components/HandleWithPlus";
import NodeMenu from "../../components/NodeMenu";
import { NodeName } from "../../components/NodeName";
import useIsExpand, {
  useGetHandleStyles,
  useGetHandleStylesForInputDot,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "../../hooks/useIsExpand";
import { getUpdateModelNode } from "../../utils";

const SourceNode = ({
  id,
  selected,
  data,
}: NodeProps<{
  label: string;
  isFromFlow?: boolean;
  desc: string;
  hidden?: boolean;
}>) => {
  const {
    isWorkflowEditable,
    setShowDeleteConfirmation,
    errors,
    updateWorkflow,
  } = useWorkflowContext();
  // const { getEdges } = useReactFlow();
  //
  // const findUnconnectedHandles = () => {
  //   const isConnected = getEdges()?.some((e) => e.source === id);
  //   return isConnected ? [] : ["model"];
  // };
  // const unconnectedHandles: Array<string> = findUnconnectedHandles();
  const [bottomPanelStatus, setBottomPanelStatus] = useBottomPanelState();

  const fontStyles = useGetNodeFontStyles();
  const iconStyles = useGetNodeIconStyles();
  const handleStyles = useGetHandleStyles();
  const isExpanded = useIsExpand();
  const nodeStyles = useGetNodeStyles();
  const queryClient = useQueryClient();
  const handleStylesInputDot = useGetHandleStylesForInputDot();
  const Icon = useGetIcon(SOURCE_NODE_TYPE);

  const hidden = useIsHiddenAfterTest(id, data?.hidden);

  const workflowId = useParams().workflowId;
  const selectedSourcesQuery = useQuery(
    getCurrentSourcesQuery(workflowId!, id)
  );

  const updateModelNodeName = (name: string) => {
    const e = getUpdateModelNode(name, id, data.desc);
    updateWorkflow(e).then(() => {
      queryClient.invalidateQueries(getWorkflowKeywordsQuery());
    });
  };

  const nodeErrors: [string, string][] =
    selectedSourcesQuery.data?.data.data
      .filter((item) => errors[id as ModelId]?.errors?.[item.id])
      .map((item) => [item.name, errors[id as ModelId].errors[item.id]]) ?? [];

  return (
    <>
      <div className="flex relative cursor-grab active:cursor-grabbing group">
        <div
          style={nodeStyles}
          className={clsx(
            "overflow-visible w-[360px] bg-white rounded-[10px] hover:border-primary-200 border p-[7px]",
            {
              "opacity-25": hidden,
              "border-primary-200": selected && !hidden,
              "border-neutral-100": !selected,
              "shadow-lg": data?.isFromFlow && !hidden,
              "shadow-studio-node": !(data?.isFromFlow && !hidden),
              "pb-2": !isWorkflowEditable,
            }
          )}
        >
          <Handle
            type="target"
            position={Position.Left}
            className="w-2 h-2 top-0 mt-[20px] opacity-50 hover:opacity-100 border-2 rounded-full bg-neutral-0  border-neutral-300 !-left-4 duration-150 overflow-visible"
            isConnectable={isWorkflowEditable}
            style={handleStylesInputDot}
          >
            <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
          </Handle>
          {!isExpanded && (
            <Handle
              type="source"
              position={Position.Right}
              className="!-right-3 top-1/2"
              isConnectable={false}
              style={handleStyles}
            />
          )}
          <div className="w-full text-neutral-black font-medium flex items-center justify-between">
            <div className="flex gap-1.5 items-center">
              {/*<img style={iconStyles} alt="policy" src={sourceIcon} />*/}
              <Icon style={iconStyles} />
              <span className="w-64 truncate" style={fontStyles}>
                <NodeName
                  onChange={(name) => updateModelNodeName(name)}
                  defaultName={data.label || "Model_Node"}
                />
              </span>
            </div>
            <NodeMenu
              isExport={false}
              items={[
                {
                  key: "delete",
                  label: (
                    <span className="text-error-500 flex gap-2 items-center">
                      <TrashIcon className="[&>path]:stroke-error-500 h-3 w-3 -mt-0.5" />
                      Delete
                    </span>
                  ),
                  onClick: () =>
                    setShowDeleteConfirmation({ id: id, label: data.label }),
                },
              ]}
              isFromFlow={!!data.isFromFlow}
            />
          </div>
          {isExpanded && (
            <>
              <SourceList
                nodeId={id}
                selectedSourcesQuery={selectedSourcesQuery}
              />
              {nodeErrors.length > 0 && (
                <span
                  className="absolute bottom-2 right-4 flex items-center gap-1.5 cursor-pointer text-error-500 font-b2-medium "
                  onClick={() => setBottomPanelStatus("Errors")}
                >
                  <img src={alertCircle} alt="!" />
                  {nodeErrors.length} Errors
                </span>
              )}
            </>
          )}
        </div>
        {isExpanded && (
          <HandleWithPlus
            nodeId={id}
            label=""
            showAdd={false}
            position={Position.Right}
            id="source"
            type="source"
            className="!absolute -top-[4px] -right-3"
            isConnectable={isWorkflowEditable}
          />
        )}
      </div>
      <BottomPanel selected={selected} show={bottomPanelStatus === "Errors"}>
        <ErrorList
          errors={nodeErrors}
          close={() => setBottomPanelStatus(CLOSE)}
        />
      </BottomPanel>
    </>
  );
};

export default memo(SourceNode);
