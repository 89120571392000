import classNames from "clsx";
import React from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import Dropdown from "@components/DropDown";
import ErrorText from "@components/ErrorText";
import Loader from "@components/Loader";
import { useGetOutputList } from "../../../queries";
import { FormType } from "../types";

export default function OutputSelect({
  form,
}: {
  form: UseFormReturn<FormType>;
}) {
  const [funnelType, funnelValues, groupByKey, groupByValues] = useWatch({
    control: form.control,
    name: ["funnelType", "funnelValues", "groupByKey", "groupByValues"],
  });

  const outputListQuery = useGetOutputList({
    funnelType,
    funnelValues: funnelValues?.map((p) => p.id) ?? [],
    groupByKey,
    groupByValues,
  });

  const disabled = !Boolean(funnelValues && funnelValues.length);

  return (
    <div
      className={classNames("flex flex-col gap-2", disabled && "opacity-50")}
    >
      <span className="font-b2">Choose Output Variable</span>
      <Controller
        control={form.control}
        name="output"
        rules={{
          required: { value: true, message: "Output is required" },
        }}
        render={({ field }) => {
          return (
            <Dropdown
              value={field.value}
              onChange={field.onChange}
              disabled={disabled}
            >
              <Dropdown.Button
                className={classNames(
                  "bg-white w-full",
                  field.value && "font-b2-medium text-neutral-black"
                )}
              >
                {field.value || "Select"}
              </Dropdown.Button>
              {!disabled && (
                <Dropdown.Options className="h-auto max-h-40">
                  {outputListQuery.isLoading ? (
                    <Loader size="xs" />
                  ) : !!outputListQuery.data?.data.data.length ? (
                    outputListQuery.isSuccess &&
                    outputListQuery.data.data.data.map((r) => (
                      <Dropdown.Option key={r} value={r}>
                        {r}
                      </Dropdown.Option>
                    ))
                  ) : (
                    <Dropdown.Option
                      className="!text-neutral-500 cursor-not-allowed"
                      value="empty"
                      key="empty"
                    >
                      No options
                    </Dropdown.Option>
                  )}
                </Dropdown.Options>
              )}
            </Dropdown>
          );
        }}
      />
      {!!form.formState.errors.output?.message && (
        <ErrorText>{form.formState.errors.output?.message}</ErrorText>
      )}
    </div>
  );
}
