import { PropsWithChildren } from "react";
import Button from "./Button";
import Modal from "./Dialogue";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  action: () => void;
  destructive?: boolean;
  actionText?: string;
}

const ConfirmationModal = ({
  children,
  onClose,
  destructive,
  isOpen,
  title,
  action,
  actionText,
}: PropsWithChildren<ConfirmationModalProps>) => {
  return (
    <Modal onClose={onClose} open={isOpen}>
      <Modal.Panel size="sm">
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          <div className="font-b2-medium">{children}</div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex w-full justify-end gap-2">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant={destructive ? "outline" : "primary"}
              error={destructive}
              className={destructive ? "text-error-500" : ""}
              onClick={() => {
                action();
                onClose();
              }}
            >
              {actionText ?? "Confirm"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
};

export default ConfirmationModal;
