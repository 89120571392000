import classNames from "clsx";
import { Button as FinBlocksButton } from "@finbox-in/finblocks";
import ButtonProps from "@finbox-in/finblocks/dist/lib/components/Button/types";

export default function Button({ className, ...props }: ButtonProps) {
  return (
    <FinBlocksButton
      {...props}
      className={classNames(
        "h-7 text-xs font-semibold rounded-md shadow-none",
        (!props.variant || props.variant === "primary") &&
          "bg-primary-900 enabled:hover:bg-primary-600 focus:ring-0",
        props.variant === "outline" && "font-medium text-neutral-black",
        props.variant === "link-gray" &&
          "hover:bg-white text-neutral-500 hover:text-neutral-black",
        className
      )}
    />
  );
}
