import alertCircle from "@assets/icons/alert-circle.svg";
import { ReactComponent as OpenIcon } from "@assets/icons/share-04.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import TestButton from "@screens/workflow/components/TestButton";
import { DECISION_TABLE_NODE_TYPE } from "@screens/workflow/config";
import { getErrors, getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import { ErrorList } from "@screens/workflow/studio/components/ErrorList";
import InputList from "@screens/workflow/studio/components/InputList";
import NodeMenu from "@screens/workflow/studio/components/NodeMenu";
import { NodeName } from "@screens/workflow/studio/components/NodeName";
import useBottomPanelState, {
  CLOSE,
} from "@screens/workflow/studio/hooks/useBottomPanelState";
import useGetIcon from "@screens/workflow/studio/hooks/useGetIcon";
import useIsHiddenAfterTest from "@screens/workflow/studio/hooks/useIsHiddenAfterTest";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { getUpdateDecisionTableNode } from "@screens/workflow/studio/utils";
import { DecisionTableId } from "@screens/workflow/types";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Handle, NodeProps, Position } from "reactflow";
import { useWorkflowContext } from "../../../WorkflowContext";
import BottomPanel from "../../components/BottomPanel";
import HandleWithPlus from "../../components/HandleWithPlus";
import useIsExpand, {
  useGetHandleStyles,
  useGetHandleStylesForInputDot,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "../../hooks/useIsExpand";

const DecisionTableNode = ({
  data,
  id,
  selected,
}: NodeProps<{
  label: string;
  desc: string;
  matrixId: string;
  isFromFlow?: boolean;
  hidden?: boolean;
}>) => {
  const {
    isWorkflowEditable,
    workflow,
    setShowDeleteConfirmation,
    updateWorkflow,
    errors,
  } = useWorkflowContext();
  const isExpanded = useIsExpand();
  const queryClient = useQueryClient();

  const fontStyles = useGetNodeFontStyles();
  const iconStyles = useGetNodeIconStyles();
  const handleStyles = useGetHandleStyles();
  const nodeStyles = useGetNodeStyles();

  const [bottomPanelStatus, setBottomPanelStatus] = useBottomPanelState();
  const workflowId = useParams<{ workflowId: string }>().workflowId;

  const navigate = useNavigate();
  const Icon = useGetIcon(DECISION_TABLE_NODE_TYPE);

  const updateNodeName = async (name: string) => {
    const e = getUpdateDecisionTableNode(name, id, data.desc);
    await updateWorkflow(e).then(() => {
      queryClient.invalidateQueries(getWorkflowKeywordsQuery());
      queryClient.invalidateQueries(getErrors(workflow?.id));
    });
  };

  const hidden = useIsHiddenAfterTest(id, data?.hidden);

  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflowId, id);
  const handleStylesInputDot = useGetHandleStylesForInputDot();

  const nodeErrors: string[] =
    errors[id as DecisionTableId]?.errors?.split(",") ?? [];

  return (
    <>
      <div className="flex relative cursor-grab group items-center">
        <div
          style={nodeStyles}
          className={clsx(
            "overflow-visible active:cursor-grabbing w-80 pt-[7px] bg-white rounded-[10px] hover:border-primary-200 border",
            isExpanded ? "" : "pb-[7px]",
            {
              "opacity-25": hidden,
              "border-primary-200 z-10": selected && !hidden,
              "border-neutral-100": !selected,
              "shadow-lg": data?.isFromFlow && !hidden,
              "shadow-studio-node": !(data?.isFromFlow && !hidden),
              "!border-error-500": !isExpanded && nodeErrors.length > 0,
            }
          )}
        >
          <Handle
            type="target"
            position={Position.Left}
            className="w-2 h-2 top-0 mt-[20px] opacity-50 hover:opacity-100 border-2 rounded-full bg-neutral-0  border-neutral-300 !-left-4 duration-150 overflow-visible"
            isConnectable={isWorkflowEditable}
            style={handleStylesInputDot}
          >
            <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
          </Handle>
          {!isExpanded && (
            <Handle
              type="source"
              position={Position.Right}
              className="w-2 h-2 opacity-50 hover:opacity-100 bg-white hover:bg-primary-50 group-hover:bg-primary-50 border-neutral-500 hover:h-3 hover:w-3 group-hover:h-3 group-hover:w-3 hover:border-primary-300 group-hover:border-primary-300 duration-150 rounded-full !-right-3 top-1/2"
              isConnectable={false}
              style={handleStyles}
            />
          )}
          <div className="w-full text-neutral-black font-medium flex items-center justify-between px-2">
            <div className="flex items-center">
              <Icon style={iconStyles} />
              <span className="w-44 truncate" style={fontStyles}>
                <NodeName
                  onChange={(name) => updateNodeName(name)}
                  defaultName={data.label || "Decision_Table"}
                />
              </span>
            </div>
            <span className="mr-auto" />
            {isExpanded && (
              <span
                onClick={() => {
                  navigate(`/workflow/${workflowId}/matrix/${data.matrixId}`, {
                    replace: true,
                  });
                }}
                role="button"
                className="-translate-x-2 font-b2 font-normal py-1 flex"
              >
                <OpenIcon className="w-4 h-4 [&>path]:stroke-primary-900" />
              </span>
            )}
            <TestButton nodeId={id} />
            <NodeMenu
              isExport={false}
              items={[
                {
                  key: "delete",
                  label: (
                    <span className="text-error-500 flex gap-2 items-center">
                      <TrashIcon className="[&>path]:stroke-error-500 h-3 w-3 -mt-0.5" />
                      Delete
                    </span>
                  ),
                  onClick: () =>
                    setShowDeleteConfirmation({ id: id, label: data.label }),
                },
              ]}
              isFromFlow={!!data.isFromFlow}
            />
          </div>
          {isExpanded && (
            <div className="mt-2 bottom-[1px] px-2 py-1.5 left-[1px] border-t bg-neutral-0 rounded-b-[10px] font-b2-medium border-neutral-100 flex gap-6 items-center">
              <span
                className="cursor-pointer text-neutral-500 hover:text-neutral-black"
                onClick={(e) => {
                  e.stopPropagation();
                  setBottomPanelStatus("Inputs");
                }}
              >
                Inputs
              </span>
              {nodeErrors.length > 0 && (
                <span
                  className="flex items-center ml-auto gap-1.5 cursor-pointer text-error-500 font-b2-medium"
                  onClick={() => setBottomPanelStatus("Errors")}
                >
                  <img src={alertCircle} alt="!" />
                  {nodeErrors.length}{" "}
                  {nodeErrors.length === 1 ? " Error" : " Errors"}
                </span>
              )}
            </div>
          )}
        </div>
        {data?.matrixId && isExpanded && (
          <HandleWithPlus
            nodeId={id}
            label=""
            className="-top-4"
            showAdd={false}
            position={Position.Right}
            id="decisionTable"
            type="source"
            isConnectable={isWorkflowEditable}
            handleStyles={handleStyles}
          />
        )}
      </div>
      <BottomPanel
        width="320px"
        show={bottomPanelStatus === "Inputs"}
        selected={selected}
      >
        <InputList
          close={() => setBottomPanelStatus(CLOSE)}
          keywords={keywordsQuery}
          query=""
        />
      </BottomPanel>
      <BottomPanel
        width="320px"
        selected={selected}
        show={bottomPanelStatus === "Errors"}
      >
        <ErrorList
          errorCount={nodeErrors.length}
          close={() => setBottomPanelStatus(CLOSE)}
        >
          {nodeErrors.map((desc) => (
            <ErrorList.ItemDescription key={desc}>
              {desc}
            </ErrorList.ItemDescription>
          ))}
        </ErrorList>
      </BottomPanel>
    </>
  );
};

export default memo(DecisionTableNode);
