import axios from "@axios";
import { LOCALSTORAGE_KEYS } from "@config";
import {
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";

interface AppStateType {
  token: string;
  name: string;
  theme: "light" | "dark";
  isAuthenticated: boolean;
  paletteOpen: boolean;
  orgName: string;
  logoURL?: string;
}

export type AppStateContextType = {
  appState: AppStateType;
};

export const AppStateContext = createContext<AppStateContextType>({
  appState: {
    token: window.localStorage.getItem(LOCALSTORAGE_KEYS.TOKEN) || "",
    name: window.localStorage.getItem(LOCALSTORAGE_KEYS.NAME) || "",
    theme: "light",
    isAuthenticated: !!window.localStorage.getItem(LOCALSTORAGE_KEYS.TOKEN),
    logoURL: window.localStorage.getItem(LOCALSTORAGE_KEYS.LOGO_URL) || "",
    paletteOpen: false,
    orgName: "",
  },
});

export const AppDispatchContext = createContext<{
  setAppState: setStateType<AppStateType>;
  togglePalette: (value: boolean) => void;
}>({
  setAppState: () => {},
  togglePalette: () => {},
});

const AppProvider = (props: { children: ReactNode }) => {
  const [appState, setAppState] = useState<AppStateType>({
    token: window.localStorage.getItem(LOCALSTORAGE_KEYS.TOKEN) || "",
    name: window.localStorage.getItem(LOCALSTORAGE_KEYS.NAME) || "",
    theme: "light",
    isAuthenticated: !!window.localStorage.getItem(LOCALSTORAGE_KEYS.TOKEN),
    paletteOpen: false,
    orgName: window.localStorage.getItem(LOCALSTORAGE_KEYS.ORG_NAME) || "",
    logoURL: window.localStorage.getItem(LOCALSTORAGE_KEYS.LOGO_URL) || "",
  });

  const togglePalette = (value: boolean) => {
    setAppState((prevState) => ({
      ...prevState,
      paletteOpen: value,
    }));
  };

  axios.defaults.headers.common["token"] = appState.token;
  axios.v2.defaults.headers.common["token"] = appState.token;

  return (
    <AppDispatchContext.Provider
      value={{
        setAppState,
        togglePalette,
      }}
    >
      <AppStateContext.Provider
        value={{
          appState,
        }}
      >
        {props.children}
      </AppStateContext.Provider>
    </AppDispatchContext.Provider>
  );
};

export const useAppState = () => {
  const appState = useContext(AppStateContext);
  if (!appState)
    throw new Error("useAppState can only be used inside an AppStateProvider");
  return appState;
};

export default AppProvider;
