import { memo } from "react";
import { NodeProps, Position } from "reactflow";
import zapIcon from "@assets/icons/zap.svg";
import { useWorkflowContext } from "../../../WorkflowContext";
import HandleWithPlus from "../../components/HandleWithPlus";
import {
  useGetNodeFontStyles,
  useGetNodeIconStyles,
} from "../../hooks/useIsExpand";

const StartNode = ({ id }: NodeProps) => {
  const { isWorkflowEditable } = useWorkflowContext();
  // const { getEdges } = useReactFlow();
  // const findUnconnectedHandles = () => {
  //   const e = getEdges()
  //     ?.filter((e) => e.source === id)
  //     .map((e) => e.sourceHandle);
  //   return ["start"].filter((sh: string) => !e.includes(sh)) || [];
  // };
  // const unconnectedHandles: Array<string> = findUnconnectedHandles();

  const fontStyles = useGetNodeFontStyles();
  const iconStyles = useGetNodeIconStyles();

  return (
    <div className="flex group items-center">
      <div
        className="bg-white shadow-studio-node overflow-hidden rounded-lg flex border border-neutral-100 items-center gap-2 justify-center text-neutral-500 py-2 px-3"
        style={fontStyles}
      >
        <img style={iconStyles} src={zapIcon} alt="" />
        START
      </div>
      <div className="[&>div]:mt-0">
        <HandleWithPlus
          nodeId={id}
          label=""
          showAdd={false}
          position={Position.Right}
          id="start"
          type="source"
          isConnectable={isWorkflowEditable}
        />
      </div>
    </div>
  );
};

export default memo(StartNode);
