import finboxLightLogo from "@assets/finbox_logo_light.svg";
import finboxDarkLogo from "@assets/new_finbox_logo.svg";

export function Logomark(props) {
  return (
    <div {...props}>
      <img src={finboxDarkLogo} className="dark:hidden h-8" alt="finbox" />
      <img
        src={finboxLightLogo}
        className="hidden dark:block h-8"
        alt="finbox"
      />
    </div>
  );
}

export function Logo(props) {
  return (
    <div className="text-2xl font-bold dark:text-white text-slate-800">
      <div {...props}>
        <div className="flex gap-3 items-center ">
          <img src={finboxDarkLogo} className="dark:hidden h-8" alt="finbox" />
          <img
            src={finboxLightLogo}
            className="hidden dark:block h-8"
            alt="finbox"
          />
        </div>
      </div>
    </div>
  );
}
