import { createContext, useState } from "react";

export const NotificationStateContext = createContext({
  appState: {},
});

export const NotificationDispatchContext = createContext({
  setAppState: () => {},
});

const NotificationProvider = (props) => {
  const [errors, setErrors] = useState([]);

  const addError = (error) => {
    setErrors((prevState) => [...prevState, error]);
  };

  const closeError = (errorIndex) => {
    setErrors((prevState) =>
      prevState.filter((err) => err.index !== errorIndex)
    );
  };

  return (
    <NotificationDispatchContext.Provider
      value={{
        addError,
        closeError,
      }}
    >
      <NotificationStateContext.Provider
        value={{
          errors,
        }}
      >
        {props.children}
      </NotificationStateContext.Provider>
    </NotificationDispatchContext.Provider>
  );
};

export default NotificationProvider;
