import { ReactComponent as FileSearch } from "@assets/icons/file-search-02.svg";
import lineChartUp from "@assets/icons/line-chart-up-02.svg";
import rocket from "@assets/icons/rocket-02.svg";
import users from "@assets/icons/users-02.svg";
import { useCardQueries, useUserTrends } from "@screens/overview/queries";
import { ProgramTemplate } from "@types";
import { getPercentage, shortNumber } from "@utils/utils";
import OverviewCard from "./OverviewCard";

type Props = {
  program: Pick<ProgramTemplate, "program" | "label" | "id">;
  dateRange: { startDate: string; endDate: string };
};

export default function StatCards({ program, dateRange }: Props) {
  const [inReviewCountQuery, liveEndpointCountQuery] = useCardQueries({
    dateRange,
    program: program.id,
  });

  const trends = useUserTrends({ dateRange, programID: program.id });

  const currentUserCount = trends.data?.data.data.new.totalCount || 0;
  const previousUserCount = trends.data?.data.data.old.totalCount || 0;
  const currentApprovalRate = trends.data?.data.data.new.totalCount
    ? (((trends.data.data.data.new.metrics.pass ?? 0) +
        (trends.data.data.data.new.metrics.approved ?? 0)) *
        100) /
      currentUserCount
    : 0;
  const previousApprovalRate = trends.data?.data.data.old.totalCount
    ? (((trends.data.data.data.old.metrics.pass ?? 0) +
        (trends.data.data.data.old.metrics.approved ?? 0)) *
        100) /
      previousUserCount
    : 0;

  const cardConfig = [
    {
      title: "User Count",
      key: "User Count",
      value: shortNumber(currentUserCount),
      deltaPerc: getPercentage(
        currentUserCount - previousUserCount,
        previousUserCount
      ),
      isLoading: trends.isLoading,
      icon: <img alt="" src={users} className="mr-2 w-4 h-4" />,
    },
    {
      title: "Average Approval Rate",
      key: "Average Approval Rate",
      value: currentApprovalRate.toFixed(2) + "%",
      deltaPerc: `${(currentApprovalRate - previousApprovalRate).toFixed(2)}%`,
      isLoading: trends.isLoading,
      icon: <img alt="" src={lineChartUp} className="mr-2 w-4 h-4" />,
    },
    {
      title: "Endpoints Live",
      key: "Endpoints Live",
      value: liveEndpointCountQuery.data?.data.data,
      isLoading: liveEndpointCountQuery.isLoading,
      icon: <img alt="" src={rocket} className="mr-2 w-4 h-4" />,
    },
    {
      title: (
        <span className="flex justify-between w-full items-center">
          Policies in Review
          {/* <Link
            to="/policies?status=inreview"
            className="ml-auto font-b2-medium text-primary-900"
          >
            View All
          </Link> */}
        </span>
      ),
      key: "Policies in Review",
      value: inReviewCountQuery.data?.data.data ?? "-",
      isLoading: inReviewCountQuery.isLoading,
      icon: <FileSearch className="mr-2 w-4 h-4 [&>path]:stroke-primary-900" />,
    },
  ];

  return (
    <div className="flex justify-between my-6">
      <div className="grid w-full grid-cols-4 items-stretch justify-items-stretch gap-6">
        {cardConfig.map((item) => (
          <OverviewCard key={item.key} item={item} />
        ))}
      </div>
    </div>
  );
}
