import sentinelLogo from "@assets/sentinel-logo.svg";
import { SideBarOption, sideBarTopOptions } from "@config";
import { useAppState } from "@context/AppProvider";
import { useGetAllMonitors } from "@screens/monitor/queries";
import classNames from "clsx";
import { Fragment, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import usePermissions from "src/context/PermissionContext";
import { PermissionTypes } from "src/types";

type NavLinkProps = {
  item: SideBarOption;
  isExpanded?: boolean;
  className?: string;
  iconClassName?: string;
};

const getIsCurrent = (
  href: (typeof sideBarTopOptions)[number][number]["href"],
  path: string
) => {
  switch (href) {
    case "/":
      return path === "/";
    case "/policies":
      return path === "/policies" || path.startsWith("/details");
    case "/endpoints":
      return path.startsWith("/endpoints");
    case "/datasources":
      return path.startsWith("/datasources");
    case "/analytics":
      return path.startsWith("/analytics");
    case "/reports":
      return path.startsWith("/reports") || path.startsWith("/outcome");
    case "/monitors":
      return path.startsWith("/monitors");
    case "/settings":
      return path.startsWith("/settings");
  }
};

function NavLink({ item, isExpanded, className }: NavLinkProps) {
  const location = useLocation();
  const current = getIsCurrent(item.href, location.pathname);
  const { getMultiplePagePermissions } = usePermissions();
  const permissions = getMultiplePagePermissions(
    [
      PERMISSIONS_TYPE.monitorsCustom,
      PERMISSIONS_TYPE.monitorLogs,
      PERMISSIONS_TYPE.monitorsEndpoint,
    ] as PermissionTypes[],
    "view"
  );
  const getAlertMonitor = useGetAllMonitors({
    permissions: !!permissions,
    state: "error",
  });
  const getWarningMonitor = useGetAllMonitors({
    permissions: !!permissions,
    state: "warning",
  });

  return (
    <Link
      key={item.name}
      to={item.href}
      className={classNames(
        current ? "bg-primary-50 text-primary-900" : "text-neutral-500",
        "group mx-2 flex items-center px-1.5 h-8 font-b2-medium group duration-100 hover:text-primary-900 rounded-md",
        className
      )}
    >
      <div className="relative">
        <item.icon
          className={classNames(
            current
              ? "stroke-primary-900 [&>path]:stroke-primary-900"
              : "stroke-neutral-500",
            "h-5 w-5 flex-shrink-0 stroke-2 mr-3 group-hover:stroke-primary-900 duration-100 group-hover:[&>path]:stroke-primary-900"
          )}
          aria-hidden="true"
        />
        {item.name === "Monitors" &&
          typeof getAlertMonitor?.data?.totalCount === "number" &&
          typeof getWarningMonitor?.data?.totalCount === "number" && (
            <>
              {getAlertMonitor?.data?.totalCount > 0 && (
                <span className="absolute rounded w-2 h-2 bg-red-500 top-[-2px] right-2.5"></span>
              )}
              {getAlertMonitor?.data?.totalCount === 0 &&
                getWarningMonitor?.data?.totalCount > 0 && (
                  <span className="absolute rounded w-2 h-2 bg-yellow-500 top-[-2px] right-2.5"></span>
                )}
            </>
          )}
      </div>
      <span className="whitespace-nowrap">{isExpanded && item.name}</span>
    </Link>
  );
}

const Sidebar = () => {
  const [expanded, setExpanded] = useState(false);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const navigate = useNavigate();
  const appState = useAppState();
  const { getSidebarPermissions } = usePermissions();
  return (
    <div
      onMouseEnter={() => {
        timeout.current = setTimeout(() => setExpanded(true), 300);
      }}
      onMouseLeave={() => {
        clearTimeout(timeout.current);
        setExpanded(false);
      }}
      style={{
        boxShadow: expanded
          ? "0px 0px 4px -2px rgba(16, 24, 41, 0.04), 0px 4px 8px -2px rgba(134, 134, 134, 0.10)"
          : "none",
      }}
      className={classNames(
        "fixed top-0 bottom-0 z-[102] bg-white flex min-h-0 w-max flex-col border-r border-neutral-100 shrink-0 grow-0 0px 0px 4px -2px",
        { "w-12": !expanded },
        {
          "w-[216px]": expanded,
        }
      )}
    >
      <div
        className={classNames(
          "flex flex-col bg-transparent pt-3 duration-150 h-full",
          { "w-12": !expanded },
          { "w-[216px]": expanded }
        )}
        style={{
          boxShadow: expanded
            ? "0px 0px 4px -2px rgba(16, 24, 41, 0.04), 0px 4px 8px -2px rgba(134, 134, 134, 0.10)"
            : "none",
        }}
      >
        <div className="flex max-w-xs flex-col">
          <nav className="flex flex-col gap-5" aria-label="Sidebar">
            <div className="flex gap-0 items-center pt-1 pb-1">
              {appState.appState.logoURL ? (
                <img
                  alt="FinBox"
                  src={appState.appState.logoURL}
                  onClick={() => navigate("/")}
                  className="h-5 w-5 ml-[14px] mx-4 cursor-pointer"
                />
              ) : (
                <img
                  alt="FinBox"
                  src={sentinelLogo}
                  onClick={() => navigate("/")}
                  className="h-6 w-6 ml-3 mr-2 cursor-pointer"
                />
              )}
              {expanded && (
                <span className="truncate font-medium text-[14px]">
                  {appState.appState.orgName}
                </span>
              )}
            </div>
            {sideBarTopOptions.map((items, index) => {
              const visibleItems = items.filter((item) =>
                getSidebarPermissions(
                  item?.permissionsTag as PermissionTypes | PermissionTypes[]
                )
              );
              if (visibleItems.length === 0) return null;
              return (
                <Fragment key={index}>
                  {visibleItems.map((item) => (
                    <NavLink
                      key={item.name}
                      item={item}
                      isExpanded={expanded}
                    />
                  ))}
                  <span
                    className={classNames(
                      "h-[1px] last:hidden duration-150 inline-block border-t border-[#d9d9d9] mx-2",
                      expanded ? "w-[200px]" : "w-8"
                    )}
                  />
                </Fragment>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
