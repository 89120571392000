import classNames from "clsx";
import { Fragment } from "react";
import { FloatProvider, useFloat } from "src/context/FloatContext";
import { FloatingPortal } from "@floating-ui/react";
import { Menu as HeadlessMenu, Transition } from "@headlessui/react";

const MenuRoot = ({
  className,
  ...props
}: Parameters<typeof HeadlessMenu>[0]) => {
  return (
    <FloatProvider placement="bottom-start">
      <HeadlessMenu
        as="div"
        className={classNames("relative", className)}
        {...props}
      />
    </FloatProvider>
  );
};

const Items = (props: Parameters<typeof HeadlessMenu.Items>[0]) => {
  const { x, y, strategy, refs } = useFloat();

  return (
    <FloatingPortal>
      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <HeadlessMenu.Items
          ref={refs.setFloating}
          className={classNames(
            "absolute right-0 top-10 box-border w-max rounded-md overflow-hidden bg-white shadow-lg ring-1 ring-gray-100 z-50",
            props.className
          )}
          style={{
            width: "max-content",
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            zIndex: 999,
          }}
          {...props}
        />
      </Transition>
    </FloatingPortal>
  );
};

type ItemProps = Parameters<typeof HeadlessMenu.Item>[0] & {
  children: React.ReactNode;
  showTick?: boolean;
};

const Item = ({ children, showTick, className, ...props }: ItemProps) => {
  return (
    <HeadlessMenu.Item className="cursor-pointer" {...props}>
      {typeof children === "function"
        ? children
        : ({ active, disabled }) => (
            <div>
              <div
                className={classNames(
                  "font-b2 text-neutral-black",
                  { "bg-neutral-25 ": active },
                  { "!text-neutral-500 !cursor-not-allowed": disabled },
                  className
                )}
              >
                {children}
              </div>
            </div>
          )}
    </HeadlessMenu.Item>
  );
};

const Button = ({
  className,
  children,
  ...props
}: Parameters<typeof HeadlessMenu.Button>[0]) => {
  const { refs } = useFloat();

  return (
    <HeadlessMenu.Button
      ref={refs.setReference}
      className={classNames(
        "flex items-center justify-between rounded-md px-2 py-1.5 text-sm text-gray-500 shadow-sm ring-1 ring-gray-300",
        className
      )}
      {...props}
    >
      {children}
    </HeadlessMenu.Button>
  );
};

let FloatingMenu = Object.assign(MenuRoot, {
  ...HeadlessMenu,
  Items,
  Item,
  Button,
});

export default FloatingMenu;
