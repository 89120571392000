import { AxiosResponse } from "axios";
import axios from "@axios";
import { DATE_FORMAT, SIMULATIONS_PAGE_LIMIT } from "@config";
import {
  keepPreviousData,
  queryOptions,
  useMutation,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import {
  getGQEndTime,
  getGQStartTime,
  getNetworkErrorText,
  normalizeQueryKey,
  notify,
} from "@utils/utils";
import { SimulationData } from "./types";

export const getTemplateQuery = (policyId?: string) => ({
  queryKey: ["sampleCSV", policyId],
  queryFn: async () => {
    return axios.post<FinBoxResponse<{ url: string }>>("simulation/simulationDump", {
      policy_id: policyId,
      sample_csv: true,
    });
  },
  select: (data: AxiosResponse<FinBoxResponse<{ url: string }>>) =>
    data.data.data.url,
  enabled: !!policyId,
});

export const fetchSimulationsQuery = (args?: {
  startDate: string | null;
  endDate: string | null;
  page: number;
  policyVersion?: string[];
}) =>
  queryOptions({
    queryKey: normalizeQueryKey([
      "fetchSimulations",
      {
        startDate: args?.startDate,
        endDate: args?.endDate,
        page: args?.page,
        policyVersion: args?.policyVersion,
      },
    ]),
    queryFn: async () =>
      axios.get<FinBoxResponse<SimulationData>>("simulation/fetchSimulations", {
        params: {
          policyVersion: args?.policyVersion,
          pageSize: SIMULATIONS_PAGE_LIMIT,
          pageNo: args?.page,
          startDate: getGQStartTime(args?.startDate ?? null, DATE_FORMAT),
          endDate: getGQEndTime(args?.endDate ?? null, DATE_FORMAT),
        },
      }),
    select: (data: AxiosResponse<FinBoxResponse<SimulationData>>) =>
      data?.data?.data,
    refetchInterval: (query) =>
      query.state.data?.data.data.simulations?.some(
        (item) => item.progress.toLowerCase() === "processing"
      )
        ? 5000
        : false,
    placeholderData: keepPreviousData,
  });

export const useDownloadSimulationReport = () => {
  return useMutation({
    mutationFn: async (simulationId: string) =>
      axios.v2.get<FinBoxResponse<string>>(
        `simulation/download/${simulationId}`
      ),
    onSuccess: (data) => window.open(data.data.data, "_blank"),
    onError: (err) =>
      notify({
        title: "Failed",
        text: getNetworkErrorText(err),
      }),
  });
};
