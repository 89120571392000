import Input from "src/components/Input";
import Toggle from "src/components/Toggle";
import { notify } from "src/utils/utils";
import AddIcon from "@assets/icons/addRoundPlus.svg";
import DeleteIcon from "@assets/icons/delete.svg";
import { CurrentApiItem, HeaderVariableType } from "./types";

export const EnterHeaderVariable = ({
  currentApiItem,
  headerVariable,
  setHeaderVariable,
  setapiTested,
}: {
  currentApiItem: CurrentApiItem;
  headerVariable: Array<HeaderVariableType>;
  setHeaderVariable: setStateType<Array<HeaderVariableType>>;
  setapiTested: setStateType<boolean>;
}) => {
  const handleChange = (newValue: string, id: number, field: string) => {
    if (field === "value") setapiTested(false);
    setHeaderVariable((headerVariable) =>
      headerVariable.map((item) =>
        item.id === id ? { ...item, [field]: newValue } : item
      )
    );
  };

  const toggleIsVariable = (id: number) => {
    setHeaderVariable((headerVariable) =>
      headerVariable.map((item) =>
        item.id === id ? { ...item, isVariable: !item.isVariable } : item
      )
    );
  };

  const addNewVariable = () => {
    const newItem = {
      id: Math.floor(Math.random() * Math.pow(10, 15)),
      key: "",
      value: "",
      isVariable: false,
    };
    setHeaderVariable((headerVariable) => [...headerVariable, newItem]);
  };

  const deleteVariable = (id: number) => {
    setHeaderVariable(headerVariable.filter((item) => item.id !== id));
  };

  return (
    <div>
      {headerVariable.map((item) => {
        return (
          <div
            key={item.id}
            className="mt-4 w-full flex gap-4 border-dashed border border-indigo-50 rounded-lg p-2 items-center"
          >
            <Input
              label="Name"
              className="[&>label]:text-indigo-400 [&>label]:font-normal [&>label]:font-b2 w-[240px] [&>input]:font-b1 [&input]:text-indigo-300"
              placeholder="Enter Name..."
              value={item.key}
              onChange={(e) => handleChange(e.target.value, item.id, "key")}
            />

            <Input
              label="Sample Value"
              className="[&>label]:text-indigo-400 [&>label]:font-b2 [&>label]:font-normal w-[240px] [&>input]:font-b1 [&input]:text-indigo-300"
              placeholder="Enter value..."
              value={item.value}
              onChange={(e) => handleChange(e.target.value, item.id, "value")}
            />
            <div className="ml-4">
              <p className="font-b2 text-indigo-400 mb-2 ">Is Variable</p>
              <Toggle
                onChange={() => {
                  toggleIsVariable(item.id);
                  setapiTested(false);
                }}
                value={item.isVariable}
              />
            </div>
            <div className="ml-4">
              <button>
                <img
                  src={DeleteIcon}
                  alt="delete icon"
                  onClick={() => {
                    deleteVariable(item.id);
                    setapiTested(false);
                  }}
                />
              </button>
            </div>
          </div>
        );
      })}
      <button
        className="flex items-center gap-2 justify-center mt-4"
        onClick={addNewVariable}
      >
        <img src={AddIcon} alt="add icon" />
        <p className="font-b1-medium text-primary-900">Add more</p>
      </button>
    </div>
  );
};

export const isJsonFormat = (code: string, msg?: string) => {
  try {
    JSON.parse(code);
    return true;
  } catch (error) {
    if (msg) {
      notify({
        title: `${msg} not in json format `,
        text: "enter data in json format",
        type: "error",
      });
    }
    notify({
      title: "Not JSON format",
      text: "enter data in json format",
      type: "error",
    });
    return false;
  }
};
