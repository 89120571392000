import { getErrors } from "@screens/workflow/queries";
import axios from "@axios";
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { getNetworkErrorText, notify } from "@utils/utils";

export function getMatrixQuery(
  workflowId: string,
  matrixId: string,
  enabled: boolean = true
) {
  return queryOptions({
    queryKey: ["wfDecisionTable", workflowId, matrixId],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<{
          default: string;
          headers: string[];
          rows: any; // Note: adding type any because not using this component for now will handle it id require
        }>
      >(`workflow/${workflowId}/decisionTable/${matrixId}`),
    enabled: Boolean(workflowId && matrixId && enabled),
  });
}

export function useUpdateMatrix() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({
      workflowId,
      matrixId,
      data,
    }: {
      workflowId: string;
      matrixId: string;
      data: {
        default: string;
        headers: string[];
        rows: any;  // Note: adding type any because not using this component for now will handle it id require
      };
    }) => axios.post(`workflow/${workflowId}/decisionTable/${matrixId}`, data),
    onSuccess: (_, vars) => {
      notify({
        title: "Saved",
        text: "Decision table updated",
        type: "success",
      });
      qc.invalidateQueries(getMatrixQuery(vars.workflowId, vars.matrixId));
      qc.invalidateQueries(getErrors(vars.workflowId));
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
  });
}
