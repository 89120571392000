import { useEffect, useState } from "react";
import Label from "src/components/Label";
import circleCheck from "@assets/icons/workflow/circle-check-filled.svg";
import circleQuestion from "@assets/icons/workflow/circle-question-filled.svg";
import circleReject from "@assets/icons/workflow/circle-reject-filled.svg";
import tool from "@assets/icons/workflow/tool-01.svg";

type decision = {
  outcome: string;
  count: number;
};

type SummaryDetails = {
  summary: SummaryProps;
};

type UserMap = {
  [key: string]: string;
};

type SummaryProps = {
  simulation_type: string;
  user_type: string;
  policies: string[];
  date_range: {
    from: string;
    to: string;
  };
  decision_details: decision[];
};

const userMap: UserMap = {
  all: "All Users",
};

const Summary = ({ summary }: SummaryDetails) => {
  const [summaryCount, setSummaryCount] = useState<number>(0);
  const [approvedCount, setApprovedCount] = useState<number>(0);
  const [rejectedCount, setRejectedCount] = useState<number>(0);
  const [cantDecideCount, setCantDecideCount] = useState<number>(0);
  const [customCount, setCustomCount] = useState<number>(0);
  useEffect(() => {
    const summaryDetails = summary?.decision_details;
    const initialValue = 0;
    setSummaryCount(
      summaryDetails?.reduce((acc, detail) => acc + detail.count, initialValue)
    );
    summaryDetails?.forEach((detail) => {
      switch (detail.outcome) {
        case "approved":
          setApprovedCount(detail.count);
          break;
        case "rejected":
          setRejectedCount(detail.count);
          break;
        case "cant_decide":
          setCantDecideCount(detail.count);
          break;
        default:
          setCustomCount(detail.count);
          break;
      }
    });
  }, [summary]);

  return (
    <div>
      <div className="px-4 pt-4 grid grid-cols-3">
        <div>
          <div className="font-b2-medium text-neutral-500 leading-5">
            Data Type
          </div>
          <Label className="mb-2 text-neutral-black font-b1 leading-5">
            {summary?.simulation_type}
          </Label>
        </div>
        <div>
          <div className="font-b2-medium text-neutral-500 leading-5">
            Date Range
          </div>
          <Label className="mb-2 text-neutral-black font-b1 leading-5">
            {summary?.date_range.from + " - " + summary?.date_range.to}
          </Label>
        </div>
        <div>
          <div className="font-b2-medium text-neutral-500 leading-5">
            User Type
          </div>
          <Label className="mb-2 text-neutral-black font-b1 leading-5">
            {userMap[summary?.user_type]}
          </Label>
        </div>
        <div>
          <div className="font-b2-medium text-neutral-500 leading-5">
            Policies
          </div>
          <div className="flex items-center">
            <Label className="mr-2 text-neutral-black font-b1 flex leading-5">
              {summary?.policies[0]}
            </Label>
          </div>
        </div>
      </div>
      <div className="px-4 mt-4">
        <div className="font-b2-medium text-neutral-500 leading-5">Summary</div>
        <label className="py-2 text-neutral-black !font-b1-medium !block leading-5">
          Out of {summaryCount} users evaluated:
        </label>
        <div>
          <Label className="px-2 py-[6px] text-neutral-black font-b2 flex leading-5">
            <img alt="CC" src={circleCheck} className="mr-2" />
            {approvedCount} were Accepted
          </Label>
          <Label className="px-2 py-[6px] text-neutral-black font-b2 flex leading-5">
            <img alt="CR" src={circleReject} className="mr-2" />
            {rejectedCount} were Denied
          </Label>
          <Label className="px-2 py-[6px] text-neutral-black font-b2 flex leading-5">
            <img alt="CQ" src={circleQuestion} className="mr-2" />
            {cantDecideCount} were Can't Decide
          </Label>
          <Label className="px-2 py-[6px] text-neutral-black font-b2 flex leading-5">
            <img alt="Tool" src={tool} className="mr-2" />
            {customCount} were Custom_Response
          </Label>
        </div>
      </div>
    </div>
  );
};

export default Summary;
