import barChart10Icon from "@assets/icons/bar-chart-10.svg";
import barChartSquareUpIcon from "@assets/icons/bar-chart-square-up.svg";
import lineChartUpIcon from "@assets/icons/line-chart-up-01.svg";
import tableIcon from "@assets/icons/table.svg";

export const ADD_CHART_OPTIONS = [
  {
    icon: lineChartUpIcon,
    label: "Outcome vs Traffic",
    key: "outcomeVsTraffic",
  },
  {
    icon: tableIcon,
    label: "Policy Insights",
    key: "policyInsights",
  },
  {
    icon: barChart10Icon,
    label: "Decision Metrics",
    key: "metrics",
  },
  {
    icon: barChartSquareUpIcon,
    label: "Reasons of Rejections",
    key: "reasonsOfRejection",
  },
] as const;

export const CHART_TYPE_ID_MAP = {
  outcomeVsTraffic: 1,
  variableDistribution: 2,
  policyInsights: 3,
  reasonsOfRejection: 4,
};

export const COLORS = ["#1982FF", "#7A66FA", "#FFA133"];
export const TRAFFIC_COLORS = ["#89BFFF", "#BBB1FD", "#FFCF97"];

export const RULE_GRAPH_COLORS = {
  pass: "#12B76A",
  fail: "#F04438",
  "can't decide": "#F79009",
};

export const DEFAULT_COLOR = "#194CFF";
export const DEFAULT_TRAFFIC_COLOR = "#b8b9ba";

export const CHART_TYPE_NAME_MAP = {
  outcomeVsTraffic: "Outcome vs Traffic",
  variableDistribution: "Variable Distribution",
  metrics: "Decision Metrics",
  policyInsights: "Policy Insights",
  reasonsOfRejection: "Top Reasons of Rejection",
};

export const FUNNEL_TYPE_NAMES_MAPPER = {
  program: "Program",
  journey: "Journey",
  endpoint: "Endpoint",
  policybucket: "Policy",
  policy: "Version",
  template: "Template",
};
