import clsx from "clsx";
import { memo } from "react";
import { Handle, NodeProps, Position, useEdges } from "reactflow";
import { ReactComponent as ExpressionIcon } from "@assets/icons/table.svg";
import { useWorkflowContext } from "../../../WorkflowContext";
import HandleWithPlus from "../../components/HandleWithPlus";
import useIsExpand, {
  useGetHandleStyles,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "../../hooks/useIsExpand";

const RunFlowDecisionTableNode = ({
  data,
  id,
  selected,
}: NodeProps<{
  label: string;
  desc: string;
  matrixId: string;
  isFromFlow?: boolean;
  hidden?: boolean;
}>) => {
  const { isWorkflowEditable } = useWorkflowContext();
  const isExpanded = useIsExpand();
  const edges = useEdges();

  const findUnconnectedHandles = () => {
    const e = edges?.filter((e) => e.source === id).map((e) => e.sourceHandle);
    return ["pass", "cant_decide", "fail"].filter((d) => !e.includes(d));
  };
  const unconnectedHandles: Array<string> = findUnconnectedHandles();

  const fontStyles = useGetNodeFontStyles();
  const iconStyles = useGetNodeIconStyles();
  const handleStyles = useGetHandleStyles();
  const nodeStyles = useGetNodeStyles();

  return (
    <>
      <div className="flex cursor-grab active:cursor-grabbing group items-center">
        <div
          style={nodeStyles}
          className={clsx(
            "overflow-visible w-[380px] h-max bg-white rounded-[10px] hover:border-primary-200 border",
            isExpanded ? "p-2" : "p-1",
            {
              "opacity-25": data?.hidden,
              "border-primary-200": selected && !data?.hidden,
              "border-neutral-100": !selected,
              "shadow-lg": data?.isFromFlow && !data?.hidden,
              "shadow-studio-node": !(data?.isFromFlow && !data?.hidden),
            }
          )}
        >
          <Handle
            type="target"
            position={Position.Left}
            className="w-2 h-2 opacity-50 hover:opacity-100 rounded-full bg-white border-neutral-500 !-left-4 duration-150 overflow-visible"
            isConnectable={isWorkflowEditable}
            style={handleStyles}
          >
            <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
          </Handle>
          {!isExpanded && (
            <Handle
              type="source"
              position={Position.Right}
              className="w-2 h-2 opacity-50 hover:opacity-100 bg-white hover:bg-primary-50 group-hover:bg-primary-50 border-neutral-500 hover:h-3 hover:w-3 group-hover:h-3 group-hover:w-3 hover:border-primary-300 group-hover:border-primary-300 duration-150 rounded-full !-right-3 top-1/2"
              isConnectable={false}
              style={handleStyles}
            />
          )}

          <div className="w-full text-neutral-black font-medium flex items-center justify-between mb-1">
            <div className="flex gap-2 items-center pt-1 ml-1">
              <ExpressionIcon style={iconStyles} className="w-4 h-4" />
              <span className="w-64 truncate" style={fontStyles}>
                {data.label}
              </span>
            </div>
          </div>
        </div>
        {data?.matrixId && isExpanded && (
          <HandleWithPlus
            nodeId={id}
            label=""
            showAdd={unconnectedHandles?.includes("decisionTable")}
            position={Position.Right}
            id="decisionTable"
            type="source"
            isConnectable={isWorkflowEditable}
            handleStyles={handleStyles}
          />
        )}
      </div>
    </>
  );
};

export default memo(RunFlowDecisionTableNode);
