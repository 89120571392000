import { createContext, PropsWithChildren, useContext } from "react";

const WfIdContext = createContext("");

export function WfIdProvider(props: PropsWithChildren<{ wfId: string }>) {
  return (
    <WfIdContext.Provider value={props.wfId}>
      {props.children}
    </WfIdContext.Provider>
  );
}

export default function useWfId() {
  return useContext(WfIdContext);
}
