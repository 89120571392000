import classNames from "clsx";

export default function Toggle({
  value,
  onChange,
  disable,
}: {
  value: boolean;
  onChange: (value: boolean) => void;
  disable?: boolean;
}) {
  return (
    <div
      onClick={() => onChange(!value)}
      className={classNames(
        "w-9 h-5 bg-neutral-25 rounded-full relative cursor-pointer transition-all",
        {
          "bg-primary-900": value,
        },
        disable && "pointer-events-none"
      )}
    >
      <span
        className={classNames(
          "absolute rounded-full h-4 w-4 bg-white top-0.5 transition-all",
          {
            "left-full -translate-x-[calc(100%_+_2px)]": value,
            "left-0.5": !value,
          }
        )}
      />
    </div>
  );
}
