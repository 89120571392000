import * as BcPreds from "./bc.json";

interface BcDocsProps {
  query: string;
}

const BcDocs = ({ query }: BcDocsProps) => {
  return (
    <>
      <div className="mx-8 font-semibold text-lg mt-5 text-gray-700">
        Predictors
      </div>
      <div className="mx-8 mt-3 border max-h-[65%] h-max overflow-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr className="divide-x">
              <th scope="col" className="px-6 py-3 sticky top-0 bg-gray-100">
                Predictor
              </th>
              <th scope="col" className="px-6 py-3 sticky top-0 bg-gray-100">
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            {BcPreds.Predictors.filter((e) =>
              e["Finbox variables"].toLowerCase().includes(query)
            ).map((bcp) => (
              <tr
                key={bcp["Finbox variables"]}
                className="divide-x bg-white even:bg-gray-50"
              >
                <td className="px-6 py-4 text-gray-700 font-medium">
                  {bcp["Finbox variables"]}
                </td>
                <td className="px-6 py-4">{bcp.Description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mx-8 font-semibold text-lg mt-8 text-gray-700">
        Monthly Analysis
      </div>
      <div className="mx-8 mt-3 border max-h-[65%] overflow-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr className="divide-x">
              <th scope="col" className="px-6 py-3 sticky top-0 bg-gray-100">
                Predictors
              </th>
              <th scope="col" className="px-6 py-3 sticky top-0 bg-gray-100">
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            {BcPreds.MonthlyAnalysis.filter((e) =>
              e["Finbox variables"].toLowerCase().includes(query)
            ).map((bcp) => (
              <tr
                key={bcp["Finbox variables"]}
                className="divide-x bg-white even:bg-gray-50"
              >
                <td className="px-6 py-4 text-gray-700 font-medium">
                  {bcp["Finbox variables"]}
                </td>
                <td className="px-6 py-4">{bcp.Definition}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mx-8 font-semibold text-lg mt-8 text-gray-700">
        Rolling Monthly Analysis
      </div>
      <div className="mx-8 mt-3 border max-h-[65%] overflow-auto mb-5">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr className="divide-x">
              <th scope="col" className="px-6 py-3 sticky top-0 bg-gray-100">
                Name
              </th>
              <th scope="col" className="px-6 py-3 sticky top-0 bg-gray-100">
                Predictor
              </th>
              <th scope="col" className="px-6 py-3 sticky top-0 bg-gray-100">
                Description
              </th>
              <th scope="col" className="px-6 py-3 sticky top-0 bg-gray-100">
                Txn Type
              </th>
            </tr>
          </thead>
          <tbody>
            {BcPreds.RollingMonthlyAnalysis.filter((e) =>
              e["Finbox variables"].toLowerCase().includes(query)
            ).map((bcp) => (
              <tr
                key={bcp["Finbox variables"] + bcp["Fields(Unique)"]}
                className="divide-x bg-white even:bg-gray-50"
              >
                <td className="px-6 py-4">{bcp["Fields(Unique)"]}</td>
                <td className="px-6 py-4 text-gray-700 font-medium">
                  {bcp["Finbox variables"]}
                </td>
                <td className="px-6 py-4">{bcp.Definition}</td>
                <td className="px-6 py-4">{bcp["Tx Type"]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BcDocs;
