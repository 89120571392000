import classNames from "clsx";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Modal from "src/components/Dialogue";
import EmptyState from "src/components/EmptyState";
import RunSimulationModal from "src/components/RunSimulationsModal";
import BadgeTabs from "@components/BadgeTabs";
import Button from "@components/Button";
import Card from "@components/Card";
import ErrorState from "@components/ErrorState";
import Input from "@components/Input";
import Shimmer from "@components/Shimmer";
import { DATE_RANGES } from "@config";
import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { validateEmail } from "@utils/validate";
import {
  useDownloadPolicyReport,
  useEvaluationMetrics,
  usePolicyDetails,
  usePredictorReport,
} from "../../queries";
import AnalyticsCards from "./AnalyticsCards";
import OutputPieChart from "./OutputPieChart";
import PolicyTrafficGraph from "./PolicyTrafficGraph";
import RuleMetricsTable from "./RuleMetricsTable";
import SubHeader from "./SubHeader";

export const COLOR_CLASS_NAMES = {
  pass: {
    bg: "bg-[#12B76A]",
    beforeBg: "before:bg-[#12B76A]",
  },
  fail: {
    bg: "bg-[#F04438]",
    beforeBg: "before:bg-[#F04438]",
  },
  cant: {
    bg: "bg-[#FDB022]",
    beforeBg: "before:bg-[#FDB022]",
  },
};

export default function PolicyAnalytics() {
  const params = useParams();
  const policyID = params.policyId || "";
  const [showRunSimulationModal, setShowRunSimulationModal] = useState(false);

  const [selectedDateFilter, setSelectedDateFilter] =
    useState<keyof typeof DATE_RANGES>("1W");

  const dateRange = DATE_RANGES[selectedDateFilter];

  const userMetrics = useEvaluationMetrics({
    entityID: policyID,
    startDate: dateRange.startDate || "",
    endDate: dateRange.endDate || "",
    entityType: "policy",
    level: "user",
  });

  const policyDetailQuery = usePolicyDetails({
    policyID,
  });

  const [showDownloadReportModal, setShowDownloadReportModal] = useState(false);
  const [showDownloadPredictorsModal, setShowDownloadPredictorsModal] =
    useState(false);

  return (
    <div className="flex h-full overflow-auto max-h-screen w-full flex-col bg-white pt-12">
      <div className="font-p1-medium text-neutral-black py-1 mt-4 mx-8 flex justify-between">
        <div>{policyDetailQuery.data?.result.policyVersion}</div>
        <div className="flex gap-2">
          <Button onClick={() => setShowRunSimulationModal(true)}>
            Run simulation
          </Button>
          <Menu as="div" className="relative">
            <Menu.Button as={Fragment}>
              <Button variant="outline" className="px-1">
                <EllipsisVerticalIcon className="h-5 w-5" />
              </Button>
            </Menu.Button>
            <Menu.Items
              as="div"
              className="absolute top-full right-0 z-10 w-max translate-y-1 rounded border bg-white shadow text-xs"
            >
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      { "bg-gray-100": active },
                      "w-full cursor-pointer py-2 px-5"
                    )}
                    onClick={() => setShowDownloadReportModal(true)}
                  >
                    Download Report
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      { "bg-gray-100": active },
                      "w-full cursor-pointer py-2 px-5"
                    )}
                    onClick={() => setShowDownloadPredictorsModal(true)}
                  >
                    Download Predictors
                  </div>
                )}
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      </div>
      <div className="flex items-start flex-col gap-4 mx-8">
        <BadgeTabs
          className="w-max mt-4"
          tabs={Object.keys(DATE_RANGES) as (keyof typeof DATE_RANGES)[]}
          onClick={(t) => {
            setSelectedDateFilter(t);
          }}
          currentTab={selectedDateFilter}
        />
        <AnalyticsCards policyID={policyID} dateRange={dateRange} />

        <div className="flex gap-3 h-fit min-w-96 w-full">
          <PolicyTrafficGraph dateRange={dateRange} policyID={policyID} />
          {userMetrics.isPending ? (
            <Shimmer className="h-96 w-full grow-0" />
          ) : (
            <Card className="w-96 grow-0">
              <div className="flex gap-2 flex-col h-96 px-2">
                <SubHeader>Approval Ratio</SubHeader>
                {userMetrics.isError && (
                  <ErrorState error={userMetrics.error}>
                    Could not fetch the metrics data
                  </ErrorState>
                )}
                {userMetrics.isSuccess &&
                  (userMetrics.data.data.data.totalCount > 0 ? (
                    <OutputPieChart
                      data={{
                        approved: userMetrics.data.data.data.passCount,
                        rejected: userMetrics.data.data.data.rejectCount,
                        cantDecide: userMetrics.data.data.data.cantDecideCount,
                        totalCount: userMetrics.data.data.data.totalCount,
                      }}
                    />
                  ) : (
                    <EmptyState>Decision metrics were not found</EmptyState>
                  ))}
              </div>
            </Card>
          )}
        </div>
        <RuleMetricsTable policyID={policyID} dateRange={dateRange} />
      </div>
      <DownloadReportModal
        isOpen={showDownloadReportModal}
        setIsOpen={setShowDownloadReportModal}
        fromDate={dateRange.startDate}
        toDate={dateRange.endDate}
      />
      <DownloadPredictorsModal
        isOpen={showDownloadPredictorsModal}
        setIsOpen={setShowDownloadPredictorsModal}
        fromDate={dateRange.startDate}
        toDate={dateRange.endDate}
      />
      {showRunSimulationModal && policyID && (
        <RunSimulationModal
          showRunSimulationModal={showRunSimulationModal}
          setShowRunSimulationModal={setShowRunSimulationModal}
          policyID={policyID || ""}
        />
      )}
    </div>
  );
}

function DownloadReportModal({
  isOpen,
  setIsOpen,
  fromDate,
  toDate,
}: {
  isOpen: boolean;
  setIsOpen: setStateType<boolean>;
  fromDate: string;
  toDate: string;
}) {
  const downloadReport = useDownloadPolicyReport();
  const { policyId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { email: "" } });

  const submit = ({ email }: { email: string }) => {
    downloadReport.mutate(
      {
        email,
        fromDate,
        toDate,
        policyId,
      },
      {
        onSuccess: () => {
          setIsOpen(false);
          reset();
        },
      }
    );
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Panel size="sm">
        <Modal.Header>Generate Report</Modal.Header>
        <Modal.Body>
          <p className="font-b1">
            We will send this report to the following email id. It can take upto
            several minutes.
          </p>
          <form onSubmit={handleSubmit(submit)}>
            <Input
              {...register("email", {
                required: { value: true, message: "required" },
                validate: validateEmail,
              })}
              className="pb-2"
              error={errors.email ? "Enter a valid email" : ""}
              label="Report Recipient"
              placeholder="Enter email id"
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit(submit)}>Generate</Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
}

function DownloadPredictorsModal({
  isOpen,
  setIsOpen,
  fromDate,
  toDate,
}: {
  isOpen: boolean;
  setIsOpen: setStateType<boolean>;
  fromDate: string;
  toDate: string;
}) {
  const downloadReport = usePredictorReport();
  const { policyId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { email: "" } });

  const submit = ({ email }: { email: string }) => {
    downloadReport.mutate(
      {
        email,
        fromDate,
        toDate,
        policyId: policyId!,
      },
      {
        onSuccess: () => {
          setIsOpen(false);
          reset();
        },
      }
    );
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Panel size="sm">
        <Modal.Header>Generate Report</Modal.Header>
        <Modal.Body>
          <p className="font-b1">
            We will send this report to the following email id. It can take upto
            several minutes.
          </p>
          <form onSubmit={handleSubmit(submit)}>
            <Input
              {...register("email", {
                required: { value: true, message: "required" },
                validate: validateEmail,
              })}
              className="pb-2"
              error={errors.email ? "Enter a valid email" : ""}
              label="Report Recipient"
              placeholder="Enter email id"
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit(submit)}>Generate</Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
}
