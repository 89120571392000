import { ReactComponent as EditIcon } from "@assets/icons/edit-01.svg";
import { ReactComponent as MinusIcon } from "@assets/icons/minus.svg";
import Button from "@components/Button";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Modal from "src/components/Dialogue";
import Dropdown from "src/components/DropDown";
import ErrorText from "src/components/ErrorText";
import Label from "src/components/Label";
import Shimmer from "src/components/Shimmer";
import { titleCase } from "src/utils/utils";
import { FilterSection } from "./FilterSection";
import { useMemberRoleListQuery, useUpdateUser } from "./queries";
import { Filters } from "./types";

type FormInput = {
  filters: Array<{
    funnelType: string;
    funnelList: { name: string; id: string }[];
  }>;
  userRole?: string;
};

type EditMembersSettingsProps = {
  isEditing: boolean;
  advancedSettings: boolean;
  setAdvancedSettings: Dispatch<SetStateAction<any>>;
  filters: Filters;
  setData: Dispatch<SetStateAction<any>>;
  userRole: string;
  userData: {
    dashboard_user_id: string;
    email: string;
    client_id: string;
    name: string;
    role: string;
    filters: Filters;
  };
};

const EditMembersSettings = ({
  isEditing,
  advancedSettings,
  setAdvancedSettings,
  filters,
  setData,
  userData,
  userRole,
}: EditMembersSettingsProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm<FormInput>({
    defaultValues: {
      filters: [{ funnelType: "", funnelList: [] }],
      userRole: "",
    },
    values: {
      filters: filters,
      userRole: userRole,
    },
  });
  const [filterSections, setFilterSections] = useState<number[]>([]);
  const [editingSections, setEditingSections] = useState<number[]>([]);
  const watchFilters = watch("filters");
  const { data: roleList, isSuccess, isPending } = useMemberRoleListQuery();
  const updateUserMutation = useUpdateUser();

  useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      const filtersArray = Object.entries(filters).map(
        ([funnelType, funnelList]) => ({
          funnelType,
          funnelList,
        })
      );
      setValue("filters", filtersArray);
      setFilterSections(
        Array.from({ length: filtersArray.length }, (_, i) => i)
      );
      setEditingSections([]);
    } else {
      setValue("filters", [{ funnelType: "", funnelList: [] }]);
      setFilterSections([0]);
      setEditingSections([0]);
    }
  }, [filters, setValue]);

  const handleDeleteFilterSection = (indexToDelete: number) => {
    const currentFilters = getValues("filters");

    const updatedFilters = currentFilters.filter(
      (_, index) => index !== indexToDelete
    );
    const newFilters =
      updatedFilters.length > 0
        ? updatedFilters
        : [{ funnelType: "", funnelList: [] }];

    setValue("filters", newFilters, { shouldValidate: true });

    setFilterSections((prev) => {
      const updatedSections = prev.filter(
        (_, index) => index !== indexToDelete
      );
      return updatedSections.length > 0 ? updatedSections : [0];
    });
    setEditingSections((prev) => {
      const updatedEditingSections = prev
        .filter((index) => index !== indexToDelete)
        .map((index) => (index > indexToDelete ? index - 1 : index));
      return updatedEditingSections.length > 0 ? updatedEditingSections : [0];
    });
    if (
      newFilters.length === 1 &&
      newFilters[0].funnelType === "" &&
      newFilters[0].funnelList.length === 0
    ) {
      setEditingSections([0]);
    }
  };

  const handleAddAccess = () => {
    const currentFilters = getValues("filters");
    const newIndex = currentFilters.length;

    setValue("filters", [
      ...currentFilters,
      { funnelType: "", funnelList: [] },
    ]);

    setFilterSections((prev) => [...prev, newIndex]);
    setEditingSections((prev) => [...prev, newIndex]);

    setTimeout(() => {
      const newSection = document.getElementById(`filter-section-${newIndex}`);
      if (newSection) {
        newSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const getAvailableFunnelTypes = (sectionIndex: number) => {
    const allFunnelTypes = [
      "program",
      "journey",
      "endpoint",
      "policybucket",
      "policy",
      "template",
    ];
    const selectedFunnelTypes =
      watchFilters && Array.isArray(watchFilters)
        ? watchFilters
            .slice(0, sectionIndex)
            .map((filter) => filter?.funnelType)
            .filter(Boolean)
        : [];
    return allFunnelTypes.filter((type) => !selectedFunnelTypes.includes(type));
  };

  const toggleEditingSection = (index: number) => {
    setEditingSections((prev) => {
      if (prev.includes(index)) {
        if (prev.length === 1) return prev;
        return prev.filter((sectionIndex) => sectionIndex !== index);
      } else {
        const currentFilter = getValues(`filters.${index}`);
        if (currentFilter && currentFilter.funnelType) {
          setValue(`filters.${index}.funnelType`, currentFilter.funnelType);
        }
        return [...prev, index];
      }
    });
  };

  const submit = handleSubmit((data) => {
    const currentFilters = getValues("filters");
    const newFilters = currentFilters.reduce((acc, filter) => {
      if (filter.funnelType && filter.funnelList.length > 0) {
        acc[filter.funnelType] = filter.funnelList;
      }
      return acc;
    }, {} as { [key: string]: { name: string; id: string }[] });

    setData((prevData: any) => ({
      ...prevData,
      role: data.userRole,
      filters: newFilters,
    }));
    setAdvancedSettings(false);
    updateUserMutation.mutate({
      dashboard_user_id: userData?.dashboard_user_id,
      email: userData?.email,
      client_id: userData?.client_id,
      name: userData?.name,
      role: data.userRole,
      filters: newFilters,
    });
  });

  return (
    <Modal open={advancedSettings} onClose={() => setAdvancedSettings(false)}>
      <Modal.Panel size="sm" onClose={() => setAdvancedSettings(false)}>
        <Modal.Header>Edit Settings</Modal.Header>
        <Modal.Body>
          <form onSubmit={submit} className="flex flex-col gap-3">
            <div>
              <Label className="mb-2 flex">User Role</Label>
              {isPending && <Shimmer w="100%" h="25px" />}
              {isSuccess && (
                <Controller
                  name="userRole"
                  rules={{
                    required: { value: true, message: "Role is required" },
                  }}
                  control={control}
                  render={({ field }) => (
                    <Dropdown value={field.value} onChange={field.onChange}>
                      <Dropdown.Button className="w-full">
                        {roleList.find((r) => r.role === field.value)?.role ? (
                          <span className="text-neutral-black">
                            {roleList.find((r) => r.role === field.value)?.role}
                          </span>
                        ) : (
                          "Select Role"
                        )}
                      </Dropdown.Button>
                      <Dropdown.Options className="h-fit">
                        {roleList.map((r) => (
                          <Dropdown.Option key={r.role} showTick value={r.role}>
                            {titleCase(r.role)}
                          </Dropdown.Option>
                        ))}
                      </Dropdown.Options>
                    </Dropdown>
                  )}
                />
              )}
              <ErrorText className="mt-1">{errors.userRole?.message}</ErrorText>
            </div>
            {userRole !== "owner" && (
              <>
                <p className="font-b2">
                  This role has access to, Select Data Level
                </p>
                {filterSections.length > 0 &&
                  filterSections.map((sectionIndex, arrayIndex) => (
                    <div key={sectionIndex} className="flex flex-col gap-2">
                      {editingSections.includes(arrayIndex) ? (
                        <>
                          <div className="flex justify-between items-center">
                            <span className="font-b2">
                              Access {arrayIndex + 1}
                            </span>
                            <div className="gap-3 flex">
                              <button
                                type="button"
                                onClick={() => toggleEditingSection(arrayIndex)}
                                disabled={!isEditing}
                              >
                                <EditIcon className="w-4 h-4 stroke-neutral-500" />
                              </button>
                              <button
                                className=" text-neutral-500"
                                type="button"
                                onClick={() =>
                                  handleDeleteFilterSection(arrayIndex)
                                }
                                disabled={
                                  !isEditing || filterSections.length === 0
                                }
                              >
                                <MinusIcon className="w-4 h-4 stroke-neutral-500" />
                              </button>
                            </div>
                          </div>

                          <FilterSection
                            index={arrayIndex}
                            control={control}
                            availableFunnelTypes={getAvailableFunnelTypes(
                              arrayIndex
                            )}
                            isEditing={
                              isEditing && editingSections.includes(arrayIndex)
                            }
                            selectedType={watchFilters[arrayIndex]?.funnelType}
                          />
                        </>
                      ) : (
                        <div className="flex flex-col gap-2">
                          <div className="flex justify-between">
                            <span className="font-b1">
                              {titleCase(watchFilters[arrayIndex]?.funnelType)}
                            </span>{" "}
                            <div className="gap-3 flex">
                              <button
                                type="button"
                                onClick={() => toggleEditingSection(arrayIndex)}
                                disabled={!isEditing}
                              >
                                <EditIcon className="w-4 h-4 stroke-neutral-500" />
                              </button>
                              <button
                                className=" text-neutral-500"
                                type="button"
                                onClick={() =>
                                  handleDeleteFilterSection(arrayIndex)
                                }
                                disabled={
                                  !isEditing || filterSections.length === 0
                                }
                              >
                                <MinusIcon className="w-4 h-4 stroke-neutral-500" />
                              </button>
                            </div>
                          </div>
                          <div className="flex flex-wrap gap-1">
                            {watchFilters[arrayIndex]?.funnelList.map(
                              (item) => (
                                <span
                                  key={item.id}
                                  className="rounded-md bg-neutral-25 font-b1 text-neutral-black px-2 py-1"
                                >
                                  {item.name}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                <div>
                  <button
                    className="font-b2-medium text-neutral-black hover:text-primary-900"
                    type="button"
                    onClick={handleAddAccess}
                    disabled={!isEditing || filterSections.length >= 6}
                  >
                    + Add Access
                  </button>
                </div>
              </>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!isEditing} onClick={submit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
};

export default EditMembersSettings;
