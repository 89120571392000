import { useState } from "react";

export default function NodeDescription({
  onChange,
  defaultDesc,
  placeholder,
}: {
  defaultDesc: string;
  placeholder: string;
  onChange: (e: string) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  if (isEditing)
    return (
      <textarea
        placeholder="Add ruleset description"
        onBlur={(e) => {
          onChange(e.target.value);
          setIsEditing(false);
        }}
        autoFocus
        className="mt-1 nodrag nowheel resize-none p-1.5 font-b2 items-center rounded-md border border-neutral-100 focus:border-neutral-100 focus:bg-white focus:text-neutral-black sm:text-xs h-[32px] focus:h-[44px] w-[100%]"
        defaultValue={defaultDesc}
      />
    );
  return (
    <div
      onClick={() => setIsEditing(true)}
      className="mt-1 p-1.5 truncate text-neutral-500 font-b2 items-center rounded-md bg-neutral-0 border border-neutral-100 h-7 w-[100%]"
    >
      {defaultDesc || placeholder}
    </div>
  );
}
