import { forwardRef, useImperativeHandle, useRef } from "react";
import { ReactComponent as UploadIcon } from "@assets/icons/upload-cloud-01.svg";
import { TrashIcon } from "@heroicons/react/24/outline";

type Props = {
  file: File | undefined;
  setFile: (file: File | undefined) => void;
  placeholder: string;
};

const FileUpload = forwardRef(({ file, setFile, placeholder }: Props, ref) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => {
    return {
      clearFile: () => {
        if (fileInputRef.current) fileInputRef.current.value = "";
      },
    };
  });

  return (
    <div className="relative h-[120px] group bg-white first-line:relative flex items-center justify-center gap-2 border-dashed rounded-md border p-5 text-xs text-neutral-500 my-4">
      {file ? (
        <span className="flex items-center gap-1">
          {file.name}
          <TrashIcon
            className="z-10 h-4 w-4 cursor-pointer text-red-600"
            onClick={() => {
              setFile(undefined);
              if (fileInputRef.current) fileInputRef.current.value = "";
            }}
          />
        </span>
      ) : (
        <div className="flex justify-between w-[100%] cursor-pointer">
          <div className="flex">
            <span className="stroke-neutral-500 mr-2">
              <UploadIcon className="w-4 h-4" />
            </span>
            <span className="flex items-center gap-2 font-b2 ">
              {placeholder}
            </span>
          </div>
          <div className="text-primary-900 font-b2-medium border-b border-primary-900 ">
            Upload
          </div>
        </div>
      )}
      <input
        ref={fileInputRef}
        onChange={(e) => {
          const file = e.target?.files?.[0];
          if (!file) return;
          setFile(file);
        }}
        type="file"
        className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 opacity-0"
      />
    </div>
  );
});

export default FileUpload;
