import { useLocation, useNavigate } from "react-router-dom";
import InnerSidebar, { InnerSidebarItem } from "src/components/InnerSidebar";
import ProtectedComponent from "src/components/ProtectedComponent";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import { PermissionTypes } from "src/types";

function SidePanel() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <InnerSidebar
      title="Monitors"
      content={
        <div className="px-6 py-4">
          <span className="font-b2-medium text-neutral-500 inline-block mb-1">
            Types
          </span>
          {[
            {
              name: "Endpoint",
              href: "/monitors/endpoint",
              permissionsTag: PERMISSIONS_TYPE.endpoints,
            },
            {
              name: "Custom API",
              href: "/monitors/custom",
              permissionsTag: PERMISSIONS_TYPE.monitorsCustom,
            },
          ].map((item) => (
            <ProtectedComponent
              type={item.permissionsTag as PermissionTypes}
              action={"view"}
            >
              <InnerSidebarItem
                key={item.href}
                selected={location.pathname === item.href}
                onClick={() => navigate(item.href)}
                content={item.name}
              />
            </ProtectedComponent>
          ))}
          <ProtectedComponent
            type={PERMISSIONS_TYPE.monitorLogs as PermissionTypes}
            action={"view"}
          >
            <span className="font-b2-medium pt-4 text-neutral-500 inline-block mb-1">
              History
            </span>
            <InnerSidebarItem
              selected={location.pathname === "/monitors/alerts"}
              onClick={() => navigate("/monitors/alerts")}
              content="Alert Logs"
            />
          </ProtectedComponent>
        </div>
      }
    />
  );
}

export default SidePanel;
