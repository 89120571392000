import { clsx } from "clsx";
import { forwardRef, useRef, useState } from "react";
import { Position } from "reactflow";
import { ReactComponent as DeleteIcon } from "@assets/icons/workflow/trash-02.svg";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import {
  useDeleteBranch,
  useUpdateBranch,
} from "@screens/workflow/studio/components/Branch/queries";
import useIsExpand, {
  useGetHandleStyles,
} from "@screens/workflow/studio/hooks/useIsExpand";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { BranchId } from "@screens/workflow/types";
import Shimmer from "@components/Shimmer";
import { Tooltip } from "@finbox-in/finblocks";
import { getNetworkErrorText, notify } from "@utils/utils";
import CustomEditor from "@components/Editor/CustomEditor";
import HandleWithPlus from "../HandleWithPlus";

type Props = {
  expression: { id: number; name: string; body: string };
  label: string;
  isLoading: boolean;
  isTesting?: boolean;
  isTestOutput?: boolean;
  isSidePanel: boolean;
  isHandleConnected: boolean;
  nodeId: string;
  isDefault: boolean;
  isEdit: boolean;
  setIsEdit: () => void;
  error: string;
  keywordsQuery: ReturnType<typeof useKeywordsFromWorkflowKeywords>;
  showDelete: boolean;
};

const BranchItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      expression,
      label,
      isLoading,
      isSidePanel,
      nodeId,
      isDefault,
      isEdit,
      setIsEdit,
      keywordsQuery,
      showDelete,
    },
    ref
  ) => {
    const [isEditingCondition, setIsEditingCondition] = useState(false);
    const { isWorkflowEditable, errors, workflow } = useWorkflowContext();
    const isExpanded = useIsExpand();

    // Made this a ref because the uptodate value is not available in the setValue function passed to CustomEditor.
    // The onBlur function is attached to the editor when the editor component mounts.
    const outputLabel = useRef(expression.name);

    const handleStyles = useGetHandleStyles();

    const updateBranch = useUpdateBranch();
    const deleteBranch = useDeleteBranch();

    const update = (value: string, type: "name" | "body") => {
      if (type === "name")
        updateBranch.mutate(
          {
            workflowId: workflow?.id!,
            branchId: nodeId,
            conditionId: expression.id,
            name: value,
            body: expression.body,
          },
          {
            onError: (err) =>
              notify({ title: "Failed", text: getNetworkErrorText(err) }),
          }
        );
      if (type === "body")
        updateBranch.mutate({
          workflowId: workflow?.id!,
          branchId: nodeId,
          conditionId: expression.id,
          name: expression.name,
          body: value,
        });
    };

    const remove = () => {
      deleteBranch.mutate({
        workflowId: workflow?.id!,
        branchId: nodeId,
        conditionId: expression.id,
      });
    };

    const error = errors?.[nodeId as BranchId]?.errors?.[expression.name];

    if (!isEdit && !isDefault) {
      return (
        <div
          ref={ref}
          id="branch-item-header"
          className={clsx(
            "bg-neutral-0 border relative border-neutral-100 p-1.5 rounded-md flex flex-col gap-2 font-b2 overflow-visible",
            error && "!border-error-500"
          )}
          onClick={() => setIsEdit()}
        >
          {!isSidePanel && isExpanded && (
            <div className="!absolute -top-3 -right-4 translate-x-1/2">
              <HandleWithPlus
                nodeId={nodeId}
                label={""}
                showAdd={false}
                position={Position.Right}
                id={outputLabel.current}
                type="source"
                isConnectable={isWorkflowEditable}
                handleStyles={handleStyles}
              />
            </div>
          )}
          <div className="flex items-center justify-start gap-3 font-b2-medium">
            {label}
            {isEditingCondition ? (
              <input
                disabled={!isWorkflowEditable}
                autoFocus
                defaultValue={expression.name}
                className="ml-auto font-b2 px-1 nodrag outline-0 rounded-sm border border-neutral-100 h-6"
                onChange={(e) => (outputLabel.current = e.target.value)}
                onBlur={(e) => {
                  if (e.target.value) {
                    update(e.target.value, "name");
                  }
                  setIsEditingCondition(false);
                }}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    (e.target as HTMLInputElement).value
                  ) {
                    update((e.target as HTMLInputElement).value, "name");
                    setIsEditingCondition(false);
                  }
                }}
              />
            ) : (
              <span
                onClick={() => setIsEditingCondition(isWorkflowEditable)}
                className="ml-auto font-b2 font-normal text-right text-neutral-500 cursor-pointer min-w-[30px] min-h-[1rem] max-w-[150px] truncate"
              >
                <Tooltip toolTipContent={expression.name}>
                  {expression.name}
                </Tooltip>
              </span>
            )}
            {!isDefault && showDelete && isWorkflowEditable && (
              <DeleteIcon
                className="cursor-pointer [&:hover>path]:stroke-error-500 w-3 h-3"
                onClick={(e) => {
                  e.stopPropagation();
                  remove();
                }}
              />
            )}
          </div>
          {!isDefault &&
            (isLoading ? (
              <Shimmer w="100%" h="1.5em" />
            ) : (
              <div className="whitespace-nowrap truncate max-w-full text-neutral-500 text-[14px] font-code">
                {expression.body}
              </div>
            ))}
        </div>
      );
    }

    return (
      <div
        ref={ref}
        id="branch-item-header"
        className="bg-neutral-0 border relative border-neutral-100 p-1.5 rounded-md flex flex-col gap-2 font-b2 overflow-visible"
        onClick={() => setIsEdit()}
      >
        {!isSidePanel && isExpanded && (
          <div className="!absolute -top-3 -right-4 translate-x-1/2">
            <HandleWithPlus
              nodeId={nodeId}
              label=""
              showAdd={false}
              position={Position.Right}
              id={outputLabel.current}
              type="source"
              isConnectable={isWorkflowEditable}
              handleStyles={handleStyles}
            />
          </div>
        )}
        <div className="flex nodrag items-center justify-start gap-3">
          {label}
          {isEditingCondition ? (
            <input
              disabled={!isWorkflowEditable}
              autoFocus
              defaultValue={expression.name}
              className="ml-auto font-b2 px-1 nodrag outline-0 rounded-sm border border-neutral-100 h-6"
              onChange={(e) => (outputLabel.current = e.target.value)}
              onBlur={(e) => {
                if (e.target.value) {
                  update(e.target.value, "name");
                }
                setIsEditingCondition(false);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && (e.target as HTMLInputElement).value) {
                  update((e.target as HTMLInputElement).value, "name");
                  setIsEditingCondition(false);
                }
              }}
            />
          ) : (
            <span
              onClick={() => setIsEditingCondition(isWorkflowEditable)}
              className="ml-auto font-b2 text-right font-normal text-neutral-500 cursor-pointer min-w-[30px] min-h-[1rem] max-w-[150px] truncate"
            >
              <Tooltip toolTipContent={expression.name}>
                {expression.name}
              </Tooltip>
            </span>
          )}
          {!isDefault && isWorkflowEditable && showDelete && (
            <DeleteIcon
              className="cursor-pointer [&:hover>path]:stroke-error-500 w-3 h-3"
              onClick={(e) => {
                e.stopPropagation();
                remove();
              }}
            />
          )}
        </div>
        {!isDefault &&
          (isLoading ? (
            <Shimmer w="100%" h="1.5em" />
          ) : (
            <CustomEditor
              setValue={(e) => {
                update(e, "body");
              }}
              value={expression.body}
              monacoOptions={{
                lineNumbers: "off",
                readOnly: !isWorkflowEditable,
                glyphMargin: false,
                fontWeight: "400",
                folding: false,
                lineDecorationsWidth: 0,
                lineNumbersMinChars: 0,
                showFoldingControls: "never",
              }}
            />
          ))}
      </div>
    );
  }
);

export default BranchItem;
