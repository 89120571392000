import axios from "@axios";
import { DATE_FORMAT } from "@config";
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { DateRange, FinBoxResponse } from "@types";
import {
  getGQEndTime,
  getGQStartTime,
  getNetworkErrorText,
  normalizeQueryKey,
  notify,
} from "@utils/utils";
import { AxiosError } from "axios";
import {
  AddChartBody,
  MetricsWithGroupingData,
  MetricsWithoutGroupingData,
  PolicyInsightsWithGroupingData,
  PolicyInsightsWithoutGroupingData,
  ReasonOfRejectionWithGroupingData,
  ReasonOfRejectionWithoutGroupingData,
  Template,
  TimeSeriesWithGroupingData,
  TimeSeriesWithoutGroupingData,
} from "./types";

export default function a(
  type: "template" | "chart",
  templateId?: number,
  chartId?: number
) {
  switch (type) {
    case "template":
      if (templateId) return [type, templateId];
      else return [type, "list"];
    case "chart":
      if (chartId) return [type, templateId, chartId];
      else return [type, "list", templateId];
  }
}

export function useGetFunnelItems(funnelType?: string) {
  return useQuery({
    queryKey: ["funnelList", funnelType],
    queryFn: async () =>
      axios.get<FinBoxResponse<Record<Uuid, string>>>("analytics/funnelList", {
        params: { funnelType: funnelType?.toLowerCase() },
      }),
    enabled: !!funnelType,
  });
}

export function useGetGroupByOptions({
  funnelType,
  funnelValues,
}: {
  funnelType?: string;
  funnelValues?: string[];
}) {
  return useQuery({
    queryKey: [
      "groupByList",
      {
        funnelType,
        funnelValues,
      },
    ],
    queryFn: async () =>
      axios.get<FinBoxResponse<string[]>>("analytics/groupByList", {
        params: {
          funnelType: funnelType?.toLowerCase(),
          funnelValues,
        },
      }),
    enabled: Boolean(funnelType && funnelValues?.length),
  });
}

export function useGetGroupByValues({
  funnelType,
  funnelValues,
  groupByKey,
}: {
  funnelType?: string;
  funnelValues?: string[];
  groupByKey?: string;
}) {
  return useQuery({
    queryKey: [
      "groupByValueList",
      {
        funnelType,
        funnelValues,
        groupByKey,
      },
    ],
    queryFn: async () =>
      axios.get<FinBoxResponse<string[]>>("analytics/groupByValueList", {
        params: {
          funnelType: funnelType?.toLowerCase(),
          funnelValues,
          groupByKey,
        },
      }),
    enabled: Boolean(funnelValues?.length && funnelType && groupByKey),
  });
}

export function useGetOutcomeList({
  funnelType,
  funnelValues,
  groupByKey,
  groupByValues,
}: {
  funnelType?: string;
  groupByKey?: string;
  funnelValues?: string[];
  groupByValues?: string[];
}) {
  return useQuery({
    queryKey: [
      "outcomeList",
      {
        funnelType,
        funnelValues,
        groupByKey,
        groupByValues,
      },
    ],
    queryFn: async () =>
      axios.get<FinBoxResponse<string[]>>("analytics/outcomeList", {
        params: {
          funnelType: funnelType?.toLowerCase(),
          funnelValues,
          groupByKey,
          groupByValues,
        },
      }),
    enabled: Boolean(funnelType && funnelValues?.length),
  });
}

export function useGetOutputList({
  funnelType,
  funnelValues,
  groupByKey,
  groupByValues,
}: {
  funnelType?: string;
  groupByKey?: string;
  funnelValues?: string[];
  groupByValues?: string[];
}) {
  return useQuery({
    queryKey: [
      "outputList",
      {
        funnelType,
        funnelValues,
        groupByKey,
        groupByValues,
      },
    ],
    queryFn: async () =>
      axios.get<FinBoxResponse<string[]>>("analytics/outputList", {
        params: {
          funnelType: funnelType?.toLowerCase(),
          funnelValues,
          groupByKey,
          groupByValues,
        },
      }),
    enabled: Boolean(funnelType && funnelValues?.length),
  });
}

const TEMPLATE_STALE_TIME = 5 * 60 * 1000;

export const templateListQuery = () =>
  queryOptions({
    queryKey: ["templateList"],
    queryFn: async () =>
      axios.get<FinBoxResponse<Template[]>>("analytics/template"),
    staleTime: TEMPLATE_STALE_TIME,
    meta: {
      errorMessage: "Failed to get template list",
    },
  });

export const getTemplateQuery = (templateId?: number) =>
  queryOptions({
    queryKey: ["template", templateId],
    queryFn: async () =>
      axios.get<FinBoxResponse<Template>>(`analytics/template/${templateId}`),
    enabled: !!templateId,
    staleTime: TEMPLATE_STALE_TIME,
    meta: {
      errorMessage: "Failed to get template data",
    },
  });

export function useAddTemplate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (name: string) =>
      axios.post<
        FinBoxResponse<{
          templateId: number;
        }>
      >("analytics/template", {
        name,
        charts: [],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: templateListQuery().queryKey,
      }),
    onError: (err) =>
      notify({
        title: "Failed to add template",
        text: getNetworkErrorText(err),
      }),
  });
}

export function useUpdateTemplate() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({
      templateId,
      name,
      charts,
    }: {
      templateId: number;
      name: string;
      charts: number[];
    }) =>
      axios.put("analytics/template", {
        id: templateId,
        name,
        charts,
      }),
    onError: (err) =>
      notify({ title: "Failed to update", text: getNetworkErrorText(err) }),
    onSuccess: (_, vars) => {
      qc.invalidateQueries(getTemplateQuery(vars.templateId));
      notify({ title: "Success", text: "Updated template", type: "success" });
    },
  });
}

export function useDeleteTemplate() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (templateId: number) => {
      queryClient.setQueryData(templateListQuery().queryKey, (prev) => {
        if (!prev) return;
        const newData = prev.data.data.filter((item) => item.id !== templateId);
        return {
          ...prev,
          data: {
            ...prev.data,
            data: newData,
          },
        };
      });
      return axios.delete(`analytics/template/${templateId}`);
    },
    onError: (err) => {
      queryClient.invalidateQueries({
        queryKey: templateListQuery().queryKey,
      });
      notify({ title: "Failed to delete", text: getNetworkErrorText(err) });
    },
    onSuccess: () =>
      notify({ title: "Success", text: "Deleted template", type: "success" }),
  });
}

export function useAddChart() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({
      templateId,
      body,
    }: {
      templateId: number;
      body: AddChartBody;
    }) =>
      axios.post<FinBoxResponse<{ id: number }>>(
        `analytics/template/${templateId}/chart`,
        body
      ),
    onError: (err) =>
      notify({ title: "Failed to add", text: getNetworkErrorText(err) }),
    onSuccess: (_, { templateId }) => {
      qc.invalidateQueries(getTemplateQuery(templateId));
      notify({ title: "Success", text: "Chart added", type: "success" });
    },
  });
}

export function useUpdateChart() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async ({
      templateId,
      chartId,
      body,
    }: {
      templateId: number;
      chartId: number;
      body: AddChartBody;
    }) =>
      axios.put<FinBoxResponse>(
        `analytics/template/${templateId}/chart/${chartId}`,
        body
      ),
    onError: (err) =>
      notify({ title: "Failed to update", text: getNetworkErrorText(err) }),
    onSuccess: (_, { templateId, chartId }) => {
      notify({
        title: "Success",
        text: "Updated chart config",
        type: "success",
      });
      return Promise.all([
        qc.invalidateQueries({
          queryKey: runChartQuery({ templateId, chartId }).queryKey,
        }),
        qc.invalidateQueries({
          queryKey: getTemplateQuery(templateId).queryKey,
        }),
      ]);
    },
  });
}

export function useDeleteChart() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      templateId,
      chartId,
    }: {
      templateId: number;
      chartId: number;
    }) => {
      queryClient.setQueryData(
        getTemplateQuery(templateId).queryKey,
        (prev) => {
          if (!prev) return prev;
          const newData = structuredClone(prev?.data.data);
          newData.charts = newData.charts.filter((c) => c.id !== chartId);
          return {
            ...prev,
            data: {
              ...prev.data,
              data: newData,
            },
          };
        }
      );
      return axios.delete(`analytics/template/${templateId}/chart/${chartId}`);
    },
    onError: (err, variables) => {
      queryClient.invalidateQueries(getTemplateQuery(variables.templateId));
      notify({ title: "Failed to delete", text: getNetworkErrorText(err) });
    },
    onSuccess: (_) => {
      notify({ title: "Success", text: "Deleted chart", type: "success" });
    },
  });
}

type RunChartResponse =
  | TimeSeriesWithoutGroupingData
  | TimeSeriesWithGroupingData
  | MetricsWithGroupingData
  | MetricsWithoutGroupingData
  | PolicyInsightsWithoutGroupingData
  | PolicyInsightsWithGroupingData
  | ReasonOfRejectionWithGroupingData
  | ReasonOfRejectionWithoutGroupingData;

export const runChartQuery = (args?: {
  templateId: number;
  chartId: number;
  dateRange?: DateRange;
}) =>
  queryOptions({
    queryKey: normalizeQueryKey([
      "runChart",
      args?.templateId,
      args?.chartId,
      args?.dateRange,
    ]),
    queryFn: async () => {
      if (!args) throw Error("invalid args in run chart");
      return axios.get<FinBoxResponse<RunChartResponse>>(
        `analytics/template/${args.templateId}/chart/${args.chartId}/run`,
        {
          params: {
            startDate: getGQStartTime(
              args.dateRange?.startDate ?? null,
              DATE_FORMAT
            ),
            endDate: getGQEndTime(args.dateRange?.endDate ?? null, DATE_FORMAT),
          },
        }
      );
    },
  });

export function useRunChart(args: {
  templateId: number;
  chartId: number;
  dateRange: DateRange;
}) {
  return useQuery(runChartQuery(args));
}

export function getRulesetAnalyticsQuery({
  ruleSetId,
  startDate,
  endDate,
  enabled = true,
}: {
  ruleSetId?: string;
  startDate?: string;
  endDate?: string;
  enabled?: boolean;
}) {
  return queryOptions({
    queryKey: ["rulesetAnalytics", ruleSetId, { startDate, endDate }],
    queryFn: async () => {
      return axios.get<
        FinBoxResponse<
          Array<{
            ruleId: string;
            ruleDescription: string;
            statistics: {
              timestamp: string;
              metrics: Record<string, number>;
              totalCount: number;
            }[];
          }>
        >
      >("analytics/workflow/ruleSetEvaluationStatistics", {
        params: {
          ruleSetId,
          startDate,
          endDate,
        },
      });
    },
    enabled: !!ruleSetId && enabled,
    staleTime: Infinity,
    select: (data) => {
      if (!data.data.data) return null;
      const response: Record<string, (typeof data)["data"]["data"][number]> =
        {};
      for (let rule of data.data.data) {
        response[rule.ruleId] = rule;
      }
      return response;
    },
  });
}

export function getBranchAnalyticsQuery({
  branchId,
  startDate,
  endDate,
  enabled = true,
}: {
  branchId?: string;
  startDate?: string;
  endDate?: string;
  enabled?: boolean;
}) {
  return queryOptions({
    queryKey: ["branchAnalytics", branchId, { startDate, endDate }],
    queryFn: async () => {
      return axios.get<
        FinBoxResponse<null | {
          statistics: Array<{
            timestamp: string;
            metrics: Record<string, number>;
            totalCount: number;
          }>;
        }>
      >(`analytics/workflow/branchEvaluationStatistics`, {
        params: {
          branchNodeId: branchId,
          startDate,
          endDate,
        },
      });
    },
    enabled: !!branchId && enabled,
    staleTime: Infinity,
    select: (data) => {
      if (!data.data.data) return null;
      return {
        statistics: data.data.data.statistics,
      };
    },
  });
}

export function getModelAnalyticsQuery({
  modelId,
  startDate,
  endDate,
  enabled = true,
}: {
  modelId?: string;
  startDate?: string;
  endDate?: string;
  enabled?: boolean;
}) {
  return queryOptions({
    queryKey: ["modelAnalytics", modelId, { startDate, endDate }],
    queryFn: async () => {
      return axios.get<
        FinBoxResponse<
          | {
              graphType: "bar";
              distributionStatistic: {
                key: string;
                value: number;
              }[];
              startDate: string;
              endDate: string;
            }
          | {
              graphType: "histogram";
              distributionStatistic: {
                rangeStart: number;
                rangeEnd: number;
                value: number;
              }[];
              startDate: string;
              endDate: string;
            }
        >
      >("analytics/workflow/modelSetVariableDistribution", {
        params: {
          modelSetNodeItemId: modelId,
          startDate,
          endDate,
        },
      });
    },
    enabled: enabled && !!modelId,
    staleTime: Infinity,
    retry: (count, error) => {
      if (error instanceof AxiosError) {
        return (
          error.response?.data.error !==
            "error getting analytics: invalid output type" && count < 3
        );
      }
      return count < 3;
    },
  });
}

export function useWfDownloadReport() {
  return useMutation({
    mutationFn: async ({
      fromDate,
      toDate,
      policyId,
      email,
    }: {
      fromDate?: string;
      toDate?: string;
      policyId?: string;
      email?: string;
    }) => {
      return axios.get("analytics/workflow/report", {
        params: {
          policyId,
          email,
          fromDate,
          toDate,
        },
      });
    },
    onSuccess: (_, variables) =>
      notify({
        title: "Success",
        text: "The report will be mailed to " + variables.email,
        type: "success",
      }),
    onError: (err) =>
      notify({
        title: "Failed",
        text: getNetworkErrorText(err),
      }),
  });
}

export function useWfPredictorReport() {
  return useMutation({
    mutationFn: async ({
      fromDate,
      toDate,
      policyId,
      email,
    }: {
      fromDate: string;
      toDate: string;
      policyId: string;
      email: string;
    }) =>
      axios.get("analytics/workflow/workflowPredictors", {
        params: {
          policyId,
          email,
          fromDate,
          toDate,
        },
      }),
    onSuccess: (_, variables) =>
      notify({
        title: "Success",
        text: "The report will be mailed to " + variables.email,
        type: "success",
      }),
    onError: (err) =>
      notify({
        title: "Failed",
        text: getNetworkErrorText(err),
      }),
  });
}

export function useAnalyticsStatistics({
  startDate,
  endDate,
  program,
  userId,
  policyVersion,
}: {
  startDate: string;
  endDate: string;
  program?: string[];
  userId?: string;
  policyVersion?: string[];
}) {
  return useQuery({
    queryKey: [
      "decisionStatistics",
      startDate,
      endDate,
      program,
      userId,
      policyVersion,
    ],
    queryFn: async () => {
      return axios.get<
        FinBoxResponse<
          Array<{
            timestamp: string;
            totalCount: number;
            metrics: Record<string, number>;
          }>
        >
      >("analytics/workflow/decisionStatistics", {
        params: {
          startDate,
          endDate,
          user: userId,
          program,
          policyVersion,
        },
      });
    },
  });
}
