import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Loader from "src/components/Loader";
import ProtectedComponent from "src/components/ProtectedComponent";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import SlideFullModal from "src/screens/monitor/components/SlideFullModal";
import { PermissionTypes } from "src/types";
import {
  getMdFileQuery,
  useDataStoreQuery,
  useSaveDataSource,
} from "../create-policy/queries";
import { EditDataSourceModalType, Variable } from "./types";

const EditDataSourceModal = ({
  open,
  selectedSource,
  setOpenModal,
}: EditDataSourceModalType) => {
  const updateVariableData = useSaveDataSource();
  const { data } = useDataStoreQuery(selectedSource?.id);
  const mdFile = useQuery(getMdFileQuery(selectedSource?.additionalInfo));
  const { register, handleSubmit, reset } = useForm<Record<string, string>>({
    values: data?.reduce(
      (prev: Record<string, string>, item: Record<string, string>) => {
        prev[item.name] = item.value;
        return prev;
      },
      {}
    ),
  });

  return (
    <SlideFullModal
      isOpen={open}
      close={() => {
        setOpenModal(false);
      }}
      title={`Edit ${selectedSource.name}`}
      classes={`overflow-auto max-h-[97%]`}
    >
      <>
        {updateVariableData.isPending && (
          <div className="absolute bg-neutral-0/70 left-0 right-0 bottom-0 top-0 flex h-full w-full items-center justify-center">
            <Loader />
          </div>
        )}
        <div className="p-6 border-b flex flex-col gap-4">
          {data?.map((variable: Variable) => {
            return (
              <div
                key={variable.name}
                className="flex justify-between items-center"
              >
                <div className="font-b2-medium">{variable.name}</div>
                <Input
                  inputClassName={`placeholder-neutral-300 ${
                    updateVariableData.isPending
                      ? "!bg-neutral-0 text-gray-500"
                      : ""
                  }`}
                  placeholder={`Enter ${variable.name}`}
                  className="w-[77%]"
                  defaultValue={variable.value}
                  disabled={updateVariableData.isPending}
                  {...register(variable.name, {
                    required: false, //can make this dynamic later on a flag from backend
                  })}
                />
              </div>
            );
          })}
          <div className="flex justify-between items-center">
            <div className="font-b2-medium">Identifier</div>
            <Input
              inputClassName="placeholder-neutral-300 text-gray-500 !bg-neutral-0"
              placeholder="Enter Identifier"
              value={selectedSource?.alias}
              disabled
              className="w-[77%]"
            />
          </div>
        </div>
        <div className="p-6 border-b flex flex-col gap-4">
          <Markdown
            remarkPlugins={[remarkGfm]}
            className="markdown"
            components={{
              code({ node, inline, className, children, ...props }: any) {
                return (
                  <pre
                    className={
                      "bg-neutral-0 p-2 border border-neutral-100 rounded-md font-b1 text-neutral-500"
                    }
                    {...props}
                  >
                    {children}
                  </pre>
                );
              },
            }}
          >
            {mdFile?.data}
          </Markdown>
        </div>
        <div>
          <div className="absolute w-full flex justify-end items-center bottom-0 border-t border-indigo-50 px-6 py-2.5 gap-2 bg-white">
            <Button
              variant="outline"
              size="xs"
              onClick={() => {
                reset();
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <ProtectedComponent
              type={PERMISSIONS_TYPE.apiStore as PermissionTypes}
              action="edit"
            >
              <Button
                variant="primary"
                size="xs"
                disabled={updateVariableData.isPending}
                onClick={handleSubmit(async (formValue) => {
                  await updateVariableData.mutateAsync({
                    variableId: selectedSource.id,
                    variables: formValue,
                  });
                  reset();
                  setOpenModal(false);
                })}
              >
                {selectedSource.status ? "Connect" : "Update"}
              </Button>
            </ProtectedComponent>
          </div>
        </div>
      </>
    </SlideFullModal>
  );
};

export default EditDataSourceModal;
