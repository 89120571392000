import classNames from "clsx";
import { ReactNode } from "react";

const ErrorText = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <span
    className={classNames(
      "text-error-600 font-medium text-xs block",
      className
    )}
  >
    {children}
  </span>
);

export default ErrorText;
