import { useParams } from "react-router-dom";
import { useLookupFunctionsInput } from "@screens/create-policy/queries";
import useMonacoContext from "@screens/workflow/studio/MonacoContext";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { getSuggestionList } from "@screens/workflow/studio/utils";

export default function useNodeSuggestionsProvider(
  keywords: ReturnType<typeof useKeywordsFromWorkflowKeywords>,
  nodeId: string
) {
  const { workflowId } = useParams();
  const { autocompleteProvider } = useMonacoContext();
  const lookupFunctionInputs = useLookupFunctionsInput({
    entityId: workflowId || "",
    entityType: "workflow",
  });

  if (!lookupFunctionInputs.isSuccess) return () => {};
  const { keywords: keywordsList, sourceList } = getSuggestionList(keywords);
  return () =>
    autocompleteProvider({
      nodeId,
      sourceList,
      keywords: keywordsList,
      autoCompleteRecords: {},
      lookupFunctionInputs: lookupFunctionInputs?.data.data,
    });
}
