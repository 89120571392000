import classNames from "clsx";
import { Fragment } from "react";
import { FloatProvider, useFloat } from "src/context/FloatContext";
import { FloatingPortal } from "@floating-ui/react";
import { Popover as HeadlessPopover, Transition } from "@headlessui/react";

const PopoverRoot = ({
  className,
  placement,
  ...props
}: Parameters<typeof HeadlessPopover>[0]) => {
  return (
    <FloatProvider placement={placement || "bottom"}>
      <HeadlessPopover
        as="div"
        className={classNames("relative flex", className)}
        {...props}
      />
    </FloatProvider>
  );
};

const Panel = ({
  className,
  ...props
}: Parameters<typeof HeadlessPopover.Panel>[0]) => {
  const { x, y, strategy, refs } = useFloat();
  return (
    <>
      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <div>
          <FloatingPortal>
            <HeadlessPopover.Panel
              className={classNames(
                "rounded-md overflow-hidden bg-white border border-neutral-100",
                className
              )}
              {...props}
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: "max-content",
                zIndex: 999,
              }}
            />
          </FloatingPortal>
        </div>
      </Transition>
    </>
  );
};

const Button = ({
  className,
  children,
  ...props
}: Parameters<typeof HeadlessPopover.Button>[0]) => {
  const { refs } = useFloat();
  return (
    <HeadlessPopover.Button
      className={classNames("outline-none", className)}
      {...props}
      ref={refs.setReference}
    >
      {children}
    </HeadlessPopover.Button>
  );
};

let Popover = Object.assign(PopoverRoot, {
  ...HeadlessPopover,
  Panel,
  Button,
});

export default Popover;
