import { useState } from "react";
import NotifySelectCombobox, {
  UserType,
} from "@screens/policyDetails/components/NotifySelectCombobox";
import { Badge } from "@finbox-in/finblocks";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PolicyStatus } from "@types";
import SimpleModal from "./Dialogue/SimpleModal";

type Props = {
  oldStatus: PolicyStatus;
  newStatus: PolicyStatus;
  isNotifyModalOpen: boolean;
  entityType?: "policy" | "workflow";
  onClose: () => void;
  action: (
    status: PolicyStatus,
    note?: string,
    emails?: string[]
  ) => Promise<void>;
};

function StatusNotifyModal({
  oldStatus,
  newStatus,
  isNotifyModalOpen,
  onClose,
  action,
  entityType = "policy",
}: Props) {
  const [multiSelectUser, setMultiSelectUser] = useState<UserType>();
  const [note, setNote] = useState("");

  const removeUser = (index: number) => {
    setMultiSelectUser((prevSelectedUser) =>
      (prevSelectedUser || []).filter((_, ind) => ind !== index)
    );
  };

  return (
    <>
      <SimpleModal
        isOpen={isNotifyModalOpen}
        onClose={() => {
          onClose();
        }}
        title={
          entityType === "policy"
            ? "Change Policy Status"
            : "Change Workflow  Status"
        }
        action={async () => {
          const emails = multiSelectUser?.map((item) => item.email);
          action(newStatus, note, emails);
        }}
        confirmationtText="Done"
      >
        <div>
          <div className="mb-6">
            <div className="font-b1-medium ">
              Change {entityType} from
              <Badge
                variant={oldStatus === "draft" ? "secondary" : "warning"}
                size="sm"
                className="inline mx-2"
              >
                {oldStatus}
              </Badge>
              to
              <Badge
                variant={newStatus === "draft" ? "secondary" : "warning"}
                size="sm"
                className="inline mx-2"
              >
                {newStatus}
              </Badge>
            </div>
            <p className="font-b2 text-neutral-500 mt-1.5">
              Changing the status of the {entityType} will be visible in the
              audit logs and notified to the users below
            </p>
          </div>
          <div className="mb-6">
            <h2 className="font-b1-medium mb-2">Notification Recipients</h2>
            <NotifySelectCombobox
              selectedUser={multiSelectUser}
              setSelectedUser={setMultiSelectUser}
            />
          </div>
          {multiSelectUser && multiSelectUser.length > 0 && (
            <div className=" flex flex-wrap mb-6 gap-x-2 gap-y-4">
              {multiSelectUser.map((item, index) => (
                <div
                  className="w-fit flex gap-2 items-center border border-neutral-200 shadow-xs rounded-lg bg-white py-2 px-3"
                  key={item.email}
                >
                  <span className="font-b1-medium text-neutral-800 block truncate max-w-[170px]">
                    {item.email}
                  </span>
                  <XMarkIcon
                    className="h-5 w-5 cursor-pointer !stroke-2 stroke-black hover:text-gray-500"
                    onClick={() => removeUser(index)}
                  />
                </div>
              ))}
            </div>
          )}
          <div className="mb-16">
            <h2 className="font-b1-medium text-indigo-700 mb-2">Add a Note</h2>
            <textarea
              value={note}
              placeholder="Enter Description Name"
              onChange={(e) => setNote(e.target.value)}
              className="block font-b1 w-full rounded-md h-[100px] max-w-xl bg-[#fafafa] overflow-auto border-indigo-50 shadow-sm focus:border-indigo-300 focus:outline-none focus:ring-0 font-b1 resize-none px-3 py-2"
            />
          </div>
        </div>
      </SimpleModal>
    </>
  );
}

export default StatusNotifyModal;
