import { PropsWithChildren } from "react";
import Button from "src/components/Button";
import Modal from "src/components/Dialogue";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  action: () => void;
  destructive?: boolean;
}

export default function ConfirmModal({
  children,
  onClose,
  destructive,
  isOpen,
  title,
  action,
}: PropsWithChildren<ConfirmationModalProps>) {
  return (
    <Modal onClose={onClose} open={isOpen}>
      <Modal.Panel size="sm">
        <Modal.Header className="[&>img]:opacity-40">{title}</Modal.Header>
        <Modal.Body className="">
          <div className="font-b2 ">{children}</div>
        </Modal.Body>
        <Modal.Footer className="!p-0">
          <div className="flex w-full justify-end gap-2 border-t border-neutral-100 py-2 px-4">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant={destructive ? "outline" : "primary"}
              error={destructive}
              onClick={() => {
                action();
                onClose();
              }}
              className=" text-error-500 font-semibold"
            >
              Confirm
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
}
