import deploymentFrame from "@assets/icons/deployment_frame.svg";
import { format, parseISO } from "date-fns";
import EmptyState from "src/components/EmptyState";
import QueryWrapper from "src/components/QueryWrapper";
import Tooltip from "src/components/Tooltip";
import { useGetDeploymentHistory } from "../queries";
import OverviewHeaderCard from "./OverviewHeaderCard";

type Props = {
  dateRange: { startDate: string; endDate: string };
  program: string;
};

const DeploymentHistoryCard = ({ dateRange, program }: Props) => {
  const getHistory = useGetDeploymentHistory({ dateRange, program });

  return (
    <OverviewHeaderCard
      className="basis-1/2 h-[346px] overflow-auto"
      title="Deployment History"
      content={
        <QueryWrapper query={getHistory}>
          {(deploymentHistoryList) => {
            if (!deploymentHistoryList || deploymentHistoryList.length === 0)
              return <EmptyState>No deployment history</EmptyState>;
            return (
              <div className="flex flex-col max-w-full">
                {deploymentHistoryList?.map((row) => {
                  return (
                    <tr className="flex justify-between w-full h-12 px-6 py-3 text-neutral-black border-b border-neutral-50 ">
                      <td className="max-w-fit w-[180px] flex items-center font-b1-medium ">
                        {format(parseISO(row.createdAt), "dd MMM - hh:mm a  ")}
                      </td>
                      <td className="w-auto ml-2 flex items-center font-b1 justify-end">
                        <Tooltip
                          center
                          left
                          tooltipClassName="z-[9999] mt-5"
                          content={
                            <img
                              src={deploymentFrame}
                              alt=""
                              className="mr-1"
                            />
                          }
                          tooltipContent={`${row.program}/${row.journeyName}/${row.endpointName}`}
                        />
                        {row.policyVersion.length < 45 ? (
                          <div className="flex max-w-[320px] truncate">
                            <span className="text-ellipsis overflow-hidden">
                              / {row.policyVersion}
                            </span>
                          </div>
                        ) : (
                          <Tooltip
                            contentClassName="max-w-[320px] truncate"
                            tooltipClassName="z-[9999] mt-5"
                            content={`/ ${row.policyVersion}`}
                            tooltipContent={row.policyVersion}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </div>
            );
          }}
        </QueryWrapper>
      }
    />
  );
};

export default DeploymentHistoryCard;
