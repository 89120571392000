import { WorkflowKeywords } from "@screens/create-policy/queries";
import * as monacoEditor from "monaco-editor";
import { languages, Position } from "monaco-editor";
import { Keywords } from "@screens/create-policy/Models/PredSearch";

export function definition(
  sourceList: Array<string>,
  backEndKeywords: Keywords
) {
  let functionNameList: Array<string> = [];
  try {
    functionNameList = Object.keys(backEndKeywords.functionsList);
  } catch (error) {}
  let keywords = [...sourceList, "model"];
  let builtInFuncs = [
    ...functionNameList,
    "contains",
    "startsWith",
    "endsWith",
    "matches",
  ];

  return {
    keywords,
    builtInFuncs,
    typeKeywords: [],
    operators: [
      "+",
      "-",
      "*",
      "/",
      "%",
      "^",
      "**",
      "==",
      "!=",
      "<",
      ">",
      "<=",
      ">=",
      "!",
      "not",
      "and",
      "&&",
      "or",
      "||",
      "?",
      ":",
      "??",
      "..",
    ],

    // we include these common regular expressions
    symbols: /[=><!~?:&|+\-*/^%]+/,
    escapes:
      /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

    // The main tokenizer for our languages
    tokenizer: {
      root: [
        // identifiers and keywords
        [
          /[a-zA-Z_$][\w$]*/,
          {
            cases: {
              "@typeKeywords": "keyword",
              "@keywords": "keyword",
              "@builtInFuncs": "keyword",
              "@default": "identifier",
            },
          },
        ],
        [/[A-Z][\w$]*/, "type.identifier"], // to show class names nicely

        // whitespace
        { include: "@whitespace" },

        // delimiters and operators
        [/[{}()[\]]/, "@brackets"],
        [/[<>](?!@symbols)/, "@brackets"],
        [
          /@symbols/,
          {
            cases: {
              "@operators": "operator",
              "@default": "",
            },
          },
        ],

        // numbers
        [/\d*\.\d+([eE][-+]?\d+)?[fFdD]?/, "number.float"],
        [/0[xX][0-9a-fA-F_]*[0-9a-fA-F][Ll]?/, "number.hex"],
        [/0[0-7_]*[0-7][Ll]?/, "number.octal"],
        [/0[bB][0-1_]*[0-1][Ll]?/, "number.binary"],
        [/\d+[lL]?/, "number"],

        // delimiter: after number because of .\d floats
        [/[;,.]/, "delimiter"],

        // strings
        [/"([^"\\]|\\.)*$/, "string.invalid"], // non-teminated string
        [/"/, "string", "@string"],

        // characters
        [/'[^\\']'/, "string"],
        [/(')(@escapes)(')/, ["string", "string.escape", "string"]],
        [/'/, "string.invalid"],
      ],

      whitespace: [
        [/[ \t\r\n]+/, "white"],
        [/\/\*/, "comment", "@comment"],
        [/\/\+/, "comment", "@comment"],
        [/\/\/.*$/, "comment"],
      ],

      comment: [
        [/[^/*]+/, "comment"],
        [/\/\+/, "comment", "@push"],
        [/\/\*/, "comment.invalid"],
        ["\\*/", "comment", "@pop"],
        ["\\+/", "comment", "@pop"],
        [/[/*]/, "comment"],
      ],

      string: [
        [/[^\\"]+/, "string"],
        [/@escapes/, "string.escape"],
        [/\\./, "string.escape.invalid"],
        [/"/, "string", "@pop"],
      ],
    },
  };
}

export function getWorkflowDefinitions(
  sourceList: Array<string>,
  backEndKeywords: {
    policies: Record<string, string[]>;
    workflows: Record<string, string[]>;
    predictorsList: Record<string, string[]>;
    input: string[];
    functionsList: Record<
      string,
      { syntax: string; returnType: string; description: string }
    >;
  }
) {
  let functionNameList: Array<string> = [];
  try {
    functionNameList = Object.keys(backEndKeywords?.functionsList ?? {});
  } catch (error) {}
  let builtInFuncs = [
    ...functionNameList,
    "contains",
    "startsWith",
    "endsWith",
    "matches",
  ];

  return {
    keywords: sourceList,
    builtInFuncs,
    typeKeywords: [],
    operators: [
      "+",
      "-",
      "*",
      "/",
      "%",
      "^",
      "**",
      "==",
      "!=",
      "<",
      ">",
      "<=",
      ">=",
      "!",
      "not",
      "and",
      "&&",
      "or",
      "||",
      "?",
      ":",
      "??",
      "..",
    ],

    // we include these common regular expressions
    symbols: /[=><!~?:&|+\-*/^%]+/,
    escapes:
      /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

    // The main tokenizer for our languages
    tokenizer: {
      root: [
        // identifiers and keywords
        [
          /[a-zA-Z_$][\w$]*/,
          {
            cases: {
              "@typeKeywords": "keyword",
              "@keywords": "keyword",
              "@builtInFuncs": "keyword",
              "@default": "identifier",
            },
          },
        ],
        [/[A-Z][\w$]*/, "type.identifier"], // to show class names nicely

        // whitespace
        { include: "@whitespace" },

        // delimiters and operators
        [/[{}()[\]]/, "@brackets"],
        [/[<>](?!@symbols)/, "@brackets"],
        [
          /@symbols/,
          {
            cases: {
              "@operators": "operator",
              "@default": "",
            },
          },
        ],

        // numbers
        [/\d*\.\d+([eE][-+]?\d+)?[fFdD]?/, "number.float"],
        [/0[xX][0-9a-fA-F_]*[0-9a-fA-F][Ll]?/, "number.hex"],
        [/0[0-7_]*[0-7][Ll]?/, "number.octal"],
        [/0[bB][0-1_]*[0-1][Ll]?/, "number.binary"],
        [/\d+[lL]?/, "number"],

        // delimiter: after number because of .\d floats
        [/[;,.]/, "delimiter"],

        // strings
        [/"([^"\\]|\\.)*$/, "string.invalid"], // non-teminated string
        [/"/, "string", "@string"],

        // characters
        [/'[^\\']'/, "string"],
        [/(')(@escapes)(')/, ["string", "string.escape", "string"]],
        [/'/, "string.invalid"],
      ],

      whitespace: [
        [/[ \t\r\n]+/, "white"],
        [/\/\*/, "comment", "@comment"],
        [/\/\+/, "comment", "@comment"],
        [/\/\/.*$/, "comment"],
      ],

      comment: [
        [/[^/*]+/, "comment"],
        [/\/\+/, "comment", "@push"],
        [/\/\*/, "comment.invalid"],
        ["\\*/", "comment", "@pop"],
        ["\\+/", "comment", "@pop"],
        [/[/*]/, "comment"],
      ],

      string: [
        [/[^\\"]+/, "string"],
        [/@escapes/, "string.escape"],
        [/\\./, "string.escape.invalid"],
        [/"/, "string", "@pop"],
      ],
    },
  };
}

export const autoComplete = (
  monaco: typeof monacoEditor,
  sourceList: Array<string>,
  backEndKeywords: Keywords | Partial<WorkflowKeywords>,
  autoCompleteRecords: Record<string, string[]>,
  lookupList?: Array<string>
) => {
  return (
    model: monacoEditor.editor.ITextModel,
    position: Position,
    context: monacoEditor.languages.CompletionContext
  ): languages.ProviderResult<languages.CompletionList> => {
    // Get the current word and its position
    let currentWord = model.getWordUntilPosition(position);

    let prevWord = model.getWordUntilPosition(
      new monaco.Position(position.lineNumber, currentWord.startColumn - 1)
    );

    let isWorkflow = false,
      isPolicy = false;

    let parsedPredKeyword: Record<string, string[]> = {};
    Object.entries(backEndKeywords?.predictorsList || {}).forEach(([k, v]) => {
      if (parsedPredKeyword[k]) {
        parsedPredKeyword[k].push(...v.list);
      } else {
        parsedPredKeyword[k] = v.list;
      }
    });

    Object.entries(backEndKeywords?.customDataSources || {}).forEach(
      ([k, v]) => {
        /* handle the special case where the root object may have a space in the key
         * in that case, we use the bracket notation for property access
         * we filter the list by the ones that start with [ and remove the first [
         */
        if (context.triggerCharacter === "[") {
          if (parsedPredKeyword[k]) {
            parsedPredKeyword[k] = [
              ...v.list
                .filter((item) => item.startsWith("["))
                .map((s) => s.substring(1)),
              ...parsedPredKeyword[k],
            ];
          } else {
            parsedPredKeyword[k] = v.list
              .filter((item) => item.startsWith("["))
              .map((s) => s.substring(1));
          }
          return;
        }
        if (parsedPredKeyword[k]) {
          parsedPredKeyword[k].push(...v.list);
        } else {
          parsedPredKeyword[k] = v.list.filter((item) => !item.startsWith("["));
        }
      }
    );

    if ("policies" in backEndKeywords) {
      backEndKeywords.policies?.forEach(({ name, output }) => {
        const key = `policies['${name}']`;
        if (parsedPredKeyword[key]) {
          parsedPredKeyword[key].push(...output);
        } else {
          parsedPredKeyword[key] = output;
        }
      });
    }

    if ("workflows" in backEndKeywords) {
      backEndKeywords.workflows?.forEach(({ name, output }) => {
        const key = `workflows['${name}']`;
        if (parsedPredKeyword[key]) {
          parsedPredKeyword[key].push(...output);
        } else {
          parsedPredKeyword[key] = output;
        }
      });
    }

    if ("modelExprs" in backEndKeywords) {
      backEndKeywords.modelExprs?.forEach(({ name, output }) => {
        const key = name;
        if (parsedPredKeyword[key]) {
          parsedPredKeyword[key].push(...output);
        } else {
          parsedPredKeyword[key] = output;
        }
      });
    }

    if ("input" in backEndKeywords) {
      parsedPredKeyword.input = backEndKeywords.input ?? [];
    }

    sourceList = Array.from(new Set([...sourceList, "model"]));

    // predictor autocomplete
    if (
      prevWord.word !== "model" &&
      prevWord.word !== "lookup" &&
      sourceList
        ?.map((item) => item.toLowerCase())
        .includes(prevWord.word.toLowerCase())
    ) {
      let valueCompletionItems = parsedPredKeyword[prevWord.word]?.map(
        function (value) {
          return {
            label: value,
            kind: monaco.languages.CompletionItemKind.Property,
            documentation: value,
            insertText: value + " ",
            insertTextRules:
              monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          } as languages.CompletionItem;
        }
      );
      return {
        suggestions: valueCompletionItems,
      };
    }

    // model autocomplete
    if (autoCompleteRecords) {
      if (
        Object.keys(autoCompleteRecords).includes(prevWord.word.toLowerCase())
      ) {
        let valueCompletionItems = autoCompleteRecords[
          prevWord.word.toLowerCase()
        ]?.map(function (value) {
          return {
            label: value,
            kind: monaco.languages.CompletionItemKind.Property,
            documentation: value,
            insertText: value + " ",
          } as languages.CompletionItem;
        });
        return {
          suggestions: valueCompletionItems,
        };
      }
    }
    //lookup autocomplete
    if (
      currentWord.word.toLowerCase() === "lookup" &&
      lookupList?.some((e) =>
        e.toLowerCase().includes(currentWord.word.toLowerCase())
      )
    ) {
      let valueComp = lookupList?.map(function (val) {
        return {
          label: val,
          kind: monaco.languages.CompletionItemKind.Function,
          documentation: val,
          insertText: val + " ",
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        } as languages.CompletionItem;
      });
      return {
        suggestions: valueComp,
      };
    }

    //sources autocomplete
    if (
      currentWord.word &&
      sourceList
        .map((s) => s.toLowerCase())
        .some((e) => e.includes(currentWord.word.toLowerCase()))
    ) {
      let valueCompletionItems = sourceList?.map(function (value) {
        return {
          label: value,
          kind: monaco.languages.CompletionItemKind.Struct,
          documentation: value,
          insertText: value,
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        } as languages.CompletionItem;
      });
      return {
        suggestions: valueCompletionItems,
      };
    }

    if (currentWord.word) {
      let functionNameList: Array<string> = [];
      try {
        functionNameList = Object.keys(backEndKeywords?.functionsList ?? {});
      } catch (error) {}

      // function autocomplete
      if (
        functionNameList.some((e) =>
          e.toLowerCase().includes(currentWord.word.toLowerCase())
        )
      ) {
        let valueCompletionItems = Object.entries(
          backEndKeywords?.functionsList ?? {}
        )?.map(function ([k, value]) {
          return {
            label: {
              label: k,
              description: value.description,
            },
            kind: monaco.languages.CompletionItemKind.Function,
            documentation: value.description,
            insertText: value.syntax,
            detail: value.syntax,
          } as languages.CompletionItem;
        });
        return {
          suggestions: valueCompletionItems,
        };
      }
    }

    const policyName = model
      .findMatches(
        "policies\\['.*?'\\]",
        {
          startColumn: 0,
          endColumn: currentWord.endColumn - 1,
          endLineNumber: position.lineNumber,
          startLineNumber: position.lineNumber,
        },
        true,
        false,
        null,
        true
      )
      .at(-1);

    if (policyName && policyName.range.endColumn === currentWord.endColumn - 1)
      isPolicy = true;

    if (
      policyName?.matches?.[0] &&
      parsedPredKeyword[policyName.matches[0]] &&
      isPolicy
    ) {
      let valueCompletionItems = Array.from(
        new Set(parsedPredKeyword[policyName.matches[0]] || [])
      )?.map(function (value) {
        return {
          label: value,
          kind: monaco.languages.CompletionItemKind.Property,
          documentation: value,
          insertText: value + " ",
        } as languages.CompletionItem;
      });
      return {
        suggestions: valueCompletionItems,
      };
    }

    const workflowName = model
      .findMatches(
        "workflows\\['.*?'\\]",
        {
          startColumn: 0,
          endColumn: currentWord.endColumn - 1,
          endLineNumber: position.lineNumber,
          startLineNumber: position.lineNumber,
        },
        true,
        false,
        null,
        true
      )
      .at(-1);

    if (
      workflowName &&
      workflowName.range.endColumn === currentWord.endColumn - 1
    )
      isWorkflow = true;

    if (
      workflowName?.matches?.[0] &&
      parsedPredKeyword[workflowName.matches[0]] &&
      isWorkflow
    ) {
      let valueCompletionItems = parsedPredKeyword[
        workflowName.matches[0]
      ]?.map(function (value) {
        return {
          label: value,
          kind: monaco.languages.CompletionItemKind.Property,
          documentation: value,
          insertText: value + " ",
        } as languages.CompletionItem;
      });
      return {
        suggestions: valueCompletionItems,
      };
    }

    return {
      suggestions: [],
    };
  };
};

export const workflowAutocomplete = (
  monaco: typeof monacoEditor,
  sourceList: Array<string>,
  backEndKeywords: {
    policies: Record<string, string[]>;
    workflows: Record<string, string[]>;
    predictorsList: Record<string, string[]>;
    input: string[];
    functionsList: Record<
      string,
      { syntax: string; returnType: string; description: string }
    >;
  },
  autoCompleteRecords: Record<string, string[]>,
  lookupFunctionInputs?: string[]
) => {
  return (
    model: monacoEditor.editor.ITextModel,
    position: Position,
    context: monacoEditor.languages.CompletionContext
  ): languages.ProviderResult<languages.CompletionList> => {
    // Get the current word and its position
    let currentWord = model.getWordUntilPosition(position);

    let prevWord = model.getWordUntilPosition(
      new monaco.Position(position.lineNumber, currentWord.startColumn - 1)
    );

    let isWorkflow = false,
      isPolicy = false;

    let parsedPredKeyword: Record<string, string[]> = {};
    Object.entries(backEndKeywords?.predictorsList || {}).forEach(([k, v]) => {
      if (parsedPredKeyword[k]) {
        parsedPredKeyword[k].push(...v);
      } else {
        parsedPredKeyword[k] = v;
      }
    });

    // Object.entries(backEndKeywords?.customDataSources || {}).forEach(
    //   ([k, v]) => {
    //     /* handle the special case where the root object may have a space in the key
    //      * in that case, we use the bracket notation for property access
    //      * we filter the list by the ones that start with [ and remove the first [
    //      */
    //     if (context.triggerCharacter === "[") {
    //       if (parsedPredKeyword[k]) {
    //         parsedPredKeyword[k] = [
    //           ...v
    //             .filter((item) => item.startsWith("["))
    //             .map((s) => s.substring(1)),
    //           ...parsedPredKeyword[k],
    //         ];
    //       } else {
    //         parsedPredKeyword[k] = v
    //           .filter((item) => item.startsWith("["))
    //           .map((s) => s.substring(1));
    //       }
    //       return;
    //     }
    //     if (parsedPredKeyword[k]) {
    //       parsedPredKeyword[k].push(...v);
    //     } else {
    //       parsedPredKeyword[k] = v.filter((item) => !item.startsWith("["));
    //     }
    //   }
    // );

    if ("policies" in backEndKeywords) {
      Object.entries(backEndKeywords.policies ?? {}).forEach(
        ([name, output]) => {
          const key = `policies['${name}']`;
          if (parsedPredKeyword[key]) {
            parsedPredKeyword[key].push(...output);
          } else {
            parsedPredKeyword[key] = output;
          }
        }
      );
    }

    if ("workflows" in backEndKeywords) {
      Object.entries(backEndKeywords.workflows ?? {}).forEach(
        ([name, output]) => {
          const key = `workflows['${name}']`;
          if (parsedPredKeyword[key]) {
            parsedPredKeyword[key].push(...output);
          } else {
            parsedPredKeyword[key] = output;
          }
        }
      );
    }

    // if ("rulesets" in backEndKeywords) {
    //   backEndKeywords.rulesets?.forEach(({ name, output }) => {
    //     const key = name;
    //     if (parsedPredKeyword[key]) {
    //       parsedPredKeyword[key].push(...output);
    //     } else {
    //       parsedPredKeyword[key] = output;
    //     }
    //   });
    // }
    //
    // if ("modelExprs" in backEndKeywords) {
    //   backEndKeywords.modelExprs?.forEach(({ name, output }) => {
    //     const key = name;
    //     if (parsedPredKeyword[key]) {
    //       parsedPredKeyword[key].push(...output);
    //     } else {
    //       parsedPredKeyword[key] = output;
    //     }
    //   });
    // }
    //
    // if ("modelSets" in backEndKeywords) {
    //   backEndKeywords.modelSets?.forEach(({ name, output }) => {
    //     const key = name;
    //     if (parsedPredKeyword[key]) {
    //       parsedPredKeyword[key].push(...output);
    //     } else {
    //       parsedPredKeyword[key] = output;
    //     }
    //   });
    // }
    //
    // if ("modelDecisionTable" in backEndKeywords) {
    //   backEndKeywords.modelDecisionTable?.forEach(({ name, output }) => {
    //     if (parsedPredKeyword[name]) {
    //       parsedPredKeyword[name].push(...output);
    //     } else {
    //       parsedPredKeyword[name] = output;
    //     }
    //   });
    // }

    if ("input" in backEndKeywords) {
      parsedPredKeyword.input = backEndKeywords.input ?? [];
    }

    // predictor autocomplete
    if (sourceList.includes(prevWord.word)) {
      let valueCompletionItems = parsedPredKeyword[prevWord.word]?.map(
        function (value) {
          return {
            label: value,
            kind: monaco.languages.CompletionItemKind.Property,
            documentation: value,
            insertText: value + " ",
            insertTextRules:
              monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          } as languages.CompletionItem;
        }
      );
      return {
        suggestions: valueCompletionItems,
      };
    }
    // model autocomplete
    if (autoCompleteRecords) {
      if (Object.keys(autoCompleteRecords).includes(prevWord.word)) {
        let valueCompletionItems = autoCompleteRecords[prevWord.word]?.map(
          function (value) {
            return {
              label: value,
              kind: monaco.languages.CompletionItemKind.Property,
              documentation: value,
              insertText: value + " ",
              insertTextRules:
                monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
            } as languages.CompletionItem;
          }
        );
        return {
          suggestions: valueCompletionItems,
        };
      }
    }

    if (currentWord.word) {
      const sourceMatches = sourceList.some((e) =>
        e.includes(currentWord.word)
      );
      const functionMatches = Object.keys(backEndKeywords?.functionsList).some(
        (e) => e.toLowerCase().includes(currentWord.word.toLowerCase())
      );

      let valueCompletionItems: languages.CompletionItem[] = [];

      if (sourceMatches) {
        const sourceCompletionItems = sourceList?.map((value) => {
          return {
            label: value,
            kind: monaco.languages.CompletionItemKind.Struct,
            documentation: value,
            insertText: value,
            insertTextRules:
              monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          } as languages.CompletionItem;
        });
        valueCompletionItems = [
          ...valueCompletionItems,
          ...sourceCompletionItems,
        ];
      }

      if (functionMatches) {
        const functionCompletionItems = Object.entries(
          backEndKeywords?.functionsList ?? {}
        )?.map(([k, value]) => {
          return {
            label: {
              label: k,
              description: value.description,
            },
            kind: monaco.languages.CompletionItemKind.Function,
            documentation: value.description,
            insertText: value.syntax,
            detail: value.syntax,
          } as languages.CompletionItem;
        });
        valueCompletionItems = [
          ...valueCompletionItems,
          ...functionCompletionItems,
        ];
      }

      if (valueCompletionItems.length > 0) {
        return {
          suggestions: valueCompletionItems,
        };
      }
    }

    // sources autocomplete
    if (
      currentWord.word &&
      sourceList.some((e) => e.includes(currentWord.word))
    ) {
      let valueCompletionItems = sourceList?.map(function (value) {
        return {
          label: value,
          kind: monaco.languages.CompletionItemKind.Struct,
          documentation: value,
          insertText: value,
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        } as languages.CompletionItem;
      });
      return {
        suggestions: valueCompletionItems,
      };
    }

    // function autocomplete
    if (
      currentWord.word &&
      Object.keys(backEndKeywords?.functionsList).some((e) =>
        e.toLowerCase().includes(currentWord?.word.toLowerCase())
      )
    ) {
      let functionNameList: Array<string> = [];
      // try {
      //   functionNameList = Object.keys(backEndKeywords?.functionsList ?? {});
      // } catch (error) {}

      if (
        functionNameList.some((e) =>
          e.toLowerCase().includes(currentWord.word.toLowerCase())
        )
      ) {
        let valueCompletionItems = Object.entries(
          backEndKeywords?.functionsList ?? {}
        )?.map(function ([k, value]) {
          return {
            label: {
              label: k,
              description: value.description,
            },
            kind: monaco.languages.CompletionItemKind.Function,
            documentation: value.description,
            insertText: value.syntax,
            detail: value.syntax,
          } as languages.CompletionItem;
        });
        return {
          suggestions: valueCompletionItems,
        };
      }
    }

    const policyName = model
      .findMatches(
        "policies\\['.*?'\\]",
        {
          startColumn: 0,
          endColumn: currentWord.endColumn - 1,
          endLineNumber: position.lineNumber,
          startLineNumber: position.lineNumber,
        },
        true,
        false,
        null,
        true
      )
      .at(-1);

    if (policyName && policyName.range.endColumn === currentWord.endColumn - 1)
      isPolicy = true;

    if (
      policyName?.matches?.[0] &&
      parsedPredKeyword[policyName.matches[0]] &&
      isPolicy
    ) {
      let valueCompletionItems = Array.from(
        new Set(parsedPredKeyword[policyName.matches[0]] || [])
      )?.map(function (value) {
        return {
          label: value,
          kind: monaco.languages.CompletionItemKind.Property,
          documentation: value,
          insertText: value + " ",
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        } as languages.CompletionItem;
      });
      return {
        suggestions: valueCompletionItems,
      };
    }

    const workflowName = model
      .findMatches(
        "workflows\\['.*?'\\]",
        {
          startColumn: 0,
          endColumn: currentWord.endColumn - 1,
          endLineNumber: position.lineNumber,
          startLineNumber: position.lineNumber,
        },
        true,
        false,
        null,
        true
      )
      .at(-1);

    if (
      workflowName &&
      workflowName.range.endColumn === currentWord.endColumn - 1
    )
      isWorkflow = true;

    if (
      workflowName?.matches?.[0] &&
      parsedPredKeyword[workflowName.matches[0]] &&
      isWorkflow
    ) {
      let valueCompletionItems = parsedPredKeyword[
        workflowName.matches[0]
      ]?.map(function (value) {
        return {
          label: value,
          kind: monaco.languages.CompletionItemKind.Property,
          documentation: value,
          insertText: value + " ",
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        } as languages.CompletionItem;
      });
      return {
        suggestions: valueCompletionItems,
      };
    }

    if (
      lookupFunctionInputs?.some((e) =>
        e.toLowerCase().includes(currentWord.word.toLowerCase())
      )
    ) {
      let valueComp = lookupFunctionInputs?.map(function (val) {
        return {
          label: val,
          kind: monaco.languages.CompletionItemKind.Function,
          documentation: val,
          insertText: val + " ",
          insertTextRules:
            monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        } as languages.CompletionItem;
      });
      return {
        suggestions: valueComp,
      };
    }

    return {
      suggestions: [],
    };
  };
};