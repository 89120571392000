import classNames from "clsx";
import { useState } from "react";
import ProtectedComponent from "src/components/ProtectedComponent";
import Tooltip from "src/components/Tooltip";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import { PermissionTypes } from "src/types";
import { notify } from "src/utils/utils";
import checkIcon from "@assets/icons/check-blue.svg";
import { ReactComponent as DotsIcon } from "@assets/icons/dots-horizontal.svg";
import failIcon from "@assets/icons/workflow/reject.svg";
import EmptyState from "@components/EmptyState";
import Loader from "@components/Loader";
import Popover from "@components/Popover";
import Table from "@components/Table";
import { DATA_SOURCE_PAGE_LIMIT } from "@config";
import { ArrowPathIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import ConfirmModal from "../monitor/components/ConfirmModalEndpoint";
import { lookupTableListDsQuery, useLookupDeactivateFile } from "./queries";
import { LookupData, LookupTableSources } from "./types";

type Props = {
  customTableData: LookupTableSources;
  isLoading: boolean;
  onPageChange: setStateType<number>;
  page: number;
  totalCount: number;
  handleOpenModel: (arg: LookupData) => void;
};

const LookupTable = ({
  customTableData,
  isLoading,
  onPageChange,
  page,
  totalCount,
  handleOpenModel,
}: Props) => {
  const deleteLookup = useLookupDeactivateFile();
  const qc = useQueryClient();
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [dataSrc, setDataSrc] = useState({ name: "", id: 0 });
  const handleDeleteLookup = () => {
    deleteLookup.mutate(
      { lookupId: dataSrc.id },
      {
        onSuccess: () => {
          qc.invalidateQueries(lookupTableListDsQuery());
          notify({ title: "Success", text: "Deleted lookup", type: "success" });
        },
      }
    );
  };

  return (
    <div className="max-w-full w-full relative z-0 rounded px-8 pb-8 overflow-auto">
      <ConfirmModal
        isOpen={isOpenDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={`Delete "${dataSrc.name}"`}
        action={handleDeleteLookup}
        destructive
      >
        Are you sure you want to delete ?
      </ConfirmModal>
      {isLoading && customTableData.length === 0 ? (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto flex h-96 max-w-4xl items-center justify-center">
            <Loader size="small" type="block" />
          </div>
        </div>
      ) : customTableData.length > 0 ? (
        <>
          <Table
            data={customTableData}
            className="sm:overflow-x-visible w-full"
            emptyText={<p>No lookups found</p>}
            isLoading={isLoading}
            headers={[
              "Source Name",
              "Alias",
              "Confidential",
              "File Status",
              "",
            ]}
            page={page}
            setPage={(page) => {
              onPageChange(page);
            }}
            pageLimit={DATA_SOURCE_PAGE_LIMIT}
            totalCount={totalCount || 0}
          >
            {(row, defaultRowClassNames) => {
              const handleUpdateLookup = () => {
                if (!isOpenDeleteModal) {
                  handleOpenModel(row);
                }
              };
              return (
                <tr
                  key={row.id}
                  className={classNames(
                    defaultRowClassNames,
                    "!cursor-pointer"
                  )}
                  onClick={handleUpdateLookup}
                >
                  <td
                    className=" max-w-[225px] truncate !font-medium pr-5"
                    style={{ overflow: "hidden" }}
                  >
                    {row.name}
                  </td>
                  <td className="truncate !overflow-hidden max-w-[400px] pr-8">
                    {row.alias}
                  </td>
                  <td className="min-w-[60px]">
                    {row.confidential ? (
                      <Tooltip
                        content={
                          <img
                            src={checkIcon}
                            alt="check"
                            className="w-4 h-4"
                          />
                        }
                        tooltipContent="This file is confidential"
                      ></Tooltip>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="min-w-[60px]">
                    {row.status === "failed" && (
                      <Tooltip
                        content={
                          <img
                            src={failIcon}
                            alt="failed"
                            className="w-4 h-4"
                          />
                        }
                        tooltipClassName="!translate-y-[8px]"
                        tooltipContent={
                          <p>
                            The last file uploaded could not be processed,{" "}
                            <br /> variables from please correct the file and
                            try again.
                          </p>
                        }
                      ></Tooltip>
                    )}
                    {row.status === "processing" && (
                      <Tooltip
                        content={
                          <ArrowPathIcon className="w-4 h-4 stroke-neutral-500 animate-spin" />
                        }
                        tooltipContent="Please wait for few minutes while we process this file"
                      ></Tooltip>
                    )}
                  </td>
                  <td>
                    <ProtectedComponent
                      type={
                        PERMISSIONS_TYPE.dataSourcesLookup as PermissionTypes
                      }
                      action="delete"
                    >
                      <Popover>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={classNames(
                                "w-7 h-7 duration-150 p-1.5 flex rounded-md hover:bg-neutral-50",
                                open ? "bg-neutral-50" : ""
                              )}
                            >
                              <DotsIcon className="w-4 h-4 stroke-neutral-600 [&>path]:stroke-neutral-600" />
                            </Popover.Button>
                            <Popover.Panel className="text-xs" static>
                              <div
                                className="p-2 flex gap-2 cursor-pointer items-center font-b2-medium bg-white  hover:bg-neutral-25 !text-error-600"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDataSrc({ name: row.name, id: row.id });
                                  setOpenDeleteModal(true);
                                }}
                              >
                                <TrashIcon className="z-10 h-4 w-4 cursor-pointer [&>path]:stroke-error-600" />
                                Delete
                              </div>
                            </Popover.Panel>
                          </>
                        )}
                      </Popover>
                    </ProtectedComponent>
                  </td>
                </tr>
              );
            }}
          </Table>
        </>
      ) : (
        <EmptyState>No Lookup found</EmptyState>
      )}
    </div>
  );
};

export default LookupTable;
