import { titleCase } from "@utils/utils";
import Badge from "src/components/Badge";
import EmptyState from "src/components/EmptyState";
import QueryWrapper from "src/components/QueryWrapper";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import usePermissions from "src/context/PermissionContext";
import { useGetAllMonitors } from "src/screens/monitor/queries";
import { PermissionTypes } from "src/types";
import OverviewHeaderCard from "./OverviewHeaderCard";

const TriggeredMonitorsCard = () => {
  const { getPermissions } = usePermissions();
  const permissions = getPermissions(
    PERMISSIONS_TYPE.overview as PermissionTypes,
    "view monitor"
  );
  const getAllMonitors = useGetAllMonitors({ permissions: !!permissions });

  return (
    <OverviewHeaderCard
      className="basis-1/2 h-[346px] overflow-auto"
      title="Triggered Monitors"
      content={
        <QueryWrapper query={getAllMonitors}>
          {(data) => {
            if (!data || !data.monitors) {
              return <EmptyState>No monitors triggered</EmptyState>;
            }
            const setTriggerMonitorList = data.monitors.filter((el) =>
              ["error", "warning"].includes(el?.state)
            );
            if (setTriggerMonitorList.length === 0) {
              return <EmptyState>No monitors triggered</EmptyState>;
            }
            return (
              <div className="flex flex-col max-w-full">
                {setTriggerMonitorList.map((row) => {
                  return (
                  <tr className="flex justify-between w-full py-4 px-6 h-12 text-neutral-black border-b border-neutral-50">
                    <td className="w-4/12 flex items-center font-b2-medium">
                      {row.name}
                    </td>
                    <td className="w-4/12">
                      <Badge
                        variant={row.state === "error" ? "error" : "warning"}
                        size="sm"
                      >
                        {titleCase(row.state)}
                      </Badge>
                    </td>
                    <td className="w-4/12 flex items-center font-b2">
                      {row.sourceName}
                    </td>
                  </tr>
                );
                })}
              </div>
            );
          }}
        </QueryWrapper>
      }
    />
  );
};

export default TriggeredMonitorsCard;
