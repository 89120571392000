import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import ShimmerFallbackUI from "@components/ShimmerFallbackUI";
import Sidebar from "./Sidebar";

const Reports = () => {
  return (
    <>
      <div className="h-full max-h-screen bg-white w-full overflow-auto flex">
        <Sidebar />
        <div className="flex-grow-[2] h-full flex flex-col pt-12">
          <Suspense fallback={<ShimmerFallbackUI />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default Reports;
