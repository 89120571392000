import classNames from "clsx";

type Props<T extends string> = {
  tabs: T[];
  onClick: (t: T) => void;
  currentTab: T;
  className?: string;
  suffixes?: Partial<Record<T, string | undefined | number>>;
};

export default function BadgeTabs<T extends string>({
  tabs,
  onClick,
  currentTab,
  suffixes,
  className,
}: Props<T>) {
  return (
    <div
      className={classNames(
        "flex content-center items-center gap-3 rounded-lg bg-gray-100 p-1",
        className
      )}
    >
      {tabs.map((t) => {
        return (
          <span
            key={t}
            onClick={() => onClick(t)}
            className={classNames(
              "cursor-pointer rounded-md px-3 py-2 text-sm font-medium duration-200",
              currentTab === t
                ? "cursor-default bg-white shadow-md text-gray-800"
                : "hover:bg-gray-200 text-gray-500 hover:text-gray-600"
            )}
          >
            {t}{" "}
            {suffixes?.[t] && (
              <span
                className={classNames(
                  "rounded-full  px-2 py-1 text-xs font-semibold text-dark-gray",
                  currentTab !== t ? "bg-gray-200" : "bg-gray-100"
                )}
              >
                {suffixes[t]}
              </span>
            )}
          </span>
        );
      })}
    </div>
  );
}
