import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  filters?: ReactNode;
  buttons?: ReactNode[];
};

function NewHeader({ children, filters, buttons }: Props) {
  return (
    <div className="bg-white px-8 pt-5 pb-3 flex flex-col gap-5">
      <div className="flex justify-between items-center w-full">
        {children}
        <div className="ml-auto gap-2 flex items-center">{buttons}</div>
      </div>
      {filters}
    </div>
  );
}

function Title({ children }: { children: ReactNode }) {
  return (
    <h1 className="font-p1-medium text-neutral-black leading-[1] py-1">
      {children}
    </h1>
  );
}

NewHeader.Title = Title;

export default NewHeader;
