import * as DcPreds from "./dc.json";

interface DcDocsProps {
  query: string;
}

const DcDocs = ({ query }: DcDocsProps) => {
  return (
    <div className="mx-8 mt-4 border max-h-[75%] h-max overflow-auto">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase">
          <tr className="divide-x">
            <th scope="col" className="px-6 py-3 sticky top-0 bg-gray-100">
              Predictor
            </th>
            <th scope="col" className="px-6 py-3 sticky top-0 bg-gray-100">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          {DcPreds.Sheet1.filter((e) =>
            e.feature.toLowerCase().includes(query)
          ).map((dcp, i) => (
            <tr
              key={dcp.feature + dcp.description + i}
              className="divide-x bg-white even:bg-gray-50"
            >
              <td className="px-6 py-4 text-gray-700 font-medium">
                {dcp.feature}
              </td>
              <td className="px-6 py-4">{dcp.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DcDocs;
