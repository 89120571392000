import classNames from "clsx";
import { format, parse } from "date-fns";
import { useState } from "react";
import { ReactComponent as CalendarIcon } from "@assets/icons/calendar.svg";
import chevronDown from "@assets/icons/chevron-down.svg";
import DatePicker from "@components/DatePicker";
import Popover from "@components/Popover";

type DateRange = {
  startDate: string;
  endDate: string;
};

function formatDateRange(dateRange?: DateRange) {
  if (!dateRange || !dateRange.startDate || !dateRange.endDate)
    return undefined;
  return `${dateRange.startDate} - ${dateRange.endDate}`;
}

type Props = {
  defaultText: string;
  onChange: (d: DateRange) => void;
  value?: DateRange;
  showShortcuts?: boolean;
  onClear: () => void;
  showAllTimeShortcut?: boolean;
  panelClassNames?: string;
};

export default function DateFilter({
  value,
  showAllTimeShortcut,
  defaultText,
  onChange,
  showShortcuts,
  onClear,
  panelClassNames,
}: Props) {
  const [dateRangeText, setDateRangeText] = useState(formatDateRange(value));
  return (
    <Popover>
      {({ close, open }) => (
        <>
          <div className="flex font-b2-medium h-7 w-fit">
            <span className="bg-neutral-0 border border-r-0 border-neutral-100 rounded-l-md flex gap-2 shrink-0 px-1.5 items-center">
              <CalendarIcon className="[&>path]:stroke-neutral-300 w-4 h-4" />
            </span>
            <Popover.Button className="border border-neutral-100 rounded-r-md flex gap-2 px-2.5 items-center hover:bg-neutral-0 focus:bg-neutral-0">
              {dateRangeText ?? defaultText}
              <img
                alt=""
                className={open ? "rotate-180 mr-1" : "mr-1"}
                src={chevronDown}
              />
            </Popover.Button>
          </div>
          <Popover.Panel
            className={classNames(
              "-translate-y-1 w-[456px] shadow-1",
              panelClassNames
            )}
          >
            <DatePicker
              showAllTimeShortcut={showAllTimeShortcut}
              close={close}
              onClear={() => {
                onClear();
                setDateRangeText("All Time");
                close();
              }}
              show={open}
              showShortcuts={showShortcuts}
              onChange={(e) => {
                if (e.type === "date-range") {
                  setDateRangeText(
                    formatDateRange({
                      startDate: format(e.startDate, "dd MMM, yyyy"),
                      endDate: format(e.endDate, "dd MMM, yyyy"),
                    })
                  );
                  onChange({
                    startDate: format(e.startDate, "dd MMM, yyyy"),
                    endDate: format(e.endDate, "dd MMM, yyyy"),
                  });
                } else {
                  setDateRangeText(e.label);
                  onChange({
                    startDate: format(e.period.startDate, "dd MMM, yyyy"),
                    endDate: format(e.period.endDate, "dd MMM, yyyy"),
                  });
                }
              }}
              value={
                value && {
                  startDate:
                    value?.startDate &&
                    format(
                      parse(value.startDate, "dd MMM, yyyy", new Date()),
                      "yyyy-MM-dd"
                    ),
                  endDate:
                    value?.endDate &&
                    format(
                      parse(value.endDate, "dd MMM, yyyy", new Date()),
                      "yyyy-MM-dd"
                    ),
                }
              }
            />
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
