import classNames from "clsx";
import * as React from "react";

const Header = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <>
      <div
        className={classNames(
          "mx-2 max-w-full bg-white px-6 ml-0 sticky top-0 border-b border-neutral-100 z-50 mr-0",
          className
        )}
      >
        {children}
      </div>
    </>
  );
};

Header.PageHeader = ({ children }: { children: React.ReactNode }) => (
  <div className="font-p1-medium text-neutral-black max-w-[35vw] truncate">
    {children}
  </div>
);

Header.Description = ({ children }: { children: React.ReactNode }) => (
  <div className="font-b2 text-neutral-500">{children}</div>
);
export default Header;
