import { Disclosure } from "@headlessui/react";

type Props = {
  content: Parameters<typeof Disclosure.Button>[0]["children"];
  children: Parameters<typeof Disclosure.Panel>[0]["children"];
  contentClassName?: string;
  defaultOpen?: boolean;
  buttonClassName?: string;
};
export function Accordion({
  content,
  children,
  defaultOpen,
  contentClassName,
  buttonClassName,
}: Props) {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      <Disclosure.Button className={buttonClassName}>
        {content}
      </Disclosure.Button>
      <Disclosure.Panel className={contentClassName}>
        {children}
      </Disclosure.Panel>
    </Disclosure>
  );
}
