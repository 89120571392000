import Tabs from "@components/Tabs";
import { useState } from "react";
import Modal from "../Dialogue";
import Application from "./Application";
import Historical from "./Historical";
import CustomCSV from "./Custom";
import { TabNames } from "./SimulationModal.types";

type Props = {
  showRunSimulationModal: boolean;
  setShowRunSimulationModal: setStateType<boolean>;
  policyID: string;
};

function RunSimulationModal({
  showRunSimulationModal,
  setShowRunSimulationModal,
  policyID,
}: Props) {
  const [tab, setTab] = useState<TabNames>(true ? "Historical" : "Custom");

  let tabs: TabNames[] = [];
  if (true) {
    tabs.push("Historical", "Application");
  }
  if (true) {
    tabs.push("Custom");
  }

  return (
    <Modal
      onClose={() => setShowRunSimulationModal(false)}
      open={showRunSimulationModal}
    >
      <Modal.Panel size="lg" className="w-[384px] min-h-fit">
        <Modal.Header>Run Simulation</Modal.Header>
        <Modal.Body>
          <Tabs
            className="!w-min h-7 mr-auto"
            tabs={["Historical", "Application", "Custom"]}
            onChange={(v) => {
              setTab(v);
            }}
            currentTab={tab}
          />

          <div className=" flex max-h-[100%]  min-w-full  grow flex-col rounded-md bg-white">
            {tab === "Historical" && (
              <Historical
                closeModal={() => setShowRunSimulationModal(false)}
                policyID={policyID}
              />
            )}
            {tab === "Application" && (
              <Application
                policyID={policyID}
                closeModal={() => setShowRunSimulationModal(false)}
              />
            )}
            {tab === "Custom" && (
              <CustomCSV
                policyID={policyID}
                closeModal={() => setShowRunSimulationModal(false)}
              />
            )}
          </div>
        </Modal.Body>
      </Modal.Panel>
    </Modal>
  );
}

export default RunSimulationModal;
