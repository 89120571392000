import classNames from "classnames";
import { ReactNode, useState } from "react";
import { Control, Controller } from "react-hook-form";
import Dropdown from "src/components/DropDown";
import Multiselect, {
  multiselectOnChangeHandler,
} from "src/components/Multiselect";
import { FUNNEL_TYPE_NAMES_MAPPER } from "src/screens/analytics/templates/config";
import { useGetRoleFunnelItems } from "./queries";

type FormInput = {
  filters: Array<{
    funnelType: string;
    funnelList: { name: string; id: string }[];
  }>;
};

export const FilterSection = ({
  index,
  control,
  availableFunnelTypes,
  isEditing,
  selectedType,
}: {
  index: number;
  control: Control<FormInput>;
  availableFunnelTypes: string[];
  isEditing: boolean;
  selectedType: string;
}) => {
  const [selectedFunnelType, setSelectedFunnelType] =
    useState<string>(selectedType);
  const [funnelItemFilterQuery, setFunnelItemFilterQuery] = useState("");

  const funnelItemsQuery = useGetRoleFunnelItems(selectedFunnelType);
  const funnelItems = funnelItemsQuery.isSuccess
    ? Object.keys(funnelItemsQuery.data.data.data)
        .filter(
          (key) =>
            funnelItemFilterQuery === "" ||
            funnelItemsQuery.data.data.data[key]
              .toLowerCase()
              .includes(funnelItemFilterQuery.toLowerCase())
        )
        .map((key) => ({
          name: funnelItemsQuery.data.data.data[key],
          id: key,
        }))
    : [];

  return (
    <div id={`filter-section-${index}`} className="flex gap-2">
      <Controller
        control={control}
        name={`filters.${index}.funnelType`}
        render={({ field }) => (
          <Dropdown
            value={
              field.value
                ? {
                    value: field.value,
                    name: FUNNEL_TYPE_NAMES_MAPPER[
                      field.value as keyof typeof FUNNEL_TYPE_NAMES_MAPPER
                    ],
                  }
                : null
            }
            onChange={(e: any) => {
              field.onChange(e.value);
              setSelectedFunnelType(e.value);
            }}
            disabled={!isEditing}
          >
            <Dropdown.Button
              className={classNames(
                "!h-7 min-w-[112px] font-b2 disabled:cursor-not-allowed disabled:text-indigo-300 [&>img]:w-3 [&>img]:h-3"
              )}
            >
              {field.value ? (
                <span className="text-neutral-black">
                  {
                    FUNNEL_TYPE_NAMES_MAPPER[
                      field.value as keyof typeof FUNNEL_TYPE_NAMES_MAPPER
                    ]
                  }
                </span>
              ) : (
                "Select Type"
              )}
            </Dropdown.Button>
            <Dropdown.Options>
              {availableFunnelTypes.map((p) => (
                <Dropdown.Option
                  value={{
                    value: p,
                    name: FUNNEL_TYPE_NAMES_MAPPER[
                      p as keyof typeof FUNNEL_TYPE_NAMES_MAPPER
                    ],
                  }}
                  key={p}
                >
                  {
                    FUNNEL_TYPE_NAMES_MAPPER[
                      p as keyof typeof FUNNEL_TYPE_NAMES_MAPPER
                    ]
                  }
                </Dropdown.Option>
              ))}
            </Dropdown.Options>
          </Dropdown>
        )}
      />
      <Controller
        control={control}
        name={`filters.${index}.funnelList`}
        render={({ field }) => (
          <Multiselect
            options={funnelItems.sort((a) =>
              field.value?.map((item) => item.id).includes(a.id) ? -1 : 1
            )}
            onSearchChange={setFunnelItemFilterQuery}
            getKey={(e) => e.id}
            values={field.value || []}
            onChange={(e) => {
              const newValues = multiselectOnChangeHandler(e, "id");
              field.onChange(newValues);
            }}
            disabled={!isEditing}
            getDisplayValue={(e) => {
              if (!e || e.length === 0) return "";
              const names = e.map(
                (item) => item.name.split("/").at(-1) as string
              );
              if (names.length === 1) return names[0];
              return `${names[0]} +${names.length - 1}`;
            }}
            getName={(e) => {
              const nameSplit = e.name.split("/");
              const output: ReactNode[] = [];

              nameSplit.forEach((item, index) => {
                output.push(
                  <span
                    key={index}
                    data-dropdown-option="yes"
                    className={classNames(
                      index === nameSplit.length - 1
                        ? "text-neutral-black"
                        : "text-neutral-500"
                    )}
                  >
                    {item}
                    {index !== nameSplit.length - 1 && " / "}
                  </span>
                );
              });
              return <>{output}</>;
            }}
            isSelected={(e) => !!field.value?.find((p) => p.id === e.id)}
          />
        )}
      />
    </div>
  );
};
