import React, { useEffect } from "react";
import { reportToSentry } from "src/utils/utils";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

type Props = { children: React.ReactNode; error: unknown };

// TODO: Add sentry error boundary
export default function ErrorState({ children, error }: Props) {
  useEffect(() => {
    reportToSentry(error);
  }, []);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-2 text-center">
      <ExclamationTriangleIcon className="h-12 w-12 text-neutral-500" />
      <p className="font-b2"> {children} </p>
    </div>
  );
}
