import chevronDown from "@assets/icons/chevron-down.svg";
import { Combobox } from "@headlessui/react";
import classNames from "clsx";
import { useEffect, useState } from "react";

import { SelectPolicyProps } from "./HistoricalPolicySelection.types";
import { useUserTypeSimulation } from "../useSimulation";
import { Decision } from "../SimulationModal.types";


export default function HistoricalUserTypeSelection({ policyID, closeModal, state, dispatch, ...props }: SelectPolicyProps) {
  const [, setSearchQuery] = useState("");
  const [userTypeList, setUserTypeList]= useState<string[]>([]);


  const handleUserTypeResponse = (data: {data:string[]}) => {
    if(data?.data ){
        setUserTypeList(data?.data);
    }
  }

  const userTypeListRequest = useUserTypeSimulation(handleUserTypeResponse);
  
  useEffect(() => {
    if(state.policy){
        dispatch({ type: "SetDecision", value: '' });
        userTypeListRequest.mutate({policyID: state.policy });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.policy]);

  if(!userTypeList?.length){
    return null;
  }

  return (
    <div className="mt-4 flex flex-col gap-2">
      <div className="text-neutral-500 font-b2-medium">
      Choose user type to simulate
      </div>
      <Combobox
        as="div"
        className="h-min"
        value={state.decision}
        onChange={(e: Decision ) => {
          dispatch({ type: "SetDecision", value: e });
        }}
      >
        <div>
          <Combobox.Button className="flex w-full items-center rounded-r-md focus:outline-none relative">
            <Combobox.Input
              placeholder="Search"
              className="w-full rounded-md border border-gray-300 bg-white py-[7px] pl-3 pr-8  focus:border-primary-700 focus:outline-none focus:ring-1 focus:ring-primary-700 font-b2 "
              onChange={(event) => setSearchQuery(event.target.value)}
              displayValue={() =>  state.decision ?? ''}
            />
            <img
              alt=""
              className={"absolute right-3 text-right mr-1"}
              src={chevronDown}
            />
          </Combobox.Button>
          <Combobox.Options className="absolute z-[21] mt-1 max-h-60 w-[645px] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {
              (userTypeList || []).map((op) => (
                <Combobox.Option
                  key={op}
                  value={op}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-pointer select-none py-2 pl-8 pr-4  flex items-center gap-2",
                      active ? "bg-primary-700 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={classNames(
                          "inline-block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {op}
                      </span>
                    </>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
}
