import classNames from "clsx";
import React, { PropsWithChildren, ReactNode } from "react";
import Search from "@components/Search";
import { debounce } from "@utils/utils";

function Filters({ children }: PropsWithChildren<{}>) {
  return <>{children}</>;
}

type Props = {
  onSearch?: (query: string) => void;
  children?: React.ReactNode;
  defaultValue?: string;
  hint?: string;
  wrapperClassName?: string;
  buttons?: ReactNode;
};

function Searchbar({
  onSearch,
  children,
  defaultValue,
  hint,
  wrapperClassName,
  buttons,
}: Props) {
  return (
    <div className={classNames("flex items-center", wrapperClassName)}>
      <div className="flex flex-wrap items-center gap-2 justify-start">
        {onSearch && (
          <Search
            className="w-[184px] !mr-0"
            hint={hint}
            onSearchChange={debounce((e) => onSearch?.(e.target.value))}
            defaultValue={defaultValue || ""}
          />
        )}
        {children}
      </div>
      <div className="ml-auto flex items-start gap-2 h-full flex-shrink-0">
        {buttons}
      </div>
    </div>
  );
}

Searchbar.Filters = Filters;

export default Searchbar;
