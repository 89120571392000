import { PermissionTypes } from "@types";
import "react-datepicker/dist/react-datepicker.css";
import usePermissions from "src/context/PermissionContext";
import { AccessDenied } from "src/screens/AccessErrorDenied";

type Props = {
  children: any;
  type: PermissionTypes;
  action: string | string[];
  showNotAllowed?: boolean;
};

export default function ProtectedComponent({
  children,
  type,
  action,
  showNotAllowed = false,
}: Props) {
  const { getPermissions } = usePermissions();
  const permissions = getPermissions(type, action);
  if (!permissions) return showNotAllowed ? <AccessDenied /> : null;
  return children;
}
