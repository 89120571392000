import classNames from "clsx";
import { memo } from "react";
import { Handle, NodeProps, Position, useReactFlow } from "reactflow";
import alertCircle from "@assets/icons/alert-circle.svg";
import { ReactComponent as DuplicateIcon } from "@assets/icons/copy-07.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import TestButton from "@screens/workflow/components/TestButton";
import {
  BE_BRANCH_NODE_TYPE,
  BRANCH_NODE_TYPE,
} from "@screens/workflow/config";
import { getErrors } from "@screens/workflow/queries";
import BottomPanel from "@screens/workflow/studio/components/BottomPanel";
import BranchConditions from "@screens/workflow/studio/components/Branch/BranchConditions";
import { getBranchConditionsQuery } from "@screens/workflow/studio/components/Branch/queries";
import ErrorList from "@screens/workflow/studio/components/ErrorList";
import InputList from "@screens/workflow/studio/components/InputList";
import NodeMenu from "@screens/workflow/studio/components/NodeMenu";
import { NodeName } from "@screens/workflow/studio/components/NodeName";
import useBottomPanelState, {
  CLOSE,
} from "@screens/workflow/studio/hooks/useBottomPanelState";
import useDuplicateNode from "@screens/workflow/studio/hooks/useDuplicateNode";
import useGetIcon from "@screens/workflow/studio/hooks/useGetIcon";
import useImportNodeWithInput from "@screens/workflow/studio/hooks/useImportNodeWithInput";
import useIsExpand, {
  useGetHandleStyles,
  useGetHandleStylesForInputDot,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "@screens/workflow/studio/hooks/useIsExpand";
import useIsHiddenAfterTest from "@screens/workflow/studio/hooks/useIsHiddenAfterTest";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { BranchId } from "@screens/workflow/types";
import QueryWrapper from "@components/QueryWrapper";
import { Transition } from "@headlessui/react";
import { ArrowsUpDownIcon } from "@heroicons/react/16/solid";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { generateAndDownloadFile } from "@utils/utils";
import { useWorkflowContext } from "../../../WorkflowContext";
import useNodeSuggestionsProvider from "../../hooks/useNodeSuggestionsProvider";
import { getNewNodeName, getUpdateBranchNode } from "../../utils";

type Props = NodeProps<{
  hidden: boolean;
  isFromFlow: boolean;
  label: string;
  desc: string;
}>;

const BranchNode = ({ data, id, selected }: Props) => {
  const {
    workflow,
    isWorkflowEditable,
    refetchWf,
    setShowDeleteConfirmation,
    updateWorkflow,
    errors,
  } = useWorkflowContext();

  const [bottomPanelStatus, setBottomPanelStatus] = useBottomPanelState();

  const isExpanded = useIsExpand();

  const iconStyles = useGetNodeIconStyles();
  const handleStyles = useGetHandleStyles();
  const nodeStyles = useGetNodeStyles();
  const fontStyles = useGetNodeFontStyles();

  const Icon = useGetIcon(BRANCH_NODE_TYPE);

  const branchConditions = useQuery(
    getBranchConditionsQuery({
      workflowId: workflow?.id!,
      branchId: id,
    })
  );

  const { getNodes } = useReactFlow();
  const { getInputProps, importNode } = useImportNodeWithInput(
    id,
    BRANCH_NODE_TYPE
  );
  const { duplicateNode } = useDuplicateNode(workflow?.id);

  const keywordsQuery = useKeywordsFromWorkflowKeywords(workflow?.id, id, data.label);

  const queryClient = useQueryClient();

  const updateBranchNodeName = async (name: string) => {
    const e = getUpdateBranchNode(name, id, data.desc);
    await updateWorkflow(e);
    refetchWf();
    queryClient.invalidateQueries(getErrors(workflow?.id));
  };

  const hidden = useIsHiddenAfterTest(id, data?.hidden);

  const provider = useNodeSuggestionsProvider(keywordsQuery, id);
  const handleStylesInputDot = useGetHandleStylesForInputDot();

  const conditions = branchConditions.data?.data.data ?? [];
  const handles = conditions.map((item) => item.name);

  const nodeErrors: [string, string][] = conditions
    .filter((item) => errors[id as BranchId]?.errors?.[item.name])
    .map((item) => [item.name, errors[id as BranchId].errors[item.name]]);

  const exportNode = () => {
    generateAndDownloadFile(
      JSON.stringify({
        type: BE_BRANCH_NODE_TYPE,
        feType: BRANCH_NODE_TYPE,
        expressions: conditions.map((i) => ({
          name: i.name,
          condition: i.body,
          seqNo: i.seqNo,
        })),
      }),
      data.label + ".json"
    );
  };

  return (
    <div
      onFocus={() => {
        // Focus fires whenever an editor is clicked.
        provider();
      }}
      tabIndex={-1}
    >
      <div
        style={nodeStyles}
        className={classNames(
          "bg-white w-[360px] relative group border border-neutral-100 rounded-[10px] overflow-visible hover:border-primary-200 cursor-grab active:cursor-grabbing pt-[7px]",
          isExpanded ? "" : "pb-[7px]",
          {
            "opacity-25": hidden,
            "border-primary-200 border-1": selected && !hidden,
            "shadow-lg": data?.isFromFlow && !hidden,
            "shadow-studio-node": !(data?.isFromFlow && !hidden),
            "!border-error-500": !isExpanded && nodeErrors.length,
          }
        )}
      >
        <Handle
          type="target"
          position={Position.Left}
          className="w-2 h-2 top-0 mt-[20px] opacity-50 hover:opacity-100 border-2 rounded-full bg-neutral-0  border-neutral-300 !-left-4 duration-150 overflow-visible"
          isConnectable={isWorkflowEditable}
          style={handleStylesInputDot}
        >
          <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
        </Handle>
        <div
          className={classNames(
            "right-0 absolute w-2 translate-x-4 transition-all duration-150 flex flex-col gap-[70px] overflow-visible items-center",
            isExpanded ? "h-auto top-11 pt-3 pb-12" : "h-full "
          )}
        >
          {!isExpanded && (
            <Handle
              type="source"
              position={Position.Right}
              className="w-2 h-2 top-3 bg-white hover:bg-primary-50 opacity-50 hover:opacity-100 group-hover:bg-primary-50 border-neutral-500 hover:h-3 hover:w-3 group-hover:h-3 group-hover:w-3 hover:border-primary-300 group-hover:border-primary-300 duration-150 rounded-full right-0"
              isConnectable={false}
              style={handleStyles}
            />
          )}
        </div>
        <div className="w-full font-b2-medium text-neutral-black px-2 flex items-center">
          <div className="flex gap-1.5 items-center mr-auto">
            <Icon style={iconStyles} />
            <span className="w-64 truncate" style={fontStyles}>
              <NodeName
                onChange={(name) => updateBranchNodeName(name)}
                defaultName={data.label || "Branch_Name"}
              />
            </span>
          </div>
          <TestButton nodeId={id} />
          <NodeMenu
            isExport={true}
            handleExportNode={exportNode}
            items={[
              {
                key: "import",
                label: (
                  <span className="text-neutral-black flex gap-2 items-center">
                    <ArrowsUpDownIcon className="text-neutral-black h-3 w-3 -mt-0.5" />
                    Import
                  </span>
                ),
                onClick: () => importNode(),
              },
              {
                key: "duplicate",
                label: (
                  <span className="text-neutral-black flex gap-2 items-center">
                    <DuplicateIcon className="text-neutral-black h-3 w-3 -mt-0.5" />
                    Duplicate
                  </span>
                ),
                onClick: () =>
                  duplicateNode({
                    type: BE_BRANCH_NODE_TYPE,
                    name: getNewNodeName(BRANCH_NODE_TYPE, getNodes()),
                    expressions: (conditions ?? []).map((item, index) => ({
                      name: item.name,
                      condition: item.body,
                      seqNo: index,
                    })),
                  }),
              },
              {
                key: "delete",
                label: (
                  <span className="text-error-500 flex gap-2 items-center">
                    <TrashIcon className="[&>path]:stroke-error-500 h-3 w-3 -mt-0.5" />
                    Delete
                  </span>
                ),
                onClick: () =>
                  setShowDeleteConfirmation({ id: id, label: data.label }),
              },

              // {
              //   key: "export",
              //   label: (
              //     <span className="text-neutral-black flex gap-2 items-center">
              //       <ArrowDownTrayIcon className="text-neutral-black h-3 w-3 -mt-0.5" />
              //       Export
              //     </span>
              //   ),
              //   onClick: exportNode,
              // },
            ]}
            isFromFlow={data.isFromFlow}
          />
        </div>
        <Transition
          show={isExpanded}
          enter="transition-all duration-150"
          enterFrom="opacity-0 h-0"
          enterTo="opacity-100 h-auto"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 h-auto"
          leaveTo="opacity-0 h-0"
          appear
        >
          <QueryWrapper query={branchConditions}>
            {() => (
              <BranchConditions
                nodeId={id}
                conditions={conditions}
                errors={nodeErrors}
                handles={handles ?? []}
                label={data?.label ?? ""}
                desc={data?.desc ?? ""}
              />
            )}
          </QueryWrapper>
        </Transition>
        {isExpanded && (
          <div className="border-t bg-neutral-0 rounded-b-[10px] font-b2-medium border-neutral-100 px-2 py-1.5 flex gap-6 items-center">
            <span
              className="cursor-pointer text-neutral-500 hover:text-neutral-black"
              onClick={() => setBottomPanelStatus("Inputs")}
            >
              Inputs
            </span>
            {nodeErrors.length > 0 && (
              <span
                className="flex items-center ml-auto gap-1.5 cursor-pointer text-error-500 font-b2-medium"
                onClick={() => setBottomPanelStatus("Errors")}
              >
                <img src={alertCircle} alt="!" />
                {nodeErrors.length} Errors
              </span>
            )}
          </div>
        )}
      </div>
      <input {...getInputProps()} />
      <BottomPanel selected={selected} show={bottomPanelStatus === "Inputs"}>
        <InputList
          close={() => setBottomPanelStatus(CLOSE)}
          keywords={keywordsQuery}
          query={""}
        />
      </BottomPanel>
      <BottomPanel selected={selected} show={bottomPanelStatus === "Errors"}>
        <ErrorList
          errors={nodeErrors}
          close={() => setBottomPanelStatus(CLOSE)}
        />
      </BottomPanel>
    </div>
  );
};

export default memo(BranchNode);
