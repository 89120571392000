import { format, subWeeks } from "date-fns";
import axios from "@axios";
import { DATE_FORMAT, REPORTS_PAGE_LIMIT } from "@config";
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { DateRange, FinBoxResponse } from "@types";
import {
  getGQEndTime,
  getGQStartTime,
  getNetworkErrorText,
  notify,
} from "@utils/utils";
import { validateEmail } from "@utils/validate";
import { ReportItem } from "./types";

export const reportListQuery = (args?: {
  dateRange: DateRange;
  page: number;
  type?: string;
}) =>
  queryOptions({
    queryKey: ["reportList", args],
    queryFn: async () =>
      axios.get<
        FinBoxResponse<{ list: null | ReportItem[]; totalCount: number }>
      >("report/list", {
        params: {
          startDate: getGQStartTime(
            args?.dateRange.startDate ?? null,
            DATE_FORMAT
          ),
          endDate: getGQEndTime(args?.dateRange.endDate ?? null, DATE_FORMAT),
          page: args?.page ?? 1,
          limit: REPORTS_PAGE_LIMIT,
          type: args?.type,
        },
      }),
    refetchInterval: (query) =>
      query.state.data?.data.data.list?.some(
        (item) => item.status.toLowerCase() === "processing"
      )
        ? 5000
        : false,
  });

export function useReportList(args: {
  dateRange: DateRange;
  page: number;
  type?: string;
}) {
  return useQuery(reportListQuery(args));
}

export function useDownloadReport() {
  return useMutation({
    mutationFn: async (reportID: string) =>
      axios.get<FinBoxResponse<string>>("report/download/" + reportID),
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: (data) => window.open(data.data.data, "_blank"),
  });
}

export function useRetryReport() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (reportID: string) =>
      axios.get(`/report/retry/${reportID}`),
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: reportListQuery().queryKey });
      notify({
        title: "Success",
        text: "Report generation started",
        type: "success",
      });
    },
  });
}

export function useGenerateReport() {
  return useMutation({
    mutationFn: async ({
      email,
      fromDate,
      toDate,
      ...params
    }: {
      fromDate?: string | null;
      toDate?: string | null;
      email?: string;
      user_id?: string | null;
      program?: string[] | null;
      policyVersion?: string[] | null;
      decision?: string[] | null;
    }) => {
      if (!validateEmail(email)) throw new Error("Invalid Email");
      if (!fromDate || !toDate) {
        fromDate = format(subWeeks(new Date(), 1), DATE_FORMAT);
        toDate = format(new Date(), DATE_FORMAT);
      }
      return axios.get(`outcomes/report`, {
        params: {
          email,
          fromDate: getGQStartTime(fromDate, DATE_FORMAT),
          toDate: getGQEndTime(toDate, DATE_FORMAT),
          ...Object.fromEntries(
            (Object.keys(params) as Array<keyof typeof params>)
              .filter((k) => params[k])
              .map((k) => [k, params[k]])
          ),
        },
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () =>
      notify({
        title: "Success",
        text: "Report generation started",
        type: "success",
      }),
  });
}

export function useEndpointGenerateReport() {
  return useMutation({
    mutationFn: async ({
      email,
      fromDate,
      toDate,
      endpointId,
    }: {
      fromDate?: string | null;
      toDate?: string | null;
      email?: string;
      endpointId?: string | null;
    }) => {
      if (!validateEmail(email)) throw new Error("Invalid Email");
      if (!fromDate || !toDate) {
        fromDate = format(subWeeks(new Date(), 1), DATE_FORMAT);
        toDate = format(new Date(), DATE_FORMAT);
      }
      return axios.get(`endpoint/report`, {
        params: {
          email,
          fromDate: getGQStartTime(fromDate, DATE_FORMAT),
          toDate: getGQEndTime(toDate, DATE_FORMAT),
          endpointId: endpointId,
        },
      });
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
    onSuccess: () =>
      notify({
        title: "Success",
        text: "Report generation started",
        type: "success",
      }),
  });
}
