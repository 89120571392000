import { useState } from "react";
import { useParams } from "react-router-dom";
import Dropdown from "@components/DropDown";
import Historical from "src/components/RunSimulationsModal/Historical";
import Application from "src/components/RunSimulationsModal/Application";
import CustomCSV from "src/components/RunSimulationsModal/Custom";

const dataTypes = ["Historical", "Application", "Custom"];

const SimulationForm = () => {
  const [selectedDataType, setSelectedDataType] = useState("Historical");
  const { workflowId } = useParams();

  return (
    <div className="pt-4 relative">
      <div className="px-4">
        <label className="font-b2-medium text-neutral-500 block leading-7">
          Select Simulation Type
        </label>
        <div>
          <Dropdown
            value={selectedDataType}
            className="w-full"
            onChange={(val: string) => setSelectedDataType(val)}
          >
            <Dropdown.Button className="relative w-full rounded-md border py-2 px-4 text-left text-neutral-black">
              {selectedDataType}
            </Dropdown.Button>
            <Dropdown.Options className="absolute left-0 top-10 z-20 box-border w-full rounded-md border bg-white shadow-lg ring-1 ring-gray-100">
              {dataTypes.map((dataType) => (
                <Dropdown.Option key={dataType} value={dataType} showTick>
                  <div>{dataType}</div>
                </Dropdown.Option>
              ))}
            </Dropdown.Options>
          </Dropdown>
        </div>
      </div>
      <div className="px-4 flex max-h-[100%]  min-w-full  grow flex-col rounded-md bg-white">
        {selectedDataType === "Historical" && (
          <Historical
            workflowId={workflowId!}
            type="workflow"
            closeModal={() => {}}
            policyID="test"
          />
        )}
        {selectedDataType === "Application" && (
          <Application
            policyID="test"
            workflowId={workflowId!}
            closeModal={() => {}}
            type="workflow"
          />
        )}
        {selectedDataType === "Custom" && (
          <CustomCSV
            policyID=""
            workflowId={workflowId!}
            type="workflow"
            closeModal={() => {}}
          />
        )}
      </div>
    </div>
  );
};

export default SimulationForm;
