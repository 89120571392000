import axios from "@axios";
import { useMutation } from "@tanstack/react-query";
import { getNetworkErrorText, notify } from "@utils/utils";

export function useSimulate() {
  return useMutation({
    mutationFn: async (
      props:
        | {
            file: File;
            type: "custom";
            workflowId: string;
          }
        | {
            file: File;
            type: "application";
            valueType: "reference_id" | "user_id";
            workflowId: string;
          }
        | {
            startDate?: string;
            endDate?: string;
            decision?: string;
            chooseFromId?: string;
            type: "historical";
            workflowId: string;
          }
    ) => {
      if (props.type === "application") {
        const formData = new FormData();
        formData.append("file", props.file);
        formData.append("valueType", props.valueType);
        formData.append("workflowId", props.workflowId);
        formData.append("simulationType", "application");
        return axios.v2.post("simulationWorkflow", formData);
      }
      if (props.type === "custom") {
        const formData = new FormData();
        formData.append("file", props.file);
        formData.append("workflowId", props.workflowId);
        formData.append("simulationType", "custom");
        return axios.v2.post("simulationWorkflow", formData);
      }
      if (props.type === "historical") {
        const formData = new FormData();
        formData.append("workflowId", props.workflowId);
        formData.append("simulationType", "historical");
        if (props.startDate && props.endDate) {
          formData.append("startDate", props.startDate);
          formData.append("endDate", props.endDate);
        }
        if (props.decision) formData.append("decision", props.decision);
        if (props.chooseFromId) formData.append("policyId", props.chooseFromId);
        return axios.v2.post("simulationWorkflow", formData);
      }
    },
    onError: (err) =>
      notify({ title: "Failed", text: getNetworkErrorText(err) }),
  });
}
