import chevronDown from "@assets/icons/chevron_down_blue.svg";
import { ReactComponent as EditIcon } from "@assets/icons/edit-01.svg";
import { ReactComponent as MinusIcon } from "@assets/icons/minus.svg";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Accordion } from "src/components/Accordion";
import Button from "src/components/Button";
import Modal from "src/components/Dialogue";
import Dropdown from "src/components/DropDown";
import ErrorText from "src/components/ErrorText";
import Input from "src/components/Input";
import Label from "src/components/Label";
import Shimmer from "src/components/Shimmer";
import { notify, titleCase } from "src/utils/utils";
import { validateEmail } from "src/utils/validate";
import { FilterSection } from "./FilterSection";
import { useAddUserWithLink, useMemberRoleListQuery } from "./queries";

type FormInput = {
  name: string;
  email: string;
  role: string;
  filters: Array<{
    funnelType: string;
    funnelList: { name: string; id: string }[];
  }>;
};

type Props = {
  show: boolean;
  setShow: setStateType<boolean>;
};

export const AddUser = ({ show, setShow }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = useForm<FormInput>({
    defaultValues: {
      filters: [{ funnelType: "", funnelList: [] }],
      role: "",
      name: "",
      email: "",
    },
  });

  const addUserMutation = useAddUserWithLink();
  const { data: roleList, isSuccess, isPending } = useMemberRoleListQuery();
  const [filterSections, setFilterSections] = useState<number[]>([]);
  const [editingSections, setEditingSections] = useState<number[]>([]);
  const watchFilters = watch("filters");
  const userRole = watch("role");

  const submit = handleSubmit(({ name, email, role, filters }) => {
    const currentFilters = getValues("filters");
    const newFilters = currentFilters.reduce((acc, filter) => {
      if (filter.funnelType && filter.funnelList.length > 0) {
        acc[filter.funnelType] = filter.funnelList;
      }
      return acc;
    }, {} as { [key: string]: { name: string; id: string }[] });

    addUserMutation.mutate(
      {
        email,
        name,
        role,
        filters: newFilters,
      },
      {
        onSuccess: () => {
          setShow(false);
          notify({
            type: "success",
            title: "Success",
            text: `Sent invite link via email`,
          });
        },
      }
    );
  });

  useEffect(() => {
    const currentFilters = getValues("filters");
    if (currentFilters.length > 0) {
      setFilterSections(
        Array.from({ length: currentFilters.length }, (_, i) => i)
      );
      setEditingSections(
        Array.from({ length: currentFilters.length }, (_, i) => i)
      );
    } else {
      setValue("filters", [{ funnelType: "", funnelList: [] }]);
      setFilterSections([0]);
      setEditingSections([0]);
    }
  }, [getValues, setValue]);

  const handleDeleteFilterSection = (indexToDelete: number) => {
    const currentFilters = getValues("filters");
    const updatedFilters = currentFilters.filter(
      (_, index) => index !== indexToDelete
    );
    const newFilters =
      updatedFilters.length > 0
        ? updatedFilters
        : [{ funnelType: "", funnelList: [] }];

    setValue("filters", newFilters);
    setFilterSections((prev) => {
      const updatedSections = prev.filter(
        (_, index) => index !== indexToDelete
      );
      return updatedSections.length > 0 ? updatedSections : [0];
    });

    setEditingSections((prev) => {
      const updatedEditingSections = prev
        .filter((index) => index !== indexToDelete)
        .map((index) => (index > indexToDelete ? index - 1 : index));
      return updatedEditingSections.length > 0 ? updatedEditingSections : [0];
    });
  };

  const handleAddAccess = () => {
    const currentFilters = getValues("filters");
    const newIndex = currentFilters.length;

    setValue("filters", [
      ...currentFilters,
      { funnelType: "", funnelList: [] },
    ]);
    setFilterSections((prev) => [...prev, newIndex]);
    setEditingSections((prev) => [...prev, newIndex]);

    setTimeout(() => {
      const newSection = document.getElementById(`filter-section-${newIndex}`);
      if (newSection) {
        newSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const getAvailableFunnelTypes = (sectionIndex: number) => {
    const allFunnelTypes = [
      "program",
      "journey",
      "endpoint",
      "policybucket",
      "policy",
      "template",
    ];
    const selectedFunnelTypes =
      watchFilters && Array.isArray(watchFilters)
        ? watchFilters
            .slice(0, sectionIndex)
            .map((filter) => filter?.funnelType)
            .filter(Boolean)
        : [];
    return allFunnelTypes.filter((type) => !selectedFunnelTypes.includes(type));
  };

  const toggleEditingSection = (index: number) => {
    setEditingSections((prev) => {
      if (prev.includes(index)) {
        if (prev.length === 1) return prev;
        return prev.filter((sectionIndex) => sectionIndex !== index);
      } else {
        const currentFilter = getValues(`filters.${index}`);
        if (currentFilter && currentFilter.funnelType) {
          setValue(`filters.${index}.funnelType`, currentFilter.funnelType);
        }
        return [...prev, index];
      }
    });
  };

  return (
    <Modal open={show} onClose={() => setShow(false)}>
      <Modal.Panel size="sm">
        <Modal.Header>Invite Members</Modal.Header>
        <Modal.Body className="!pt-3">
          <form onSubmit={submit} className="flex flex-col gap-3">
            <div>
              <Label className="mb-2 flex">User Email</Label>
              <Input
                {...register("email", {
                  required: { value: true, message: "Email is required" },
                  validate: (email) => {
                    return validateEmail(email) || "Enter a valid email";
                  },
                })}
                type="email"
                error={errors.email?.message}
                placeholder="Enter email id"
                autoComplete="off"
              />
            </div>
            <div>
              <Label className="mb-2 flex">User Name</Label>
              <Input
                {...register("name", {
                  required: { value: true, message: "Name is required" },
                })}
                type="text"
                error={errors.name?.message}
                autoComplete="off"
                placeholder="Enter name"
                {...register("name", {
                  required: { value: true, message: "User name is required" },
                  pattern: {
                    value: /^[a-zA-Z0-9_ ]+$/,
                    message:
                      "User name must contain only letters, numbers, spaces, and underscores",
                  },
                })}
              />
            </div>

            <div>
              <Label className="mb-2 flex">User Role</Label>
              {isPending && <Shimmer w="100%" h="25px" />}
              {isSuccess && (
                <Controller
                  name="role"
                  rules={{
                    required: { value: true, message: "Role is required" },
                  }}
                  control={control}
                  render={({ field }) => (
                    <Dropdown value={field.value} onChange={field.onChange}>
                      <Dropdown.Button className="w-full">
                        {roleList.find((r) => r.role === field.value)?.role ? (
                          <span className="text-neutral-black">
                            {roleList.find((r) => r.role === field.value)?.role}
                          </span>
                        ) : (
                          "Select Role"
                        )}
                      </Dropdown.Button>
                      <Dropdown.Options className="h-fit">
                        {roleList.map((r) => (
                          <Dropdown.Option key={r.role} showTick value={r.role}>
                            {r.role}
                          </Dropdown.Option>
                        ))}
                      </Dropdown.Options>
                    </Dropdown>
                  )}
                />
              )}
              <ErrorText className="mt-1">{errors.role?.message}</ErrorText>
            </div>
            {userRole !== "owner" && (
              <Accordion
                contentClassName=""
                content={({ open }) => (
                  <div className="font-b1-medium text-primary-900 flex gap-1 items-center">
                    <span>Configure Data Level Access</span>
                    <img
                      src={chevronDown}
                      className={classNames(
                        "right-3 duration-100",
                        open && "rotate-180"
                      )}
                      aria-hidden="true"
                      alt=""
                    />
                  </div>
                )}
              >
                <div className="flex flex-col gap-2">
                  <>
                    <p className="font-b2">
                      This role has access to, Select Data Level
                    </p>
                    {filterSections.length > 0 &&
                      filterSections.map((sectionIndex, arrayIndex) => (
                        <div key={sectionIndex} className="flex flex-col gap-2">
                          {editingSections.includes(arrayIndex) ? (
                            <>
                              <div className="flex justify-between items-center">
                                <span className="font-b2">
                                  Access {arrayIndex + 1}
                                </span>
                                <div className="gap-3 flex">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      toggleEditingSection(arrayIndex)
                                    }
                                  >
                                    <EditIcon className="w-4 h-4 stroke-neutral-500" />
                                  </button>
                                  <button
                                    className=" text-neutral-500"
                                    type="button"
                                    onClick={() =>
                                      handleDeleteFilterSection(arrayIndex)
                                    }
                                    disabled={filterSections.length === 0}
                                  >
                                    <MinusIcon className="w-4 h-4 stroke-neutral-500" />
                                  </button>
                                </div>
                              </div>

                              <FilterSection
                                index={arrayIndex}
                                control={control}
                                availableFunnelTypes={getAvailableFunnelTypes(
                                  arrayIndex
                                )}
                                isEditing={editingSections.includes(arrayIndex)}
                                selectedType={
                                  watchFilters[arrayIndex]?.funnelType
                                }
                              />
                            </>
                          ) : (
                            <div className="flex flex-col gap-2">
                              <div className="flex justify-between">
                                <span className="font-b1">
                                  {titleCase(
                                    watchFilters[arrayIndex]?.funnelType
                                  )}
                                </span>{" "}
                                <div className="gap-3 flex">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      toggleEditingSection(arrayIndex)
                                    }
                                  >
                                    <EditIcon className="w-4 h-4 stroke-neutral-500" />
                                  </button>
                                  <button
                                    className=" text-neutral-500"
                                    type="button"
                                    onClick={() =>
                                      handleDeleteFilterSection(arrayIndex)
                                    }
                                    disabled={filterSections.length === 0}
                                  >
                                    <MinusIcon className="w-4 h-4 stroke-neutral-500" />
                                  </button>
                                </div>
                              </div>
                              <div className="flex flex-wrap gap-1">
                                {watchFilters[arrayIndex]?.funnelList.map(
                                  (item) => (
                                    <span
                                      key={item.id}
                                      className="rounded-md bg-neutral-25 font-b1 text-neutral-black px-2 py-1"
                                    >
                                      {item.name}
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}

                    <div>
                      <button
                        className="font-b2-medium text-neutral-black hover:text-primary-900"
                        type="button"
                        onClick={handleAddAccess}
                        disabled={filterSections.length >= 6}
                      >
                        + Add Access
                      </button>
                    </div>
                  </>
                </div>
              </Accordion>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={addUserMutation.isPending || isPending}
            onClick={submit}
          >
            {addUserMutation.isPending ? "Loading..." : "Send Invitation"}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  );
};
