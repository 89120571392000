import classNames from "clsx";

type Props = {
  h?: string;
  w?: string;
  className?: string;
};
export default function Shimmer({ h, w, className }: Props) {
  return (
    <div
      className={classNames("shimmer bg-gray-200 rounded-md", className)}
      style={{
        height: h,
        width: w,
      }}
    />
  );
}
