import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { initSentry } from "@utils/sentry";
import App from "./App";
import "./styles/index.css";

if (["PROD", "UAT"].includes(import.meta.env.REACT_APP_API_ENV)) {
  initSentry();
}

async function enableMocking() {
  if (
    import.meta.env.REACT_APP_API_ENV !== "LOCAL" &&
    !import.meta.env.VITEST
  ) {
    return;
  }

  const { worker } = await import("./mocks/browser");
  if (import.meta.env.VITEST) {
    return worker.start();
  } else {
    // return worker.start();
  }
}

enableMocking().then(() => {
  const root = ReactDOM.createRoot(document.getElementById("root")!);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
});
