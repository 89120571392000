import classNames from "clsx";
import { memo } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import workflowIcon from "@assets/icons/dataflow-01.svg";
import { ReactComponent as ShareIcon } from "@assets/icons/share-04.svg";
import HandleWithPlus from "@screens/workflow/studio/components/HandleWithPlus";
import Button from "@components/Button";
import StateConnector from "../../components/StateConnectors";
import useIsExpand, {
  useGetHandleStyles,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "../../hooks/useIsExpand";

const RunFlowWorkflowNode = ({ data, selected, id }: NodeProps) => {
  const isExpanded = useIsExpand();

  const fontStyles = useGetNodeFontStyles();
  const iconStyles = useGetNodeIconStyles();
  const handleStyles = useGetHandleStyles();
  const nodeStyles = useGetNodeStyles();

  return (
    <div className="flex group cursor-grab active:cursor-grabbing">
      <div
        style={nodeStyles}
        className={classNames(
          "overflow-visible w-[380px] h-max bg-white border hover:border-primary-200 rounded-[10px]",
          isExpanded ? "p-2" : "p-1",
          {
            "opacity-25": data?.hidden,
            "border-primary-200": selected && !data?.hidden,
            "border-neutral-100": !selected,
            "shadow-lg": data?.isFromFlow && !data?.hidden,
            "shadow-studio-node": !(data?.isFromFlow && !data?.hidden),
          }
        )}
      >
        <Handle
          type="target"
          position={Position.Left}
          className="w-2 h-2 hover:opacity-100 opacity-50 rounded-full bg-white border-neutral-500 !-left-4 duration-150"
          isConnectable={false}
          style={handleStyles}
        >
          <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
        </Handle>
        {!isExpanded && (
          <Handle
            type="source"
            position={Position.Right}
            className="w-2 h-2 opacity-50 hover:opacity-100 bg-white hover:bg-primary-50 group-hover:bg-primary-50 border-neutral-500 hover:h-3 hover:w-3 group-hover:h-3 group-hover:w-3 hover:border-primary-300 group-hover:border-primary-300 duration-150 rounded-full !-right-3 top-1/2"
            isConnectable={false}
            style={handleStyles}
          />
        )}

        <div className="w-full text-neutral-black font-medium flex items-center justify-between">
          <div className="flex gap-2 mb-1 items-center pt-1 ml-1">
            <img style={iconStyles} alt="workflow" src={workflowIcon} />
            <span className="w-56 truncate" style={fontStyles}>
              {data.label || "Workflow_Name"}
            </span>
          </div>
        </div>
        {isExpanded && (
          <div className="relative">
            <span className="font-b2 text-neutral-500 mb-1">
              <span>Select Workflow</span>
              {data.workflowID && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`/workflow/${data.workflowID}`, "_blank");
                  }}
                  variant="outline"
                  className="font-b2 !font-medium h-6 w-6 !p-0 group absolute z-10 bottom-1 right-8 justify-center"
                >
                  <ShareIcon className="[&>path]:stroke-neutral-500 group-hover:[&>path]:stroke-neutral-500 w-3 h-3" />
                </Button>
              )}
            </span>
            <span className="font-b1-medium pl-3 h-8 flex items-center rounded-md mt-2 w-full border border-neutral-100">
              {data.label}
            </span>
          </div>
        )}
      </div>
      {data?.workflowID && isExpanded && (
        <div
          className={classNames("flex items-center ml-1", {
            "opacity-25": data?.hidden,
          })}
        >
          <StateConnector length={data?.handles?.length} />
          <div className="flex justify-between gap-3 h-max flex-col">
            {data?.handles?.map((h: string) => (
              <HandleWithPlus
                key={h}
                id={h}
                label={h}
                nodeId={id}
                noIcon
                showAdd={false}
                isConnectable={false}
                position={Position.Right}
                type={"source"}
                handleStyles={handleStyles}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(RunFlowWorkflowNode);
