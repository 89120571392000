interface StateConnectorProps {
  length: number;
}

const StateConnector = ({ length }: StateConnectorProps) => {
  // based on height of each item and gap between each item
  const height = (length - 1) * 2 + (length - 1) * 0.75;
  return (
    <div className="flex items-center relative">
      <div className="w-4 self-start border-t-[1.5px] border-[#dfdfdf]" />
      <div
        className="border-l-[1.5px] border-[#dfdfdf] rounded-bl-md"
        style={{
          height: `${height}rem`,
        }}
      >
        <div className="h-full w-3 flex flex-col justify-between rounded-md">
          {Array.from(Array(length)).map((_, i) => (
            <div
              key={i}
              className="last:border-t-0 last:border-b-[1.5px] border-t-[1.5px] last:rounded-bl-md border-[#dfdfdf] first:h-2 last:h-2"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StateConnector;
