import { Edge, Node } from "reactflow";
import {
  DECISION_TABLE_NODE_TYPE,
  MODEL_NODE_TYPE,
  MODEL_SET_NODE_TYPE,
  SOURCE_NODE_TYPE,
  START_NODE_TYPE,
} from "@screens/workflow/config";

export type WfWarning = {
  type: "unconnected_node" | "unconnected_handles";
  node: string;
  handle?: string;
  message: string;
};
export const validateWorkflow = ({
  edges,
  nodes,
  getNode,
}: {
  edges: Edge[];
  nodes: Node[];
  getNode: (id: string) => Node<any> | undefined;
}) => {
  let errors = new Set<WfWarning>();
  let edgeTargets = edges.map((e) => e.target);
  let edgeSources = edges.map((e) => {
    if (e.sourceHandle) {
      return `${e.source}:${e.sourceHandle}`;
    }
    return e.source;
  });

  nodes.forEach((node) => {
    if (node.type === MODEL_NODE_TYPE || node.type === MODEL_SET_NODE_TYPE) {
      edgeSources.push(`${node.id}:model`);
    } else if (node.type === DECISION_TABLE_NODE_TYPE) {
      edgeSources.push(`${node.id}:decisionTable`);
    } else if (node.type === SOURCE_NODE_TYPE) {
      edgeSources.push(`${node.id}:source`);
    }
  });

  nodes.forEach((n) => {
    if (!edgeTargets.includes(n.id) && n.type !== START_NODE_TYPE) {
      errors.add({
        type: "unconnected_node",
        node: n.id,
        message: `No incoming connection to node ${n.data.label} `,
      });
    }
    const nn = getNode(n.id);
    try {
      const symbol = Object.getOwnPropertySymbols(nn);
      const handles = (nn as any)[symbol[0]].handleBounds;
      handles?.source?.forEach((hs: any) => {
        const id = hs.id ? n.id + ":" + hs.id : n.id;
        if (!edgeSources.includes(id) && hs.id) {
          let message = `${hs.id} of node ${n.data.label} not connected`;
          if (n.id === "Start") {
            message = `${n.data.label} node not connected`;
          }
          errors.add({
            type: "unconnected_handles",
            node: n.id,
            handle: hs.id,
            message,
          });
        }
      });
    } catch (error) {}
  });
  return Array.from(errors);
};
