import Modal from "src/components/Dialogue";
import Button from "@components/Button";

export default function Footer({
  isLoading,
  submit,
}: {
  isLoading: boolean;
  submit: () => void;
}) {
  return (
    <Modal.Footer className="flex w-full justify-end gap-2">
      <Button onClick={submit} disabled={isLoading}>
        {isLoading ? "Loading.." : "Run"}
      </Button>
    </Modal.Footer>
  );
}
