import accessLockIcon from "@assets/icons/access_lock.svg";

export const AccessDeniedErrorMessage = "Request failed with status code 403";
export const AccessDenied = () => {
  return (
    <div className="flex w-full h-full flex-col items-center justify-center gap-2 text-center">
      <img src={accessLockIcon} alt="access not allowed" />
      <p className="text-xl">You need access</p>
      <p className="font-b1 text-neutral-500">
        Looks like you don't have enough permissions to view this page
      </p>
    </div>
  );
};
