import { useState } from "react";
import BadgeTabs from "src/components/BadgeTabs";
import Header from "src/components/Header";
import BcDocs from "./BcDocs";
import BureauDocs from "./BureauDocs";
import DcDocs from "./DcDocs";

const TABS = ["Device", "Bank", "Bureau"] as const;
type TabStrings = (typeof TABS)[number];
const DataDictionary = () => {
  const [tab, setTab] = useState<TabStrings>("Device");
  const [query, setQuery] = useState("");

  return (
    <div className="max-w-screen  max-h-screen w-screen  bg-white overflow-auto h-full">
      <Header>
        <div className="mt-4 mb-8">
          <div className="flex items-center justify-between">
            <div className="sm:flex-auto">
              <Header.PageHeader>Data dictionary</Header.PageHeader>
            </div>
            <div className="mx-8">
              <input
                type="search"
                className="rounded-lg h-9 text-sm border-gray-300"
                placeholder="Search"
                value={query}
                onChange={(e) => setQuery(e.currentTarget.value.toLowerCase())}
              />
            </div>
          </div>
        </div>
      </Header>
      <BadgeTabs
        // @ts-ignore
        tabs={TABS}
        onClick={setTab}
        currentTab={tab}
        className="mx-8 w-max"
      />
      {tab === "Bank" && <BcDocs query={query} />}
      {tab === "Bureau" && <BureauDocs query={query} />}
      {tab === "Device" && <DcDocs query={query} />}
    </div>
  );
};

export default DataDictionary;
