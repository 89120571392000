import classNames from "clsx";
import { useEffect, useState } from "react";
import Search from "src/components/Search";
import branch from "@assets/icons/workflow/branch.svg";
import policy from "@assets/icons/workflow/policy.svg";
import rule from "@assets/icons/workflow/rule.svg";

type OutputDetails = {
  name: string;
  count: number;
  cssStyle?: any;
};

type EvaluationObject = {
  type: string;
  name: string;
  total_count: number;
  output: OutputDetails[];
};

type EvaluationProps = EvaluationObject[];

type EvaluationDetails = {
  evaluations: EvaluationProps;
};

const Evaluations = ({ evaluations }: EvaluationDetails) => {
  const [evaluationData, setEvaluationData] = useState<EvaluationProps>([]);
  const [originalEvaluationData, setOriginalEvaluationData] =
    useState<EvaluationProps>([]);

  useEffect(() => {
    setEvaluationData(evaluations);
    setOriginalEvaluationData(evaluations);
  }, [evaluations]);

  const onSearch = (searchValue: string) => {
    const evData = originalEvaluationData?.filter((evaluation) => {
      return evaluation?.name
        ?.toLowerCase()
        .includes(searchValue?.toLowerCase());
    });
    setEvaluationData([...evData]);
  };

  const getIcon = (type: string) => {
    switch (type) {
      case "branch":
        return branch;
      case "rule_set":
        return rule;
      case "policy":
      case "workflow":
        return policy;
      default:
        return policy;
    }
  };

  const outcomeDetails = (
    evaluation: EvaluationObject,
    outputMetric: OutputDetails
  ) => {
    let max = 0;
    let type = "";
    evaluation?.output.forEach((outputMetric) => {
      if (max < outputMetric?.count) {
        max = outputMetric?.count;
        type = outputMetric?.name;
      }
      outputMetric.cssStyle = "text-neutral-500";
    });
    evaluation?.output.map(() => {
      if (type === outputMetric?.name) {
        if (["pass", "approved"].includes(outputMetric?.name.toLowerCase())) {
          outputMetric.cssStyle = "text-success-500";
        } else if (
          ["rejected", "fail"].includes(outputMetric?.name.toLowerCase())
        ) {
          outputMetric.cssStyle = "text-error-500";
        } else if (["cant_decide"].includes(outputMetric?.name.toLowerCase())) {
          outputMetric.cssStyle = "text-neutral-black";
        } else {
          outputMetric.cssStyle = "text-neutral-black";
        }
      }
      return outputMetric;
    });
    return (
      <label
        className={classNames("mr-2 font-b2 leading-5", outputMetric.cssStyle)}
      >
        {outputMetric?.name} : {outputMetric?.count}
      </label>
    );
  };

  return (
    <div className="border-t mt-2 pt-2">
      <div className="ml-4">
        <label className="mb-2 pt-2 text-neutral-black font-b1-medium leading-5">
          Evaluations
        </label>
        <Search
          className="w-[95%] mt-2"
          hint={""}
          onSearchChange={(e) => onSearch(e.target.value)}
          inputClassName={"h-8 bg-neutral-0"}
          defaultValue={""}
        />
        <div className="pt-4">
          {evaluationData?.map((evaluation) => {
            return (
              <div className="px-2 py-[6px] overflow-auto">
                <div className="flex">
                  <img alt="rule" src={getIcon(evaluation.type)} className="" />
                  <label className="ml-2 text-neutral-black font-b1-medium leading-5">
                    {evaluation.name}
                  </label>
                </div>
                <label className="mt-2 text-neutral-black font-b2 block leading-5">
                  User Volume: {evaluation.total_count}
                </label>
                <label className="mt-2 mr-2 text-neutral-black font-b2 leading-5">
                  Outcome:
                </label>
                {evaluation?.output.map((outputMetric) =>
                  outcomeDetails(evaluation, outputMetric)
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Evaluations;
