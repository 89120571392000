import classNames from "clsx";

type Props<T extends string> = {
  tabs: T[];
  onChange: (t: T) => void;
  className?: string;
  currentTab: T;
};

const Tabs = <T extends string>({
  tabs,
  onChange,
  className,
  currentTab,
}: Props<T>) => {
  if (tabs.length === 0) return null;

  return (
    <div
      className={classNames(
        "z-0 flex w-fit items-center overflow-hidden rounded-md border transition-all duration-100 font-b2-medium",
        className
      )}
    >
      {tabs.map((t) => (
        <span
          key={t}
          className={classNames(
            "relative flex cursor-pointer items-center justify-between px-2.5 h-full text-neutral-500",
            {
              "bg-neutral-50 text-neutral-black": t === currentTab,
            }
          )}
          onClick={() => onChange(t)}
        >
          {t}
        </span>
      ))}
    </div>
  );
};

export default Tabs;
