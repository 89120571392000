import { useReactFlow } from "reactflow";
import { getWorkflowQuery, useImportNode } from "@screens/workflow/queries";
import { NodeImportData } from "@screens/workflow/types";
import { useQueryClient } from "@tanstack/react-query";

export default function useDuplicateNode(workflowId: string | undefined) {
  const importNode = useImportNode();
  const { setCenter } = useReactFlow();
  const qc = useQueryClient();

  const duplicateNode = (data: NodeImportData) => {
    if (!workflowId) return;
    importNode.mutate(
      {
        workflowId: workflowId,
        data,
      },
      {
        onSuccess: (data, vars) => {
          qc.invalidateQueries(getWorkflowQuery(vars.workflowId));
          if (!data?.data.data) return;
          setCenter(0, 0, { duration: 500 });
        },
      }
    );
  };
  return { duplicateNode };
}
