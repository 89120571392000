import { LOCALSTORAGE_KEYS } from "@config";
import { useAppState } from "@context/AppProvider";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  children: JSX.Element;
};

export default function PrivateRoute({ children }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { appState } = useAppState();

  useEffect(() => {
    if (!appState.isAuthenticated) {
      Object.keys(LOCALSTORAGE_KEYS).forEach((item) =>
        localStorage.removeItem(item)
      );
      navigate(
        "/login?redirectTo=" +
          encodeURI(`${location.pathname}${location.search}`),
        {
          replace: true,
        }
      );
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.isAuthenticated, navigate]);

  if (!appState.isAuthenticated) return null;
  return children;
}
