export const getUniqueRuleSetName = (
  ruleSetNames: string[],
  i: number
): string => {
  const newName = `rule_${i}`;
  if (!ruleSetNames.includes(newName)) {
    return newName;
  }
  return getUniqueRuleSetName(ruleSetNames, i + 1);
};
