import classNames from "clsx";
import { memo } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import branchIcon from "@assets/icons/workflow/git-branch-01.svg";
import { useGetKeywords } from "@screens/create-policy/queries";
import useWfId from "@screens/outcome-details/eval-components/WfIdContext";
import { getBranchConditionsQuery } from "@screens/workflow/studio/components/Branch/queries";
import useIsExpand, {
  useGetHandleStyles,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "@screens/workflow/studio/hooks/useIsExpand";
import QueryWrapper from "@components/QueryWrapper";
import Shimmer from "@components/Shimmer";
import { Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";

const RunFlowBranchNode = ({ data, id, selected }: NodeProps) => {
  const wfId = useWfId();

  const keywordsQuery = useGetKeywords<"workflow">({
    ruleGroupID: id,
    id: wfId,
    type: "workflow",
  });

  const isExpanded = useIsExpand();
  const iconStyles = useGetNodeIconStyles();
  const fontStyles = useGetNodeFontStyles();
  const handleStyles = useGetHandleStyles();
  const nodeStyles = useGetNodeStyles();

  const branchConditions = useQuery(
    getBranchConditionsQuery({ workflowId: wfId, branchId: id })
  );

  const conditions = branchConditions.data?.data.data ?? [];

  return (
    // TODO: Find a better solution for this.
    // isExpanded is set as the key to unmount the handles to update the handle positions.
    <div key={isExpanded.toString()}>
      <div
        className={classNames(
          "bg-white w-[360px] relative group border border-neutral-100 rounded-[10px] overflow-visible hover:border-primary-200 cursor-grab active:cursor-grabbing",
          {
            "opacity-25": data?.hidden,
            "border-primary-200 border-1": selected && !data?.hidden,
            "shadow-lg": data?.isFromFlow && !data?.hidden,
            "shadow-studio-node": !(data?.isFromFlow && !data?.hidden),
          }
        )}
        style={nodeStyles}
      >
        <Handle
          type="target"
          position={Position.Left}
          className="w-2 h-2 rounded-full bg-white border-neutral-500 !-left-4 opacity-50 hover:opacity-100"
          isConnectable={false}
          style={handleStyles}
        >
          <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
        </Handle>
        <div
          className={classNames(
            "right-0 absolute w-2 translate-x-4 transition-all duration-150 flex flex-col gap-[70px] overflow-visible",
            isExpanded ? "h-auto top-11 pt-3 pb-12" : "h-full"
          )}
        >
          {!isExpanded && (
            <Handle
              type="source"
              position={Position.Right}
              className="w-2 h-2 bg-white hover:bg-primary-50 opacity-50 hover:opacity-100 group-hover:bg-primary-50 border-neutral-500 hover:h-3 hover:w-3 group-hover:h-3 group-hover:w-3 hover:border-primary-300 group-hover:border-primary-300 duration-150 rounded-full right-0 top-1/2"
              isConnectable={false}
              style={handleStyles}
            />
          )}
        </div>
        <div className="w-full p-2 font-b2-medium text-neutral-black flex items-center gap-4">
          <div className="flex gap-1.5 items-center ml-1">
            <img alt="RG" src={branchIcon} style={iconStyles} />
            <span
              style={fontStyles}
              className="max-w-44 w-max truncate cursor-pointer font-medium hover:bg-neutral-25 px-1 py-0.5 rounded-md"
            >
              {data.label || "Branch_Name"}
            </span>
          </div>
        </div>
        <Transition
          show={isExpanded}
          enter="transition-all duration-150"
          enterFrom="opacity-0 h-0"
          enterTo="opacity-100 h-auto"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 h-auto"
          leaveTo="opacity-0 h-0"
          appear
        >
          <div className="px-2 pb-3 flex gap-2 flex-col max-h-full overflow-visible">
            <QueryWrapper query={branchConditions}>
              {() => {
                return (
                  <>
                    {conditions.map((item, index) => {
                      if (!item.body && index !== conditions.length - 1)
                        return null;

                      return (
                        <div
                          key={item.id}
                          id="branch-item-header"
                          className="bg-neutral-0 border relative border-neutral-100 p-1.5 rounded-md flex flex-col gap-2 font-b2 overflow-visible"
                        >
                          <div className="!absolute top-0 -right-4 translate-x-1/2">
                            <Handle
                              type="source"
                              position={Position.Right}
                              id={item.name}
                              className="w-2 h-2 !static opacity-50 translate-y-0 translate-x-2 my-1.5 pointer-events-auto rounded-full bg-white hover:bg-primary-50 group-hover:bg-primary-50 border-neutral-500 hover:scale-150 group-hover:opacity-100s group-hover:scale-150 hover:border-primary-300 group-hover:border-primary-300 duration-150"
                              isConnectable={false}
                              style={handleStyles}
                            />
                          </div>
                          <div className="flex items-center justify-start gap-3">
                            {index === 0
                              ? "If"
                              : index === conditions.length - 1
                              ? "Else(Default)"
                              : "Else If"}
                            <span className="ml-auto font-b2 text-neutral-500 cursor-pointer min-w-[30px] min-h-[1rem]">
                              {item.name}
                            </span>
                          </div>
                          {index !== conditions.length - 1 &&
                            (keywordsQuery.isLoading ? (
                              <Shimmer w="100%" h="1.5em" />
                            ) : (
                              <div
                                className="leading-[18px] break-all rounded-md px-1.5 p-1 bg-white border border-neutral-100"
                                style={{
                                  fontFamily: "Monaco",
                                }}
                              >
                                {item.body}
                              </div>
                            ))}
                        </div>
                      );
                    })}
                  </>
                );
              }}
            </QueryWrapper>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default memo((props: NodeProps) => <RunFlowBranchNode {...props} />);
