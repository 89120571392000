import useBreadcrumbs from "@context/BreadcrumbsContext";
import { useState } from "react";
import Badge from "src/components/Badge";
import EmptyState from "src/components/EmptyState";
import NewHeader from "src/components/NewHeader";
import Searchbar from "src/components/Searchbar";
import FilterDropdown from "src/components/Searchbar/filters/FilterDropdown";
import { useApiStoreQuery } from "src/screens/create-policy/queries";
import EditDataSourceModal from "../EditDataSourceModal";
import { Source, SourceMap, SubHeaderMapping } from "../types";

const STATUS_OPTIONS = [
  { name: "Connected", value: 0 },
  { name: "Not Connected", value: 1 },
  { name: "All types", value: 2 },
];

const DEFAULT_STATUS_OPTION = { name: "All types", value: 2 };

const SUB_HEADER_MAPPING: SubHeaderMapping = {
  Finbox: "Finbox Data Sources",
  Hyperverge: "Hyperverge",
  Izidata: "IZI Data",
};

export default function FinBoxDataSources() {
  useBreadcrumbs([
    {
      name: "API Store",
      link: "#",
    },
  ]);

  const [filterParam, setFilterParam] = useState<number | undefined>();
  const [searchParam, setSearchParam] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedSource, setSelectedSource] = useState<Source | undefined>();
  const { data } = useApiStoreQuery();
  let dataSourceObj: SourceMap = {};

  data?.forEach((source: Source) => {
    if (
      dataSourceObj[source.vendor] &&
      dataSourceObj[source.vendor].length > 0
    ) {
      dataSourceObj[source.vendor]?.push(source);
    } else {
      dataSourceObj[source.vendor] = [];

      dataSourceObj[source.vendor]?.push(source);
    }
  });

  const showDetails = (source: Source) => {
    setOpenModal(true);
    setSelectedSource(source);
  };

  return (
    <div>
      <NewHeader
        filters={
          <Searchbar
            onSearch={(searchVal) => {
              setSearchParam(searchVal);
            }}
            hint="Search API"
            defaultValue={""}
          >
            <Searchbar.Filters>
              <FilterDropdown
                panelClassname="!w-[150px] !max-w-[400px]"
                displayValue={(v) => v.name}
                options={STATUS_OPTIONS}
                onChange={(v) => {
                  setFilterParam(v.value);
                }}
                value={STATUS_OPTIONS.find((p) => p.value === filterParam)}
                getKey={(v) => v.value}
                onClear={() => {
                  setFilterParam(undefined);
                }}
                defaultValue={DEFAULT_STATUS_OPTION}
              />
            </Searchbar.Filters>
          </Searchbar>
        }
      >
        <NewHeader.Title>API Store</NewHeader.Title>
      </NewHeader>
      <div className="max-w-full w-full relative z-0 rounded px-8 pb-8 overflow-auto">
        {!!dataSourceObj ? (
          Object.keys(dataSourceObj).map((key) => {
            return (
              <div key={key} className="flex flex-col gap-3">
                <div className="font-b1-medium">{SUB_HEADER_MAPPING[key]}</div>
                <div className="grid grid-cols-3 mb-4">
                  {dataSourceObj[key]
                    ?.filter((src) =>
                      filterParam !== undefined
                        ? src.status === filterParam || filterParam === 2
                        : src
                    )
                    ?.filter((src) =>
                      src.name.toLowerCase().includes(searchParam)
                    )
                    ?.map((source: Source) => {
                      return (
                        <div
                          key={source.name}
                          onClick={() => showDetails(source)}
                          className="group border cursor-pointer border-neutral-100 font-b1-medium rounded-md overscroll-none bg-white flex flex-col p-4 h-[170px] mb-4 mr-6"
                        >
                          <div className="h-[28px] w-[28px] bg-neutral-25 flex items-center justify-center rounded-md">
                            <img
                              src={source.logo}
                              height="16px"
                              width="16px"
                              alt="API Store"
                            />
                          </div>
                          <span className="font-b1-medium mt-2">
                            {source.name}
                          </span>
                          <span className="font-b2-medium text-neutral-500 mt-1">
                            {source.description}
                          </span>
                          <Badge
                            className={` mt-5 ${
                              source.status
                                ? "bg-neutral-50 text-neutral-800"
                                : "bg-success-100 text-success-700"
                            }`}
                          >
                            {source.status ? "Not Connected" : "Connected"}
                          </Badge>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })
        ) : (
          <EmptyState>No data found</EmptyState>
        )}
      </div>
      {selectedSource && Object.keys(selectedSource).length > 0 && (
        <EditDataSourceModal
          open={openModal}
          selectedSource={selectedSource}
          setOpenModal={(value) => {
            if (!value) setSelectedSource(undefined);
            setOpenModal(value);
          }}
        />
      )}
    </div>
  );
}
