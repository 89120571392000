import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useLogout, useResetPassword } from "src/apis";
import Button from "src/components/Button";
import { useAppState } from "@context/AppProvider";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { FinBoxResponse } from "@types";
import { notify } from "@utils/utils";
import { validatePassword } from "@utils/validate";
import LoginWrapper from "../login/components/LoginWrapper";

const SetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<{ password: string; confirmPassword: string }>();
  const resetPasswordMutation = useResetPassword();
  const { appState } = useAppState();
  const { resetToken } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout(false);

  useEffect(() => {
    appState.isAuthenticated && logout.mutate();
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (data: {
    password: string;
    confirmPassword: string;
  }) => {
    const { valid: isValidPassword, errors: passwordErrors } = validatePassword(
      data.password
    );
    if (!isValidPassword) {
      setError("password", {
        type: "validate",
        message: passwordErrors.join("  "),
      });
      return;
    }
    if (data.password !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "validate",
        message: "password mismatch",
      });
      return;
    }

    try {
      await resetPasswordMutation.mutateAsync({
        password: data.password,
        token: resetToken || "",
      });
      setIsSuccess(true);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      if (error instanceof AxiosError) {
        notify({
          text:
            (error as AxiosError<FinBoxResponse<any>>).response?.data.error ||
            "Unable to set password",
          title: "Error",
          type: "error",
        });
      }
    }
  };
  return (
    <LoginWrapper>
      <div className="w-4/5 sm:max-w-[380px]">
        {isSuccess ? (
          <div className="flex flex-col items-center justify-center">
            <CheckCircleIcon className="h-10 w-10 text-green-500" />
            <div className="text-lg text-gray-800 font-bold py-3">
              Password set successfully
            </div>
            <p className="text-sm text-gray-500">
              Now you can login using your new password
            </p>
          </div>
        ) : (
          <>
            <div className="font-bold mb-5 text-lg">Set Password</div>
            <form className="space-y-4" onSubmit={handleSubmit(submit)}>
              <div>
                <label
                  htmlFor="password"
                  className="block font-b2-medium text-neutral-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    type="password"
                    autoComplete="new-password"
                    className="block w-full h-10 appearance-none rounded-md border border-indigo-100 px-4 py-2.5 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 font-b1"
                    {...register("password", {
                      required: true,
                    })}
                  />
                  {errors.password && (
                    <div className="mt-2 text-xs text-red-500 leading-4">
                      {errors.password.type === "validate"
                        ? errors.password.message
                        : "Password is required."}
                    </div>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="confirm-password"
                  className="block font-b2-medium text-neutral-900"
                >
                  Confirm Password
                </label>
                <div className="mt-2">
                  <input
                    id="confirm-password"
                    type="password"
                    autoComplete="new-password"
                    className="block w-full h-10 appearance-none rounded-md border border-indigo-100 px-4 py-2.5 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 font-b1"
                    {...register("confirmPassword", {
                      required: true,
                    })}
                  />
                  {errors.confirmPassword && (
                    <span className="mt-2 text-xs text-red-500">
                      {errors.confirmPassword.type === "validate"
                        ? errors.confirmPassword.message
                        : "Password is required."}
                    </span>
                  )}
                </div>
              </div>

              <div className="mt-8">
                <Button
                  disabled={resetPasswordMutation.isPending}
                  type="submit"
                  variant="primary"
                  className="w-full justify-center rounded-md"
                >
                  {resetPasswordMutation.isPending
                    ? "Submitting..."
                    : "Set Password"}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </LoginWrapper>
  );
};

export default SetPassword;
