import axios from "@axios";
import { DATE_FORMAT, POLICY_PAGE_LIMIT } from "@config";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { FinBoxResponse, PolicyStatus } from "@types";
import { getGQEndTime, getGQStartTime } from "@utils/utils";

export type PolicyItem = {
  policyId: string;
  policyVersion: string;
  policyTypeVersion: string;
  program: string;
  status: PolicyStatus;
  createdAt: string;
  createdBy: string;
};

export const usePolicyList = ({
  startDate,
  endDate,
  page,
  searchQuery,
  enabled,
  policyStatus = ["active", "inreview", "draft"],
}: {
  startDate?: string | null;
  endDate?: string | null;
  page: number;
  searchQuery?: string;
  enabled: boolean;
  policyStatus?: string[];
}) => {
  return useQuery({
    queryKey: [
      "policyList",
      startDate,
      endDate,
      page,
      searchQuery,
      policyStatus,
    ],
    placeholderData: keepPreviousData,
    enabled: enabled,
    queryFn: async () =>
      axios.get<FinBoxResponse<{ policies: PolicyItem[]; totalCount: number }>>(
        "policy",
        {
          params: {
            policyVersion: searchQuery,
            pageSize: POLICY_PAGE_LIMIT,
            pageNumber: page,
            startDate: getGQStartTime(startDate || null, DATE_FORMAT),
            endDate: getGQEndTime(endDate || null, DATE_FORMAT),
            status: policyStatus,
          },
        }
      ),
  });
};

export default usePolicyList;

export const useSimulationPolicyList = ({
  startDate,
  endDate,
  page,
  searchQuery,
  enabled,
  policyStatus = ["active", "inreview", "draft"],
}: {
  startDate?: string | null;
  endDate?: string | null;
  page: number;
  searchQuery?: string;
  enabled: boolean;
  policyStatus?: string[];
}) => {
  return useQuery({
    queryKey: [
      "policyList",
      startDate,
      endDate,
      page,
      searchQuery,
      policyStatus,
    ],
    placeholderData: keepPreviousData,
    enabled: enabled,
    queryFn: async () =>
      axios.get<FinBoxResponse<{ policies: PolicyItem[]; totalCount: number }>>(
        "simulation/policy",
        {
          params: {
            policyVersion: searchQuery,
            pageSize: POLICY_PAGE_LIMIT,
            pageNumber: page,
            startDate: getGQStartTime(startDate || null, DATE_FORMAT),
            endDate: getGQEndTime(endDate || null, DATE_FORMAT),
            status: policyStatus,
          },
        }
      ),
  });
};
