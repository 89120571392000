import Loader from "@components/Loader";
import { NODE_IMPORT_INPUT_ID } from "@screens/workflow/config";
import { useQuery } from "@tanstack/react-query";
import { debounce, getNetworkErrorText, notify } from "@utils/utils";
import { AxiosError } from "axios";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { Node, useReactFlow } from "reactflow";
import { PERMISSIONS_TYPE } from "src/constants/permissionsConstant";
import usePermissions from "src/context/PermissionContext";
import { PermissionTypes } from "src/types";
import {
  getErrors,
  useGetWorkflow,
  useRunWorkflowTest,
  useUpdateWorkflow,
  useUpdateWorkflowMeta,
} from "./queries";
import { ErrorResponse, Workflow, WorkflowEventType } from "./types";

type WorkflowContextType = {
  workflow: Workflow | undefined;
  isWorkflowEditable: boolean;
  refetchWf: () => Promise<void>;
  isFetchingWf: boolean;
  saveMeta: () => void;
  updateWorkflow: (event: WorkflowEventType) => Promise<string>;
  showDeleteConfirmation: false | { id: string; label: string };
  setShowDeleteConfirmation: setStateType<
    false | { id: string; label: string }
  >;
  newNode: Node | undefined;
  setNewNode: setStateType<Node | undefined>;
  runWorkflowMutation: ReturnType<typeof useRunWorkflowTest>;
  isShowingTestResult: boolean;
  setIsShowingTestResult: setStateType<boolean>;
  debuggerNodeId: string | null;
  setDebuggerNodeId: setStateType<string | null>;
  importNode: (nodeId: string) => void;
  errors: ErrorResponse;
  accessError: Error | null;
};

const WorkflowContext = createContext<WorkflowContextType>({
  workflow: undefined,
  isWorkflowEditable: false,
  refetchWf: () => {},
  isFetchingWf: false,
  saveMeta: () => {},
  updateWorkflow: () => {},
  showDeleteConfirmation: false,
  setShowDeleteConfirmation: () => {},
  newNode: undefined,
  setNewNode: () => {},
  importNode: () => {},
  errors: {},
  accessError: {},
} as unknown as WorkflowContextType);

const checkMetadataEquals = (
  a?: Record<string, { x: number; y: number }>,
  b?: Record<string, { x: number; y: number }>
) => {
  if (!a || !b) {
    return false;
  }
  const aKeys = new Set(Object.keys(a));
  const bKeys = new Set(Object.keys(b));

  if (aKeys.size !== bKeys.size) {
    return false;
  }

  for (let key of Array.from(aKeys)) {
    if (bKeys.has(key) && a[key].x === b[key].x && a[key].y === b[key].y) {
      bKeys.delete(key);
      aKeys.delete(key);
    } else {
      return false;
    }
  }

  return bKeys.size === 0;
};

const WorkflowProvider = ({ children }: PropsWithChildren) => {
  const updateWorkflowMeta = useUpdateWorkflowMeta();
  const [isShowingTestResult, setIsShowingTestResult] = useState(false);

  const { getNodes } = useReactFlow();
  const { workflowId } = useParams();
  const {
    data: workflow,
    isPending,
    isFetching,
    error: accessError,
    refetch,
  } = useGetWorkflow(workflowId);
  const [debuggerNodeId, setDebuggerNodeId] = useState<string | null>(null);

  const runWorkflowMutation = useRunWorkflowTest(
    () => {},
    () => setIsShowingTestResult(true)
  );

  const errorQuery = useQuery(getErrors(workflowId));
  const { getPermissions } = usePermissions();
  const permissions = getPermissions(
    PERMISSIONS_TYPE.policy as PermissionTypes,
    "edit"
  );
  const isWorkflowEditable =
    !!workflow && workflow.status === "draft" && !!permissions;

  const updateWorkflowMutation = useUpdateWorkflow(workflowId);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<
    false | { id: string; label: string }
  >(false);
  const [newNode, setNewNode] = useState<Node | undefined>();

  const saveMeta = useCallback(
    debounce(async () => {
      const nodes = getNodes();
      if (!nodes.length) {
        return;
      }
      let nodesMeta: Record<string, any> = {};
      nodes.forEach((n) => {
        nodesMeta[n.id] = { x: n.position.x, y: n.position.y };
      });

      // If metadata is same, don't call the api
      if (checkMetadataEquals(nodesMeta, workflow?.metadata)) return;

      try {
        await updateWorkflowMeta.mutateAsync({
          workflowID: workflowId!,
          metadata: nodesMeta,
        });
      } catch (error) {
        notify({
          text: getNetworkErrorText(error),
          title: "Error",
          type: "error",
        });
      }
    }),
    [getNodes, updateWorkflowMeta, workflowId, workflow?.metadata]
  );

  const refetchWf = useCallback(async () => {
    await saveMeta();
    await refetch();
  }, [refetch, saveMeta]);

  const updateWorkflow: WorkflowContextType["updateWorkflow"] = useCallback(
    async (event: WorkflowEventType) => {
      let refID = "";
      if (!workflow?.id) {
        return refID;
      }

      let b = {
        workflowID: workflow?.id,
        ...event,
      };
      try {
        let resp = await updateWorkflowMutation.mutateAsync(b);
        refID = resp.data?.data;
      } catch (error) {
        if (error instanceof AxiosError) {
          notify({
            text: getNetworkErrorText(error),
            title: "Error",
            type: "error",
          });
        }

        return "error";
      }
      return refID || "";
    },
    [updateWorkflowMutation, workflow?.id]
  );

  const importNode = useCallback(() => {
    document.getElementById(NODE_IMPORT_INPUT_ID)?.click();
  }, []);

  const errors = useMemo(() => errorQuery.data?.data.data ?? {}, [errorQuery]);

  const value = useMemo(() => {
    return {
      isWorkflowEditable,
      isFetchingWf: isFetching,
      workflow,
      refetchWf,
      saveMeta,
      updateWorkflow,
      showDeleteConfirmation,
      setShowDeleteConfirmation,
      newNode,
      setNewNode,
      runWorkflowMutation,
      isShowingTestResult,
      setIsShowingTestResult,
      debuggerNodeId,
      setDebuggerNodeId,
      importNode,
      errors,
      accessError,
    };
  }, [
    debuggerNodeId,
    errors,
    importNode,
    isFetching,
    isShowingTestResult,
    isWorkflowEditable,
    newNode,
    refetchWf,
    runWorkflowMutation,
    saveMeta,
    showDeleteConfirmation,
    updateWorkflow,
    workflow,
  ]);

  if (isPending) {
    return (
      <div className="fixed left-0 top-0 flex h-full w-full items-center justify-center backdrop-blur-sm">
        <Loader size="small" type="block" />
      </div>
    );
  }

  return (
    <WorkflowContext.Provider value={value}>
      {children}
    </WorkflowContext.Provider>
  );
};

export const useWorkflowContext = () => {
  return useContext(WorkflowContext);
};

export default WorkflowProvider;
