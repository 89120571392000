import classNames from "clsx";
import React from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import Dropdown from "@components/DropDown";
import ErrorText from "@components/ErrorText";
import Loader from "@components/Loader";
import { titleCase } from "@utils/utils";
import { useGetOutcomeList } from "../../../queries";
import { FormType } from "../types";

export default function OutcomeSelect({
  form,
}: {
  form: UseFormReturn<FormType>;
}) {
  const [funnelType, funnelValues, groupByKey, groupByValues] = useWatch({
    control: form.control,
    name: ["funnelType", "funnelValues", "groupByKey", "groupByValues"],
  });

  const outcomesListQuery = useGetOutcomeList({
    funnelType,
    funnelValues: funnelValues?.map((p) => p.id) ?? [],
    groupByKey,
    groupByValues,
  });

  const disabled = !Boolean(funnelValues && funnelValues.length);

  return (
    <div
      className={classNames("flex flex-col gap-2", disabled && "opacity-50")}
    >
      <span className="font-b2">Choose Outcome</span>
      <Controller
        control={form.control}
        name="config.outcome"
        rules={{
          required: { value: true, message: "Outcome is required" },
        }}
        render={({ field }) => {
          return (
            <Dropdown
              value={field.value}
              className={disabled && "cursor-not-allowed"}
              onChange={field.onChange}
              disabled={disabled}
            >
              <Dropdown.Button
                className={classNames(
                  "bg-white w-full",
                  disabled && "pointer-events-none",
                  !!field.value && "text-neutral-black font-b2-medium"
                )}
              >
                {titleCase(field.value ?? "") || "Select"}
              </Dropdown.Button>
              {!disabled && (
                <Dropdown.Options className="max-h-40 h-auto">
                  {outcomesListQuery.isLoading ? (
                    <Loader size="xs" />
                  ) : !!outcomesListQuery.data?.data.data.length ? (
                    outcomesListQuery.isSuccess &&
                    outcomesListQuery.data.data.data.map((r) => (
                      <Dropdown.Option key={r} value={r}>
                        {titleCase(r)}
                      </Dropdown.Option>
                    ))
                  ) : (
                    <Dropdown.Option disabled key="empty" value="empty">
                      No options
                    </Dropdown.Option>
                  )}
                </Dropdown.Options>
              )}
            </Dropdown>
          );
        }}
      />
      {!!form.formState.errors.config?.outcome?.message && (
        <ErrorText>{form.formState.errors.config?.outcome?.message}</ErrorText>
      )}
    </div>
  );
}
