import classNames from "clsx";
import React from "react";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { ReactComponent as ChartIcon } from "@assets/icons/bar-chart-square-02.svg";
import { ReactComponent as MetricsIcon } from "@assets/icons/switch-vertical-01.svg";
import Dropdown from "@components/DropDown";
import Input from "@components/Input";
import DataLevelSelect from "./components/DataLevelSelect";
import FunnelValuesSelect from "./components/FunnelValuesSelect";
import GroupBySelect from "./components/GroupBySelect";
import OutputSelect from "./components/OutputSelect";
import { FormType } from "./types";

export default function VariableDistributionForm({
  form,
}: {
  form: UseFormReturn<FormType>;
}) {
  const { register, control } = form;

  const [output] = useWatch({
    control: form.control,
    name: ["output"],
  });

  return (
    <form className="flex gap-4 flex-col">
      <div>
        <span className="font-b2 font-normal">Name</span>
        <Input {...form.register("name")} />
      </div>
      <input
        value="variableDistribution"
        {...register("type")}
        className="hidden"
      />
      <div className="hidden flex-col gap-2 font-b2">
        <span>Choose Representation</span>
        <Controller
          control={control}
          name="representation"
          defaultValue="Metric"
          render={({ field }) => (
            <div className="flex w-fit cursor-pointer h-7 rounded-md bg-white font-b2-medium text-neutral-500 overflow-clip border border-neutral-100 divide-x divide-indigo-100">
              <span
                className={classNames(
                  "gap-2 flex px-3 items-center",
                  field.value === "Chart" && "bg-neutral-50"
                )}
                onClick={() => field.onChange("Chart")}
              >
                <ChartIcon className="[&>path]:stroke-neutral-500 hover:[&>path]:stroke-neutral-black" />
                Chart
              </span>
              <span
                className={classNames(
                  "gap-2 flex px-3 items-center",
                  field.value === "Metric" && "bg-neutral-50"
                )}
                onClick={() => field.onChange("Metric")}
              >
                <MetricsIcon className="[&>path]:stroke-neutral-500 hover:[&>path]:stroke-neutral-black" />
                Metric
              </span>
            </div>
          )}
        />
      </div>
      <div className="flex flex-col gap-2 font-b1-medium">
        <DataLevelSelect form={form} />
        <FunnelValuesSelect form={form} />
      </div>
      <OutputSelect form={form} />
      {form.getValues("funnelType")?.toLowerCase() === "policy" && (
        <GroupBySelect form={form} />
      )}
      <div className="flex flex-col gap-2">
        <span className="font-b2">Choose Operator</span>
        <Controller
          defaultValue="COUNT"
          control={form.control}
          name="operator"
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <Dropdown
                value={field.value}
                onChange={field.onChange}
                disabled={!Boolean(output)}
              >
                <Dropdown.Button className="bg-white w-full">
                  {{ SUM: "Sum", AVG: "Average", COUNT: "Count" }[
                    field.value
                  ] || "Select"}
                </Dropdown.Button>
                <Dropdown.Options className="h-auto">
                  <Dropdown.Option value="SUM">Sum</Dropdown.Option>
                  <Dropdown.Option value="AVG">Average</Dropdown.Option>
                  <Dropdown.Option value="COUNT">Count</Dropdown.Option>
                </Dropdown.Options>
              </Dropdown>
            );
          }}
        />
      </div>
    </form>
  );
}
