import classNames from "clsx";
import { ReactNode, useRef, useState } from "react";
import { FixedSizeList } from "react-window";
import chevronDown from "@assets/icons/chevron-down.svg";
import crossIcon from "@assets/icons/x-close-blue.svg";
import useOnClickOutside from "@hooks/useOnClickOutside";
import { FloatProvider, useFloat } from "@context/FloatContext";
import { FloatingPortal } from "@floating-ui/react";
import { Combobox } from "@headlessui/react";

type Props<T extends Record<string, string | number>> = {
  options: T[];
  values: T[];
  disabled?: boolean;
  onSearchChange: (e: string) => void;
  getKey: (e: T) => string | number;
  onChange: (e: T[]) => void;
  getName: (e: T) => ReactNode;
  getDisplayValue: (e: T[]) => string;
  isSelected: (e: T) => boolean;
  className?: string;
  panelWidth?: string;
  closeOnSelect?: boolean;
  error?: string;
  positionBottomStart?: boolean;
};

function Multiselect<T extends Record<string, string | number>>({
  values = [],
  onChange,
  disabled,
  options,
  getKey,
  onSearchChange,
  getDisplayValue,
  getName,
  isSelected,
  className,
  panelWidth,
  closeOnSelect,
  error,
  positionBottomStart,
}: Props<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { x, y, strategy, refs } = useFloat();

  useOnClickOutside(
    ref,
    () => {
      onSearchChange("");
      setIsOpen(false);
    },
    (e) => {
      if (
        !(e.target instanceof HTMLElement) ||
        !e.target.dataset.dropdownOption
      )
        return false;
      return e.target.dataset.dropdownOption === "yes";
    },
    "click"
  );

  const handleClearAll = () => {
    onChange([]);
    onSearchChange("");
  };

  return (
    <Combobox
      value={values}
      onChange={(ee) => {
        onChange(ee);
        if (closeOnSelect) setIsOpen(false);
      }}
      multiple
      // disabled={disabled}
    >
      <div
        ref={ref}
        className={classNames(
          "relative w-full"
          // disabled && "cursor-not-allowed opacity-50"
        )}
      >
        <Combobox.Input
          autoFocus={false}
          placeholder="Select"
          autoComplete="off"
          ref={refs.setReference}
          onChange={(event) => onSearchChange(event.target.value)}
          className={classNames(
            "truncate cursor-pointer w-full h-7 rounded-md border focus:border-primary-300 focus:ring-0 border-neutral-100 font-b2-medium bg-white py-1.5 pl-3 pr-6 text-neutral-black placeholder:text-neutral-500",
            error && "!border-error-500",
            className
          )}
          displayValue={getDisplayValue}
          onClick={() => setIsOpen(!isOpen)}
        />
        {values.length > 0 && (
          <img
            src={crossIcon}
            className="absolute right-2 top-1/2 -translate-y-1/2 w-3 h-3 cursor-pointer"
            aria-hidden="true"
            alt="Clear"
            onClick={handleClearAll}
          />
        )}
        {values.length === 0 && (
          <img
            src={chevronDown}
            className={classNames(
              "absolute right-2 top-1/2 -translate-y-1/2 w-3 h-3 pointer-events-none",
              isOpen && "rotate-180"
            )}
            aria-hidden="true"
            alt=""
          />
        )}
        {isOpen && (
          <FloatingPortal>
            <Combobox.Options
              static
              style={{
                width: !panelWidth
                  ? refs.reference.current?.getBoundingClientRect().width ??
                    "max-content"
                  : panelWidth,
                position: strategy,
                top: y ?? 0,
                left: positionBottomStart && x ? x + 145 : x ?? 0,
              }}
              ref={refs.setFloating}
              className="absolute z-[1000] -translate-y-1 max-h-60 w-full h-auto overflow-auto rounded-md bg-white py-1 font-b2 border border-neutral-100"
            >
              <FixedSizeList
                itemSize={32}
                height={
                  options.length !== 0 ? (options.length <= 5 ? 90 : 240) : 0
                }
                itemCount={options.length ?? 0}
                width="100%"
                overscanCount={5}
                itemData={options ?? []}
              >
                {(props) => {
                  return (
                    <Combobox.Option
                      data-dropdown-option="yes"
                      className="relative w-full flex cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-neutral-25 bg-white"
                      key={getKey(props.data[props.index])}
                      value={props.data[props.index]}
                      style={props.style}
                      disabled={disabled}
                    >
                      <input
                        data-dropdown-option="yes"
                        type="checkbox"
                        className="mr-2 rounded-sm h-4 w-4"
                        defaultChecked={isSelected(props.data[props.index])}
                      />
                      <span
                        data-dropdown-option="yes"
                        className="w-[90%] truncate"
                      >
                        {getName(props.data[props.index])}
                      </span>
                    </Combobox.Option>
                  );
                }}
              </FixedSizeList>
              {options.length === 0 && (
                <Combobox.Option
                  data-dropdown-option="yes"
                  className="relative cursor-not-allowed opacity-50 select-none py-2 pl-3 pr-9 hover:bg-neutral-25 bg-white"
                  key="empty"
                  value="empty"
                  disabled
                >
                  No Options
                </Combobox.Option>
              )}
            </Combobox.Options>
          </FloatingPortal>
        )}
      </div>
    </Combobox>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function <T extends Record<string, string | number>>(
  props: Props<T>
) {
  return (
    <FloatProvider placement="bottom">
      <Multiselect {...props} />
    </FloatProvider>
  );
}

export function multiselectOnChangeHandler<T>(e: T[], idKey: keyof T) {
  // Required when the options are objects, because of object comparision shenanigans.
  // The last value in `e` is always the last clicked option.
  // We check if it is already present, if yes remove it, if not, add it.

  if (e.length <= 1) return e;
  const prevValues = structuredClone(e);
  const newValue = prevValues.pop()!;
  if (!!prevValues.find((w) => w[idKey] === newValue[idKey]))
    return prevValues.filter((p) => p[idKey] !== newValue[idKey]);
  return e;
}
