import classNames from "clsx";
import { Controller, UseFormReturn } from "react-hook-form";
import ErrorText from "@components/ErrorText";
import { FUNNEL_TYPE_NAMES_MAPPER } from "../../../config";
import { FormType } from "../types";

export default function DataLevelSelect({
  form,
  showPolicyBucket,
}: {
  form: UseFormReturn<FormType>;
  showPolicyBucket?: boolean;
}) {
  return (
    <>
      <span className="font-b2 font-normal">Select Data Level</span>
      <Controller
        control={form.control}
        name="funnelType"
        rules={{
          required: { value: true, message: "Data level is required" },
        }}
        render={({ field }) => (
          <div className="flex gap-2">
            {[
              "program",
              "journey",
              "endpoint",
              ...(showPolicyBucket ? ["policybucket"] : []),
              "policy",
            ].map((p) => (
              <span
                key={p}
                className={classNames(
                  "cursor-pointer border border-neutral-100 px-2 py-[1px] rounded-full text-neutral-500 font-b2",
                  field.value === p &&
                    "text-primary-500 bg-primary-50 border-primary-500"
                )}
                onClick={() => {
                  if (p === field.value) return;
                  form.resetField("funnelValues", { defaultValue: [] });
                  form.resetField("groupByKey", { defaultValue: "" });
                  form.resetField("groupByValues", { defaultValue: [] });
                  form.resetField("output", { defaultValue: "" });
                  form.resetField("config.outcome", { defaultValue: "" });
                  field.onChange(p);
                }}
              >
                {
                  FUNNEL_TYPE_NAMES_MAPPER[
                    p as keyof typeof FUNNEL_TYPE_NAMES_MAPPER
                  ]
                }
              </span>
            ))}
          </div>
        )}
      />
      <ErrorText>{form.formState.errors.funnelType?.message}</ErrorText>
    </>
  );
}
