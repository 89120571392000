import { clsx } from "clsx";
import { CSSProperties, ReactNode } from "react";
import { Handle, HandleType, Position } from "reactflow";

interface HandleWithPlusProps {
  label: ReactNode;
  showAdd: boolean;
  position: Position;
  isConnectable?: boolean;
  handleStyles?: CSSProperties;
  id: string;
  nodeId: string;
  type: HandleType;
  noIcon?: boolean;
  className?: string;
}

const HandleWithPlus = ({
  label,
  showAdd,
  position,
  isConnectable = true,
  handleStyles,
  className,
  id,
  type,
  noIcon,
}: HandleWithPlusProps) => {
  return (
    <div
      className={clsx(
        "flex w-max text-neutral-500 pl-0.5 py-1.5 font-b2 relative mt-2 items-center",
        className
      )}
    >
      <div>{label}</div>
      {/* {showAdd ? (
        <Handle
          type={type}
          position={position}
          id={id}
          className={
            !noIcon
              ? "h-5 w-5 !static opacity-0 translate-y-0 translate-x-2 focus-within:z-10 rounded-none border-none bg-transparent flex items-center justify-center hover:scale-110 group-hover:opacity-100 group-hover:scale-110 duration-150"
              : "h-6 w-6 !opacity-0"
          }
          isConnectable={isConnectable}
          style={{
            backgroundImage: `url(${PlusCircleIcon})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
        />
      ) : ( */}
      <Handle
        type={type}
        position={position}
        id={id}
        className={
          !noIcon
            ? "w-2 h-2 !static border-2 group-hover:ring-0 ring-[6px] ring-[#f6f6f6] translate-y-0 translate-x-2 my-1.5 pointer-events-auto rounded-full bg-neutral-0 hover:bg-success-100 group-hover:bg-success-100 border-neutral-300 group-hover:border hover:scale-[2] group-hover:opacity-100 group-hover:scale-[2] hover:border-success-500 group-hover:border-success-500 duration-150"
            : "w-6 h-6 !opacity-0"
        }
        isConnectable={isConnectable}
        style={handleStyles}
      />
      {/* )} */}
    </div>
  );
};

export default HandleWithPlus;
