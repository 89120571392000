export const PERMISSIONS_CONSTANT = {
  draftoinreview: "draft to inreview",
  toreadytodeploy: "inreview to ready_to_deploy",
  downloadCamReport: "download cam report",
  downloadPredictors: "download predictors report",
  policyBucket: "policy Bucket",
};

export const PERMISSIONS_TYPE = {
  apiStore: "Data Sources - API Store",
  monitorLogs: "Monitors - Alert Logs",
  analytics: "Analytics Dashboards",
  dataSourcesCustom: "Data Sources - Custom API Sources",
  monitorsCustom: "Monitors - Custom API Monitors",
  endpoints: "Endpoints",
  monitorsEndpoint: "Monitors - Endpoint Monitors",
  dataSourcesLookup: "Data Sources - Lookup Table",
  administrationMembers: "Administration - Members",
  reportsEvaluation: "Reports - Evaluation Details",
  reportsOutcome: "Reports - Outcomes Overview",
  overview: "Home",
  policy: "Policies - List View",
  settingsProgram: "Settings - Programs",
  reportsAll: "Reports - All Reports",
  administrationRoles: "Administration - Roles",
  simulations: "Policy - Simulations",
};
