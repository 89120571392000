import { useParams } from "react-router-dom";
import { Node } from "reactflow";
import { ReactComponent as ExpressionIcon } from "@assets/icons/workflow/calculator.svg";
import { useTestModelSet } from "@screens/workflow/studio/components/Model/queries";
import { getModelSetItemsQuery } from "@screens/workflow/studio/components/ModelSet/queries";
import { useQuery } from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import RightPanel from "./RightPanel";

type ModelNodeProps = {
  data: Node<{
    label: string;
  }>;
};

const ModelNode = ({ data }: ModelNodeProps) => {
  const testModel = useTestModelSet();
  const { workflowId } = useParams();

  const modelSetItems = useQuery(
    getModelSetItemsQuery(workflowId ?? "", data.id)
  );
  const items = modelSetItems.data ?? [];

  const test = (
    variables: Record<string, Record<string, Record<string, string | null>>>
  ) => {
    testModel.mutate({
      variables,
      modelSetId: data.id,
      workflowId: workflowId ?? "",
    });
  };

  return (
    <RightPanel
      nodeId={data.id}
      label={data?.data.label}
      expressions={items.map((item) => {
        if (item.type === "expression") return item.body;
        else return item.decisionTableRules.headers.join(" + ");
      })}
      onTest={test}
      ignoreNamesList={items.map((i) => i.name)}
      testResult={
        testModel.isSuccess ? (
          <div className="text-neutral-500 flex flex-col gap-4 font-b2">
            <div className="flex text-neutral-black font-b2-medium">
              <ExpressionIcon className="w-4 h-4 mr-2" />
              {data?.data.label || "Model_Name"}
            </div>
            {Object.keys(testModel.data?.data.data ?? {}).map((key) => {
              return (
                <div key={key} className="font-b2-medium flex justify-between">
                  {key}:{" "}
                  <span>{JSON.stringify(testModel.data?.data.data[key])}</span>
                </div>
              );
            })}
          </div>
        ) : testModel.isError ? (
          <div className="text-error-500 font-code flex flex-col gap-2 font-b2 py-3">
            <span>
              {(
                testModel.error as unknown as {
                  response: { data: FinBoxResponse };
                }
              ).response.data.error
                .split("\n")
                .map((row) => (
                  <>
                    {row}
                    <br />
                  </>
                ))}
            </span>
          </div>
        ) : (
          <></>
        )
      }
    />
  );
};
export default ModelNode;
