import clsx from "clsx";
import { KeyboardEvent } from "react";
import {
  Control,
  Controller,
  RegisterOptions
} from "react-hook-form";
import { FormType } from "./types";

export function MatrixItem({
  control,
  data,
  path,
  setIsEditing,
  isEditing,
  keyPressHandler,
  rules,
}: {
  control: Control<FormType>;
  data: string;
  path: `rows.${number}.output` | `rows.${number}.column.${number}`;
  setIsEditing: () => void;
  isEditing: boolean;
  keyPressHandler: (key: KeyboardEvent<HTMLInputElement>) => void;
  rules?:
    | Omit<
        RegisterOptions<
          FormType,
          `rows.${number}.output` | `rows.${number}.column.${number}`
        >,
        "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
      >
    | undefined;
}) {
  return (
    <Controller
      control={control}
      name={path}
      defaultValue={data}
      rules={rules}
      render={({ field, fieldState }) => {
        return !isEditing ? (
          <div
            tabIndex={0}
            className={clsx(
              fieldState.error?.message && "bg-error-100",
              "first:rounded-tl-md py-1 pr-1 pl-4 font-normal border-r border-b border-neutral-100 h-10 flex items-center w-full"
            )}
            onClick={() => setIsEditing()}
            onFocus={() => setIsEditing()}
          >
            <span className="group truncate max-w-full">
              {field.value && (
                <span className="group-hover:block hidden absolute top-full left-0 p-1 rounded-md bg-white shadow">
                  {field.value}
                </span>
              )}
              {field.value ? field.value : <>&nbsp;</>}
            </span>
          </div>
        ) : (
          <input
            tabIndex={0}
            className={clsx(
              "py-1 px-1 bg-primary-25 font-medium last:border-r-0 border-l-0 border-t-0 border-r border-b border-neutral-100 focus:border-neutral-100 focus:border-r w-full h-10 focus:ring-0",
              fieldState.error?.message && "!border-error-500"
            )}
            autoFocus
            value={field.value}
            onKeyUp={(e) => {
              keyPressHandler(e);
            }}
            onChange={(e) => {
              field.onChange(e.target.value);
            }}
          />
        );
      }}
    />
  );
}
