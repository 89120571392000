import classNames, { clsx } from "clsx";
import { createContext, Fragment, ReactNode, useContext } from "react";
import { FloatProvider, useFloat } from "src/context/FloatContext";
import chevronDownIcon from "@assets/icons/chevron-down.svg";
import { FloatingPortal } from "@floating-ui/react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";

const DisabledContext = createContext(false);

const DropDownRoot = ({
  className,
  disabled,
  ...props
}: Parameters<typeof Listbox>[0]) => {
  return (
    <FloatProvider placement="bottom">
      <DisabledContext.Provider value={!!disabled}>
        <Listbox
          as="div"
          className={classNames(
            "relative",
            disabled && "cursor-not-allowed opacity-50",
            className
          )}
          disabled={disabled}
          {...props}
        />
      </DisabledContext.Provider>
    </FloatProvider>
  );
};

const Options = ({
  className,
  fullWidth = true,
  ...props
}: Parameters<typeof Listbox.Options>[0] & {
  className?: string;
  fullWidth?: boolean;
}) => {
  const { x, y, strategy, refs } = useFloat();

  return (
    <FloatingPortal>
      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Listbox.Options
          ref={refs.setFloating}
          style={{
            width: fullWidth
              ? refs.reference.current?.getBoundingClientRect().width ??
                "max-content"
              : "max-content",
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          className={classNames(
            "box-border z-[999] rounded-md bg-white border border-neutral-100 overflow-auto w-max max-h-52 shadow-1 -translate-y-1.5",
            fullWidth ? "right-0" : "",
            className
          )}
          {...props}
        />
      </Transition>
    </FloatingPortal>
  );
};

type OptionProps = Parameters<typeof Listbox.Option>[0] & {
  showTick?: boolean;
  className?: string;
};

const Option = ({ children, showTick, className, ...props }: OptionProps) => {
  return (
    <Listbox.Option className="cursor-pointer" {...props}>
      {typeof children === "function"
        ? children
        : ({ selected, disabled }) => (
            <div
              data-dropdown-option="yes"
              className={classNames(
                "px-4 h-8 font-b2 hover:bg-neutral-50 flex items-center",
                selected && "bg-primary-50 hover:bg-neutral-50",
                {
                  "text-neutral-500 cursor-not-allowed": disabled,
                  "text-neutral-black": !disabled,
                },
                className
              )}
            >
              {children}
              {showTick && selected && (
                <CheckIcon className="ml-auto h-4 w-4 text-primary-900" />
              )}
            </div>
          )}
    </Listbox.Option>
  );
};

type ButtonProps = Parameters<typeof Listbox.Button>[0] & {
  children: ReactNode;
  error?: string;
  disabled?: boolean;
};

const Button = ({ className, children, error, ...props }: ButtonProps) => {
  const { refs } = useFloat();
  const disabled = useContext(DisabledContext);
  return (
    <Listbox.Button
      ref={refs.setReference}
      className={classNames(
        "relative flex items-center !px-2.5 justify-between gap-2 rounded-md h-7 text-xs text-neutral-500",
        error ? "border border-error-500" : "border border-neutral-100",
        disabled ? "cursor-not-allowed" : "cursor-pointer",
        className
      )}
      {...props}
    >
      {({ open }) => (
        <>
          {children}
          <img
            src={chevronDownIcon}
            alt=""
            className={clsx("w-3 h-3", open && "rotate-180")}
          />
          {error ? (
            <small className="absolute -bottom-5 left-0 text-error-500 text-xs">
              {error}
            </small>
          ) : (
            ""
          )}
        </>
      )}
    </Listbox.Button>
  );
};

let Dropdown = Object.assign(DropDownRoot, {
  ...Listbox,
  Options,
  Option,
  Button,
});

export default Dropdown;
