import { createContext, PropsWithChildren, useContext, useState } from "react";

const __tabTypeSymbol = Symbol();

type TabOptions<T> = (T & { [__tabTypeSymbol]: T[][number] })[];

export const tabOptions = <T extends string>(options: readonly T[]) => {
  return options as TabOptions<T>;
};

type ContextType = {
  selectedTab: TabOptions<string>[number];
  setSelectedTab: setStateType<TabOptions<string>[number]>;
};

const TabContext = createContext<ContextType | null>(null);

const useTabContext = () => {
  const context = useContext(TabContext);
  if (!context)
    throw Error(
      "TabRoot.Panel and TabRoot.Trigger can only be used inside a TabRoot"
    );
  return context;
};

type Props = {
  defaultTab: TabOptions<string>[number];
};

function TabRoot({ defaultTab, children }: PropsWithChildren<Props>) {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  return (
    <TabContext.Provider value={{ selectedTab, setSelectedTab }}>
      {children}
    </TabContext.Provider>
  );
}

TabRoot.Trigger = function TabTrigger({
  children,
  tabName,
}: {
  tabName: TabOptions<string>[number];
  children: (props: {
    onClick: () => void;
    isSelected: boolean;
  }) => JSX.Element;
}) {
  const { selectedTab, setSelectedTab } = useTabContext();
  const isSelected = selectedTab === tabName;
  const onClick = () => setSelectedTab(tabName);
  return children({ onClick, isSelected });
};

TabRoot.Panel = function TabPanel({
  tabName,
  children,
}: PropsWithChildren<{ tabName: TabOptions<string>[number] }>) {
  const { selectedTab } = useTabContext();
  if (tabName !== selectedTab) return null;
  return children as JSX.Element;
};

export default TabRoot;
