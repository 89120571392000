import classNames from "clsx";
import { PropsWithChildren, ReactNode, useContext } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SidebarContext from "./SidebarContext";

export function BaseTab({
  title,
  className,
  children,
  sideNode,
}: PropsWithChildren<{
  title: string | ReactNode;
  className?: string;
  sideNode?: ReactNode;
}>) {
  const sideBarContext = useContext(SidebarContext);
  return (
    <div className="h-full">
      <div
        className={classNames(
          "w-64 bg-white border-r border-neutral-100 h-full",
          className
        )}
      >
        <div className="font-b1-medium border-b border-neutral-100 px-3 py-1.5 flex justify-between items-center">
          {title}
          <XMarkIcon
            className="h-4 w-4 cursor-pointer"
            onClick={() => sideBarContext?.setCurrentTab?.(null)}
          />
        </div>
        {children}
      </div>
      {sideNode && <div className="flex-1">{sideNode}</div>}
    </div>
  );
}

function Content({ children }: PropsWithChildren) {
  return <div className="h-[calc(100%-2.5rem)]">{children}</div>;
}

BaseTab.Content = Content;
