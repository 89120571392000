import { clsx } from "clsx";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { usePolicyWorkflowList } from "src/hooks/usePolicyWokflowList";
import { useWorkflowContext } from "@screens/workflow/WorkflowContext";
import { getWorkflowOutcomeConfig } from "@screens/workflow/queries";
import Dropdown from "@components/DropDown";
import Multiselect, {
  multiselectOnChangeHandler,
} from "@components/Multiselect";
import QueryWrapper from "@components/QueryWrapper";
import { useQuery } from "@tanstack/react-query";
import useKeywordsFromWorkflowKeywords from "../../hooks/useKeywordsFromWorkflowKeywords";
import { useUpdateOutcomeNode } from "./queries";
import { OutcomeNodeConfig } from "./types";

type Props = {
  config: OutcomeNodeConfig;
  nodeId: string;
};

export default function CustomScript({ config, nodeId }: Props) {
  const updateConfig = useUpdateOutcomeNode();
  const { workflowId } = useParams();
  const { isWorkflowEditable } = useWorkflowContext();

  const [searchQuery, setSearchQuery] = useState("");

  const form = useForm<
    | {
        type: "last_workflow_run";
      }
    | {
        type: "choose_workflow";
        outcomeLogic: {
          predictor: string | null;
          function: "max" | "min";
          workflows: Array<{
            name: string;
            id: string;
            type: "policy" | "workflow";
          }>;
        };
      }
  >({
    values:
      config.workflowState.type === "last_workflow_run"
        ? { type: "last_workflow_run" }
        : config.workflowState,
  });

  const workflowOutcomeConfig = useQuery(getWorkflowOutcomeConfig(workflowId!));

  const policyList =
    usePolicyWorkflowList({
      entity: ["workflow", "normal"],
      status: ["active", "readytodeploy", "inactive"],
    }).data ?? [];

  const type = form.watch("type");
  const getKeywordsQuery = useKeywordsFromWorkflowKeywords(workflowId, nodeId);

  const workflowsUsed = [
    ...(Object.keys(getKeywordsQuery.workflows)
      ?.filter((wf) => wf.toLowerCase().includes(searchQuery.toLowerCase()))
      .map((wf) => ({
        name: wf,
        id: policyList.find((s) => s.version === wf)?.id!,
        type: "workflow",
      })) ?? []),
    ...(Object.keys(getKeywordsQuery.policies)
      ?.filter((wf) => wf.toLowerCase().includes(searchQuery.toLowerCase()))
      .map((wf) => ({
        name: wf,
        id: policyList.find((s) => s.version === wf)?.id!,
        type: "policy",
      })) ?? []),
  ];

  if (type === "choose_workflow") {
  }

  const save = form.handleSubmit((data) => {
    const newConfig = structuredClone(config);
    newConfig.from = "workflows";
    newConfig.workflowState = data;
    updateConfig.mutate({
      workflowId: workflowId!,
      nodeId,
      payload: newConfig,
    });
  });

  return (
    <div className="flex flex-col">
      <Controller
        control={form.control}
        name="type"
        render={({ field }) => (
          <Dropdown
            disabled={!isWorkflowEditable}
            onChange={(e) => {
              field.onChange(e);
              save();
            }}
          >
            <Dropdown.Button className="w-full font-b2 font-normal disabled:cursor-not-allowed text-neutral-black disabled:text-indigo-300 [&>img]:w-3 [&>img]:h-3 ">
              {{
                last_workflow_run: "Last workflow/policy run",
                choose_workflow: "Choose workflow/policy",
              }[field.value] || "Select"}
            </Dropdown.Button>
            <Dropdown.Options>
              <Dropdown.Option value="last_workflow_run">
                Last workflow/policy run
              </Dropdown.Option>
              <Dropdown.Option value="choose_workflow">
                Choose workflow/policy
              </Dropdown.Option>
            </Dropdown.Options>
          </Dropdown>
        )}
      />
      {type === "choose_workflow" && (
        <QueryWrapper query={workflowOutcomeConfig}>
          {(data) => {
            return (
              <div className="grid grid-cols-3 gap-x-2 gap-y-1 mt-3">
                <span className="font-b2 font-normal ">Workflows</span>
                <span className="font-b2 font-normal">Variable</span>
                <span className="font-b2 font-normal">Operator</span>
                <Controller
                  control={form.control}
                  name="outcomeLogic.workflows"
                  rules={{
                    validate: {
                      isValid: (value, formValues) =>
                        (formValues.type === "choose_workflow" &&
                          value?.length > 0) ||
                        "required",
                    },
                  }}
                  render={({ field }) => (
                    <Multiselect
                      error={
                        // @ts-expect-error
                        form.formState.errors.outcomeLogic?.workflows?.message
                      }
                      disabled={!isWorkflowEditable}
                      panelWidth="430px"
                      options={workflowsUsed}
                      values={
                        field.value
                          ? workflowsUsed.filter((i) =>
                              field.value.map((i) => i.id).includes(i.id)
                            )
                          : []
                      }
                      onSearchChange={setSearchQuery}
                      getKey={(e) => e.id}
                      onChange={(e) => {
                        field.onChange(multiselectOnChangeHandler(e, "id"));
                        save();
                      }}
                      getDisplayValue={(e) => {
                        if (!e || e.length === 0) return "";
                        const names = e.map((e) => e.name);
                        if (names.length === 1) return names[0];
                        return `${names[0]} +${names.length - 1}`;
                      }}
                      getName={(e) => e.name}
                      isSelected={(e) =>
                        field.value?.map((i) => i.id).includes(e.id)
                      }
                      positionBottomStart={true}
                    />
                  )}
                />
                <Controller
                  control={form.control}
                  name="outcomeLogic.predictor"
                  rules={{
                    validate: {
                      isValid: (value, formValues) =>
                        (formValues.type === "choose_workflow" &&
                          value?.length &&
                          value?.length > 0) ||
                        "required",
                    },
                  }}
                  render={({ field }) => (
                    <Dropdown
                      onChange={(e) => {
                        field.onChange(e);
                        save();
                      }}
                      disabled={!isWorkflowEditable}
                    >
                      <Dropdown.Button
                        className={clsx(
                          "w-full font-b2 text-neutral-black disabled:cursor-not-allowed disabled:text-indigo-300 [&>img]:w-3 [&>img]:h-3",
                          // @ts-expect-error
                          form.formState.errors.outcomeLogic?.predictor
                            ?.message && "!border-error-500"
                        )}
                      >
                        {field.value ? (
                          <span className="text-neutral-black truncate w-[150px]">
                            {field.value}
                          </span>
                        ) : (
                          <span className="text-neutral-500">Select</span>
                        )}
                      </Dropdown.Button>
                      <Dropdown.Options fullWidth={false}>
                        {!data.data.data || data.data.data.length === 0 ? (
                          <Dropdown.Option disabled key={"no"} value={""}>
                            No Mandatory Outcomes
                          </Dropdown.Option>
                        ) : (
                          data.data.data.map((item) => (
                            <Dropdown.Option key={item.name} value={item.name}>
                              {item.name}
                            </Dropdown.Option>
                          ))
                        )}
                      </Dropdown.Options>
                    </Dropdown>
                  )}
                />
                <Controller
                  control={form.control}
                  name="outcomeLogic.function"
                  rules={{
                    validate: {
                      isValid: (value, formValues) =>
                        (formValues.type === "choose_workflow" &&
                          value?.length > 0) ||
                        "required",
                    },
                  }}
                  render={({ field }) => (
                    <Dropdown
                      disabled={!isWorkflowEditable}
                      onChange={(e) => {
                        field.onChange(e);
                        save();
                      }}
                    >
                      <Dropdown.Button
                        className={clsx(
                          "w-full font-b2 text-neutral-black disabled:cursor-not-allowed disabled:text-indigo-300 [&>img]:w-3 [&>img]:h-3",
                          // @ts-expect-error
                          form.formState.errors.outcomeLogic?.function
                            ?.message && "!border-error-500"
                        )}
                      >
                        {{
                          min: "Minimum",
                          max: "Maximum",
                        }[field.value] ?? (
                          <span className="text-neutral-500">Select</span>
                        )}
                      </Dropdown.Button>
                      <Dropdown.Options fullWidth={false}>
                        <Dropdown.Option value="min">Minimum</Dropdown.Option>
                        <Dropdown.Option value="max">Maximum</Dropdown.Option>
                      </Dropdown.Options>
                    </Dropdown>
                  )}
                />
              </div>
            );
          }}
        </QueryWrapper>
      )}
    </div>
  );
}
