import { Outlet } from "react-router-dom";
import SidePanel from "./components/SidePanel";

function Monitor() {
  return (
    <div className="flex h-full max-h-screen bg-white overflow-auto">
      <SidePanel />
      <div className="pt-12 w-full ">
        <Outlet />
      </div>
    </div>
  );
}

export default Monitor;
