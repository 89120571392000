import classNames from "clsx";
import { useState } from "react";
import Button from "src/components/Button";
import Modal from "src/components/Dialogue";
import { ModelScreen } from "../types";

export const SourceDecideFunc = ({
  sourceModel,
  setSourceModel,
  modelScreen,
  setModelScreen,
  setOpenSourceModel,
}: {
  sourceModel: boolean;
  setSourceModel: setStateType<boolean>;
  modelScreen: ModelScreen;
  setModelScreen: setStateType<ModelScreen>;
  setOpenSourceModel: setStateType<boolean>;
}) => {
  const [selectVal, setSelectedVal] = useState<ModelScreen>("");
  return (
    <Modal
      open={sourceModel}
      onClose={() => setSourceModel(false)}
      className="mb-1"
    >
      <Modal.Panel size="sm">
        <Modal.Header>Add Custom API Source</Modal.Header>
        <Modal.Body className="mb-5 px-6 pt-[17px] pb-0 ">
          <div>
            <div className="flex justify-between items-center">
              <button
                className={classNames(
                  "w-4/10 flex p-4 flex-col items-center rounded-lg font-b1 text-indigo-700 border border-indigo-100 min-w-[160px]",
                  selectVal === "curl" &&
                    "bg-primary-100 text-primary-900 border-primary-900"
                )}
                onClick={() => {
                  setSelectedVal("curl");
                }}
              >
                <div
                  className={classNames(
                    "w-16 h-10 flex justify-center items-start",
                    selectVal === "curl"
                      ? "fill-primary-900"
                      : "fill-indigo-700"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4 10.7838C3.44772 10.7838 3 11.2315 3 11.7838C3 12.3361 3.44772 12.7838 4 12.7838L4 10.7838ZM11.7838 11.7838L12.4909 12.4909C12.8814 12.1004 12.8814 11.4672 12.4909 11.0767L11.7838 11.7838ZM9.03146 7.61724C8.64093 7.22672 8.00777 7.22672 7.61724 7.61724C7.22672 8.00777 7.22672 8.64093 7.61724 9.03146L9.03146 7.61724ZM7.61724 14.5362C7.22672 14.9267 7.22672 15.5599 7.61725 15.9504C8.00777 16.3409 8.64094 16.3409 9.03146 15.9504L7.61724 14.5362ZM5.86484 8.32432V6H3.86484V8.32432H5.86484ZM6.86484 5H18V3H6.86484V5ZM19 6V17.5676H21V6H19ZM18 18.5676H6.86484V20.5676H18V18.5676ZM5.86484 17.5676V15.2432H3.86484V17.5676H5.86484ZM6.86484 18.5676C6.31256 18.5676 5.86484 18.1199 5.86484 17.5676H3.86484C3.86484 19.2244 5.20799 20.5676 6.86484 20.5676V18.5676ZM19 17.5676C19 18.1199 18.5523 18.5676 18 18.5676V20.5676C19.6568 20.5676 21 19.2244 21 17.5676H19ZM18 5C18.5523 5 19 5.44772 19 6H21C21 4.34314 19.6568 3 18 3V5ZM5.86484 6C5.86484 5.44772 6.31256 5 6.86484 5V3C5.20799 3 3.86484 4.34314 3.86484 6H5.86484ZM4 12.7838L11.7838 12.7838V10.7838L4 10.7838L4 12.7838ZM7.61724 9.03146L11.0767 12.4909L12.4909 11.0767L9.03146 7.61724L7.61724 9.03146ZM11.0767 11.0767L7.61724 14.5362L9.03146 15.9504L12.4909 12.4909L11.0767 11.0767Z" />
                  </svg>
                </div>
                Import via curl
              </button>
              <div className="font-b1-medium text-indigo-400">or</div>
              <button
                className={classNames(
                  "w-4/10 flex p-4 flex-col items-center rounded-lg font-b1 text-indigo-700 border border-indigo-100",
                  selectVal === "config" &&
                    "bg-primary-100 text-primary-900 border-primary-900"
                )}
                onClick={() => {
                  setSelectedVal("config");
                }}
              >
                <div
                  className={classNames(
                    "w-16 h-10 flex justify-center items-start",
                    selectVal === "config"
                      ? "fill-primary-900 stroke-primary-900"
                      : "fill-indigo-700 stroke-indigo-700"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3 8L15 8M15 8C15 9.65686 16.3431 11 18 11C19.6569 11 21 9.65685 21 8C21 6.34315 19.6569 5 18 5C16.3431 5 15 6.34315 15 8ZM9 16L21 16M9 16C9 17.6569 7.65685 19 6 19C4.34315 19 3 17.6569 3 16C3 14.3431 4.34315 13 6 13C7.65685 13 9 14.3431 9 16Z"
                      // stroke="#343F59"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                Configure Manually
              </button>
            </div>
            <Button
              className="mt-4 w-[120px] justify-center items-center ml-0 float-right"
              onClick={() => {
                setSourceModel(false);
                setOpenSourceModel(true);
                setModelScreen(selectVal);
              }}
            >
              Add
            </Button>
          </div>
        </Modal.Body>
      </Modal.Panel>
    </Modal>
  );
};
