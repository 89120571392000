import clsx from "clsx";
import { memo } from "react";
import { Handle, NodeProps, Position } from "reactflow";
import Tooltip from "src/components/Tooltip";
import expressionIcon from "@assets/icons/workflow/calculator.svg";
import useWfId from "@screens/outcome-details/eval-components/WfIdContext";
import HandleWithPlus from "@screens/workflow/studio/components/HandleWithPlus";
import { getModelSetItemsQuery } from "@screens/workflow/studio/components/ModelSet/queries";
import useIsHiddenAfterTest from "@screens/workflow/studio/hooks/useIsHiddenAfterTest";
import { getModelSetItemIcon } from "@screens/workflow/utils";
import QueryWrapper from "@components/QueryWrapper";
import Shimmer from "@components/Shimmer";
import { useQuery } from "@tanstack/react-query";
import useIsExpand, {
  useGetHandleStyles,
  useGetHandleStylesForInputDot,
  useGetNodeFontStyles,
  useGetNodeIconStyles,
  useGetNodeStyles,
} from "../../hooks/useIsExpand";

const RunFlowModelNode = ({
  id,
  selected,
  data,
}: NodeProps<{
  label: string;
  isFromFlow?: boolean;
  desc: string;
  hidden?: boolean;
}>) => {
  const wfId = useWfId();

  const modelSetItemsQuery = useQuery(getModelSetItemsQuery(wfId ?? "", id));

  const fontStyles = useGetNodeFontStyles();
  const iconStyles = useGetNodeIconStyles();
  const handleStyles = useGetHandleStyles();
  const isExpanded = useIsExpand();
  const nodeStyles = useGetNodeStyles();
  const handleStylesInputDot = useGetHandleStylesForInputDot();

  const hidden = useIsHiddenAfterTest(id, data?.hidden);

  return (
    <>
      <div className="flex relative cursor-grab active:cursor-grabbing group">
        <div
          style={nodeStyles}
          className={clsx(
            "overflow-visible w-[360px] bg-white rounded-[10px] hover:border-primary-200 border p-[7px] pb-0",
            {
              "opacity-25": hidden,
              "border-primary-200": selected && !hidden,
              "border-neutral-100": !selected,
              "shadow-lg": data?.isFromFlow && !hidden,
              "shadow-studio-node": !(data?.isFromFlow && !hidden),
            }
          )}
        >
          <Handle
            type="target"
            position={Position.Left}
            className="w-2 h-2 top-0 mt-[20px] opacity-50 hover:opacity-100 border-2 rounded-full bg-neutral-0  border-neutral-300 !-left-4 duration-150 overflow-visible"
            isConnectable={false}
            style={handleStylesInputDot}
          >
            <div className="bg-transparent h-24 w-12 -translate-x-8 -translate-y-1/2" />
          </Handle>
          {!isExpanded && (
            <Handle
              type="source"
              position={Position.Right}
              className="!-right-3 top-1/2"
              isConnectable={false}
              style={handleStyles}
            />
          )}
          <div className="w-full text-neutral-black font-medium flex gap-1.5 items-center">
            <img style={iconStyles} alt="policy" src={expressionIcon} />
            <span
              style={fontStyles}
              className="max-w-44 w-max truncate cursor-pointer font-medium hover:bg-neutral-25 px-1 py-0.5 rounded-md"
            >
              {data.label || "Model_Name"}
            </span>
          </div>
          {isExpanded && (
            <div className="relative flex flex-col gap-2 mt-2 mb-3">
              <QueryWrapper
                loader={
                  <>
                    <Shimmer w="100%" h="60px" />
                    <Shimmer w="100%" h="60px" />
                  </>
                }
                query={modelSetItemsQuery}
              >
                {(data) => (
                  <>
                    {(data ?? []).map((expression) => (
                      <div key={expression.id} className="relative flex">
                        <div className="border border-neutral-100 rounded-md p-2 bg-neutral-0 font-b2 w-full max-w-[360px] transition-all ease-in max-h-14">
                          <div
                            className={clsx(
                              "flex items-center gap-2 overflow-visible",
                              expression.body && "mb-2"
                            )}
                          >
                            {getModelSetItemIcon(expression.type, {
                              className: "w-4 h-4",
                            })}
                            <Tooltip
                              contentClassName="font-b2-medium max-w-[250px] truncate"
                              tooltipContent={expression.name}
                              content={<span>{expression.name}</span>}
                            />
                          </div>
                          <div className="text-neutral-500 text-[14px] font-code truncate">
                            {expression.body}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </QueryWrapper>
            </div>
          )}
        </div>
        {isExpanded && (
          <HandleWithPlus
            nodeId={id}
            label=""
            showAdd={false}
            position={Position.Right}
            id="model"
            className="!absolute top-0 -right-3"
            type="source"
            isConnectable={false}
            handleStyles={handleStyles}
          />
        )}
      </div>
    </>
  );
};

export default memo(RunFlowModelNode);
