import chevronDown from "@assets/icons/chevron-down.svg";
import { FloatProvider, useFloat } from "@context/FloatContext";
import { FloatingPortal } from "@floating-ui/react";
import { Combobox } from "@headlessui/react";
import { classNames, debounce } from "@utils/utils";
import { MouseEvent } from "react";
import { getPredictorsName } from "../utils";

type Props<T extends string> = {
  onChange: (e: T) => void;
  options: T[];
  value: string;
  isSearch: boolean;
  classname?: string;
  isDisabled?: boolean;
  setSearchQuery?: setStateType<string>;
  searchQuery?: string;
  handleButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  placeholder?: string;
  optionsInfo?: number[];
};

export default function AutocompleteDropdown<T extends string>({
  ...props
}: Props<T>) {
  return (
    <FloatProvider placement="bottom">
      <ComboBox {...props} />
    </FloatProvider>
  );
}

function ComboBox<T extends string>({
  value,
  onChange,
  options,
  setSearchQuery,
  isSearch,
  searchQuery,
  classname,
  isDisabled,
  handleButtonClick,
  placeholder,
  optionsInfo,
}: Props<T>) {
  const { x, y, strategy, refs } = useFloat();
  return (
    <Combobox
      as="div"
      className={classNames("relative h-min")}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
    >
      <div className={classNames("relative", classname)}>
        <Combobox.Button
          ref={refs.setReference}
          className="flex items-center rounded-r-md focus:outline-none w-full"
          onClick={handleButtonClick}
        >
          {({ open }) => {
            return (
              <>
                <Combobox.Input
                  autoComplete="off"
                  placeholder={placeholder || "Select"}
                  className={classNames(
                    "w-full rounded-md border border-neutral-100 disabled:cursor-not-allowed bg-white flex items-center h-7 pl-3 pr-8 focus:border-primary-400 focus:ring-0 focus:outline-none font-b2"
                  )}
                  onChange={debounce((e) => {
                    if (isSearch) {
                      //@ts-ignore
                      setSearchQuery(e.target.value);
                    }
                  })}
                />
                <img
                  src={chevronDown}
                  className={classNames(
                    "absolute right-3 duration-100",
                    open && "rotate-180"
                  )}
                  aria-hidden="true"
                  alt=""
                />
              </>
            );
          }}
        </Combobox.Button>
        <FloatingPortal>
          <Combobox.Options
            style={{
              width:
                refs.reference.current?.getBoundingClientRect().width ??
                "max-content",
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: 9999,
            }}
            ref={refs.setFloating}
            className={classNames(
              "absolute z-[201] -mt-1 max-h-60 !w-[350px] !max-w-screen overflow-auto rounded-md bg-white shadow-sm focus:outline-none font-b2 border border-neutral-100"
            )}
          >
            {options?.filter((p) =>
              p.toLowerCase().includes(searchQuery?.toLowerCase() ?? "")
            )?.length > 0 ? (
              options
                ?.filter((p) =>
                  p.toLowerCase().includes(searchQuery?.toLowerCase() ?? "")
                )
                .slice(0, 599)
                .map((op, id) => (
                  <Combobox.Option
                    key={op}
                    value={op}
                    className={({ active }) =>
                      classNames(active ? "bg-neutral-50" : "", "!max-w-screen")
                    }
                  >
                    {({ selected }) => (
                      <div
                        className={classNames(
                          "cursor-pointer flex justify-between select-none py-2 px-3 text-neutral-black !max-w-screen",
                          selected && "bg-primary-50 hover:bg-neutral-50"
                        )}
                      >
                        {op}
                        {optionsInfo && optionsInfo?.length > 0 && (
                          <span className="text-neutral-500 text-xs">
                            {getPredictorsName(optionsInfo[id])}
                          </span>
                        )}
                      </div>
                    )}
                  </Combobox.Option>
                ))
            ) : (
              <Combobox.Option
                disabled={true}
                value={"No options"}
                className={({ active }) =>
                  classNames(active ? "bg-neutral-50" : "")
                }
              >
                {() => (
                  <div
                    className={classNames(
                      "relative cursor-pointer select-none py-2 px-3 text-neutral-black"
                    )}
                  >
                    No options
                  </div>
                )}
              </Combobox.Option>
            )}
          </Combobox.Options>
        </FloatingPortal>
      </div>
    </Combobox>
  );
}
