import classNames from "clsx";
import { ReactNode } from "react";
import Card from "@components/Card";
import Shimmer from "@components/Shimmer";

interface OverviewCardProps {
  item: {
    title: ReactNode;
    value?: ReactNode;
    deltaPerc?: string;
    isLoading: boolean;
    icon?: ReactNode;
  };
}

const OverviewCard = ({ item }: OverviewCardProps) => {
  if (item.isLoading) return <Shimmer h="100%" w="100%" />;
  return (
    <Card className="flex items-center h-22 px-6 !py-5 !shadow-card">
      <div className="ml-1 flex flex-col gap-3 h-full w-full justify-between">
        <div className="flex">
          {item.icon}
          <div className="font-b2-medium text-neutral-black w-full">
            {item.title}
          </div>
        </div>
        <div className="flex items-baseline gap-1.5">
          <h3 className="text-2xl font-medium text-neutral-black f">
            {item.value ?? "-"}
          </h3>
          {item.deltaPerc && (
            <span
              className={classNames("flex gap-0 items-center font-b1-medium", {
                "text-success-500": !item.deltaPerc.startsWith("-"),
                "text-error-500": item.deltaPerc.startsWith("-"),
              })}
            >
              {item.deltaPerc && !item.deltaPerc.startsWith("-") ? "+" : "-"}
              {item.deltaPerc.replace(/^-/, "")}
            </span>
          )}
        </div>
      </div>
    </Card>
  );
};

export default OverviewCard;
