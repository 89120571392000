import { useQuery } from "@tanstack/react-query";
import { FinBoxResponse, PermissionTypes } from "@types";
import axios from "axios";
import { createContext, useContext } from "react";
import EmptyState from "src/components/EmptyState";
import Loader from "src/components/Loader";
import {
  FeatureFlags,
  UserDetailsResources,
} from "src/screens/settings/pages/SettingsAdmin/types";
import { useAppState } from "./AppProvider";

type ContextType = {
  permissions: Record<PermissionTypes, string[]>;
  getPermissions: (
    page: PermissionTypes,
    action: string | string[]
  ) => PermissionTypes | boolean;
  getSidebarPermissions: (
    pages: PermissionTypes | PermissionTypes[]
  ) => boolean;
  getMultiplePagePermissions: (
    pages: PermissionTypes | PermissionTypes[],
    action: string
  ) => boolean;
  userRole: string;
  featureFlags: FeatureFlags;
};

const PermissionContext = createContext({} as ContextType);

const useUserDetails = (token: string) => {
  return useQuery({
    queryKey: ["useUserDetails", token],
    queryFn: async () => {
      return axios.get<FinBoxResponse<UserDetailsResources>>(
        `${
          import.meta.env.REACT_APP_API_ENDPOINT
        }/sentinel/dashboard/userDetails`,
        {
          headers: {
            token,
          },
        }
      );
    },
    select: (data) => data.data.data,
    enabled: !!token,
  });
};

export function PermissionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    appState: { token, isAuthenticated },
  } = useAppState();

  const {
    data: userDetData,
    isPending,
    isError,
    isSuccess,
  } = useUserDetails(token!);

  const getPermissions = (page: PermissionTypes, action: string | string[]) => {
    // if (import.meta.env.REACT_APP_API_ENV === "LOCAL") return true;
    if (!userDetData) return false;
    const allowedActions = userDetData.resources![page];
    let requestedActions: string[];

    if (Array.isArray(action)) {
      requestedActions = action;
    } else {
      requestedActions = [action];
    }
    return allowedActions
      ? requestedActions.some((act) => allowedActions.includes(act))
      : false;
  };

  const getSidebarPermissions = (page: PermissionTypes | PermissionTypes[]) => {
    // if (import.meta.env.REACT_APP_API_ENV === "LOCAL") return true;
    if (!userDetData) return false;
    if (page === "default") {
      return true;
    }
    const action = "view";
    const checkPermission = (singlePage: PermissionTypes) => {
      const res = userDetData?.resources[singlePage];
      return !!res?.includes(action);
    };

    if (page) {
      if (Array.isArray(page)) {
        return page.some(checkPermission);
      } else {
        return checkPermission(page);
      }
    }

    return false;
  };

  const getMultiplePagePermissions = (
    page: PermissionTypes | PermissionTypes[],
    action: string
  ) => {
    // if (import.meta.env.REACT_APP_API_ENV === "LOCAL") return true;
    if (!userDetData) return false;
    const checkPermission = (singlePage: PermissionTypes) => {
      const res = userDetData?.resources[singlePage];
      return !!res?.includes(action);
    };

    if (page) {
      if (Array.isArray(page)) {
        return page.some(checkPermission);
      } else {
        return checkPermission(page);
      }
    }

    return false;
  };

  return (
    <PermissionContext.Provider
      value={{
        permissions: userDetData?.resources!,
        getPermissions,
        getSidebarPermissions,
        getMultiplePagePermissions,
        userRole: userDetData?.role!,
        featureFlags: userDetData?.feature_flags || {},
      }}
    >
      {isAuthenticated && !window.location.href.includes("login") ? (
        <>
          {isSuccess && children}
          {isPending && (
            <div className="h-screen w-screen flex items-center justify-center">
              <Loader size="large" />
            </div>
          )}
          {isError && (
            <EmptyState className="h-screen w-screen flex items-center justify-center">
              We're having some trouble reaching our servers at the moment.
              Please try again later.
            </EmptyState>
          )}{" "}
        </>
      ) : (
        <>{children}</>
      )}
    </PermissionContext.Provider>
  );
}

export default function usePermissions() {
  const context = useContext(PermissionContext);
  if (!context)
    throw Error("usePermissions can only be used inside PermissionContext");
  return context;
}
