import { clsx } from "clsx";
import { PropsWithChildren } from "react";
import useIsExpand from "@screens/workflow/studio/hooks/useIsExpand";

export default function BottomPanel({
  children,
  selected,
  show,
  width = "360px",
}: PropsWithChildren<{
  selected: boolean;
  show: boolean;
  width?: string;
}>) {
  const isExpanded = useIsExpand();
  if (!isExpanded) return null;

  return (
    <div
      className={clsx(
        "px-3 bg-white nowheel overflow-y-auto max-h-[288px] rounded-md mt-1 border border-neutral-100 shadow-studio-node py-2 overflow-auto",
        selected && "border-primary-200",
        show ? "block" : "hidden"
      )}
      style={{ width }}
    >
      {children}
    </div>
  );
}
