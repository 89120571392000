import classNames from "clsx";
import { toast } from "react-hot-toast";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

export default function CustomToast({ t, title, text, type = "error" }) {
  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-[200]`}
    >
      <div className="w-0 flex-1 p-2">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {type === "success" && (
              <CheckCircleIcon
                className="h-6 w-6 text-green-400"
                aria-hidden="true"
              />
            )}
            {type === "error" && (
              <XCircleIcon
                className="h-6 w-6 text-red-400"
                aria-hidden="true"
              />
            )}
          </div>
          <div className="ml-3 flex-1">
            <p
              className={classNames("text-xs font-semibold", {
                "text-gray-900": type === "error",
              })}
            >
              {title}
            </p>
            <p
              className={classNames("mt-1 text-xs", {
                "text-gray-900": type === "error",
                // "text-red-500": type === "error",
              })}
            >
              {text}
            </p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.remove(t.id)}
          className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-xs font-medium text-primary-700 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Close
        </button>
      </div>
    </div>
  );
}
