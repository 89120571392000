import { useSimulation } from "../../queries";
import Evaluations from "./Evaluations";
import Summary from "./Summary";

const SimulationDetails = () => {
  const simulationData = useSimulation(1);
  const summary = simulationData?.data?.summary;
  const evaluations = simulationData?.data?.evaluation_details;

  return (
    <>
      <Summary summary={summary} />
      <Evaluations evaluations={evaluations} />
    </>
  );
};

export default SimulationDetails;
