import { useReactFlow, XYPosition } from "reactflow";
import {
  BRANCH_NODE_TYPE,
  DECISION_TABLE_NODE_TYPE,
  MODEL_NODE_TYPE,
  MODEL_SET_NODE_TYPE,
  POLICY_NODE_TYPE,
  RULE_SET_NODE_TYPE,
  SOURCE_NODE_TYPE,
  WORKFLOW_NODE_TYPE,
} from "@screens/workflow/config";
import { getErrors, getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { useWorkflowContext } from "../../WorkflowContext";
import {
  getAddNewBranchNode,
  getAddNewEndNode,
  getAddNewMatrixNode,
  getAddNewModelNode,
  getAddNewModelSetNode,
  getAddNewPolicyNode,
  getAddNewRuleSetRule,
  getAddNewSource,
  getAddNewWorkflowNode,
  getNewNodeName,
} from "../utils";

const invalidateAfterAddNode = (qc: QueryClient, wfId?: string) => {
  qc.invalidateQueries(getErrors(wfId));
  qc.invalidateQueries(getWorkflowKeywordsQuery(wfId));
};

const useAddNode = () => {
  const { updateWorkflow, workflow } = useWorkflowContext();
  const queryClient = useQueryClient();

  const { getNodes } = useReactFlow();

  const onAddEnd = async (
    p: string,
    position: XYPosition,
    from?: string,
    fromHandle?: string
  ) => {
    const e = getAddNewEndNode(p, position, from, fromHandle);
    return await updateWorkflow(e).then(() =>
      invalidateAfterAddNode(queryClient, workflow?.id)
    );
  };
  const onAddPolicy = async (
    p: string,
    position: XYPosition,
    from?: string,
    fromHandle?: string
  ) => {
    const e = getAddNewPolicyNode(p, position, from, fromHandle);
    return await updateWorkflow(e).then(() =>
      invalidateAfterAddNode(queryClient, workflow?.id)
    );
  };

  const onAddWorkflow = async (
    p: string,
    position: XYPosition,
    from?: string,
    fromHandle?: string
  ) => {
    const e = getAddNewWorkflowNode(p, position, from, fromHandle);
    return await updateWorkflow(e).then(() =>
      invalidateAfterAddNode(queryClient, workflow?.id)
    );
  };

  const onAddBranch = async (
    p: string,
    position: XYPosition,
    from?: string,
    fromHandle?: string
  ) => {
    const e = getAddNewBranchNode(p, position, from, fromHandle);
    return await updateWorkflow(e).then(() =>
      invalidateAfterAddNode(queryClient, workflow?.id)
    );
  };

  const onAddRuleSet = async (
    p: string,
    position: XYPosition,
    from?: string,
    fromHandle?: string
  ) => {
    const e = getAddNewRuleSetRule(p, position, from, fromHandle);
    return await updateWorkflow(e).then(() =>
      invalidateAfterAddNode(queryClient, workflow?.id)
    );
  };

  const onAddSource = async (
    p: string,
    position: XYPosition,
    from?: string,
    fromHandle?: string
  ) => {
    const e = getAddNewSource(p, position, from, fromHandle);
    return await updateWorkflow(e).then(() =>
      invalidateAfterAddNode(queryClient, workflow?.id)
    );
  };

  const onAddModel = async (
    p: string,
    position: XYPosition,
    from?: string,
    fromHandle?: string
  ) => {
    const e = getAddNewModelNode(p, position, from, fromHandle);
    return await updateWorkflow(e).then(() =>
      invalidateAfterAddNode(queryClient, workflow?.id)
    );
  };

  const onAddMatrix = async (
    p: string,
    position: XYPosition,
    from?: string,
    fromHandle?: string
  ) => {
    const e = getAddNewMatrixNode(p, position, from, fromHandle);
    return await updateWorkflow(e).then(() =>
      invalidateAfterAddNode(queryClient, workflow?.id)
    );
  };

  const onAddModelSet = async (
    p: string,
    position: XYPosition,
    from?: string,
    fromHandle?: string
  ) => {
    const e = getAddNewModelSetNode(p, position, from, fromHandle);
    return await updateWorkflow(e).then(() =>
      invalidateAfterAddNode(queryClient, workflow?.id)
    );
  };

  const onAddNode = (
    id: string,
    position: XYPosition,
    from?: string,
    fromHandle?: string
  ) => {
    switch (id) {
      case "Blocks:Workflow":
        onAddWorkflow(
          getNewNodeName(WORKFLOW_NODE_TYPE, getNodes()),
          position,
          from,
          fromHandle
        );
        break;
      case "Blocks:Policy":
        onAddPolicy(
          getNewNodeName(POLICY_NODE_TYPE, getNodes()),
          position,
          from,
          fromHandle
        );
        break;
      case "Outcomes:Approved":
        onAddEnd("approved", position, from, fromHandle);
        break;
      case "Outcomes:Rejected":
        onAddEnd("rejected", position, from, fromHandle);
        break;
      case "Outcomes:Can't Decide":
        onAddEnd("cant_decide", position, from, fromHandle);
        break;
      case "Outcomes:Custom":
        onAddEnd("custom_outcome", position, from, fromHandle);
        break;
      case "Blocks:Branch":
        onAddBranch(
          getNewNodeName(BRANCH_NODE_TYPE, getNodes()),
          position,
          from,
          fromHandle
        );
        break;
      case "Blocks:Rule":
        onAddRuleSet(
          getNewNodeName(RULE_SET_NODE_TYPE, getNodes()),
          position,
          from,
          fromHandle
        );
        break;
      case "Blocks:Source":
        onAddSource(
          getNewNodeName(SOURCE_NODE_TYPE, getNodes()),
          position,
          from,
          fromHandle
        );
        break;
      case "Models:Expression":
        onAddModel(
          getNewNodeName(MODEL_NODE_TYPE, getNodes()),
          position,
          from,
          fromHandle
        );
        break;
      case "Models:Decision Table":
        onAddMatrix(
          getNewNodeName(DECISION_TABLE_NODE_TYPE, getNodes()),
          position,
          from,
          fromHandle
        );
        break;
      case "Models:Model Set":
        onAddModelSet(
          getNewNodeName(MODEL_SET_NODE_TYPE, getNodes()),
          position,
          from,
          fromHandle
        );
    }
  };
  return { onAddNode };
};
export default useAddNode;
