import classNames from "clsx";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type ContextType = {
  selected: any;
  setSelected: (selected: any) => void;
  onChange: (selected: any) => void;
  name: string;
};

const RadioContext = createContext<ContextType>({
  setSelected: () => {},
  onChange: () => {},
  selected: undefined,
  name: "",
});
RadioContext.displayName = "RadioContext";

function RadioProvider<T>({
  children,
  onChange: _onChange,
  defaultValue,
  name,
}: {
  children: React.ReactNode;
  onChange: (op: T) => void;
  defaultValue: T;
  name: string;
}) {
  const [selected, setSelected] = useState<T>(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  const onChange = (e: any) => {
    setSelected(e);
    _onChange(e);
  };

  return (
    <RadioContext.Provider
      value={{
        selected,
        setSelected,
        onChange,
        name,
      }}
    >
      {children}
    </RadioContext.Provider>
  );
}

type Props = {
  children: React.ReactNode;
  onChange: (s: any) => void;
  defaultValue?: string;
  oneline?: boolean;
  name: string;
  className?: string;
};

function RadioGroupComponent({ className, ...props }: Props) {
  return (
    <div
      className={classNames(
        "flex gap-2",
        {
          "flex-col": !props.oneline,
        },
        className
      )}
    >
      {props.children}
    </div>
  );
}

RadioGroup.Radio = function Radio({
  children,
  value,
  className,
}: {
  value: string;
  children: ReactNode;
  className?: string;
}) {
  const { onChange, name, selected } = useContext(RadioContext);

  return (
    <span
      className={classNames(
        "flex items-center gap-2 font-b1 text-neutral-black",
        className
      )}
      onClick={() => onChange(value)}
    >
      <input
        type="radio"
        value={value}
        name={name}
        checked={selected === value}
        className="cursor-pointer w-3 h-3 border-neutral-100 ring-0 focus:ring-0"
      />
      <label htmlFor={value}>{children}</label>
    </span>
  );
};

export default function RadioGroup(props: Props) {
  return (
    <RadioProvider
      onChange={props.onChange}
      name={props.name}
      defaultValue={props.defaultValue}
    >
      <RadioGroupComponent {...props} />
    </RadioProvider>
  );
}
