import classNames from "clsx";
import { forwardRef, Fragment } from "react";
import { Menu as HeadlessMenu, Transition } from "@headlessui/react";

const MenuRoot = ({
  className,
  ...props
}: Parameters<typeof HeadlessMenu>[0]) => {
  return (
    <HeadlessMenu
      as="div"
      className={classNames("relative", className)}
      {...props}
    />
  );
};

const Items = forwardRef<
  HTMLDivElement,
  Parameters<typeof HeadlessMenu.Items>[0]
>((props, ref) => {
  return (
    <Transition
      as={Fragment}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <HeadlessMenu.Items
        ref={ref}
        className={classNames(
          "absolute right-0 top-10 box-border w-max rounded-md overflow-hidden bg-white shadow-lg ring-1 ring-gray-100 z-10",
          props.className
        )}
        {...props}
      />
    </Transition>
  );
});

type ItemProps = Parameters<typeof HeadlessMenu.Item>[0] & {
  children: React.ReactNode;
  showTick?: boolean;
};

const Item = ({ children, showTick, className, ...props }: ItemProps) => {
  return (
    <HeadlessMenu.Item className="cursor-pointer" {...props}>
      {typeof children === "function"
        ? children
        : ({ active, disabled }) => (
            <div>
              <div
                className={classNames(
                  "font-b2 text-neutral-black",
                  { "bg-neutral-25 ": active },
                  { "!text-neutral-500 !cursor-not-allowed": disabled },
                  className
                )}
              >
                {children}
              </div>
            </div>
          )}
    </HeadlessMenu.Item>
  );
};

const Button = forwardRef<
  HTMLButtonElement,
  Parameters<typeof HeadlessMenu.Button>[0]
>(({ className, children, ...props }, ref) => {
  return (
    <HeadlessMenu.Button
      ref={ref}
      className={classNames(
        "flex items-center justify-between rounded-md px-2 py-1.5 text-sm text-gray-500 shadow-sm ring-1 ring-gray-300",
        className
      )}
      {...props}
    >
      {children}
    </HeadlessMenu.Button>
  );
});

let Menu = Object.assign(MenuRoot, {
  ...HeadlessMenu,
  Items,
  Item,
  Button,
});

export default Menu;
