import { ReactComponent as ReportsIcon } from "@assets/icons/file-02.svg";
import { ReactComponent as DatasourceIcon } from "@assets/icons/nav-datasource.svg";
import { ReactComponent as HomeIcon } from "@assets/icons/nav-home.svg";
import { ReactComponent as AlertIcon } from "@assets/icons/nav-monitors.svg";
import { ReactComponent as PolicyIcon } from "@assets/icons/nav-policy.svg";
import { ReactComponent as JourneyIcon } from "@assets/icons/nav-program.svg";
import { ReactComponent as AnayticsIcon } from "@assets/icons/nav-reports.svg";
import { ReactComponent as SettingsIcon } from "@assets/icons/settings-02.svg";
import { PolicyStatus } from "@types";
import { endOfDay, format, startOfDay, subDays, subMonths } from "date-fns";
import React from "react";
import {
  PERMISSIONS_CONSTANT,
  PERMISSIONS_TYPE,
} from "src/constants/permissionsConstant";

export const DATE_FORMAT = "dd MMM, yyyy";

export type SideBarOption = {
  name: string;
  href: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  permissionsTag: string | string[];
};

export const sideBarTopOptions: SideBarOption[][] = [
  [
    {
      name: "Overview",
      icon: HomeIcon,
      href: "/",
      permissionsTag: PERMISSIONS_TYPE.overview,
    },
  ],
  [
    {
      name: "Policies",
      icon: PolicyIcon,
      href: "/policies",
      permissionsTag: PERMISSIONS_TYPE.policy,
    },
    {
      name: "Endpoints",
      icon: JourneyIcon,
      href: "/endpoints",
      permissionsTag: PERMISSIONS_TYPE.endpoints,
    },
    {
      name: "Data Sources",
      icon: DatasourceIcon,
      href: "/datasources",
      permissionsTag: [
        PERMISSIONS_TYPE.dataSourcesCustom,
        PERMISSIONS_TYPE.apiStore,
        PERMISSIONS_TYPE.dataSourcesLookup,
      ],
    },
  ],
  [
    {
      name: "Reports",
      icon: ReportsIcon,
      href: "/reports",
      permissionsTag: [
        PERMISSIONS_TYPE.reportsOutcome,
        "simulation",
        PERMISSIONS_TYPE.reportsAll,
      ],
    },
    {
      name: "Analytics",
      icon: AnayticsIcon,
      href: "/analytics",
      permissionsTag: PERMISSIONS_TYPE.analytics,
    },
    {
      name: "Monitors",
      icon: AlertIcon,
      href: "/monitors",
      permissionsTag: [
        PERMISSIONS_TYPE.monitorsCustom,
        PERMISSIONS_TYPE.monitorLogs,
        PERMISSIONS_TYPE.monitorsEndpoint,
      ],
    },
  ],
  [
    {
      name: "Settings",
      icon: SettingsIcon,
      href: "/settings",
      permissionsTag: "default",
    },
  ],
];

export const DEFAULT_PAGE_LIMIT = 20;
export const POLICY_PAGE_LIMIT = DEFAULT_PAGE_LIMIT;
export const WORKFLOW_PAGE_LIMIT = DEFAULT_PAGE_LIMIT;
export const EVENT_PAGE_LIMIT = 15;
export const SIMULATIONS_PAGE_LIMIT = DEFAULT_PAGE_LIMIT;
export const DASHBOARD_USERS_LIMIT = DEFAULT_PAGE_LIMIT;
export const ENDPOINT_PAGE_LIMIT = DEFAULT_PAGE_LIMIT;
export const ENDPOINT_LIST_PAGE_LIMIT = 50;
export const DATA_SOURCE_PAGE_LIMIT = DEFAULT_PAGE_LIMIT;
export const REPORTS_PAGE_LIMIT = DEFAULT_PAGE_LIMIT;
export const MONITOR_PAGE_LIMIT = DEFAULT_PAGE_LIMIT;

export const GRAPH_COLORS = {
  decisions: {
    pass_count: "#12b76a",
    fail_count: "#f04438",
    pass: "#12b76a",
    reject: "#f04438",
    cant_decide_count: "#7E8596",
  },
};

export const DECISION_COLORS: Record<string, string> = {
  pass: "text-success-500",
  reject: "text-error-400",
  cant_decide: "text-orange-500",
};

export const GRAPH_LABELS = {
  decisions: {
    pass_count: "Approved",
    fail_count: "Rejected",
    pass: "Approved",
    reject: "Rejected",
    cant_decide_count: "Can't Decide",
  },
};

export const decisionTextMap: Record<string, string> = {
  pass: "Approved",
  reject: "Rejected",
  approved: "Approved",
  rejected: "Rejected",
  cant_decide: "Can't Decide",
};
export const POLICY_STATUS_CHIP_CLASS: Record<PolicyStatus, string> = {
  [PolicyStatus.ARCHIVE]: "bg-slate-100 text-slate-700 before:bg-current",
  [PolicyStatus.DRAFT]: "bg-gray-100 text-gray-600 before:bg-current",
  [PolicyStatus.ACTIVE]: "bg-green-100 text-green-700 before:bg-current",
  [PolicyStatus.INREVIEW]: "bg-blue-100 text-blue-700 before:bg-current",
  [PolicyStatus.READYTODEPLOY]: "bg-blue-100 text-blue-700 before:bg-current",
};

export const POLICY_STATUS_FILL_CLASS: Record<PolicyStatus, string> = {
  [PolicyStatus.ARCHIVE]: "fill-slate-700",
  [PolicyStatus.DRAFT]: "fill-gray-500",
  [PolicyStatus.ACTIVE]: "fill-green-500",
  [PolicyStatus.INREVIEW]: "fill-blue-500",
  [PolicyStatus.READYTODEPLOY]: "fill-blue-500",
};

export const SOURCE_COLORS: Record<any, string> = {
  bureau: "!text-[#444CE7]",
  "Bank Predictors": "!text-[#E31B54]",
  bank: "!text-[#E31B54]",
  "GST Predictors": "!text-[#039855]",
  gst: "!text-[#039855]",
  "Platform Predictors": "!text-[#7C3AED]",
  application: "!text-[#7C3AED]",
  "Device Connect": "!text-blue-900",
  device: "!text-blue-900",
  Custom: "!text-indigo-700",
};

export const SOURCE_LABELS: Record<any, string> = {
  bureau: "BUREAU",
  "Bank Predictors": "BANK",
  bank: "BANK",
  "GST Predictors": "GST",
  gst: "GST",
  "Platform Predictors": "INPUT",
  "Device Connect": "DEVICE",
  device: "DEVICE",
  Custom: "CUSTOM",
};

export const PROGRAMS = [
  {
    label: "Personal Loan",
    key: "pl",
  },
  {
    label: "Business Loan",
    key: "bl",
  },
  {
    label: "Buy Now Pay Later",
    key: "bnpl",
  },
];

export const POLICY_STATUS_MAP = {
  active: "Active",
  archive: "Archive",
  inreview: "In Review",
  draft: "Draft",
  readytodeploy: "Ready to Deploy",
};

export const OWNER_ROLE = "owner";

export const PAGES = {
  OVERVIEW: "overview",
  POLICY_LIST: "policy_list",
  POLICY_DETAILS: "policy_details",
  ALERTS: "overview",
  DECISION_LIST: "overview",
  SIMULATIONS_LIST: "overview",
  ADMIN: "overview",
};

export const LOCALSTORAGE_KEYS = {
  SIDEBAR_EXPANDED: "sentinel.sidebar-expanded",
  // PERMISSIONS: "sentinel.permissions",
  TOKEN: `sentinel.${import.meta.env.REACT_APP_API_ENV}.auth`,
  NAME: "name",
  // ROLE: "role",
  LATEST_REPORT: "latestReport",
  USER: "user",
  LOGO_URL: "logo_url",
  ORG_NAME: "orgName",
  // FEATURE_FLAGS: "sentinel.feature_flags",
  STUDIO_MAX_ZOOM: "sentinel.studio.max_zoom",
} as const;

export const DATE_RANGES = {
  "24H": {
    label: "Last 24 hours",
    startDate: format(startOfDay(new Date()), DATE_FORMAT),
    endDate: format(endOfDay(new Date()), DATE_FORMAT),
  },
  "1W": {
    label: "Last 7 days",
    startDate: format(subDays(new Date(), 7), DATE_FORMAT),
    endDate: format(new Date(), DATE_FORMAT),
  },
  "1M": {
    label: "Last 1 month",
    startDate: format(subMonths(new Date(), 1), DATE_FORMAT),
    endDate: format(new Date(), DATE_FORMAT),
  },
  "3M": {
    label: "Last 3 months",
    startDate: format(subMonths(new Date(), 3), DATE_FORMAT),
    endDate: format(new Date(), DATE_FORMAT),
  },
} as const;

const defaultList = [
  {
    status: PolicyStatus.DRAFT,
    disabled: true,
    permissionsTag: PERMISSIONS_CONSTANT.draftoinreview,
  },
  {
    status: PolicyStatus.INREVIEW,
    disabled: true,
    permissionsTag: PERMISSIONS_CONSTANT.toreadytodeploy,
  },
  {
    status: PolicyStatus.READYTODEPLOY,
    disabled: true,
    permissionsTag: PERMISSIONS_CONSTANT.toreadytodeploy,
  },
  {
    status: PolicyStatus.ARCHIVE,
    disabled: true,
    permissionsTag: "archive",
  },
];

export const STATUS_CONFIG_WITH_ARCHIVE = {
  active: {
    label: "Active",
    allowedStatusList: defaultList,
  },
  archive: {
    label: "Archive",
    allowedStatusList: defaultList,
  },
  inactive: {
    label: "Inactive",
    allowedStatusList: defaultList,
  },
  inreview: {
    label: "In Review",
    allowedStatusList: [
      {
        status: PolicyStatus.DRAFT,
        disabled: false,
        permissionsTag: PERMISSIONS_CONSTANT.draftoinreview,
      },
      {
        status: PolicyStatus.ARCHIVE,
        disabled: false,
        permissionsTag: "archive",
      },
      {
        status: PolicyStatus.READYTODEPLOY,
        disabled: false,
        permissionsTag: PERMISSIONS_CONSTANT.toreadytodeploy,
      },
    ],
  },
  draft: {
    label: "Draft",
    allowedStatusList: [
      {
        status: PolicyStatus.ARCHIVE,
        disabled: false,
        permissionsTag: "archive",
      },
      {
        status: PolicyStatus.INREVIEW,
        disabled: false,
        permissionsTag: PERMISSIONS_CONSTANT.draftoinreview,
      },
      {
        status: PolicyStatus.READYTODEPLOY,
        disabled: true,
        permissionsTag: PERMISSIONS_CONSTANT.toreadytodeploy,
      },
    ],
  },
  readytodeploy: {
    label: "Ready to Deploy",
    allowedStatusList: [
      {
        status: PolicyStatus.INREVIEW,
        disabled: false,
        permissionsTag: PERMISSIONS_CONSTANT.toreadytodeploy,
      },
      {
        status: PolicyStatus.ARCHIVE,
        disabled: false,
        permissionsTag: "archive",
      },
    ],
  },
};

export const FINBOX_SOURCES = [
  "input",
  "bank",
  "bureau",
  "application",
  "device",
  "gst",
];

export const POLICY_STATUS_OPTIONS = [
  { name: "Ready to Deploy", value: "readytodeploy" },
  { name: "Inactive", value: "inactive" },
  { name: "In Review", value: "inreview" },
  { name: "Draft", value: "draft" },
  { name: "Archive", value: "archive" },
];

export const POLICY_BUCKET_STATUS_OPTIONS = [
  { name: "Archive", value: "archive" },
];

export const WORKFLOW_FILE_EXTENSION = ".workflow";
