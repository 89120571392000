import classNames from "clsx";
import { ChangeEvent, ComponentProps } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

type Props = Omit<ComponentProps<"input">, "onChange"> & {
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  hint?: string;
  noIcon?: boolean;
  inputClassName?: string;
};

export default function Search({
  onSearchChange,
  hint,
  noIcon,
  className = "w-[500px]",
  inputClassName = "",
  ...props
}: Props) {
  return (
    <div className={classNames("mr-4", className)}>
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative flex items-center">
        {!noIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-4 w-4 text-neutral-400 00"
              aria-hidden="true"
            />
          </div>
        )}
        <input
          id="search"
          name="search"
          className={classNames(
            "flex w-full items-center rounded-md border-neutral-100 pr-2 h-7 focus:border-primary-700 focus:ring-0 text-xs placeholder-neutral-500",
            {
              "pl-9": !noIcon,
            },
            inputClassName
          )}
          autoComplete="off"
          placeholder={hint ? hint : "Search"}
          type="search"
          onChange={onSearchChange}
          {...props}
        />
      </div>
    </div>
  );
}
