import chevronDown from "@assets/icons/chevron-down.svg";
import closeIcon from "@assets/icons/x-close-blue.svg";
import { Popover, Transition } from "@headlessui/react";
import classNames from "clsx";
import { Fragment, Key, ReactNode } from "react";

type Props<T> = {
  value?: T;
  onChange: (value: T) => void;
  options: T[];
  displayValue: (value: T) => ReactNode;
  panelClassname?: string;
  onClear: () => void;
  defaultValue: T;
  getKey: (s: T) => Key;
  placeholder?: string;
};

export default function FilterDropdown<T>({
  onChange,
  value,
  displayValue,
  options,
  panelClassname,
  onClear,
  getKey,
  defaultValue,
  placeholder,
}: Props<T>) {
  return (
    <Popover className="relative">
      {({ open }) => {
        return (
          <>
            <Popover.Button
              className={classNames(
                "focus:outline-0 focus:ring-0 rounded-md transition duration-150 px-2.5 py-1 inline-flex items-center h-7 gap-2 font-b2-medium border",
                value
                  ? "text-primary-900 bg-primary-50 border-primary-50"
                  : "text-neutral-black bg-white border-neutral-100 hover:bg-neutral-0 focus:bg-neutral-0"
              )}
            >
              <span>
                {value || defaultValue
                  ? displayValue(value || defaultValue)
                  : placeholder}
              </span>
              {value ? (
                <img
                  alt=""
                  src={closeIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear();
                  }}
                />
              ) : (
                <img
                  className={open ? "rotate-180" : ""}
                  alt=""
                  src={chevronDown}
                />
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={classNames(
                  "absolute flex-col bg-white rounded-md border border-neutral-100 font-b2 left-0 top-full mt-4 flex w-screen !-translate-y-2.5 max-w-min z-[200] shadow-1",
                  panelClassname
                )}
              >
                {({ close }) => {
                  return (
                    <>
                      {options.map((item) => {
                        const label = displayValue(item);

                        return (
                          <span
                            onClick={() => {
                              onChange(item);
                              close();
                            }}
                            key={getKey(item)}
                            className="flex items-center gap-2 p-2 first:rounded-t-md cursor-pointer last:rounded-b-md hover:bg-neutral-25"
                          >
                            <input
                              checked={value && getKey(value) === getKey(item)}
                              type="radio"
                              className="w-3 h-3 border-neutral-100"
                            />
                            {label}
                          </span>
                        );
                      })}
                    </>
                  );
                }}
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
}
