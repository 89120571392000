import "react-datepicker/dist/react-datepicker.css";

export default function ShimmerFallbackUI() {
  return (
    <div className="w-full">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center justify-between">
            <div className="h-8 w-36 rounded-sm bg-gray-200"></div>
          </div>
          <div className="xs:block">
            <div className="h-9 w-9 rounded-full bg-gray-200"></div>
          </div>
        </div>
        <div className="mb-4 xs:flex sm:hidden">
          <div className="h-10 w-full rounded bg-gray-200"></div>
        </div>
      </div>
      <div className="px-4 pt-8 pb-6 sm:px-6 lg:px-8">
        <div className="h-8 w-56 rounded bg-gray-200"></div>
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-9 w-full rounded bg-gray-200"></div>
      </div>

      <div className="px-4 py-8 sm:px-6 lg:px-8">
        <div className="h-56 w-full rounded bg-gray-200"></div>
      </div>
    </div>
  );
}
