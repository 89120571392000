import Button from "@components/Button";
import Link from "@components/Link";
import RadioGroup from "@components/RadioGroups";
import useSimulation from "@components/RunSimulationsModal/useSimulation";
import { useSimulate } from "@screens/workflow/components/Simulation/queries";
import {
  getGQEndTime,
  getGQStartTime,
} from "@utils/utils";
import { format, subMonths } from "date-fns";
import { useReducer, useState } from "react";
import {
  DATE_FORMAT,
} from "src/config";
import SimulationDateFilter from "../SimulationDateFilter";
import {
  Decision,
  SimulationModalProps,
} from "../SimulationModal.types";
import Footer from "../Footer";
import HistoricalPolicySelection from "./HistoricalPolicySelection";
import HistoricalUserTypeSelection from './HistoricalUserTypeSelection';
import { initialState } from "./HistoricalSimulation.helper";
import { historicalSimulationReducer } from "./HistoricalSimulation.reducers";


export default function Historical({ policyID, closeModal, ...props }: SimulationModalProps) {
  const [state, dispatch] = useReducer(historicalSimulationReducer, initialState);
  
  const { isPending, mutate } = useSimulation((data: any) => {
    window.open(data.data.url, "_blank");
    closeModal();
    dispatch({ type: "Reset" });
  });


  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const runWfSimulation = useSimulate();

  const submitSimulation = () => {
    if (props.type === "workflow") {
      runWfSimulation.mutate({
        type: "historical",
        workflowId: props.workflowId,
        startDate: getGQStartTime(dateRange.startDate!, "dd MMM, yyyy"),
        endDate: getGQEndTime(dateRange.endDate!, "dd MMM, yyyy"),
        decision: state.decision,
        chooseFromId: state.policy,
      });
    } else {
      const formData = new FormData();
      formData.append("policy_id", policyID);
      if (state.policy) formData.append("match_policy_version", state.policy);
      if (state.decision) formData.append("decision", state.decision);
      if (state.startDate)
        formData.append(
          "date_start",
          getGQStartTime(format(new Date(state.startDate), "yyyy-MM-dd"))!
        );
      if (state.endDate)
        formData.append(
          "date_end",
          getGQEndTime(format(new Date(state.endDate), "yyyy-MM-dd"))!
        );
      formData.append("limit", "500");

      mutate({ formData, type: "Historical" });
    }
  };

  return (
    <>
      {props.type !== "workflow" && (
        <div className="mt-4 flex flex-col gap-2">
          <div className="text-neutral-500 font-b2-medium">
            Choose user type to simulate
          </div>
          <RadioGroup
            defaultValue={state.decision}
            onChange={(e: Decision) => {
              dispatch({ type: "SetDecision", value: e });
            }}
            name="decision"
            className=""
          >
            <RadioGroup.Radio value="pass">Pass</RadioGroup.Radio>
            <RadioGroup.Radio value="reject">Fail</RadioGroup.Radio>
            <RadioGroup.Radio value="under_review">
              Can't Decide
            </RadioGroup.Radio>
          </RadioGroup>
        </div>
      )}
      <HistoricalPolicySelection 
        policyID={policyID} 
        closeModal={closeModal} 
        state={state}
        dispatch={dispatch}
        {...props}
      />
      {state?.policy && <HistoricalUserTypeSelection 
        policyID={policyID} 
        closeModal={closeModal} 
        state={state}
        dispatch={dispatch}
        {...props}
      />}

      <div className="mt-4 flex flex-col gap-2 w-full">
        <SimulationDateFilter
          panelClassNames="w-full py-[7px] font-b2"
          value={dateRange}
          onChange={setDateRange}
          onClear={() =>
            setDateRange({
              startDate: format(subMonths(new Date(), 1), DATE_FORMAT),
              endDate: format(new Date(), DATE_FORMAT),
            })
          }
        />
      </div>

      {props.type === "workflow" &&
        !runWfSimulation.isPending &&
        runWfSimulation.isSuccess && (
          <div className="font-b2 mt-4 text-neutral-black">
            You can download the simulation report from{" "}
            <Link to="/reports/simulations">Reports &gt; Simulations</Link>
          </div>
        )}

      {/* remove this at end */}
      {props.type !== "workflow" && (
        <Footer isLoading={isPending} submit={submitSimulation} />
      )}
      {props.type === "workflow" && (
        <div className="fixed w-[680px] -translate-x-4 bottom-0 justify-end flex items-center border-t border-indigo-50 bg-neutral-0 px-4 py-2.5">
          <Button
            disabled={isPending || state?.policy?.length === 0}
            onClick={submitSimulation}
          >
            Run Simulation
          </Button>
        </div>
      )}
    </>
  );
}
