import { ReactComponent as PlusIcon } from "@assets/icons/workflow/plus-add-condition.svg";
import { ReactComponent as DeleteIcon } from "@assets/icons/workflow/trash-02.svg";
import Button from "@components/Button";
import Dropdown from "@components/DropDown";
import Input from "@components/Input";
import QueryWrapper from "@components/QueryWrapper";
import Shimmer from "@components/Shimmer";
import {
  getErrors,
  getWorkflowOutcomeConfig,
  useUpdateWorkflowOutcomeConfig,
} from "@screens/workflow/queries";
import { getOutcomeConfig } from "@screens/workflow/studio/components/Outcome/queries";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getNetworkErrorText, notify } from "@utils/utils";
import { clsx } from "clsx";
import { Fragment } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useWorkflowContext } from "../../WorkflowContext";

const MAX_OUTCOME_COUNT = 40;

const Outcome = () => {
  const { workflow, isWorkflowEditable } = useWorkflowContext();

  const globalConfig = useQuery(getWorkflowOutcomeConfig(workflow?.id!));
  const queryClient = useQueryClient();

  const form = useForm<{
    variables: Array<{
      type: "text" | "number" | "boolean";
      name: string;
      isMandatory: boolean;
    }>;
  }>({
    values: {
      variables: globalConfig.data?.data.data ?? [
        {
          name: "",
          type: "text",
          isMandatory: false,
        },
      ],
    },
    defaultValues: {
      variables: globalConfig.data?.data.data ?? [
        {
          name: "",
          type: "text",
          isMandatory: false,
        },
      ],
    },
  });

  const variablesFieldArray = useFieldArray({
    name: "variables",
    control: form.control,
    rules: {
      maxLength: {
        value: MAX_OUTCOME_COUNT,
        message: "Max " + MAX_OUTCOME_COUNT + " outcomes",
      },
    },
  });

  const updateConfig = useUpdateWorkflowOutcomeConfig();

  const onSave = form.handleSubmit((data) => {
    updateConfig.mutate(
      {
        workflowId: workflow?.id!,
        variables: data.variables,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            getOutcomeConfig({
              workflowId: workflow?.id!,
            })
          );
          queryClient.invalidateQueries(
            getWorkflowOutcomeConfig(workflow?.id!)
          );
          queryClient.invalidateQueries(getErrors(workflow?.id!));
          notify({
            title: "Updated",
            text: "Updated workflow config",
            type: "success",
          });
        },
        onError: (err) =>
          notify({ title: "Failed", text: getNetworkErrorText(err) }),
      }
    );
  });

  return (
    <div className="relative flex flex-col h-full pt-3">
      <QueryWrapper
        query={globalConfig}
        loader={
          <>
            <Shimmer w="100%" h="32px" />
            <Shimmer w="100%" h="32px" />
            <Shimmer w="100%" h="32px" />
          </>
        }
      >
        {() => {
          return (
            <>
              <div
                className="grid gap-2 items-start pb-4 px-4 overflow-y-auto"
                style={{ gridTemplateColumns: "7fr 3fr 16px" }}
              >
                <span className="font-b2 text-neutral-black">
                  Variable Name
                </span>
                <span className="font-b2 text-neutral-black">Type</span>{" "}
                <span />
                {variablesFieldArray.fields.map((field, index) => (
                  <Fragment key={field.id}>
                    <Input
                      placeholder="Enter the Name"
                      error={
                        form.formState.errors.variables?.[index]?.name?.message
                      }
                      disabled={
                        !isWorkflowEditable ||
                        globalConfig.data?.data.data?.[index]?.isMandatory
                      }
                      {...form.register(`variables.${index}.name`, {
                        maxLength: { value: 50, message: "Max 50 characters" },
                        required: {
                          value: true,
                          message: "Name is required",
                        },
                        pattern: {
                          value: /[a-z_A-Z]/,
                          message:
                            "Invalid variable name, cannot start with numbers or contain special characters (except '_')",
                        },
                      })}
                    />
                    <Controller
                      control={form.control}
                      name={`variables.${index}.type`}
                      defaultValue="text"
                      rules={{
                        required: {
                          value: true,
                          message: "Type is required",
                        },
                      }}
                      render={({ field, fieldState }) => {
                        return (
                          <Dropdown
                            disabled={!isWorkflowEditable}
                            onChange={field.onChange}
                            className={clsx(
                              "h-[27px]",
                              (globalConfig.data?.data.data?.[index]
                                ?.isMandatory ||
                                !isWorkflowEditable) &&
                                "col-span-2"
                            )}
                          >
                            <Dropdown.Button
                              className={clsx(
                                "min-w-full text-neutral-black",
                                fieldState.error?.message && "!border-error-500"
                              )}
                            >
                              {field.value ? (
                                field.value
                              ) : (
                                <span className="text-neutral-300">Select</span>
                              )}
                            </Dropdown.Button>
                            <Dropdown.Options>
                              <Dropdown.Option value="text">
                                Text
                              </Dropdown.Option>
                              <Dropdown.Option value="number">
                                Number
                              </Dropdown.Option>
                              <Dropdown.Option value="boolean">
                                Boolean
                              </Dropdown.Option>
                            </Dropdown.Options>
                          </Dropdown>
                        );
                      }}
                    />
                    {isWorkflowEditable &&
                      !globalConfig.data?.data.data?.[index]?.isMandatory && (
                        <DeleteIcon
                          className="cursor-pointer h-[27px] [&:hover>path]:stroke-error-500 w-4 "
                          onClick={() => variablesFieldArray.remove(index)}
                        />
                      )}
                  </Fragment>
                ))}
              </div>
              {form.formState.errors.variables?.root?.message && (
                <div className="text-error-500 px-4 font-b2">
                  {form.formState.errors.variables?.root?.message}
                </div>
              )}
              {isWorkflowEditable && (
                <div
                  className={clsx(
                    "font-b2-medium px-4 cursor-pointer mt-1 text-neutral-black group/add-expr hover:text-primary-900 w-max flex items-center gap-1 mb-1",
                    variablesFieldArray.fields.length >= MAX_OUTCOME_COUNT &&
                      "opacity-80 !cursor-not-allowed"
                  )}
                  onClick={() => {
                    if (variablesFieldArray.fields.length < MAX_OUTCOME_COUNT)
                      variablesFieldArray.append({
                        name: "",
                        type: "text",
                        isMandatory: false,
                      });
                  }}
                >
                  <PlusIcon className="w-4 h-4 group-hover/add-expr:[&>path]:stroke-primary-900 [&>path]:stroke-neutral-black" />
                  Add
                </div>
              )}
            </>
          );
        }}
      </QueryWrapper>
      <div className="sticky bottom-0 mt-auto w-full flex justify-end py-2 pr-2 bg-neutral-0 border-t border-neutral-100">
        <Button disabled={!isWorkflowEditable} onClick={onSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Outcome;
