import { Fragment, PropsWithChildren } from "react";
import alertCircle from "@assets/icons/alert-circle.svg";
import approvedIcon from "@assets/icons/workflow/circle-check-filled.svg";
import xClose from "@assets/icons/x-close.svg";

type Props = {
  close: () => void;
  errorCount: number;
};

function ErrorItemName({ children }: PropsWithChildren) {
  return <div className="font-b2-medium">{children}</div>;
}

function ErrorItemDescription({ children }: PropsWithChildren) {
  return <div className="font-b2">{children}</div>;
}

export function ErrorList({
  errorCount,
  close,
  children,
}: PropsWithChildren<Props>) {
  if (errorCount === 0)
    return (
      <div>
        <div className="flex justify-between">
          <span className="flex items-center gap-1.5 cursor-pointer text-neutral-500 font-b2-medium">
            <img src={approvedIcon} alt="!" />
            No Errors
          </span>
          <img src={xClose} alt="!" onClick={close} />
        </div>
      </div>
    );

  return (
    <div className="nowheel overflow-auto">
      <div className="flex justify-between">
        <span className="flex items-center gap-1.5 cursor-pointer text-error-500 font-b2-medium">
          <img src={alertCircle} alt="!" />
          {errorCount} {errorCount === 1 ? " Error" : " Errors"}
        </span>
        <img src={xClose} alt="!" onClick={close} />
      </div>
      <div className="flex flex-col gap-3 mt-1.5">{children}</div>
    </div>
  );
}

ErrorList.ItemName = ErrorItemName;
ErrorList.ItemDescription = ErrorItemDescription;

export default function DefaultErrorList({
  errors,
  close,
}: {
  errors: [string, string][];
  close: Props["close"];
}) {
  return (
    <ErrorList close={close} errorCount={errors.length}>
      {errors.map(([name, desc]) => (
        <Fragment key={name}>
          <ErrorList.ItemName>{name}</ErrorList.ItemName>
          <ErrorList.ItemDescription>{desc}</ErrorList.ItemDescription>
        </Fragment>
      ))}
    </ErrorList>
  );
}
