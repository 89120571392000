import finboxDarkLogo from "@assets/new_finbox_logo.svg";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ContentWrapper from "src/components/ContentWrapper";
import { useLoginWithToken } from "./useLogin";

const LoginWithToken = () => {
  const { token } = useParams();
  const location = useLocation();
  const redirectTo = decodeURI(location.search.replace("?redirectTo=", ""));
  const loginWithTokenMutation = useLoginWithToken(token!, {
    redirect: redirectTo,
  });

  useEffect(() => {
    if (token) {
      loginWithTokenMutation.mutate(undefined);
    }
    //   eslint-disable-next-line
  }, [token]);

  return (
    <ContentWrapper>
      <div className="flex min-h-full w-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto mb-4 h-10 w-auto"
            src={finboxDarkLogo}
            alt="Finbox - Sentinel"
          />
          <h1 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl">
            Sentinel
          </h1>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md"></div>
      </div>
    </ContentWrapper>
  );
};

export default LoginWithToken;
