import axios from "@axios";
import { lookupFunctionInputs } from "@screens/create-policy/queries";
import { ViewVariableType } from "@screens/datasources/types";
import { getErrors, getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import {
  QueryClient,
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { getNetworkErrorText, normalizeQueryKey, notify } from "@utils/utils";
import { CurrentSourceItem, SourceItem } from "./types";

const invalidationAfterSourceChange = (
  queryClient: QueryClient,
  workflowId: string,
  nodeId: string
) => {
  queryClient.invalidateQueries(getWorkflowKeywordsQuery());
  queryClient.invalidateQueries(getCurrentSourcesQuery(workflowId, nodeId));
  queryClient.invalidateQueries(getErrors(workflowId));
  queryClient.invalidateQueries(
    lookupFunctionInputs({ entityId: workflowId, entityType: "workflow" })
  );
};

export function useAddSource() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      workflowId,
      dataSourceId,
      variables,
      dataSourceType,
      nodeId,
    }: {
      workflowId: string;
      dataSourceId: number;
      nodeId: string;
      dataSourceType: string;
      variables: {
        dependsOn: number;
        value: string;
        dataSourceType: number;
        id: number;
      }[];
    }) => {
      return axios.post(`workflow/${workflowId}/dataSource/${nodeId}`, {
        dataSourceID: dataSourceId,
        dataSourceType,
        variables,
      });
    },
    onSuccess: (_, { workflowId, nodeId }) => {
      invalidationAfterSourceChange(queryClient, workflowId, nodeId);
    },
    onError: (err) => {
      notify({
        title: "Update failed",
        text: getNetworkErrorText(err),
      });
    },
  });
}

export function useDeleteSource() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      workflowId,
      dataSourceId,
      nodeId,
      dataSourceType,
    }: {
      workflowId: string;
      dataSourceId: number;
      nodeId: string;
      dataSourceType: string;
    }) => {
      return axios.delete(`workflow/${workflowId}/dataSource/${nodeId}`, {
        data: {
          dataSourceID: dataSourceId,
          dataSourceType,
        },
      });
    },
    onSuccess: (_, { workflowId, nodeId }) => {
      invalidationAfterSourceChange(queryClient, workflowId, nodeId);
    },
  });
}

export type DataSourceVariable = {
  variableID: number;
  variableName: string;
  datasourceID: number;
  type: string;
};

export function useUpdateSourceItem() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      workflowId,
      datasourceId,
      oldDatasourceId,
      dataSourceType,
      nodeId,
      variables,
    }: {
      workflowId: string;
      datasourceId: number;
      oldDatasourceId: number;
      dataSourceType: string;
      nodeId: string;
      variables: {
        dependsOn: number;
        value: string;
        dataSourceType: number;
        id: number;
      }[];
    }) =>
      axios.put(`workflow/${workflowId}/dataSource/${nodeId}`, {
        dataSourceID: datasourceId,
        oldDataSourceID: oldDatasourceId,
        dataSourceType,
        variables,
      }),
    onSuccess: (_, { workflowId, nodeId }) => {
      invalidationAfterSourceChange(queryClient, workflowId, nodeId);
    },
    onError: (err) => {
      notify({
        title: "Update failed",
        text: getNetworkErrorText(err),
      });
    },
  });
}

export function getVariablesQuery(
  workflowId: string,
  dataSourceId: string | number
) {
  return queryOptions({
    queryKey: ["dataSourceVariableList", workflowId, dataSourceId],
    queryFn: async () =>
      axios.get<FinBoxResponse<{ variables: DataSourceVariable[] }>>(
        "datasource/variableList",
        {
          params: {
            dataSourceID:
              typeof dataSourceId === "string"
                ? Number(dataSourceId)
                : dataSourceId,
            entityID: workflowId,
            entityType: "workflow",
          },
        }
      ),
    enabled: !!dataSourceId,
  });
}

export const getCurrentSourcesQuery = (workflowId: string, nodeId: string) =>
  queryOptions({
    queryKey: normalizeQueryKey(["sourceNodeSources", workflowId, nodeId]),
    queryFn: async () =>
      axios.get<FinBoxResponse<CurrentSourceItem[]>>(
        `workflow/${workflowId}/dataSource/${nodeId}`
      ),
  });

export const getSourceListQuery = (workflowId: string) =>
  queryOptions({
    queryKey: normalizeQueryKey(["sourceList", workflowId]),
    queryFn: async () =>
      axios.get<FinBoxResponse<SourceItem[]>>(
        `workflow/${workflowId}/dataSource`
      ),
  });

export const getSourceVariableMapping = ({
  enabled,
  dataSourceId,
  workflowId,
}: {
  enabled?: boolean;
  dataSourceId: number;
  workflowId: string;
}) => {
  return queryOptions({
    queryKey: ["variableMapping", workflowId, dataSourceId],
    queryFn: () => {
      return axios.post<
        FinBoxResponse<Record<"variables", ViewVariableType[]>>
      >("datasource/entityMapping/view", {
        dataSourceID: dataSourceId,
        entityID: workflowId,
        entityType: "workflow",
      });
    },
    enabled,
  });
};
