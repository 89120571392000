import customEdge, { CustomRunFlowEdge } from "./custom-edge";
import verticalCustomEdge from "./custom-edge-vertical";

const edgeTypes = {
  customedge: customEdge,
};

export const runFlowEdgeTypes = {
  customedge: CustomRunFlowEdge,
};

export const verticalEdgeTypes = {
  customedge: verticalCustomEdge,
};

export default edgeTypes;
