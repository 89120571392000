import { useSearchParams } from "react-router-dom";

export function useSearchParamState<T extends Record<string, string>>(
  defaultValue: T
) {
  const [searchParams, _setSearchParams] = useSearchParams(defaultValue);

  const setSearchParams = (key: keyof T, newValue: string) => {
    _setSearchParams((params) => {
      params.set(key as string, newValue);
      return params;
    });
  };

  const removeSearchParam = (key: keyof T) => {
    _setSearchParams((params) => {
      params.delete(key as string);
      return params;
    });
  };

  const getSearchParam = (key: keyof T) =>
    searchParams.get(key as string) ?? defaultValue[key];

  const getAllSearchParam = (key: keyof T) =>
    searchParams.getAll(key as string) ?? defaultValue[key];

  return {
    searchParams,
    setSearchParams,
    removeSearchParam,
    getSearchParam,
    getAllSearchParam,
  };
}
