import { getErrors } from "@screens/workflow/queries";
import { OutcomeNodeConfig } from "@screens/workflow/studio/components/Outcome/types";
import axios from "@axios";
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { FinBoxResponse } from "@types";
import { getNetworkErrorText, normalizeQueryKey, notify } from "@utils/utils";

export function getOutcomeConfig({
  workflowId,
  nodeId,
  enabled,
}: {
  workflowId: string;
  nodeId?: string;
  enabled?: boolean;
}) {
  return queryOptions({
    queryKey: normalizeQueryKey(["outcomeConfig", workflowId, nodeId]),
    queryFn: async () =>
      axios.get<FinBoxResponse<OutcomeNodeConfig>>(
        `workflow/${workflowId}/end/${nodeId}`
      ),
    enabled,
  });
}

export function useUpdateOutcomeNode() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async ({
      workflowId,
      nodeId,
      payload,
    }: {
      workflowId: string;
      nodeId: string;
      payload: OutcomeNodeConfig;
    }) => axios.post(`workflow/${workflowId}/end/${nodeId}`, payload),
    onSuccess: (_, vars) => {
      qc.invalidateQueries(
        getOutcomeConfig({ workflowId: vars.workflowId, nodeId: vars.nodeId })
      );
      qc.invalidateQueries(getErrors(vars.workflowId));
    },
    onError: (err, vars) => {
      qc.invalidateQueries(
        getOutcomeConfig({ workflowId: vars.workflowId, nodeId: vars.nodeId })
      );
      notify({ title: "Failed", text: getNetworkErrorText(err) });
    },
  });
}
