import { ReactComponent as PlusIcon } from "@assets/icons/workflow/plus-add-condition.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/workflow/trash-02.svg";
import { ReactComponent as CheckIcon } from "@assets/notificationIcons/check-circle-broken.svg";
import Button from "@components/Button";
import Dropdown from "@components/DropDown";
import FileUpload from "@components/FileUpload";
import Input from "@components/Input";
import QueryWrapper from "@components/QueryWrapper";
import Shimmer from "@components/Shimmer";
import { InputVariableDataTypes } from "@screens/create-policy/Sources/types";
import {
  getCustomInputsQuery,
  useGetCustomInputs,
  useSaveCustomInputs,
} from "@screens/create-policy/queries";
import { getErrors, getWorkflowKeywordsQuery } from "@screens/workflow/queries";
import { useQueryClient } from "@tanstack/react-query";
import {
  generateAndDownloadFile,
  getNetworkErrorText,
  notify,
  readFile,
} from "@utils/utils";
import { clsx } from "clsx";
import { parse } from "csv-parse/browser/esm/sync";
import { Fragment, useRef, useState } from "react";
import {
  Controller,
  UseFormReturn,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import Tooltip from "src/components/Tooltip";
import { useWorkflowContext } from "../../WorkflowContext";

function InputForm({ form }: { form: UseFormReturn<FormType> }) {
  const { isWorkflowEditable, workflow } = useWorkflowContext();
  const [file, setFile] = useState<File>();
  const inputsFieldArray = useFieldArray({
    control: form.control,
    name: "inputs",
  });

  const isNullableEnabled = !!workflow?.settings.isNullableInputsAllowed;
  const fileUploadRef = useRef<{ clearFile: () => void }>(null);

  const deleteRow = (e: any, index: number) => {
    e.stopPropagation();
    inputsFieldArray.remove(index);
  };

  const addRow = (e: any) => {
    inputsFieldArray.append({
      dataType: "text",
      name: "",
      defaultInput: "",
      isNullable: false,
    });
    e.stopPropagation();
  };

  const onFileChange = async (file?: File) => {
    if (!file) return;
    setFile(file);
    const fileType = file.name.split(".").at(-1)?.toLowerCase();
    if (!fileType) return;

    const fileContents = await readFile(file);
    switch (fileType) {
      case "json":
        try {
          if (Array.isArray(JSON.parse(fileContents))) {
            inputsFieldArray.replace(JSON.parse(fileContents));
            notify({
              title: "Success",
              text: "File uploaded successfully",
              type: "success",
            });
          } else {
            notify({ title: "Invalid file", text: "Could not parse JSON" });
          }
        } catch {
          notify({ title: "Invalid file", text: "Could not parse JSON" });
        }
        break;
      case "csv":
        try {
          const isNullable = !!workflow?.settings.isNullableInputsAllowed;
          let data = [];
          if (isNullable)
            data = (parse(fileContents) as [string, string, string, string][])
              .slice(1)
              .map((row) => ({
                name: row[0],
                dataType: row[1],
                isNullable: row[2] === "true",
                defaultInput: row[3],
              }));
          else
            data = (parse(fileContents) as [string, string, string][])
              .slice(1)
              .map((row) => ({
                name: row[0],
                dataType: row[1],
                defaultInput: row[2],
              }));

          for (let index = 0; index < data.length; index++) {
            const row = data[index];
            if (!row || !row.name || !row.dataType) {
              notify({
                title: "Invalid file",
                text: `Could not parse row: ${index + 1}`,
              });
              break;
            }
            if (!["text", "number", "boolean"].includes(row.dataType)) {
              notify({
                title: "Invalid file",
                text: `Invalid type in row: ${index + 1}`,
              });
              break;
            }
          }
          inputsFieldArray.replace(data as FormType["inputs"]);
          notify({
            title: "Success",
            text: "File uploaded successfully",
            type: "success",
          });
        } catch {
          notify({ title: "Invalid file", text: "Could not parse CSV" });
        }
        break;
      default:
        notify({ title: "Invalid file", text: "Upload CSV or JSON file" });
    }
    setFile(undefined);
    fileUploadRef.current?.clearFile();
  };

  const downloadSample = (type: "csv" | "json") => {
    switch (type) {
      case "csv":
        if (workflow?.settings.isNullableInputsAllowed)
          generateAndDownloadFile(
            `name,dataType,nullable,defaultInput
name1,"text",true,"string"
name2,"number",true,"1"
name3,"boolean",true,"false"`,
            "sample_input.csv"
          );
        else
          generateAndDownloadFile(
            `name,dataType,defaultInput
name1,"text","string"
name2,"number","1"
name3,"boolean","false"`,
            "sample_input.csv"
          );
        break;
      case "json":
        if (workflow?.settings.isNullableInputsAllowed)
          generateAndDownloadFile(
            JSON.stringify([
              {
                name: "name1",
                dataType: "text",
                isNullable: true,
                defaultInput: "string",
              },
              {
                name: "name2",
                dataType: "number",
                isNullable: true,
                defaultInput: "1",
              },
              {
                name: "name3",
                dataType: "boolean",
                isNullable: true,
                defaultInput: "true",
              },
            ]),
            "sample_input.json"
          );
        else
          generateAndDownloadFile(
            JSON.stringify([
              {
                name: "name1",
                dataType: "text",
                defaultInput: "string",
              },
              {
                name: "name2",
                dataType: "number",
                defaultInput: "1",
              },
              {
                name: "name3",
                dataType: "boolean",
                defaultInput: "true",
              },
            ]),
            "sample_input.json"
          );
        break;
    }
  };

  return (
    <>
      <table>
        <thead className="flex mb-2 mt-3">
          <tr>
            <th className="pl-3 text-left font-normal text-[12px] w-[159px]">
              Variable
            </th>
            <th className="text-left font-normal text-[12px] w-[99px]">Type</th>
            {isNullableEnabled && (
              <th className="text-left font-normal text-[12px] w-[50px]">
                Nullable
              </th>
            )}
            <th
              className={clsx(
                "text-left font-normal text-[12px] pr-3",
                isWorkflowEditable ? "w-[138px]" : "w-[158px]"
              )}
            >
              Default
            </th>
            {isWorkflowEditable && <th></th>}
          </tr>
        </thead>
        <tbody>
          {inputsFieldArray.fields.map((iv, index) => (
            <Fragment key={iv.id}>
              <tr key={iv.id} className="flex w-full h-[27px] mb-1">
                <td className="pl-3 w-[159px]">
                  <Input
                    disabled={!isWorkflowEditable}
                    placeholder="Enter variable"
                    className="h-[27px] mr-1"
                    inputClassName={
                      form.formState.errors.inputs?.[index]?.name?.message &&
                      "!border-error-500"
                    }
                    {...form.register(`inputs.${index}.name`, {
                      required: {
                        value: true,
                        message: "Name is required",
                      },
                      validate: {
                        requiredTrimSpaces: (value) =>
                          value.trim().length > 0 || "Name is required",
                      },
                    })}
                  />
                </td>
                <td className="w-[99px]">
                  <Controller
                    control={form.control}
                    name={`inputs.${index}.dataType`}
                    defaultValue="text"
                    rules={{
                      required: {
                        value: true,
                        message: "Type is required",
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <Dropdown
                          disabled={!isWorkflowEditable}
                          onChange={field.onChange}
                          className="h-[27px] mr-1"
                        >
                          <Dropdown.Button className="min-w-full text-neutral-black">
                            {field.value ? (
                              field.value
                            ) : (
                              <span className="text-neutral-300">Select</span>
                            )}
                          </Dropdown.Button>
                          <Dropdown.Options>
                            {InputVariableDataTypes.map((o) => (
                              <Dropdown.Option
                                key={o}
                                value={o}
                                className="capitalize"
                              >
                                {o}
                              </Dropdown.Option>
                            ))}
                          </Dropdown.Options>
                        </Dropdown>
                      );
                    }}
                  />
                </td>
                {isNullableEnabled && (
                  <td className="w-[50px]">
                    <input
                      type="checkbox"
                      {...form.register(`inputs.${index}.isNullable`, {
                        validate: (data, formValues) => {
                          if (
                            formValues.inputs[index].defaultInput &&
                            formValues.inputs[index].defaultInput.length > 0 &&
                            !formValues.inputs[index].isNullable
                          ) {
                            return "Input should be nullable when there is a default value";
                          }
                          return true;
                        },
                      })}
                    />
                  </td>
                )}
                <td
                  className={clsx(
                    isWorkflowEditable ? "w-[138px]" : "w-[170px]"
                  )}
                >
                  <Input
                    disabled={!isWorkflowEditable}
                    placeholder="Enter value"
                    className="h-[27px] mr-1"
                    inputClassName={
                      form.formState.errors.inputs?.[index]?.defaultInput
                        ?.message && "!border-error-500"
                    }
                    {...form.register(`inputs.${index}.defaultInput`, {
                      validate: {
                        number: (_, formValues) => {
                          const type = formValues.inputs?.[index].dataType;
                          const input = formValues.inputs?.[index].defaultInput;
                          if (type !== "number" || !input) return true;
                          if (!/\d/.test(input) && input !== "null")
                            return "Default value should be a number or null";
                        },
                        boolean: (_, formValues) => {
                          const type = formValues.inputs?.[index].dataType;
                          const input = formValues.inputs?.[index].defaultInput;
                          if (type !== "boolean" || !input) return true;
                          if (
                            input !== "true" &&
                            input !== "false" &&
                            input !== "null"
                          )
                            return "Default value should be one of true, false or null";
                        },
                      },
                      onChange: (event) => {
                        if (event.target.value.length > 0) {
                          form.setValue(`inputs.${index}.isNullable`, true);
                        }
                      },
                    })}
                  />
                </td>
                {isWorkflowEditable && (
                  <td className="pr-3">
                    <TrashIcon
                      className="h-4 w-4 cursor-pointer [&:hover>path]:stroke-error-500"
                      onClick={(e) => deleteRow(e, index)}
                    />
                  </td>
                )}
              </tr>
              <tr>
                <td
                  className="px-3 text-error-500 font-b2 flex items-center"
                  colSpan={4}
                >
                  {form.formState.errors.inputs?.[index]?.name?.message}
                  {!!form.formState.errors.inputs?.[index]?.dataType?.message &&
                    !!form.formState.errors.inputs?.[index]?.name?.message &&
                    ", "}
                  {form.formState.errors.inputs?.[index]?.dataType?.message}
                  {!!form.formState.errors.inputs?.[index]?.dataType?.message &&
                    !!form.formState.errors.inputs?.[index]?.isNullable
                      ?.message &&
                    ", "}
                  {form.formState.errors.inputs?.[index]?.isNullable?.message}
                  {!!form.formState.errors.inputs?.[index]?.isNullable
                    ?.message &&
                    !!form.formState.errors.inputs?.[index]?.defaultInput
                      ?.message &&
                    ", "}
                  {form.formState.errors.inputs?.[index]?.defaultInput?.message}
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
      {isWorkflowEditable && (
        <div
          className="font-b2-medium cursor-pointer px-3 mt-1 text-neutral-black group/add-expr hover:text-primary-900 w-max flex items-center gap-1 mb-1"
          onClick={(e) => addRow(e)}
        >
          <PlusIcon className="w-4 h-4 group-hover/add-expr:[&>path]:stroke-primary-900 [&>path]:stroke-neutral-black" />
          Add
        </div>
      )}
      <div className="mt-auto w-full px-4 font-b2 mb-4">
        <FileUpload
          ref={fileUploadRef}
          file={file}
          setFile={onFileChange}
          placeholder="Upload a CSV or JSON file"
        />
        Download the{" "}
        <button
          onClick={() => downloadSample("csv")}
          className="text-primary-900 font-medium"
        >
          sample CSV file
        </button>{" "}
        or the{" "}
        <button
          onClick={() => downloadSample("json")}
          className="text-primary-900 font-medium"
        >
          sample JSON file
        </button>
        .
      </div>
    </>
  );
}

type FormType = {
  inputs: Array<{
    dataType: "text" | "number" | "boolean";
    defaultInput: string;
    name: string;
    isNullable: boolean;
  }>;
};

const InputParameters = () => {
  const queryClient = useQueryClient();
  const { workflowId } = useParams();
  const inputListQuery = useGetCustomInputs(workflowId || "", "workflow");
  const { isWorkflowEditable, workflow } = useWorkflowContext();

  const isNullableEnabled = !!workflow?.settings.isNullableInputsAllowed;

  const saveCustomInputMutation = useSaveCustomInputs();

  const form = useForm<FormType>({
    values: {
      inputs: inputListQuery.data?.data as FormType["inputs"],
    },
    shouldUnregister: true,
  });

  const onSave = form.handleSubmit((data) => {
    if (!workflowId) {
      return;
    }

    saveCustomInputMutation.mutate(
      isNullableEnabled
        ? {
            inputs: data.inputs.map((item) => {
              return {
                ...item,
                defaultInput:
                  isNullableEnabled &&
                  item.defaultInput === "" &&
                  item.isNullable
                    ? null
                    : item.defaultInput,
              };
            }),
            policyID: workflowId,
            policyType: "workflow",
          }
        : {
            inputs: data.inputs.map((item) => ({
              ...item,
              isNullable: !!item.defaultInput,
            })),
            policyID: workflowId,
            policyType: "workflow",
          },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(getCustomInputsQuery());
          queryClient.invalidateQueries(getWorkflowKeywordsQuery());
          queryClient.invalidateQueries(getErrors(workflowId));
          notify({
            title: "Saved",
            text: "Workflow inputs updated",
            type: "success",
          });
        },
        onError: (err) => {
          notify({
            title: "Error",
            text: getNetworkErrorText(err),
          });
        },
      }
    );
  });

  const exportInputs = () => {
    if (!inputListQuery.isSuccess) return;
    if (workflow?.settings.isNullableInputsAllowed)
      generateAndDownloadFile(
        `name,dataType,nullable,defaultInput
${inputListQuery.data.data
  .map(
    (item) =>
      `${item.name},${item.dataType},${item.isNullable},${item.defaultInput}`
  )
  .join("\n")}`,
        `${workflow?.name}_inputs.csv`
      );
    else
      generateAndDownloadFile(
        `name,dataType,defaultInput
${inputListQuery.data.data
  .map((item) => `${item.name},${item.dataType},${item.defaultInput}`)
  .join("\n")}`,
        `${workflow?.name}_inputs.csv`
      );
  };
  const handleMarkAllNullable = () => {
    if (form.getValues(`inputs`)?.length > 0) {
      form.getValues(`inputs`).forEach((_, index) => {
        form.setValue(`inputs.${index}.isNullable`, true);
      });
    }
  };

  return (
    <>
      <QueryWrapper query={inputListQuery} loader={<></>}>
        {() => (
          <div
            className={clsx(
              "absolute top-11 items-center flex gap-2",
              isNullableEnabled ? "left-[350px]" : "left-[370px]"
            )}
          >
            {isNullableEnabled && (
              <div
                className="w-6 h-6 ml-4 flex justify-center items-center rounded-md hover:bg-neutral-25 cursor-pointer"
                onClick={handleMarkAllNullable}
              >
                <Tooltip
                  bottom
                  left
                  contentClassName="flex items-center"
                  content={
                    <CheckIcon
                      className="w-4 h-4"
                      onClick={handleMarkAllNullable}
                    />
                  }
                  tooltipContent={
                    <p className="font-b2 text-neutral-black">
                      Mark all as nullable
                    </p>
                  }
                />
              </div>
            )}
            <Button
              onClick={() => exportInputs()}
              variant="outline"
              className="!h-6"
            >
              Export
            </Button>
          </div>
        )}
      </QueryWrapper>
      <QueryWrapper
        query={inputListQuery}
        loader={<Shimmer className="w-[calc(100%-32px)] mx-4 h-[150px] mt-4" />}
      >
        {() => (
          <div className="flex flex-col h-full pb-10 overflow-auto">
            <InputForm form={form} />
          </div>
        )}
      </QueryWrapper>
      <div className="absolute mt-auto bottom-0 w-[479px] flex justify-end py-2 pr-2 bg-neutral-0 border-t border-neutral-100">
        <Button
          onClick={onSave}
          disabled={saveCustomInputMutation.isPending || !isWorkflowEditable}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default InputParameters;
