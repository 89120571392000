import chevronDown from "@assets/icons/chevron-down.svg";
import { Combobox } from "@headlessui/react";
import { usePolicyWorkflowList } from "@hooks/usePolicyWokflowList";
import {
  debounce,
} from "@utils/utils";
import classNames from "clsx";
import { useState } from "react";
import Chip from "src/components/Chip";
import {
  POLICY_STATUS_CHIP_CLASS,
  POLICY_STATUS_MAP,
} from "src/config";
import usePolicyList from "src/screens/policy-dashboard/usePolicyList";
import { PolicyStatus } from "src/types";

import { SelectPolicyProps } from "./HistoricalPolicySelection.types";


export default function HistoricalPolicySelection({ policyID, closeModal, state, dispatch, ...props }: SelectPolicyProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const policyList = usePolicyList({
    page: 1,
    searchQuery: searchQuery,
    enabled: props.type !== "workflow",
    policyStatus: ["active", "inactive", "readytodeploy"],
    startDate: "",
    endDate: "",
  });

  const policyWfList = usePolicyWorkflowList({
    enabled: props.type === "workflow",
    status: ["active", "inactive", "readytodeploy"],
    policy: searchQuery,
  });


  return (
    <div className="mt-4 flex flex-col gap-2">
      <div className="text-neutral-500 font-b2-medium">
        Choose from policies
      </div>
      <Combobox
        as="div"
        className="h-min"
        value={state.policy}
        onChange={(e: string) => {
          const [policy_version] = e?.split?.("^") || "";
          dispatch({
            value: policy_version,
            type: "SetPolicy",
          });
        }}
      >
        <div>
          <Combobox.Button className="flex w-full items-center rounded-r-md focus:outline-none relative">
            <Combobox.Input
              placeholder="Search"
              className="w-full rounded-md border border-gray-300 bg-white py-[7px] pl-3 pr-8  focus:border-primary-700 focus:outline-none focus:ring-1 focus:ring-primary-700 font-b2 "
              onChange={debounce((e) => {
                setSearchQuery(e.target.value);
              })}
              displayValue={(op: string) => {
                return props.type === "workflow"
                  ? policyWfList?.data?.find((i) => i.id === state.policy)
                      ?.version ?? ""
                  : op?.split?.("^")?.[0] || "";
              }}
            />
            <img
              alt=""
              className={"absolute right-3 text-right mr-1"}
              src={chevronDown}
            />
          </Combobox.Button>
          <Combobox.Options className="absolute z-[21] mt-1 max-h-60 w-[645px] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {props.type !== "workflow" &&
              (policyList?.data?.data?.data?.policies || []).map((op) => (
                <Combobox.Option
                  key={op.policyId}
                  value={op.policyVersion}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-pointer select-none py-2 pl-8 pr-4  flex items-center gap-2",
                      active ? "bg-primary-700 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={classNames(
                          "inline-block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {op.policyVersion}
                      </span>
                      {op.status && (
                        <Chip
                          className={classNames(
                            POLICY_STATUS_CHIP_CLASS[
                              op.status as PolicyStatus
                            ],
                            "ml-auto"
                          )}
                          text={POLICY_STATUS_MAP[op.status as PolicyStatus]}
                        />
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            {props.type === "workflow" &&
              (policyWfList?.data || []).map((op) => (
                <Combobox.Option
                  key={op.id}
                  value={op.id}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-pointer select-none py-2 pl-8 pr-4  flex items-center gap-2",
                      active ? "bg-primary-700 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={classNames(
                          "inline-block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {op.version}
                      </span>
                      {op.status && (
                        <Chip
                          className={classNames(
                            POLICY_STATUS_CHIP_CLASS[
                              op.status as PolicyStatus
                            ],
                            "ml-auto"
                          )}
                          text={POLICY_STATUS_MAP[op.status as PolicyStatus]}
                        />
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
}
